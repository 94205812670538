import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const msa = `${HOST}msa`

export default {
  getEmployerMsaById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${msa}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployerDetails(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employerNotFound(error.response.data.return_message)
    })
  },
  sendEmployerMsaForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${msa}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployerMsaPdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${msa}/${id}/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getEmployerMsaTemplateById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${msa}/${id}/update-content`, body).then(response => {
      context.$vs.loading.close()
      context.msaTemplate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  sendMsaUpdatedContentForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${msa}/${id}/sign`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getMsaPdfByIdAndUpdatedContent(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${msa}/${id}/update-content/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
}
