<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Required Document</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer
      ref="documentRules"
      tag="form"
    >
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Parent Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Parent Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Parent Type"
                    rules="required"
                  >
                    <v-select
                      id="parent_type_id"
                      v-model="addRequiredDocument.parent_type_id"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="isEmployee"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Workforce Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Workforce Type"
                    :rules="isEmployee ? 'required' : ''"
                  >
                    <v-select
                      id="workforce_id"
                      v-model="addRequiredDocument.workforce_id"
                      :options="workforce"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Required Document Details</h6>
            </div>

            <div class="repeat-border">
              <div
                v-for="(documentType, index) in document_types"
                :key="index"
                class="d-flex"
              >
                <div class="left w-100">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Document Type</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Document Type' + index"
                          rules="required"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="documentType.document_type_id"
                                :options="documentTypes"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                @input="documentTypeChanged(documentType.document_type_id, index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Document Type' + index, 'Document Type') : ''"
                              />
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Required Document Names</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Required Document Names' + index"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="documentType.required_document_name_ids"
                                :options="documentType.documentNames"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                multiple
                                @input="requiredDocumentNameChanged(index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Required Document Names' + index, 'Required Document Names') : ''"
                              />
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Optional Document Names</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Optional Document Names' + index"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="documentType.optional_document_name_ids"
                                :options="documentType.documentNames"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                multiple
                                @input="optionalDocumentNameChanged(index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Optional Document Names' + index, 'Optional Document Names') : ''"
                              />
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Optional Min No.of</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Optional Min' + index"
                          rules="required|integer"
                        >
                          <b-form-input
                            v-model="documentType.optional_document_count"
                          />
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined ? errors[0].replace('Optional Min' + index, 'Optional Min') : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="right mt-2 ml-1">
                  <b-button
                    v-show="index === 0 && documentTypes.length > document_types.length"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    @click="addNewRequiredDocument"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-show="index !== 0"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                    @click="removeRequiredDocument(index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </app-timeline-item>

        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Required Document
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, VBModal, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import LIST from '@/js/lists'
import ParentDocumentrequired from '@/js/parentDocumentrequired'

export default {
  name: 'DocumentTrackerNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // currency,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      addRequiredDocument: {
        parent_type_id: null,
        workforce_id: null,
        document_types: [],
        optional_document_count: 0,
      },
      isEmployee: false,
      document_types: [],
      documentTypes: [],
      types: [],
      workforce: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRDocumentParentTypes(this)
        LIST.getDocumentTypes(this)
        this.document_types.forEach((documentType, index) => {
          LIST.getDocumentNames(this, documentType.document_type_id, index)
        })
      },
    },
    '$store.state.lists.hrLists.response': {
      immediate: true,
      handler() {
        this.onTypeChanged()
      },
    },
  },
  created() {
    this.addNewRequiredDocument()
    LIST.getHRDocumentParentTypes(this)
    LIST.getDocumentTypes(this)
  },
  methods: {
    submitForm() {
      this.$refs.documentRules.validate().then(result => {
        if (result) {
          this.addRequiredDocument.document_types = []
          this.document_types.forEach(documentType => {
            const newDocumentType = {
              document_type_id: documentType.document_type_id,
              required_document_name_ids: documentType.required_document_name_ids,
              optional_document_name_ids: documentType.optional_document_name_ids,
              optional_document_count: documentType.optional_document_count,
            }
            this.addRequiredDocument.document_types.push(newDocumentType)
          })
          ParentDocumentrequired.addRequiredDocument(this, this.addRequiredDocument)
        }
      })
    },
    onTypeChanged() {
      this.addRequiredDocument.workforce_id = null
      if (this.addRequiredDocument.parent_type_id === null) {
        this.workforce = []
        return
      }
      const employerType = this.types.find(type => type.parent_type_id === this.addRequiredDocument.parent_type_id)
      switch (employerType.id) {
        case 'employee':
          LIST.getHRWorkforcePartners(this)
          this.isEmployee = true
          break
        default:
          this.isEmployee = false
          this.addRequiredDocument.workforce_id = null
          break
      }
    },
    documentTypeChanged(documentTypeId, index) {
      this.document_types[index].required_document_name_ids = []
      this.document_types[index].optional_document_name_ids = []
      if (this.document_types.filter(documentType => documentType.document_type_id === documentTypeId).length > 1) {
        this.document_types[index].document_type_id = null
        return
      }
      LIST.getDocumentNames(this, this.document_types[index].document_type_id, index)
    },
    addNewRequiredDocument() {
      const documentType = {
        document_type_id: null,
        required_document_name_ids: [],
        optional_document_name_ids: [],
        documentNames: [],
        optional_document_count: 0,
      }
      this.document_types.push(documentType)
    },
    removeRequiredDocument(index) {
      this.document_types.splice(index, 1)
    },
    documentRequiredAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentRequiredNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    requiredDocumentNameChanged(index) {
      this.document_types[index].optional_document_name_ids = this.document_types[index].optional_document_name_ids.filter(el => !this.document_types[index].required_document_name_ids.includes(el))
    },
    optionalDocumentNameChanged(index) {
      this.document_types[index].required_document_name_ids = this.document_types[index].required_document_name_ids.filter(el => !this.document_types[index].optional_document_name_ids.includes(el))
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    setEmployeeTypes(workForce) {
      this.workforce = workForce
    },
    setDocumentTypes(documentTypes) {
      this.documentTypes = documentTypes
    },
    setDocumentNames(documentNames, index) {
      this.document_types[index].documentNames = documentNames
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
