import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import money from 'v-money'
import Constant from '@/js/constant'
import axios from 'axios'
import auth from '@/auth/authService'
import User from '@/js/user'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax
import './global-components'
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Vue2Filters from 'vue2-filters'
import * as VeeValidate from 'vee-validate'
import store from './store'
import App from './App.vue'
import router from './router/index'

Vue.use(Vuesax)
extendMoment(Moment)

axios.defaults.baseURL = Constant.hosts.url
axios.defaults.timeout = 18000000
axios.defaults.maxContentLength = 200000000
axios.defaults.maxBodyLength = 200000000

Vue.use(Vue2Filters)
Vue.use(VeeValidate)
Vue.use(money, { precision: 4 })

Vue.filter('toCurrency', value => {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
})

axios.interceptors.request.use(config1 => {
  const config = config1
  let company = window.location.hostname
  company = company.substring(0, company.indexOf('.'))
  config.headers.common.dns = company
  if (store.state.AppActiveUser.access_token !== undefined && !config.url.includes('password')) {
    if (store.state.AppActiveUser.access_token !== null) {
      config.headers.common.Authorization = `Bearer ${store.state.AppActiveUser.access_token}`
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.AppActiveUser.access_token}`
    } else {
      config.headers.common.Authorization = null
      axios.defaults.headers.common.Authorization = null
    }
  } else {
    config.headers.common.Authorization = null
    axios.defaults.headers.common.Authorization = null
  }
  return config
}, error => Promise.reject(error))

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('access_token')
      localStorage.removeItem('lists')
      store.commit('UPDATE_USER_INFO', {})
      store.commit('UPDATE_USER_INFO', {})
      store.commit('HR_LISTS', {})
      store.commit('COMMON_LISTS', {})
      store.commit('AD_LISTS', {})
      store.commit('FA_LISTS', {})
      store.commit('MODULE_LISTS', {})
      store.commit('USER_ROLES', {})
      store.commit('ALERTS_COUNT', {})
      store.commit('UPDATE_COMPANY_NAME', {})
      store.commit('UPDATE_COMPANY_LOGO', {})
      store.state.AppActiveUser = {}
      store.state.lists = {
        commonLists: {}, hrLists: {}, faLists: {}, adLists: {}, moduleLists: {},
      }
      //    this.emit(loginEvent, { loggedIn: false })
      router.push('/login').catch(() => {})
      return Promise.reject(error)
    }
    return Promise.reject(error)
  },
)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (!(from.path.includes(to.path) || to.path.includes(from.path) || to.path.includes('tasks/parent') || from.path.includes('tasks/parent') || to.path.includes('create-task') || from.path.includes('create-task'))) {
    store.state.query = {
      activeTab: 'Monthly', page: 1, month: null, startdate: null, endDate: null, query: '', innerQuery: '', innerpage: 1, parent_type: null, parent_type_id: null, parent_id: null, type: null,
    }
    store.state.padvreq = {
      query: '', page: 1, skill_id: null, secondary_skill_ids: [], resident_state_ids: [], preferred_state_ids: [], min_experience: 0, max_experience: 0,
    }
    store.state.qpadvreq = {
      query: '', page: 1, skills: [], visa_type_ids: [], source_ids: [], status_ids: [], experience: 0, resume: false, linkedin: false, email: false, phone: false, verified: false, relocate: false, w2: false, c2c: false,
    }
  }
  if ((from.path.includes('/prospect-administration/dashboard') && !from.path.includes('/human-resources')) && to.path.includes('/human-resources')) {
    store.state.query = {
      activeTab: 'Monthly', page: 1, month: null, startdate: null, endDate: null, query: '', innerQuery: '', innerpage: 1, parent_type: null, parent_type_id: null, parent_id: null, type: null,
    }
    store.state.padvreq = {
      query: '', page: 1, skill_id: null, secondary_skill_ids: [], resident_state_ids: [], preferred_state_ids: [], min_experience: 0, max_experience: 0,
    }
  }
  if ((to.path.includes('/prospect-administration/dashboard') && !to.path.includes('/human-resources')) && from.path.includes('/human-resources')) {
    store.state.query = {
      activeTab: 'Monthly', page: 1, month: null, startdate: null, endDate: null, query: '', innerQuery: '', innerpage: 1, parent_type: null, parent_type_id: null, parent_id: null, type: null,
    }
    store.state.qpadvreq = {
      query: '', page: 1, skills: [], visa_type_ids: [], source_ids: [], status_ids: [], experience: 0, resume: false, linkedin: false, email: false, phone: false, verified: false, relocate: false, w2: false, c2c: false,
    }
  }
  if (to.path === '/prospect-administration/dashboard') {
    store.state.padvreq = {
      query: '', page: 1, skill_id: null, secondary_skill_ids: [], resident_state_ids: [], preferred_state_ids: [], min_experience: 0, max_experience: 0,
    }
    store.state.qpadvreq = {
      query: '', page: 1, skills: [], visa_type_ids: [], source_ids: [], status_ids: [], experience: 0, resume: false, linkedin: false, email: false, phone: false, verified: false, relocate: false, w2: false, c2c: false,
    }
  }
  if (to.path === '/human-resources/prospect-administration/dashboard') {
    store.state.padvreq = {
      query: '', page: 1, skill_id: null, secondary_skill_ids: [], resident_state_ids: [], preferred_state_ids: [], min_experience: 0, max_experience: 0,
    }
  }
  if (from.path === '/human-resources/quick-prospects/new' && to.path !== '/human-resources/quick-prospects/new') {
    store.state.quickProspectEmail = ''
  }
  if ((to.path === '/vendor-jobs-management/job-requests' && from.path.includes('/vendor-jobs-management/job-requests/prospects')) || (to.path === '/administration/workflow' && from.path.includes('/administration/workflow-tasks'))) {
    store.state.query.innerQuery = ''
    store.state.query.innerpage = 1
  }
  if (auth.isAuthenticated() && (to.path === '/login' || to.path === '/forgot-password')) {
    document.body.className = 'nav-md'
    if (User.isMainAdmin()) {
      next({ path: '/companies' })
    } else {
      next({ path: '/' })
    }
  } if (!auth.isAuthenticated() && (to.path !== '/login' && !to.path.includes('/admin-login/') && !to.path.includes('/msa/') && !to.path.includes('/po/') && !to.path.includes('/ol/') && !to.path.includes('/cl/') && !to.path.includes('/create-password/') && to.path !== '/forgot-password')) {
    next({ path: '/login' })
    document.body.className = 'login'
  } else {
    if (to.path === '/login' || to.path === '/forgot-password' || to.path.includes('/create-password/') || to.path.includes('/msa/') || to.path.includes('/po/') || to.path.includes('/ol/') || to.path.includes('/cl/')) {
      document.body.className = 'login'
    }
    document.body.className = 'nav-md'
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
