var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Invite Job User")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Provider Type")]),_c('validation-provider',{attrs:{"name":"Provider Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"employer_type_id","options":_vm.types,"reduce":function (item) { return item.parent_type_id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.onTypeChanged($event)}},model:{value:(_vm.addInvitation.employer_type_id),callback:function ($$v) {_vm.$set(_vm.addInvitation, "employer_type_id", $$v)},expression:"addInvitation.employer_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Employer Name")]),_c('validation-provider',{attrs:{"name":"Employer Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"employer","options":_vm.employers,"reduce":function (item) { return item; },"clearable":false,"disabled":_vm.addInvitation.employer_type_id == null,"placeholder":"Select"},on:{"input":function($event){return _vm.onEmployerChanged()}},model:{value:(_vm.employer),callback:function ($$v) {_vm.employer=$$v},expression:"employer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Contact Type")]),_c('validation-provider',{attrs:{"name":"Contact Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contact_type_id","options":_vm.contactTypes,"label":"contact_type","reduce":function (item) { return item.contact_type_id; },"clearable":false,"disabled":_vm.addInvitation.employer_id === null,"placeholder":"Select"},model:{value:(_vm.addInvitation.contact_type_id),callback:function ($$v) {_vm.$set(_vm.addInvitation, "contact_type_id", $$v)},expression:"addInvitation.contact_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-xxl-4",attrs:{"cols":"12","md":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Send Notification Before")]),_c('v-select',{attrs:{"id":"invitaion_before","options":_vm.days,"reduce":function (item) { return item.id; },"clearable":true,"placeholder":"Select"},model:{value:(_vm.addInvitation.invitaion_before),callback:function ($$v) {_vm.$set(_vm.addInvitation, "invitaion_before", $$v)},expression:"addInvitation.invitaion_before"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Send Notification After")]),_c('v-select',{attrs:{"id":"invitaion_after","options":_vm.days,"reduce":function (item) { return item.id; },"clearable":true,"placeholder":"Select"},model:{value:(_vm.addInvitation.invitaion_after),callback:function ($$v) {_vm.$set(_vm.addInvitation, "invitaion_after", $$v)},expression:"addInvitation.invitaion_after"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status_id","options":_vm.status,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addInvitation.status_id),callback:function ($$v) {_vm.$set(_vm.addInvitation, "status_id", $$v)},expression:"addInvitation.status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"mt-md-2",attrs:{"id":"send_notification"},model:{value:(_vm.addInvitation.send_notification),callback:function ($$v) {_vm.$set(_vm.addInvitation, "send_notification", $$v)},expression:"addInvitation.send_notification"}},[_vm._v(" Send Invitation ")])],1)])],1)],1)],1),_c('app-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.addInvitation.user_defined_fields.length !== 0),expression:"addInvitation.user_defined_fields.length !== 0"}],staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("User Defined Fields")])]),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.addInvitation.user_defined_fields),function(field){return _c('b-col',{key:field.id,attrs:{"id":field.id,"cols":"12","md":"6","lg":"4"}},[(field.type.toLowerCase() === 'input')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'dropdown')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":field.options,"label":"option","reduce":function (item) { return item.option; },"clearable":!field.required,"placeholder":"Select"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'textarea')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Add Job Invitation ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }