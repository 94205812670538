<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Interviewer</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Interviewer Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Interviewer Name</label>
              <span
                class="form-control h-auto"
                v-text="interviewer.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Email Address</label>
              <span
                class="form-control h-auto"
                v-text="interviewer.email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Phone</label>
              <span
                class="form-control"
                v-text="interviewer.phone"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Status</label>
              <span
                class="form-control"
                v-text="interviewer.status"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Time Slots</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Start Date</label>
              <span
                class="form-control"
                v-text="interviewer.start_date"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>End Date</label>
              <span
                class="form-control"
                v-text="interviewer.end_date"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Timings From</label>
              <span
                class="form-control"
                v-text="interviewer.available_start_time"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Timings To</label>
              <span
                class="form-control"
                v-text="interviewer.available_end_time"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Duration</label>
              <span
                class="form-control"
                v-text="interviewer.duration"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Recurring</label>
              <span
                class="form-control"
                v-text="interviewer.recurring"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="showWeeks"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Week Days</label>
              <span
                class="form-control"
                v-text="interviewer.week_days"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Block Time From</label>
              <span
                class="form-control"
                v-text="interviewer.block_start_time"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Block Time To</label>
              <span
                class="form-control"
                v-text="interviewer.block_end_time"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Weekends</label>
              <span class="form-control h-auto">
                <span
                  v-for="(weekend, index) in interviewer.weekends"
                  :key="index"
                  :value="index"
                >
                  <b-badge
                    title="name"
                    variant="light-primary"
                    class="line-height-inherit my-25 mr-1"
                  >
                    <span>{{ weekend }}</span>
                  </b-badge>
                </span>
              </span>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Holidays</label>
              <span class="form-control h-auto">
                <span
                  v-for="(holiday, index) in interviewer.holidays"
                  :key="index"
                  :value="index"
                >
                  <b-badge
                    title="name"
                    variant="light-primary"
                    class="line-height-inherit my-25 mr-1"
                  >
                    <span>{{ holiday }}</span>
                  </b-badge>
                </span>
              </span>
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Communication Mode Details</h6>
        </div>
        <b-row
          v-for="(communicationInfo, index) in interviewer.communications"
          :key="index"
          :value="index"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Type</label>
              <span
                class="form-control"
                v-text="communicationInfo.type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Value</label>
              <span
                class="form-control"
                v-text="communicationInfo.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-50">
          </b-col>
        </b-row>
        <div
          v-if="interviewer.communications.length == 0"
          class="text-center"
        >
          <p> No Communication Mode Details Available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Interviewer Skills</h6>
        </div>
        <div class="d-flex mt-1">
          <h5 class="mb-2 ml-2">
            Main Areas
          </h5>
        </div>
        <b-row>
          <b-col
            cols="12"
            class="pl-5"
          >
            <b-row>
              <b-col
                v-for="(skill, index) in interviewer.skills"
                :key="index"
                :value="index"
                cols="6"
                md="3"
                class="col-xxl-2 mb-1"
              >
                <b-form-checkbox
                  :checked="index"
                  :value="index"
                  disabled
                  class="custom-control-primary"
                >
                  {{ skill }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <div
              v-if="interviewer.skills.length == 0"
              class="text-center"
            >
              <p> No Skills Available </p>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Vacation Dates</h6>
        </div>
        <b-row
          v-for="(vacationInfo, index) in interviewer.vacations"
          :key="index"
          :value="index"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>From</label>
              <span
                class="form-control"
                v-text="vacationInfo.vacation_start_date_text"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>To</label>
              <span
                class="form-control"
                v-text="vacationInfo.vacation_end_date_text"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>All Day</label>
              <span
                class="form-control"
                v-text="vacationInfo.is_all_day ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="!vacationInfo.is_all_day"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Duration From</label>
              <span
                class="form-control"
                v-text="vacationInfo.vacation_start_time"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="!vacationInfo.is_all_day"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Duration To</label>
              <span
                class="form-control"
                v-text="vacationInfo.vacation_end_time"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-50">
          </b-col>
        </b-row>
        <div
          v-if="interviewer.vacations.length == 0"
          class="text-center"
        >
          <p> No Vacation Dates Available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="interviewer.user_defined_fields.length !== 0"
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in interviewer.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Interviewer from '@/js/interviewers'

export default {
  name: 'InterviewerView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      interviewer: {},
    }
  },
  mounted() {
  },
  created() {
    Interviewer.getInterviewerById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setInterviewer(interviewer) {
      this.interviewer = interviewer
    },
    back() {
      this.$router.push('/administration/interviewers').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
