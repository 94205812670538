import Dashboard from '@/views/vendor-jobs-management/Dashboard.vue'
import JobRequests from '@/views/vendor-jobs-management/job-requests/JobRequests.vue'
import JobRequestNew from '@/views/vendor-jobs-management/job-requests/actions/New.vue'
import JobRequestEdit from '@/views/vendor-jobs-management/job-requests/actions/Edit.vue'
import JobRequestView from '@/views/vendor-jobs-management/job-requests/actions/View.vue'
import Prospects from '@/views/vendor-jobs-management/job-requests/actions/Prospects.vue'
import VendorRecruiters from '@/views/vendor-jobs-management/vendor-recruiters/VendorRecruiters.vue'
import VendorRecruitersNew from '@/views/vendor-jobs-management/vendor-recruiters/actions/New.vue'
import VendorRecruitersEdit from '@/views/vendor-jobs-management/vendor-recruiters/actions/Edit.vue'
import VendorRecruitersView from '@/views/vendor-jobs-management/vendor-recruiters/actions/View.vue'
import MainSkills from '@/views/vendor-jobs-management/main-skills/MainSkills.vue'
import MainSkillsNew from '@/views/vendor-jobs-management/main-skills/actions/New.vue'
import MainSkillsEdit from '@/views/vendor-jobs-management/main-skills/actions/Edit.vue'
import MainSkillsView from '@/views/vendor-jobs-management/main-skills/actions/View.vue'
import SecondarySkills from '@/views/vendor-jobs-management/secondary-skills/SecondarySkills.vue'
import SecondarySkillsNew from '@/views/vendor-jobs-management/secondary-skills/actions/New.vue'
import SecondarySkillsEdit from '@/views/vendor-jobs-management/secondary-skills/actions/Edit.vue'
import SecondarySkillsView from '@/views/vendor-jobs-management/secondary-skills/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Vendor Jobs Management -------------------------------*
  // *===============================================---*
  // *------------ Dashobard --------------------*
  {
    path: '/vendor-jobs-management/dashboard',
    name: 'vendor-jobs-management-dashboard',
    component: Dashboard,
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  // *------------ Job Requests --------------------*
  {
    path: '/vendor-jobs-management/job-requests',
    name: 'vendor-jobs-management-job-requests',
    component: JobRequests,
    meta: {
      navActiveLink: 'vendor-jobs-management-job-requests',
    },
  },
  {
    path: '/vendor-jobs-management/job-requests/new',
    name: 'vendor-jobs-management-job-requests-new',
    component: JobRequestNew,
    meta: {
      navActiveLink: 'vendor-jobs-management-job-requests',
    },
  },
  {
    path: '/vendor-jobs-management/job-requests/edit/:id',
    name: 'vendor-jobs-management-job-requests-edit',
    component: JobRequestEdit,
    meta: {
      navActiveLink: 'vendor-jobs-management-job-requests',
    },
  },
  {
    path: '/vendor-jobs-management/job-requests/view/:id',
    name: 'vendor-jobs-management-job-requests-view',
    component: JobRequestView,
    meta: {
      navActiveLink: 'vendor-jobs-management-job-requests',
    },
  },
  {
    path: '/vendor-jobs-management/job-requests/prospects/:id',
    name: 'vendor-jobs-management-job-requests-prospects',
    component: Prospects,
    meta: {
      navActiveLink: 'vendor-jobs-management-job-requests',
    },
  },
  // *------------ Vendor Recruiters --------------------*
  {
    path: '/vendor-jobs-management/vendor-recruiters',
    name: 'vendor-jobs-management-vendor-recruiters',
    component: VendorRecruiters,
    meta: {
      navActiveLink: 'vendor-jobs-management-vendor-recruiters',
    },
  },
  {
    path: '/vendor-jobs-management/vendor-recruiters/new',
    name: 'vendor-jobs-management-vendor-recruiters-new',
    component: VendorRecruitersNew,
    meta: {
      navActiveLink: 'vendor-jobs-management-vendor-recruiters',
    },
  },
  {
    path: '/vendor-jobs-management/vendor-recruiters/edit/:id',
    name: 'vendor-jobs-management-vendor-recruiters-edit',
    component: VendorRecruitersEdit,
    meta: {
      navActiveLink: 'vendor-jobs-management-vendor-recruiters',
    },
  },
  {
    path: '/vendor-jobs-management/vendor-recruiters/view/:id',
    name: 'vendor-jobs-management-vendor-recruiters-view',
    component: VendorRecruitersView,
    meta: {
      navActiveLink: 'vendor-jobs-management-vendor-recruiters',
    },
  },
  // *------------ Main Skill --------------------*
  {
    path: '/vendor-jobs-management/main-skills',
    name: 'vendor-jobs-management-main-skills',
    component: MainSkills,
    meta: {
      navActiveLink: 'vendor-jobs-management-main-skills',
    },
  },
  {
    path: '/vendor-jobs-management/main-skills/new',
    name: 'vendor-jobs-management-main-skills-new',
    component: MainSkillsNew,
    meta: {
      navActiveLink: 'vendor-jobs-management-main-skills',
    },
  },
  {
    path: '/vendor-jobs-management/main-skills/edit/:id',
    name: 'vendor-jobs-management-main-skills-edit',
    component: MainSkillsEdit,
    meta: {
      navActiveLink: 'vendor-jobs-management-main-skills',
    },
  },
  {
    path: '/vendor-jobs-management/main-skills/view/:id',
    name: 'vendor-jobs-management-main-skills-view',
    component: MainSkillsView,
    meta: {
      navActiveLink: 'vendor-jobs-management-main-skills',
    },
  },
  // *------------ Secondary Skill --------------------*
  {
    path: '/vendor-jobs-management/secondary-skills',
    name: 'vendor-jobs-management-secondary-skills',
    component: SecondarySkills,
    meta: {
      navActiveLink: 'vendor-jobs-management-secondary-skills',
    },
  },
  {
    path: '/vendor-jobs-management/secondary-skills/new',
    name: 'vendor-jobs-management-secondary-skills-new',
    component: SecondarySkillsNew,
    meta: {
      navActiveLink: 'vendor-jobs-management-secondary-skills',
    },
  },
  {
    path: '/vendor-jobs-management/secondary-skills/edit/:id',
    name: 'vendor-jobs-management-secondary-skills-edit',
    component: SecondarySkillsEdit,
    meta: {
      navActiveLink: 'vendor-jobs-management-secondary-skills',
    },
  },
  {
    path: '/vendor-jobs-management/secondary-skills/view/:id',
    name: 'vendor-jobs-management-secondary-skills-view',
    component: SecondarySkillsView,
    meta: {
      navActiveLink: 'vendor-jobs-management-secondary-skills',
    },
  },
]
