<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <h4 class="card-title mb-0 pt-50">
          View Employer</h4>
      </b-col>
      <b-col
        cols="12"
        md="8 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-dropdown class="mr-1" variant="primary" right text="Generate Document">
            <b-dropdown-item >
              <b-button
                variant="primary"
                :to="{ name: 'human-resources-employers-msa', params: { id: $route.params.id }}"
              >
                Generate MSA
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item >
              <b-button
                variant="primary"
                :to="{ name: 'human-resources-employers-po', params: { id: $route.params.id }}"
              >
                Generate PO
              </b-button>
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            variant="primary"
            :to="{ name: 'parent-task-view', params: { parent_type_id: 2, parent_id: $route.params.id }}"
            class="mr-1"
          >
            Tasks
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="employer.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Payment Terms
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.payment_terms_days"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Allow employer to submit timesheets
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.allow_timesheets ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Contact Information</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.contact_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="employer.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="employer.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="employer.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.address.state.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Federal Tax Id
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.federal_tax_id"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Additional Contact Information</h6>
        </div>
        <b-row
          v-for="(contact_info, index) in employer.contact_information"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.contact_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contact_info.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contact_info.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contact_info.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.state"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
          <b-col cols="12">
            <hr class="my-0">
          </b-col>
        </b-row>
        <div
          v-if="employer.contact_information.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No additional contact information available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Bank Details</h6>
        </div>
        <b-row
          v-if="employer.bank !== null"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Bank Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Bank ACC#
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.acct_no"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Swift Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.swift_code"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Routing No
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.routing_no"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="employer.bank.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="employer.bank.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.address.state.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employer.bank.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
        <div
          v-if="employer.bank === null"
          class="text-center"
        >
          <p class="mb-0">
            No bank details available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th>Issuer</th>
                    <th>Name</th>
                    <th>Version</th>
                    <th class="th-minw-125">Upload Date</th>
                    <th class="th-minw-110">Exp Date</th>
                    <th>Uploaded By</th>
                    <th>Reminder</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(priviousVersion, index) in employer.documents"
                    :key="index"
                  >
                    <td>{{ priviousVersion.document_type }}</td>
                    <td>{{ priviousVersion.issuer }}</td>
                    <td>{{ priviousVersion.document_name }}</td>
                    <td>{{ priviousVersion.version }}</td>
                    <td>{{ priviousVersion.uploaded_date }}</td>
                    <td>{{ priviousVersion.exp_date }}</td>
                    <td>{{ priviousVersion.uploaded_by }}</td>
                    <td>{{ priviousVersion.reminder }}</td>
                    <td>
                      <b-link
                        target="_blank"
                        :href="priviousVersion.document_path"
                      >
                        <b-badge
                          variant="primary"
                          class="mr-1 mb-50"
                        >
                          <span>{{ priviousVersion.document }}</span>
                        </b-badge>
                      </b-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <div
          v-if="employer.documents.length == 0"
          class="text-center"
        >
          <p> No Documents available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="primary"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in employer.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="employer.comments.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Comments available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="employer.user_defined_fields.length !== 0"
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in employer.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Employer from '@/js/employers'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BDropdown, BDropdownItem, BFormTextarea, BBadge, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'EmployerVeiw',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      employer: {},
    }
  },
  mounted() {
  },
  created() {
    Employer.getEmployerById(this, this.$route.params.id)
  },
  methods: {
    setEmployer(employer) {
      this.employer = employer
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
