import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const h1b = `${HOST}h1b`

export default {
  getH1bUsers(context1) {
    const context = context1
    const params = { params: { status_id: context.$store.state.query.status_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(h1b, params).then(response => {
      context.setH1bUsers(response.data)
    }, () => {
    })
  },
  addH1bUsers(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(h1b, body).then(response => {
      context.$vs.loading.close()
      context.h1bUserAdded(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.h1bUserNotAdded(error.response.data.return_message)
    })
  },
  updateH1bUsers(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${h1b}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.h1bUserUpdated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.h1bUserNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${h1b}/${id}`).then(response => {
      context.$vs.loading.close()
      context.h1bUserDeleted(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.h1bUserNotDeleted(error.response.data.return_message)
    })
  },
  resend(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${h1b}/resend/${id}`).then(response => {
      context.$vs.loading.close()
      context.resended(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.notResended(error.response.data.return_message)
    })
  },
  printPdf(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${h1b}/${id}/pdf`).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getH1bUserById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${h1b}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setH1buser(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setH1buserNotFound(error.response.data.return_message)
    })
  },
  endProcessByH1bId(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.put(`${h1b}/end-process/${id}`, data).then(response => {
      context.$vs.loading.close()
      context.endProcessUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.endProcessNotUpdated(error.response.data.return_message)
    })
  },
  updateH1bTask(context1, id, body, index) {
    const context = context1
    context.$vs.loading()
    axios.put(`${h1b}/task/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.h1bTaskUpdated(response.data, index)
    }, error => {
      context.$vs.loading.close()
      context.h1bTaskNotUpdated(error.response.data.return_message)
    })
  },
}
