<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            H-1B Administration</h4>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <v-select
              id="status_id"
              v-model="status_id"
              :options="status"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Status"
              @input="statusChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
          class="col-xxl-6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-130"
              @click="showForm()"
            >
              Send Form
            </b-button>
          </div>
          <b-modal
            ref="sendForm"
            cancel-variant="outline-secondary"
            modal-class="modal-primary"
            ok-title="Send"
            cancel-title="Close"
            centered
            title="Send Form"
            :no-close-on-backdrop="true"
            @hide="closingFormPopup"
          >
            <validation-observer
              ref="newh1b"
              tag="form"
            >
              <b-form>
                <b-form-group>
                  <label for="email">Email:</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="sendFormRequest.email_address"
                      type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <div class="d-flex justify-content-between w-100">
                <b-button
                  variant="outline-primary"
                  class="px-1"
                  @click="closingFormPopup()"
                >
                  Clear
                </b-button>
                <b-button
                  variant="primary"
                  class="ml-1 px-1"
                  @click="sendForm()"
                >
                  Send
                </b-button>
              </div>
            </template>
          </b-modal>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="h1bResponse.h1b_users"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Resend -->
            <span v-if="props.column.field === 'cellRendererResendButton'">
              <b-button
                variant="primary"
                type="button"
                class="py-50 px-1"
                @click="resendEmail(props.row)"
              >
                Resend
              </b-button>
              <span class="d-block mt-25">{{ props.row.resent_date }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.row.status)"
                class="custom-color"
              >
                {{ props.row.status }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'intrested'">
              <b-badge
                :variant="IntrestVariant(props.row.intrested)"
                class="custom-color"
              >
                {{ props.row.intrested }}
              </b-badge>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'last_activity'">
              <span class="d-block mt-25">{{ props.row.last_activity }}</span>
              <span class="d-block mt-25">{{ props.row.last_activity_date }}</span>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none pl-0"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'administration-h-1b-administration-edit', params: { id: props.row.id } }">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="printPdf(props.row)">
                    <feather-icon icon="FileIcon" />
                    <span class="ml-50">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-link
                  id="view"
                  :to="{ name: 'administration-h-1b-administration-view', params: { id: props.row.id } }"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="h1bResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BPagination, BForm, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BLink, BBadge, BButton, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import H1b from '@/js/h1b'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'

export default {
  name: 'H1bAdministration',
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BLink,
    BBadge,
    BButton,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      searchQuery: '',
      pageLength: 10,
      dir: false,
      sendFormRequest: {
        email_address: '',
      },
      h1bResponse: {
        h1b_users: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Email',
          field: 'email_address',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
        },
        {
          label: 'Resend',
          field: 'cellRendererResendButton',
          width: '115px',
          sortable: false,
        },
        {
          label: 'Last Activity',
          field: 'last_activity',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Current Stage',
          field: 'current_activity',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '125px',
          sortable: false,
        },
        {
          label: 'Intrested',
          field: 'intrested',
          width: '125px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      status_id: null,
      status: [],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        H1b.getH1bUsers(this)
      },
    },
    statusVariant() {
      const statusColor = {
        Submitted: 'light-primary',
        Approved: 'light-success',
        Rejected: 'light-danger',
        Terminated: 'light-warning',
      }

      return status => statusColor[status]
    },
    IntrestVariant() {
      const intrestColor = {
        Yes: 'light-primary',
        No: 'light-warning',
      }

      return intrest => intrestColor[intrest]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getH1UserStatus(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.status_id = this.$store.state.query.status_id
    this.h1bResponse.count = this.$store.state.query.page
    LIST.getH1UserStatus(this)
    H1b.getH1bUsers(this)
  },
  methods: {
    sendForm() {
      this.$refs.newh1b.validate().then(result => {
        if (result) {
          H1b.addH1bUsers(this, this.sendFormRequest)
        }
      })
    },
    resendEmail(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to resend mail to this ${obj.email_address}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, resend it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          H1b.resend(this, obj.id)
        }
      })
    },
    printPdf(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to print this ${obj.email_address}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, print it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          H1b.printPdf(this, obj.id)
        }
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.email_address}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          H1b.delete(this, obj.id)
        }
      })
    },
    closingFormPopup() {
      this.sendFormRequest = {
        email_address: '',
      }
    },
    pdfPath(data) {
      window.open(data.pdf_path, '_blank')
    },
    pdfNotFound(message) {
      this.$swal({
        icon: 'warning',
        title: 'Resend!',
        text: message,
      })
    },
    showForm() {
      this.sendFormRequest.email_address = ''
      this.$refs.sendForm.show()
    },
    statusChanged() {
      this.$store.state.query.status_id = this.status_id
      this.currentPage = 1
    },
    setStatus(status) {
      this.status = status
    },
    resended(message) {
      H1b.getH1bUsers(this)
      this.$swal({
        icon: 'success',
        title: 'Resend!',
        text: message,
      })
    },
    notResended(message) {
      this.$swal({
        icon: 'warning',
        title: 'Resend!',
        text: message,
      })
    },
    h1bUserAdded(message) {
      this.$refs.sendForm.hide()
      this.currentPage = 1
      this.$swal({
        icon: 'success',
        title: 'H1b user Added!',
        text: message,
      })
    },
    h1bUserNotAdded(message) {
      this.$swal({
        icon: 'warning',
        title: 'H1b user Not Added!',
        text: message,
      })
    },
    h1bUserDeleted(message) {
      H1b.getH1bUsers(this)
      this.$swal({
        icon: 'success',
        title: 'H1b user Deleted!',
        text: message,
      })
    },
    h1bUserNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'H1b user Not Deleted!',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setH1bUsers(h1bResponse) {
      this.h1bResponse = h1bResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
