<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View H-1B Administration</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>First Name</label>
              <span
                class="form-control"
                v-text="h1bResponse.first_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Middle Name</label>
              <span
                class="form-control"
                v-text="h1bResponse.middle_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Last Name</label>
              <span
                class="form-control"
                v-text="h1bResponse.last_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Email</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.email_address"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Phone</label>
              <span
                class="form-control"
                v-text="h1bResponse.phone_number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>H-1B/I-94 Expiration Date</label>
              <span
                class="form-control"
                v-text="h1bResponse.expiration_date"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Education (Field of Study)</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.education"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Bachelor's</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.bachelors"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Master's (if applicable)</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.masters"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Is the foreign national currently employed with your company?</label>
              <span
                class="form-control"
                v-text="h1bResponse.is_currently_employeed ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>If yes, is the work location the same as the previous petition?</label>
              <span
                class="form-control"
                v-text="h1bResponse.is_work_location_previous_location ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Proposed/Current Salary</label>
              <span class="form-control">{{ h1bResponse.salary | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Employee’s Job Title</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.job_title"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>In-house at employer’s own location</label>
              <span
                class="form-control"
                v-text="h1bResponse.is_employer_location ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Client location – please provide the following</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.client_location"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>End-user’s name, full address with street, city, state, and zip code</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.end_user_details"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Middle vendor (if applicable)</label>
              <span
                class="form-control"
                v-text="h1bResponse.middle_vendor"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group>
              <label>Detailed description of job duties/responsibilities (can be attached as a separate document)</label>
              <b-form-textarea
                v-model="h1bResponse.description"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Do you have a spouse and/or child currently in the US who need H4?</label>
              <span
                class="form-control"
                v-text="h1bResponse.need_h4 ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Do you have a green card petition in process?</label>
              <span
                class="form-control"
                v-text="h1bResponse.green_card_petitions ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Status</label>
              <span
                class="form-control"
                v-text="h1bResponse.status"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Linkedin Url</label>
              <span
                class="form-control h-auto"
                v-text="h1bResponse.linkedin_url"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Reference</h6>
        </div>
        <b-row
          v-for="reference in h1bResponse.referrers"
          :key="reference"
          class="border-b-repeat"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>First name</label>
              <span
                class="form-control"
                v-text="reference.first_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Last Name</label>
              <span
                class="form-control"
                v-text="reference.last_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Company</label>
              <span
                class="form-control h-auto"
                v-text="reference.company"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Type</label>
              <span
                class="form-control"
                v-text="reference.type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Official Email</label>
              <span
                class="form-control h-auto"
                v-text="reference.email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Phone Number</label>
              <span
                class="form-control"
                v-text="reference.phone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0">
          </b-col>
        </b-row>
        <div
          v-if="h1bResponse.referrers.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No References available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Workflow Tasks</h6>
        </div>
        <b-tabs class="wizard-tabs">
          <b-tab
            v-for="(task, index) in h1bResponse.tasks"
            :key="index"
            :value="index"
            :active="task.active"
            :disabled="task.disabled"
            :before-change="validationForm"
            @click="tabSelected(index)"
          >
            <template #title>
              <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'wizard-icon-circle md checked square_shape' : 'wizard-icon-circle md square_shape'">
                <span class="wizard-icon-container square_shape">
                  <span class="wizard-icon">{{ index + 1 }}</span>
                </span>
              </span>
              <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'stepTitle checked' : 'stepTitle'">{{ task.name }}</span>
            </template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ task.name }}
                </h5>
              </b-col>
              <b-col
                v-for="(field, fieldIndex) in task.fields"
                :key="fieldIndex"
                :value="fieldIndex"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>{{ field.name }}</label>
                  <span
                    class="form-control h-auto"
                    v-text="field.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <hr class="mt-0">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Workflow Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="h1bResponse.process_status"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row v-if="h1bResponse.documents!==null">
          <b-col cols="12">
            <b-link
              v-for="option in h1bResponse.documents"
              :key="option.id"
              :value="option.id"
              :href="option.attachment_link"
              :title="option.name"
              target="_blank"
              :class="{'mt-75': option}"
            >
              <b-badge
                variant="primary"
                class="mr-1 mb-50"
              >
                <span>{{ option.name }}</span>
              </b-badge>
            </b-link>
          </b-col>
        </b-row>
        <div
          v-if="h1bResponse.documents.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Documents available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in h1bResponse.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="h1bResponse.comments.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Comments available
          </p>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormTextarea, BBadge, BButton, BLink, BTabs, BTab,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import H1b from '@/js/h1b'

export default {
  name: 'H1bAdministrationView',
  components: {
    BCard,
    BLink,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BTabs,
    BTab,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      h1bResponse: {},
    }
  },
  mounted() {
  },
  created() {
    H1b.getH1bUserById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setH1buser(h1bResponse) {
      this.h1bResponse = h1bResponse
    },
    setH1buserNotFound(message) {
      this.back()
      this.$swal({
        icon: 'warning',
        title: 'H1b user Not Found!',
        text: message,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
