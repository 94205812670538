import axios from 'axios'
import Constants from '@/js/constant'
import Lists from '@/js/lists'

const HOST = Constants.hosts.vjmsUrl
const mainSkill = `${HOST}vendor-job/main-skill`

export default {
  getMainSkills(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(mainSkill, params).then(response => {
      context.setMainSkills(response.data)
    }, () => {
    })
  },
  addMainSkill(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(mainSkill, body).then(response => {
      context.$vs.loading.close()
      const { skills } = context.$store.state.lists.commonLists.lists
      skills.push({ id: response.data.id, label: body.skill_name, name: body.skill_name })
      const { commonLists } = context.$store.state.lists
      commonLists.lists.skills = skills
      context.$store.commit('COMMON_LISTS', commonLists)
      context.mainSkillAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.mainSkillNotAdded(error.response.data.return_message)
    })
  },
  updateMainSkill(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${mainSkill}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.mainSkillUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.mainSkillNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${mainSkill}/${id}`).then(response => {
      context.$vs.loading.close()
      context.mainSkillDeleted(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.mainSkillNotDeleted(error.response.data.return_message)
    })
  },
  getMainSkillById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${mainSkill}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setMainSkill(response.data)
    }, error => {
      context.$vs.loading.close()
      context.mainSkillNotFound(error.response.data.return_message)
    })
  },

}
