<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row class="mb-md-2">
        <b-col
          cols="12"
          md="4"
        >
          <h4 class="card-title mb-0 w-75 pt-50">
            Account Payables</h4>
        </b-col>
        <!-- <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <v-select
              id="status_id"
              v-model="status_id"
              :options="status"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Status"
              @input="statusChanged"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          md="3 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="invoicesResponse.invoices"
            :rtl="direction"
            :search-options="{
              enabled: false }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >

            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Currency -->
              <span v-if="props.column.field === 'cellRendererCurrency'">
                <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: invoice -->
              <span v-else-if="props.column.field === 'invoice'">
                <b-link
                  v-if="props.row.type === 'subvendor'"
                  id="edit"
                  :to="{ name: 'finance-and-accounting-invoices-from-subvendors-edit', params: { id: props.row.id } }"
                >
                  {{ props.row.invoice }}
                </b-link>
                <b-link
                  v-if="props.row.type === 'referrer'"
                  id="edit"
                  :to="{ name: 'finance-and-accounting-invoices-from-referrers-edit', params: { id: props.row.id } }"
                >
                  {{ props.row.invoice }}
                </b-link>
                <b-link
                  v-if="props.row.type === 'employer'"
                  id="edit"
                  :to="{ name: 'finance-and-accounting-invoices-from-employers-edit', params: { id: props.row.id } }"
                >
                  {{ props.row.invoice }}
                </b-link>
                <b-link
                  v-if="props.row.type === 'suppliers'"
                  id="edit"
                  :to="{ name: 'finance-and-accounting-invoices-from-suppliers-edit', params: { id: props.row.id } }"
                >
                  {{ props.row.invoice }}
                </b-link>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status)"
                  class="custom-color"
                >
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="invoicesResponse.count"
                    first-number
                    last-number
                    align="right"
                    :per-page="1"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Financialstatement from '@/js/financialstatement'
import LIST from '@/js/lists'

export default {
  name: 'AccountPayables',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BLink,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      status_id: null,
      invoicesResponse: {
        invoice: [],
        count: 1,
      },
      status: [],
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Invoice#',
          field: 'invoice',
          sortable: false,
        },
        {
          label: 'Receiver',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Name Of Consultant',
          field: 'consultants',
          sortable: false,
        },
        {
          label: 'Period',
          field: 'period',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Paid Date',
          field: 'paid_date',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Financialstatement.getAccountPayables(this, this.$route.query.year)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Submitted  : 'light-primary',
        Approved   : 'light-success',
        Rejected   : 'light-danger',
        Corrected  : 'light-primary',
        Paid       : 'light-success',
        Partially  : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getInvoiceEmployersStatusFullList(this)
      },
    },
  },
  created() {
    this.invoicesResponse.count = this.$store.state.query.page
    Financialstatement.getAccountPayables(this, this.$route.query.year)
    LIST.getInvoiceEmployersStatusFullList(this)
  },
  methods: {
    setAccountPayables(invoicesResponse) {
      this.invoicesResponse = invoicesResponse
    },
    statusChanged() {
      this.currentPage = 1
    },
    setStatus(status) {
      this.status = status
    },
    back() {
      this.$router.push('/financial-statements/dashboard').catch(() => {})
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-color.badge-secondary {
    background-color: rgba(40, 199, 111, 0.12);
    color: #28c76f !important;
}
</style>
