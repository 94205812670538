<template>
  <div>
    <b-card
      class="ex-large mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <h4 class="card-title mb-0 pt-50">
              Prospects</h4>
          </b-col>
          <b-col
            cols="12"
            md="8 ml-auto"
            class="col-xxl-6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search"
                  @keyup.enter="updateSearchQuery"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="updateSearchQuery"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="14"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-button
                variant="outline-primary"
                :to="{ name: 'human-resources-prospect-administration-dashboard'}"
                class="ml-2"
              >
                Back
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="prospectsResponse.prospects"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="btn-action"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none pl-0"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- <b-dropdown-item :to="{ name: 'human-resources-prospects-edit', params: { skillid: $route.params.skillid, id: props.row.id } }">
                  <feather-icon icon="Edit2Icon" />
                  <span class="ml-50">Edit</span>
                </b-dropdown-item> -->
                <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                  <feather-icon icon="TrashIcon" />
                  <span class="ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
              <b-link
                id="view"
                :to="{ name: 'human-resources-prospect-administration-prospects-view', params: { skillid: $route.params.skillid, id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-primary"
                ><feather-icon icon="EyeIcon" />
                </b-badge>
              </b-link>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="prospectsResponse.count"
                first-number
                last-number
                align="right"
                :per-page="1"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Prospects from '@/js/prospects'

export default {
  name: 'ProspectsList',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      prospectsResponse: {
        prospects: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Email Address',
          field: 'email_addreess',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone_number',
          sortable: false,
        },
        {
          label: 'Main Skill',
          field: 'main_skill',
          sortable: false,
        },
        {
          label: 'Experience',
          field: 'experience',
          width: '115px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Prospects.getProspectsBySkillList(this, this.$route.params.skillid)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.status_id = this.$store.state.query.status_id
    this.prospectsResponse.count = this.$store.state.query.page
    Prospects.getProspectsBySkillList(this, this.$route.params.skillid)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Prospects.delete(this, obj.id)
        }
      })
    },
    prospectDeleted() {
      Prospects.getProspectsBySkillList(this, this.$route.params.skillid)
      this.$swal({
        icon: 'success',
        title: 'Prospect Deleted!',
        text: 'Your Prospect has been deleted',
      })
    },
    prospectNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Prospect Not Deleted!',
        text: 'Your Prospect has been not deleted',
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    openResume(path) {
      window.open(path, '_blank')
    },
    setProspects(prospectsResponse) {
      this.prospectsResponse = prospectsResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
