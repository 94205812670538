<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Quick Prospect</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <b-form-input
                    id="name"
                    v-model="addProspect.name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="addProspect.email"
                      :state="errors.length > 0 ? false:null"
                      @blur="emailChanged"
                      @keyup.enter="emailChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-show="invalidEmail"
                      class="text-danger"
                    >
                      {{ emailError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="integer|digits:10"
                  >
                    <b-form-input
                      id="phone"
                      v-model="addProspect.phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Experience</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required"
                  >
                    <b-input-group append="Years">
                      <b-form-input
                        v-model="addProspect.experience"
                        type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
                v-for="(skill, index) in addProspect.skills"
                :id="index"
                :key="index"
              >
                <div class="d-flex">
                  <b-row class="w-100">
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label>Main Skill</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Skill Name' + index"
                          rules="required"
                        >
                          <v-select
                            id="role"
                            v-model="skill.skill_id"
                            :options="skills"
                            :reduce="item => item.id"
                            @input="skillIdMatcher(skill.skill_id, index)"
                            :clearable="false"
                            placeholder="Select"
                          />
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined ? errors[0].replace('Skill Name' + index, 'Skill Name') : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <div class="d-flex">
                        <div class="left w-100">
                          <b-form-group>
                            <label>Years of Exp</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Years of Exp' + index"
                              rules="required"
                            >
                              <b-input-group append="Years">
                                <b-form-input
                                  v-model="skill.experience"
                                  type="number"
                                />
                              </b-input-group>
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Years of Exp' + index, 'Years of Exp') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </div>
                        <div class="right text-right mt-2 ml-1">
                          <b-button
                            v-if="index === 0"
                            variant="outline-primary"
                            class="btn-icon rounded-circle"
                            @click="addSkill"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                          <b-button
                            v-if="index !== 0"
                            variant="outline-danger"
                            class="btn-icon rounded-circle"
                            @click="removeSkill(index)"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Resume</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Resume"
                    :rules="selectedFile === null && addProspect.document.document === null ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <input
                        ref="myFileInput"
                        type="file"
                        placeholder="Choose a file or drop it here..."
                        class="form-control text-left"
                        @change="attahamentFileSelected"
                      >
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Relocate</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="addProspect.is_relocate"
                      name="is_relocate_yes"
                      value="true"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="addProspect.is_relocate"
                      name="is_relocate_no"
                      value="false"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Visa Type</label>
                  <v-select
                    id="visa_type"
                    v-model="addProspect.visa_type_id"
                    :options="visaTypes"
                    :reduce="item => item.id"
                    :clearable="true"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>W2/C2C</label>
                  <v-select
                    id="w2_c2c"
                    v-model="addProspect.w2_c2c"
                    :options="w2C2C"
                    :reduce="item => item.id"
                    :clearable="true"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="showExistingSource">
                  <label>Source</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Source"
                    :rules="showExistingSource ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <v-select
                          id="source"
                          v-model="addProspect.source_id"
                          :options="sources"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          @click="addNewSource"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="showNewSource">
                  <label>Source</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Source"
                    :rules="showNewSource ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-input
                          id="skill_name"
                          v-model="addProspect.source"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="showExistingSources"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Linkedin</label>
                  <b-form-input
                    id="linkedin"
                    v-model="addProspect.linkedin"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-2"
              >
                <b-form-group>
                  <b-form-checkbox
                    v-model="addProspect.is_verified"
                  >
                    Verified
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-for="(comment, index) in addProspect.comments"
                :key="index"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Notes</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Quick Prospect
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormRadio, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'
import QuickProspect from '@/js/quickProspect'
import Constants from '@/js/constant'
import moment from 'moment'

export default {
  name: 'QuickProspectNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      addProspect: {
        name: '',
        email: '',
        phone: '',
        is_relocate: false,
        experience: null,
        visa_type_id: null,
        w2_c2c_id: null,
        source_id: null,
        source: '',
        linkedin: '',
        is_verified: false,
        comments: [],
        skills: [],
        document: {
          document: null,
        },
      },
      showExistingSource: true,
      showNewSource: false,
      invalidEmail: false,
      emailError: '',
      selectedFile: null,
      skills: [],
      visaTypes: [],
      w2C2C: [],
      sources: [],
      validEmailAddress: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getVisaFullList(this)
        LIST.getSkillsFullList(this)
        LIST.getQuickProspectSources(this)
      },
    },
  },
  created() {
    LIST.getSkillsFullList(this)
    LIST.getVisaFullList(this)
    LIST.getQuickProspectSources(this)
    this.addComment()
    this.addSkill()
    Constants.getW2C2c(this)
    this.validEmailAddress.push(this.$store.state.quickProspectEmail)
    this.addProspect.email = this.$store.state.quickProspectEmail
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result && !this.invalidEmail) {
          if (this.selectedFile !== null) {
            this.addProspect.document = {
              attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
            }
            this.selectedFile = null
            this.url = null
          }
          if (this.addProspect.document.document === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose resume',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          this.showLoading = true
          QuickProspect.addProspect(this, this.addProspect)
        }
      })
    },
    emailChanged() {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

      if (this.addProspect.email.toLowerCase().match(validRegex)) {
        if (!this.validEmailAddress.includes(this.addProspect.email)) {
          QuickProspect.getProspectByEmail(this, this.addProspect.email)
        } else {
          this.invalidEmail = false
          this.emailError = ''
        }
      }
    },
    setProspectNotExist() {
      this.invalidEmail = false
      this.emailError = 'Email already exist'
      this.validEmailAddress.push(this.addProspect.email)
    },
    skillIdMatcher(skillId, index) {
      if (this.addProspect.skills.filter(skill => skill.skill_id === skillId).length > 1) {
        this.addProspect.skills[index].skill_id = null
      }
    },
    addSkill() {
      const skillItem = {
        skill_id: null,
        experience: 0,
      }
      this.addProspect.skills.push(skillItem)
    },
    removeSkill(position) {
      this.addProspect.skills.splice(position, 1)
    },
    setProspectExist() {
      this.invalidEmail = true
      this.emailError = 'Email already exist'
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    addFile() {
      if (this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.selectedFile !== null) {
        this.addProspect.document = {
          attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        }
        this.selectedFile = null
        this.url = null
        this.$refs.myFileInput.value = ''
      }
    },
    setQuickProspectSources(sources) {
      this.sources = sources
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    setSkills(skills) {
      this.skills = skills
    },
    prospectAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      LIST.getCommonList(this)
    },
    prospectNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
    addNewSource() {
      this.addProspect.source_id = null
      this.showExistingSource = false
      this.showNewSource = true
    },
    showExistingSources() {
      this.addProspect.source = ''
      this.showExistingSource = true
      this.showNewSource = false
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addProspect.comments.push(comment)
    },
    removeComment(index) {
      this.addProspect.comments.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
