<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <h4 class="card-title mb-0 pt-50">
          Candidate Edit for {{ addCandidate.title }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="4 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Candidate Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      v-model="addCandidate.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      v-model="addCandidate.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="addCandidate.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      v-model="addCandidate.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-link
                  class="btn mt-2 btn-outline-primary"
                  :to="{ name: 'human-resources-prospects-edit', params: { id: addCandidate.prospect_id } }"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add Additional Info</span>
                </b-link>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Workflow Tasks</h6>
            </div>
            <b-tabs class="wizard-tabs">
              <b-tab
                v-for="(task, index) in addCandidate.tasks"
                :key="index"
                :value="index"
                :active="task.active"
                :disabled="task.disabled"
                @click="tabSelected(index)"
              >
                <template #title>
                  <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'wizard-icon-circle md checked square_shape' : 'wizard-icon-circle md square_shape'">
                    <span class="wizard-icon-container square_shape">
                      <span class="wizard-icon">{{ index + 1 }}</span>
                    </span>
                  </span>
                  <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'stepTitle checked' : 'stepTitle'">{{ task.name }}</span>
                </template>
                <validation-observer ref="tasks">
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        {{ task.name }}
                      </h5>
                    </b-col>
                    <b-col
                      v-for="(field, fieldIndex) in task.fields"
                      :key="fieldIndex"
                      :value="fieldIndex"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group
                        v-if="field.type === 'Text'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <b-form-input
                            :v-model="task.fields[fieldIndex].value"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'DropDown'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <v-select
                            v-model="task.fields[fieldIndex].value"
                            :options="field.options"
                            label="option"
                            :clearable="!(field.required && task.active)"
                            :reduce="item => item.option"
                            placeholder="Select"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'TextArea'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <b-form-textarea
                            v-model="task.fields[fieldIndex].value"
                            rows="2"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'Date'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <flat-pickr
                            v-model="task.fields[fieldIndex].value"
                            class="form-control"
                            :config="startDateConfig"
                            :placeholder="'MM-DD-YYYY'"
                            :clear-button="true"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'Time'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <flat-pickr
                            v-model="task.fields[fieldIndex].value"
                            class="form-control"
                            :config="timeConfig"
                            :placeholder="'HH:mm a'"
                            :clear-button="true"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="task.name.toLowerCase().includes('interview')"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-link
                        class="btn mt-2 btn-outline-primary"
                        @click="scheduleInterview()"
                      >
                        <span>Schedule Interview</span>
                      </b-link>
                    </b-col>
                  </b-row>
                  <hr class="my-50">
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        v-if="index !== 0"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="previous(index)"
                      >
                        Previous
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-center
                        variant="outline-danger"
                        class="ml-1"
                        @click="endWorkFlow(index)"
                      >
                        End Workflow
                      </b-button>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-flex align-items-center justify-content-end text-left">
                        <b-button
                          v-if="index + 1 < addCandidate.tasks.length"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="next(index)"
                        >
                          Next
                        </b-button>
                        <b-button
                          v-if="index + 1 === addCandidate.tasks.length"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="next(index)"
                        >
                          Complete Workflow
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
            <b-modal
              id="modal-center"
              ref="endprocess"
              centered
              title="Reject Reason"
              ok-only
              ok-title="End Process"
              :no-close-on-backdrop="true"
              @ok="endProcess()"
            >
              <b-form-group v-if="showList">
                <label>Reason</label>
                <div class="d-flex">
                  <div class="left w-100">
                    <v-select
                      id="reason_id"
                      v-model="reason_id"
                      :options="reasons"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                  </div>
                  <div class="right ml-50">
                    <b-button
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addNewReason"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="showName">
                <label>Reason</label>
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-input
                      id="reason_name"
                      v-model="reason"
                    />
                  </div>
                  <div class="right ml-50">
                    <b-button
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="showListOption"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-modal>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row class="mt-1">
              <b-col cols="12">
                <div class="table-responsive">
                  <table class="table b-table table-th-pd table-td-pd">
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th>Issuer</th>
                        <th>Name</th>
                        <th>Version</th>
                        <th class="th-minw-125">Upload Date</th>
                        <th class="th-minw-110">Exp Date</th>
                        <th>Uploaded By</th>
                        <th>Reminder</th>
                        <th>Document</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(priviousVersion, index) in addCandidate.documents"
                        :key="index"
                      >
                        <td>{{ priviousVersion.document_type }}</td>
                        <td>{{ priviousVersion.issuer }}</td>
                        <td>{{ priviousVersion.document_name }}</td>
                        <td>{{ priviousVersion.version }}</td>
                        <td>{{ priviousVersion.uploaded_date }}</td>
                        <td>{{ priviousVersion.exp_date }}</td>
                        <td>{{ priviousVersion.uploaded_by }}</td>
                        <td>{{ priviousVersion.reminder }}</td>
                        <td>
                          <b-link
                            target="_blank"
                            :href="priviousVersion.document_path"
                          >
                            <b-badge
                              variant="primary"
                              class="mr-1 mb-50"
                            >
                              <span>{{ priviousVersion.document }}</span>
                            </b-badge>
                          </b-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <div
              v-if="addCandidate.documents.length == 0"
              class="text-center"
            >
              <p> No Documents available </p>
            </div>
          </app-timeline-item>

          <app-timeline-item
            v-show="addCandidate.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addCandidate.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Candidates
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BTabs, BTab, BModal, VBModal, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Candidate from '@/js/candidates'
import Constants from '@/js/constant'
import moment from 'moment'
import LIST from '@/js/lists'

export default {
  name: 'CandidateEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BButton,
    flatPickr,
    BBadge,
    BTabs,
    BTab,
    BModal,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      addCandidate: {
        job_id: null,
        employer_id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        skype_id: '',
        date_of_birth: null,
        submitted_date: null,
        interviews: [],
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      url: null,
      tabIndex: null,
      status_id: null,
      result_id: null,
      reason_id: null,
      showList: true,
      showName: false,
      documentNameId: null,
      selectedFile: null,
      date_of_birth: null,
      status: [],
      results: [],
      reasons: [],
      documentNames: [],
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'h:i K',
        altFormat: 'h:i K',
        defaultHour: new Date().getHours(),
        defaultMinute: new Date().getMinutes(),
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: moment(new Date()).format('MM-DD-YYYY'),
      },
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        end: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getPtaskStatus(this)
        LIST.getPtaskResults(this)
        LIST.getProcessReasons(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getProspectsDocumentNameFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addCandidate.job_id = this.$route.params.job_id
    this.addComment()
    LIST.getPtaskStatus(this)
    LIST.getPtaskResults(this)
    LIST.getProspectsDocumentNameFullList(this)
    LIST.getProcessReasons(this)
    Candidate.getCandidateById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.tasks.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    previous(index) {
      this.addCandidate.tasks[index].active = false
      this.addCandidate.tasks[index - 1].active = true
    },
    next(index) {
      return new Promise((resolve, reject) => {
        this.$refs.tasks[index].validate().then(result => {
          if (result) {
            resolve(true)
            const request = {
              fields: this.addCandidate.tasks[index].fields,
              status_id: this.addCandidate.tasks[index].status_id,
              result_id: this.addCandidate.tasks[index].result_id,
            }
            Candidate.updateCandidateTask(this, this.addCandidate.tasks[index].task_id, request, index)
          } else {
            reject()
          }
        })
      })
    },
    endWorkFlow(index) {
      this.tabIndex = index
      this.$refs.endprocess.show()
    },
    endProcess() {
      if ((this.reason === '' || this.reason === null || this.reason === undefined) && this.reason_id === null) {
        this.$swal({
          title: 'Error!',
          text: 'Please Select Reason',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        const request = {
          reason: this.reason,
          reason_id: this.reason_id,
          fields: this.addCandidate.tasks[this.tabIndex].fields,
          status_id: this.addCandidate.tasks[this.tabIndex].status_id,
          result_id: this.addCandidate.tasks[this.tabIndex].result_id,
        }
        Candidate.endProcessByJobCandidateId(this, this.addCandidate.tasks[this.tabIndex].task_id, request)
      }
    },
    addNewReason() {
      this.reason_id = null
      this.showList = false
      this.showName = true
    },
    showListOption() {
      this.showList = true
      this.showName = false
      this.reason = ''
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.addCandidate.job_id = this.$route.params.job_id
          if (this.date_of_birth != null) {
            this.addCandidate.date_of_birth = Constants.getEprmMonthDateFromString(this.date_of_birth)
          }
          for (let i = 0; i < this.addCandidate.interviews.length; i += 1) {
            this.addCandidate.interviews[i].date = Constants.getEprmMonthDateFromString(this.addCandidate.interviews[i].date_text)
          }
          this.showLoading = true
          Candidate.updateCandidate(this, this.$route.params.id, this.addCandidate)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      if (file.id == null) {
        this.addCandidate.documents.splice(position, 1)
      } else {
        this.deleteConfirm(file, position)
      }
    },
    deleteConfirm(obj, position) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addCandidate.documents.splice(position, 1)
        }
      })
    },
    tabSelected(index) {
      for (let i = 0; i < this.addCandidate.tasks.length; i += 1) {
        if (!this.addCandidate.tasks[i].disabled) {
          this.addCandidate.tasks[i].active = false
        }
      }
      this.addCandidate.tasks[index].active = true
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addCandidate.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setTechnologies(technologies) {
      this.technologies = technologies
    },
    setPtaskStatus(status) {
      this.status = status
    },
    setPtaskResults(results) {
      this.results = results
    },
    setProcessReasons(reasons) {
      this.reasons = reasons
    },
    setCandidate(candidate) {
      this.addCandidate = candidate
      if (candidate.address == null) {
        this.addCandidate.address = {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: '',
        }
      }
      if (this.addCandidate.comments.length === 0) {
        this.addComment()
      }
      this.date_of_birth = candidate.date_of_birth
    },
    scheduleInterview() {
      this.$store.state.scheduleCandidateInteview.prospectId = this.addCandidate.prospect_id
      this.$router.push('/human-resources/interviews/new').catch(() => {})
    },
    candidateTaskUpdated(data, index) {
      if (index + 1 < this.addCandidate.tasks.length) {
        this.addCandidate.tasks[index].active = false
        this.addCandidate.tasks[index + 1].disabled = false
        this.addCandidate.tasks[index + 1].active = true
      } else {
        this.back()
      }
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    candidateTaskNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    endProcessUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    endProcessNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    candidateUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        html: Constants.getParentSuccessMessage(data.return_message),
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: Constants.getUploadButtonText(),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push(`/documents/document-tracker/new?parent_id=${data.id}&parent_type_id=${data.parent_type_id}`).catch(() => {})
        }
      })
    },
    candidateNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        // date: Constants.getSnapPmsDateFromString(moment()),
        // time: moment().format('hh:mm a')
      }
      this.addCandidate.comments.push(comment)
    },
    removeComment(index) {
      this.addCandidate.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  transition: .35s height;
}
</style>
