<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Role User</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <span
                    class="form-control"
                    v-text="addRoleuser.name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <span
                    class="form-control h-auto"
                    v-text="addRoleuser.email"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>User Type</label>
                  <span
                    class="form-control"
                    v-text="addRoleuser.type"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="addRoleuser.type.toLowerCase() === 'admin'">
              <b-col cols="12">
                <b-alert
                  variant="primary"
                  show
                >
                  <div class="alert-body">
                    <span><strong>All Roles Selected</strong></span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="addRoleuser.type.toLowerCase() === 'internal'"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Select Roles</h6>
            </div>
            <b-row
              v-for="(departmentDevision, index) in Object.keys(addRoleuser.devisions)"
              :key="index"
              :value="index"
              class="mt-1"
            >
              <b-col cols="12">
                <b-form-checkbox
                  v-model="addRoleuser.devisions[departmentDevision].selected"
                  class="custom-control-primary mb-1"
                  @input="departmentSelected(departmentDevision)"
                >
                  {{ departmentDevision }}
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                class="pl-5"
              >
                <b-row v-show="addRoleuser.devisions[departmentDevision].selected">
                  <b-col
                    v-for="(devision, devisionIndex) in addRoleuser.devisions[departmentDevision]"
                    :key="devisionIndex"
                    :value="devisionIndex"
                    cols="3"
                  >
                    <b-form-checkbox
                      v-model="devision.selected"
                      class="custom-control-primary mb-1"
                      @input="devisionSelected(departmentDevision, devisionIndex, devision.selected)"
                    >
                      {{ devision.name }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addRoleuser.user_defined_fields.length !== 0"
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addRoleuser.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Role User
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BFormCheckbox, BAlert, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import CryptoJS from 'crypto-js'
import RoleUser from '@/js/roleusers'

export default {
  name: 'RoleUserEdit',
  components: {
    vSelect,
    BCard,
    ValidationObserver,
    ValidationProvider,
    AppTimeline,
    AppTimelineItem,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BAlert,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addRoleuser: {
        name: '',
        email: '',
        type: '',
        fa_expenses_devision: false,
        bs_devision: false,
        do_devision: false,
        st_devision: false,
        ta_devision: false,
        rc_devision: false,
        ji_devision: false,
        ii_devision: false,
        ti_devision: false,
        mc_devision: false,
        cc_devision: false,
        pd_devision: false,
        im_devision: false,
        mt_devision: false,
        cv_devision: false,
        cr_devision: false,
        cp_devision: false,
        cs_devision: false,
        ce_devision: false,
        am_devision: false,
        ae_devision: false,
        ai_devision: false,
        mp_devision: false,
        me_devision: false,
        mi_devision: false,
        reports_devision: false,
        forcast_devision: false,
        fa_vendor_devision: false,
        fa_referrer_devision: false,
        fa_supplier_devision: false,
        fa_subvendor_devision: false,
        fa_employer_devision: false,
        fa_timesheet_devision: false,
        hr_jobs_devision: false,
        hr_exception_devision: false,
        hr_interviews_devision: false,
        hr_onboarding_devision: false,
        hr_endclients_devision: false,
        hr_vendor_devision: false,
        hr_referrer_devision: false,
        hr_supplier_devision: false,
        hr_subvendor_devision: false,
        hr_employer_devision: false,
        hr_prospect_devision: false,
        hr_consultant_devision: false,
        hr_employee_devision: false,
        vjms_db_division: false,
        vjms_jr_division: false,
        vjms_vr_division: false,
        vjms_ms_division: false,
        vjms_ss_division: false,
        user_defined_fields: [],
      },
    }
  },
  mounted() {
  },
  created() {
    RoleUser.getRoleuserById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.addRoleuser.password = encodeURIComponent(CryptoJS.MD5(this.password).toString())
          if (!this.addRoleuser.reports_devision) {
            this.addRoleuser.pd_devision = false
            this.addRoleuser.im_devision = false
            this.addRoleuser.mt_devision = false
            this.addRoleuser.cv_devision = false
            this.addRoleuser.cr_devision = false
            this.addRoleuser.cp_devision = false
            this.addRoleuser.cs_devision = false
            this.addRoleuser.ce_devision = false
            this.addRoleuser.am_devision = false
            this.addRoleuser.ae_devision = false
            this.addRoleuser.ai_devision = false
            this.addRoleuser.mp_devision = false
            this.addRoleuser.me_devision = false
            this.addRoleuser.mi_devision = false
          }
          RoleUser.updateRoleuser(this, this.$route.params.id, this.addRoleuser)
        }
      })
    },
    devisionSelected(departmentDevision, devision, selected) {
      if (selected) {
        this.addRoleuser.devisions[departmentDevision][devision].selected = true
      } else {
        this.addRoleuser.devisions[departmentDevision][devision].selected = false
        this.addRoleuser.devisions[departmentDevision].selected = false
        Object.keys(this.addRoleuser.devisions[departmentDevision]).forEach(key => {
          if (key !== 'selected') {
            if (this.addRoleuser.devisions[departmentDevision][key].selected) {
              this.addRoleuser.devisions[departmentDevision].selected = true
            }
          }
        })
      }
    },
    departmentSelected(departmentDevision) {
      Object.keys(this.addRoleuser.devisions[departmentDevision]).forEach(devision => {
        if (devision !== 'selected') {
          if (this.addRoleuser.devisions[departmentDevision].selected) {
            this.addRoleuser.devisions[departmentDevision][devision].selected = true
          } else {
            this.addRoleuser.devisions[departmentDevision][devision].selected = false
          }
        }
      })
    },
    // onTypeChanged() {
    //   if (this.addRoleuser.type !== null) {
    //   }
    // },
    setRoleuser(data) {
      this.addRoleuser = data
      Object.keys(this.addRoleuser.devisions).forEach(key => {
        this.addRoleuser.devisions[key].selected = false
        Object.keys(this.addRoleuser.devisions[key]).forEach(devision => {
          if (this.addRoleuser.devisions[key][devision].selected === true) {
            this.addRoleuser.devisions[key].selected = true
          }
        })
      })
    },
    roleUserUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    roleUserNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
