import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const prospect = `${HOST}prospect`

export default {
  getProspects(context1) {
    const context = context1
    const params = { params: { status_id: context.$store.state.query.status_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(prospect, params).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  getProspectsBySkillList(context1, skillId) {
    const context = context1
    const params = { params: { skill_id: skillId, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(prospect, params).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  getProspectsAdvancedSearch(context1, request) {
    const context = context1
    const body = request
    body.page = context.$store.state.query.page - 1
    axios.post(`${prospect}/advanced-search`, body).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  getJobProspects(context, jobId) {
    const params = {
      params: {
        query: context.$store.state.query.innerQuery, page: context.$store.state.query.innerpage - 1, user_id: context.employer_id, type_id: context.employer_type_id,
      },
    }
    axios.get(`${prospect}/job/${jobId}`, params).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  addProspect(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(prospect, body).then(response => {
      context.$vs.loading.close()
      context.prospectAdded(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.prospectNotAdded(error.response.data.return_message)
    })
  },
  updateProspect(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${prospect}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.prospectUpdated(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.prospectNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${prospect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.prospectDeleted(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.prospectNotDeleted(error.response.data.return_message)
    })
  },
  getProspectById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${prospect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setProspect(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotFound(error.response.data.return_message)
    })
  },

}
