<template>
  <b-card
    class="mb-0"
  >
    <div
      v-if="!isNextClicked"
      class="mb-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Invoices To Vendors</h4>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <flat-pickr
              v-model="month"
              class="form-control"
              :config="$store.state.monthConfig"
              placeholder="Select Month"
              :clear-button="true"
              @on-change="monthDateChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              v-model="vendor"
              :options="employees"
              label="name"
              :reduce="item => item"
              :clearable="false"
              placeholder="Select Vendor"
              @input="onVendorChanged()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="5"
          class="col-xxl-4"
        >
          <b-alert
            variant="warning"
            show
          >
            <div
              v-if="vendorInvoicesResponse.reject_exist"
              class="alert-body mb-0"
            >
              <span>You have one or more rejected invoices.</span>
            </div>
          </b-alert>
        </b-col>
        <b-col
          cols="12"
          md="7 ml-auto"
          class="col-xxl-6"
        >
          <div class="business-card mb-1">
            <div class="business-items">
              <div class="business-item">
                <div class="budget-wrapper">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="mr-50">Bank Balance:</span>
                    <h2 class="mb-25 mr-2 text-primary">
                      {{ vendorInvoicesResponse.account_balance | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                    </h2>
                    <span class="mr-1">as of {{ vendorInvoicesResponse.balance_date }}</span>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      @click="fetchBalance()"
                    >
                      <feather-icon
                        size="21"
                        icon="RefreshCwIcon"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="vgt_custom__footer"
        >
          <vue-good-table
            :columns="columns"
            :rows="vendorInvoicesResponse.invoices"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: vendorId !== null,
              selectOnCheckboxOnly: vendorId !== null, // only select when checkbox is clicked instead of the row
              disableSelectInfo: vendorId !== null, // disable the select info panel on top
              selectAllByGroup: vendorId !== null, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
            }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            @on-selected-rows-change="selectionChanged"
          >
            <template slot="table-actions-bottom">
              <!-- <table
                v-if="vendorInvoicesResponse.show_total_amount && !(vendorInvoicesResponse.invoices.length !== 0 && page === 0)"
                class="vgt-table bordered"
              > -->
              <table
                class="vgt-table bordered"
              >
                <tfoot>
                  <tr>
                    <td class="text-right">
                      Total
                    </td>
                    <td
                      style="width:160px"
                    >
                      {{ vendorInvoicesResponse.total_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Currency -->
              <span v-if="props.column.field === 'cellRendererCurrency'">
                <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'cellRendererConsultant'">
                <span>{{ props.row.consultants }}</span>
                <b-badge
                  v-if="props.row.reject_exist"
                  variant="light-warning"
                  class="ml-1"
                >
                  This invoice is rejected
                </b-badge>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status)"
                  class="custom-color"
                >
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Mail Sent -->
              <span v-else-if="props.column.field === 'cellRendererMailSent'">
                <span v-if="props.row.mail_sent">
                  <feather-icon
                    v-b-tooltip.hover.v-success
                    icon="CheckSquareIcon"
                    size="16"
                    :title="props.row.message"
                    class="align-middle text-success"
                  />  {{ props.row.sent_date }}
                </span>
                <span v-if="!props.row.mail_sent">
                  <feather-icon
                    v-b-tooltip.hover.v-danger
                    icon="AlertTriangleIcon"
                    size="16"
                    :title="props.row.message"
                    class="align-middle text-danger"
                  /> {{ props.row.sent_date }}
                </span>
              </span>

              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-action"
              >
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none pl-0"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item :to="{ name: 'finance-and-accounting-invoices-to-vendors-edit', params: { id: props.row.id } }">
                      <feather-icon icon="Edit2Icon" />
                      <span class="ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-link
                    id="view"
                    :to="{ name: 'finance-and-accounting-invoices-to-vendors-view', params: { id: props.row.id } }"
                  >
                    <b-badge
                      pill
                      variant="light-primary"
                    ><feather-icon icon="EyeIcon" />
                    </b-badge>
                  </b-link>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <b-row
                v-show="consultants.length > 0"
                class="mt-1"
              >
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="sendEmail"
                  >
                    Send Email
                  </b-button>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="vendorInvoicesResponse.count"
                    first-number
                    last-number
                    align="right"
                    :per-page="1"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="pageChanged(value)"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>

    <!-- Send Email -->
    <div
      v-if="isNextClicked && isEmail"
      class="mb-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Invoices To Vendors</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-form-group
                    label="To"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <v-select
                        v-model="mass_communication.to_emails"
                        :options="vendor.emails"
                        multiple
                        :clearable="false"
                        placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Subject"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Subject"
                      rules="required"
                    >
                      <b-form-input
                        v-model="mass_communication.subject"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Body"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Body"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="mass_communication.message"
                        rows="12"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Signature"
                    class="text-left"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Signature"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="mass_communication.signature"
                        rows="2"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="ml-2">
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="submitForm"
                  >
                    Send
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BForm, BPagination, BFormTextarea, BFormInput, BFormGroup, BDropdown, BDropdownItem, BButton, BLink, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import moment from 'moment'
import VendorInvoices from '@/js/invoicesvendor'
import LIST from '@/js/lists'
import 'flatpickr/dist/flatpickr.css'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'InvoicesVendors',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormInput,
    BFormGroup,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    flatPickr,
    BAlert,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      month: null,
      vendorId: null,
      employees: [],
      selectedInvoices: [],
      isNextClicked: false,
      isMessage: false,
      isEmail: false,
      consultants: [],
      mass_communication: {
        vendor_id: null,
        subject: '',
        message: '',
        to_emails: [],
        signature: `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`,
        invoice_ids: [],
      },
      vendorInvoicesResponse: {
        invoices: [],
        count: 1,
        account_balance: 0.00,
        balance_date: '',
      },
      columns: [
        {
          label: 'Invoice#',
          field: 'number',
          sortable: false,
        },
        {
          label: 'Name Of Consultant',
          field: 'cellRendererConsultant',
          sortable: false,
        },
        {
          label: 'Period',
          field: 'period',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Sent Date',
          field: 'cellRendererMailSent',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '145px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Paid Date',
          field: 'paid_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      submitted: false,
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M, Y',
              altFormat: 'F, Y',
            }),
          ],
        },
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        VendorInvoices.getInvoices(this, this.vendorId)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Submitted   : 'light-primary',
        Corrected   : 'light-info',
        Approved    : 'light-success',
        Paid        : 'light-success',
        Rejected    : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getMassCommunicationVendors(this)
      },
    },
  },
  created() {
    if (this.$store.state.query.month === null) {
      this.month = moment().subtract(1, 'months').format('MMM-YYYY')
      this.$store.state.query.month = this.month
    } else {
      this.month = this.$store.state.query.month
    }
    this.vendor = this.$store.state.query.vendor
    if (this.vendor !== null && this.vendor !== undefined) {
      this.vendorId = this.vendor.id
    }
    this.vendorInvoicesResponse.count = this.$store.state.query.page
    LIST.getMassCommunicationVendors(this)
    if (this.vendorId !== null) {
      this.selectedInvoices = []
      this.consultants = []
    }
    VendorInvoices.getInvoices(this, this.vendorId)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          VendorInvoices.sendInvoices(this, this.mass_communication)
        }
      })
    },
    fetchBalance() {
      LIST.getBalance(this)
    },
    setBalance(balanceResponse) {
      this.vendorInvoicesResponse.account_balance = balanceResponse.balance
      this.vendorInvoicesResponse.balance_date = balanceResponse.date
    },
    sendEmail() {
      if (!this.submitted) {
        let consultantNames = this.consultants.join(',')
        const MCM = 'Please find the attached invoices for: \nPeriod:'
        const IF = 'Invoice for'
        consultantNames = consultantNames.split(',').join('\n')
        this.mass_communication.to_emails = []
        this.mass_communication.vendor_id = this.vendorId
        this.mass_communication.invoice_ids = this.selectedInvoices
        this.mass_communication.message = `${MCM}${this.month}\nConsultants\n${consultantNames}`
        this.mass_communication.subject = `${IF} ${this.month}`
        this.isNextClicked = true
        this.isMessage = false
        this.isEmail = true
        this.submitted = true
      }
    },
    creatError(message) {
      this.submitted = false
      this.massSubmitted = false
      this.$swal({
        icon: 'warning',
        title: 'Email Sent',
        text: message,
      })
      this.$dialog.alert(message)
    },
    created(message) {
      this.$swal({
        icon: 'success',
        title: 'Email Sent',
        text: message,
      })
      this.massSubmitted = false
      this.selectedInvoices = []
      this.consultants = []
      this.back()
      if (this.vendorId != null) {
        this.selectedInvoices = []
        this.consultants = []
      }
      this.currentPage = 1
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.number}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          VendorInvoices.delete(this, obj.id)
        }
      })
    },
    invoiceDeleted() {
      VendorInvoices.getInvoices(this, this.vendorId)
      this.$swal({
        icon: 'success',
        title: 'Vendor Invoice Deleted!',
        text: 'Your Vendor Invoice has been deleted',
      })
    },
    invoiceNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Vendor Invoice Not Deleted!',
        text: 'Your Vendor Invoice has been not deleted',
      })
    },
    selectionChanged(params) {
      const rows = params.selectedRows
      this.consultants = []
      this.selectedInvoices = []
      for (let i = 0; i < rows.length; i += 1) {
        if (!this.selectedInvoices.contains(rows[i].id)) {
          this.selectedInvoices.push(rows[i].id)
        }
        if (!this.consultants.contains(rows[i].consultants)) {
          this.consultants.push(rows[i].consultants)
        }
      }
    },
    monthDateChanged(selectedDate, dateStr) {
      this.month = dateStr
      this.$store.state.query.month = this.month
      this.selectedInvoices = []
      this.consultants = []
      VendorInvoices.getInvoices(this, this.vendorId)
    },
    onVendorChanged() {
      this.$store.state.query.vendor = this.vendor
      if (this.vendor !== null) {
        this.vendorId = this.vendor.id
      }
      this.currentPage = 1
    },
    setReport(invoices) {
      this.invoices = invoices
      this.recordsCount = invoices.count
    },
    setEmployees(employees) {
      this.employees = employees
      for (let i = 0; i < this.employees.length; i += 1) {
        if (this.employees[i].id === this.vendorId) {
          this.vendor = this.employees[i]
        }
      }
    },
    back() {
      this.isNextClicked = false
      this.submitted = false
      this.mass_communication.subject = ''
      this.mass_communication.message = ''
      this.mass_communication.signature = `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`
      this.consultants = []
    },
    setInvoices(vendorInvoicesResponse) {
      this.vendorInvoicesResponse = vendorInvoicesResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-color.badge-secondary {
    background-color: rgba(40, 199, 111, 0.12);
    color: #28c76f !important;
}
</style>
