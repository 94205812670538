<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4
            v-if="parent_type !== 'employer' && parent_type !== 'vendors' && parent_type !== 'suppliers' && parent_type !== 'referrer' && parent_type !== 'subvendor' && parent_type !== 'endclients'"
            class="card-title mb-0 pt-50"
          >Business Partners</h4>
          <h4
            v-if="parent_type === 'employer'"
            class="card-title mb-0 pt-50"
          >Employers</h4>
          <h4
            v-if="parent_type === 'vendors'"
            class="card-title mb-0 pt-50"
          >Vendors</h4>
          <h4
            v-if="parent_type === 'suppliers'"
            class="card-title mb-0 pt-50"
          >Suppliers</h4>
          <h4
            v-if="parent_type === 'referrer'"
            class="card-title mb-0 pt-50"
          >Rreferrers</h4>
          <h4
            v-if="parent_type === 'subvendor'"
            class="card-title mb-0 pt-50"
          >Sub Vendors</h4>
          <h4
            v-if="parent_type === 'endclients'"
            class="card-title mb-0 pt-50"
          >End Clients</h4>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <v-select
              id="parent_type"
              v-model="parent_type"
              :options="parents"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Business Partner"
              @input="parentChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              v-if="parent_type === 'employer'"
              variant="primary"
              class="ml-2 min-w-150"
              :to="{ name: 'human-resources-employers-new'}"
            >Add Employer</b-button>
            <b-button
              v-if="parent_type === 'referrer'"
              variant="primary"
              class="ml-2 min-w-150"
              :to="{ name: 'human-resources-referrers-new'}"
            >Add Referrer</b-button>
            <b-button
              v-if="parent_type === 'subvendor'"
              variant="primary"
              class="ml-2 min-w-170"
              :to="{ name: 'human-resources-sub-vendors-new'}"
            >Add Sub Vendor</b-button>
            <b-button
              v-if="parent_type === 'suppliers'"
              variant="primary"
              class="ml-2 min-w-150"
              :to="{ name: 'human-resources-suppliers-new'}"
            >Add Supplier</b-button>
            <b-button
              v-if="parent_type === 'vendors'"
              variant="primary"
              class="ml-2 min-w-150"
              :to="{ name: 'human-resources-vendors-new'}"
            >Add Vendor</b-button>
            <b-button
              v-if="parent_type === 'endclients'"
              variant="primary"
              class="ml-2 min-w-160"
              :to="{ name: 'human-resources-end-clients-new'}"
            >
              Add End Client
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="parentsResponse.parents"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: parent_type === 'employer' ? 'human-resources-employers-edit' : parent_type === 'vendors' ? 'human-resources-vendors-edit' : parent_type === 'suppliers' ? 'human-resources-suppliers-edit' : parent_type === 'referrer' ? 'human-resources-referrers-edit' : parent_type === 'subvendor' ? 'human-resources-sub-vendors-edit' : parent_type === 'endclients' ? 'human-resources-end-clients-edit' : '', params: { id: props.row.id } }"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: parent_type === 'employer' ? 'human-resources-employers-view' : parent_type === 'vendors' ? 'human-resources-vendors-view' : parent_type === 'suppliers' ? 'human-resources-suppliers-view' : parent_type === 'referrer' ? 'human-resources-referrers-view' : parent_type === 'subvendor' ? 'human-resources-sub-vendors-view' : parent_type === 'endclients' ? 'human-resources-end-clients-view' : '', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="parentsResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BBadge, BPagination, BInputGroup, BFormGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Employers from '@/js/employers'
import Vendors from '@/js/vendors'
import SubVendors from '@/js/subvendor'
import Referrers from '@/js/referrers'
import Suppliers from '@/js/suppliers'
import EndClients from '@/js/endclient'
import LIST from '@/js/lists'

export default {
  name: 'BusinessPartners',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      parents: [],
      parent_type: null,
      parentsResponse: {
        parents: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Contact Name',
          field: 'contact_name',
          sortable: false,
        },
        {
          label: 'Contact Email',
          field: 'email_address',
          sortable: false,
        },
        {
          label: 'Contact Phone',
          field: 'phone_number',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        this.getParentList()
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRBusinessPartners(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.parentsResponse.count = this.$store.state.query.page
    this.parent_type = this.$store.state.query.parent_type
    LIST.getHRBusinessPartners(this)

    this.getParentList()
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteParent(obj.id)
        }
      })
    },
    parentDeleted(data) {
      this.getParentList()
      this.$swal({
        icon: 'success',
        title: 'Deleted!',
        text: data.return_message,
      })
    },
    parentNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Not Deleted!',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setPartners(parents) {
      this.parents = parents
    },
    setEmployers(employersResponse) {
      this.parentsResponse.parents = employersResponse.employers
      this.parentsResponse.count = employersResponse.count
    },
    setReferrers(referrersResponse) {
      this.parentsResponse.parents = referrersResponse.referrers
      this.parentsResponse.count = referrersResponse.count
    },
    setSubVendors(subVendorsResponse) {
      this.parentsResponse.parents = subVendorsResponse.sub_vendors
      this.parentsResponse.count = subVendorsResponse.count
    },
    setSuppliers(suppliersResponse) {
      this.parentsResponse.parents = suppliersResponse.suppliers
      this.parentsResponse.count = suppliersResponse.count
    },
    setVendors(vendorsResponse) {
      this.parentsResponse.parents = vendorsResponse.vendors
      this.parentsResponse.count = vendorsResponse.count
    },
    setEndClients(endClientsResponse) {
      this.parentsResponse.parents = endClientsResponse.end_clients
      this.parentsResponse.count = endClientsResponse.count
    },

    parentChanged() {
      this.$store.state.query.parent_type = this.parent_type
      this.parentsResponse.parents = []
      this.$store.state.query.query = ''
      this.searchQuery = ''
      this.parentsResponse.count = 1
      this.currentPage = 1
    },
    getParentList() {
      switch (this.parent_type) {
        case 'employer':
          Employers.getEmployers(this)
          break
        case 'vendors':
          Vendors.getVendors(this)
          break
        case 'suppliers':
          Suppliers.getSuppliers(this)
          break
        case 'referrer':
          Referrers.getReferrers(this)
          break
        case 'subvendor':
          SubVendors.getSubVendors(this)
          break
        case 'endclients':
          EndClients.getEndClients(this)
          break
        default:
          break
      }
    },
    deleteParent(id) {
      switch (this.parent_type) {
        case 'employer':
          Employers.delete(this, id)
          break
        case 'vendors':
          Vendors.delete(this, id)
          break
        case 'suppliers':
          Suppliers.delete(this, id)
          break
        case 'referrer':
          Referrers.delete(this, id)
          break
        case 'subvendor':
          SubVendors.delete(this, id)
          break
        case 'endclients':
          EndClients.delete(this, id)
          break
        default:
          break
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
