<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Prospect</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            :to="{ name: 'parent-task-view', params: { parent_type_id: 3, parent_id: $route.params.id }}"
            class="mr-1"
          >
            Tasks
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Personal Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                First Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.first_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Middle Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.middle_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Last Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.last_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Gender
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.gender"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Date of Birth
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.date_of_birth"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                SSN
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.ssn"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Prospect Start Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.employee_start_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Prospect End Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.employee_end_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contract Start Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.contract_start_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contract End Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.contract_end_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.status.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                End Client
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.client_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Role
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                >
                  <b-badge
                    v-for="(role_name, index) in prospect.role_names"
                    :key="index"
                    variant="light-primary"
                    class="mr-50"
                  >
                    {{ role_name }}
                  </b-badge>
                </span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Passport Number
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.passport_number"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Skills</h6>
        </div>
        <b-row
          v-for="employeeSkill in prospect.skills"
          :key="employeeSkill"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Skill
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employeeSkill.skill"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Experience
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employeeSkill.experience"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Rating
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="employeeSkill.rating"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Visa Details</h6>
        </div>
        <b-row
          v-if="prospect.visa !== null"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Type
              </dt>
              <dd
                class="col-sm-12"
              >
                <span
                  class="form-control"
                  v-text="prospect.visa.visa_type.type ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Expiry Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.visa.expiration_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Validated
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.visa.validated"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
        v-if="prospect.address !== null"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Contact Information</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.address.state.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="prospect.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
        <div
          v-if="prospect.address === null"
          class="text-center"
        >
          <p>Contact information available</p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Additional Contact Information</h6>
        </div>
        <b-row
          v-for="(contact_info, index) in prospect.contact_information"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.contact_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.state"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-0">
          </b-col>
        </b-row>
        <div
          v-if="prospect.contact_information.length == 0"
          class="text-center"
        >
          <p>No additional contact information available</p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Business Partners</h6>
        </div>
        <b-row
          v-for="(business_partner, businessPartnerIndex) in prospect.business_partners"
          :key="businessPartnerIndex"
          :value="businessPartnerIndex"
        >
          <b-col cols="12">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Partner Type
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control txt-capt"
                      v-text="business_partner.parent_type"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    <label>Name</label>
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="business_partner.userName"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <dl class="row">
                      <dt class="col-sm-12 dt-text">
                        <label>Payment Frequency</label>
                      </dt>
                      <dd class="col-sm-12">
                        <span
                          class="form-control"
                          v-text="business_partner.paymentType"
                        />
                      </dd>
                    </dl>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <dl class="row">
                      <dt class="col-sm-12 dt-text">
                        <label>Pay Rate</label>
                      </dt>
                      <dd class="col-sm-12">
                        <span class="form-control">{{ business_partner.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                      </dd>
                    </dl>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <dl class="row">
                      <dt class="col-sm-12 dt-text">
                        <label>Discount</label>
                      </dt>
                      <dd class="col-sm-12">
                        <span class="form-control">{{ business_partner.discount | currency('% ', 2, { thousandsSeparator: ',' }) }}</span>
                      </dd>
                    </dl>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0">
          </b-col>
        </b-row>
        <div
          v-show="prospect.business_partners.length == 0"
          class="text-center"
        >
          <p>No business partners available</p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th>Issuer</th>
                    <th>Name</th>
                    <th>Version</th>
                    <th class="th-minw-125">Upload Date</th>
                    <th class="th-minw-110">Exp Date</th>
                    <th>Uploaded By</th>
                    <th>Reminder</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(priviousVersion, index) in prospect.documents"
                    :key="index"
                  >
                    <td>{{ priviousVersion.document_type }}</td>
                    <td>{{ priviousVersion.issuer }}</td>
                    <td>{{ priviousVersion.document_name }}</td>
                    <td>{{ priviousVersion.version }}</td>
                    <td>{{ priviousVersion.uploaded_date }}</td>
                    <td>{{ priviousVersion.exp_date }}</td>
                    <td>{{ priviousVersion.uploaded_by }}</td>
                    <td>{{ priviousVersion.reminder }}</td>
                    <td>
                      <b-link
                        target="_blank"
                        :href="priviousVersion.document_path"
                      >
                        <b-badge
                          variant="primary"
                          class="mr-1 mb-50"
                        >
                          <span>{{ priviousVersion.document }}</span>
                        </b-badge>
                      </b-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <div
          v-if="prospect.documents.length == 0"
          class="text-center"
        >
          <p> No Documents available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in prospect.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></label>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="prospect.comments.length == 0"
          class="text-center"
        >
          <p> No Comments available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Previous Tasks</h6>
        </div>
        <app-collapse accordion>
          <app-collapse-item
            v-for="(task, index) in Object.keys(prospect.tasks)"
            :id="index"
            :key="index"
            :title="task"
          >
            <b-table-simple
              hover
              bordered
              responsive
              class="mb-1"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>
                    Task Name
                  </b-th>
                  <b-th>
                    Schedule Date
                  </b-th>
                  <b-th>
                    Complete Date
                  </b-th>
                  <b-th>
                    Status
                  </b-th>
                  <b-th>
                    Result
                  </b-th>
                  <b-th>
                    Comments
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(view_task, taskIndex) in prospect.tasks[task].view_tasks"
                  :id="taskIndex"
                  :key="taskIndex"
                >
                  <b-td> {{ view_task.task_name }}</b-td>
                  <b-td> {{ view_task.scheduled_date }}</b-td>
                  <b-td> {{ view_task.completed_date }}</b-td>
                  <b-td> {{ view_task.status }}</b-td>
                  <b-td> {{ view_task.result }}</b-td>
                  <b-td> {{ view_task.comments }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Workflow Status
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="prospect.tasks[task].process_status"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                v-if="prospect.tasks[task].reject_reason !== null"
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Reject Reason
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="prospect.tasks[task].reject_reason"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </app-timeline-item>

      <app-timeline-item
        v-show="prospect.user_defined_fields.length !== 0"
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in prospect.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Prospect from '@/js/prospects'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BBadge, BLink, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'

export default {
  name: 'AdvancedSearchView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BLink,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      prospect: {},
    }
  },
  mounted() {
  },
  created() {
    Prospect.getProspectById(this, this.$route.params.id)
  },
  methods: {
    setProspect(prospect) {
      this.prospect = prospect
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
