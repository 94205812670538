<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Exception</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="exception.parent_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="exception.parent_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent Level
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="exception.parent_level ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
        <b-row v-if="!exception.parent_level">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Month
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="exception.month"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Bypass Invoice
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="exception.is_bypass_invoice ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Bypass Timesheet
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="exception.is_bypass_timesheet ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Reason
              </dt>
              <dd class="col-sm-12">
                <b-form-textarea
                  v-model="exception.reason"
                  rows="2"
                  disabled
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-show="exception.user_defined_fields.length !== 0"
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in exception.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Exception from '@/js/exceptions'

import {
  BCard, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'ExceptionView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },
  directives: {
  },
  data() {
    return {
      exception: {},
    }
  },
  mounted() {
  },
  created() {
    Exception.getExceptionById(this, this.$route.params.id)
  },
  methods: {
    setException(exception) {
      this.exception = exception
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
