<template>
  <b-card
    class="mb-0"
  >
    <div v-show="!isNextClicked">
      <div class="mb-0">
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="6"
          >
            <h4 class="card-title mb-0 pt-50">
              All Invoice Statuses Report</h4>
          </b-col>
          <b-col
            cols="12"
            md="6 ml-auto"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click="allBack"
              >
                Back
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Parent Type"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                id="type"
                v-model="type"
                :options="types"
                :reduce="item => item.parent_type_id"
                :clearable="false"
                placeholder="Select"
                @input="onTypeChanged($event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Period"
              label-cols-md="3"
              class="text-right"
            >
              <flat-pickr
                id="month"
                v-model="month"
                class="form-control"
                :config="configs.monthSelect"
                placeholder="MM-YYYY"
                :clear-button="true"
                @on-change="monthDateChanged"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Status"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                id="missing_status"
                v-model="missing_status"
                :options="status"
                :reduce="item => item.id"
                :clearable="false"
                placeholder="Select"
                @input="onStatusChanged($event)"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="reports.invoices"
        refs="invoice_table"
        :rtl="direction"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: false,
        }"
        class="table-custome-height"
        @on-selected-rows-change="selectionChanged"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Currency -->
          <span v-if="props.column.field === 'cellRendererCurrency'">
            <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </span>

          <!-- Column: Select Email -->
          <span v-if="props.column.field === 'cellRendererEmailSelect'">
            <span v-if="props.row.emails.length === 1">{{ props.row.email }}</span>
            <span v-if="props.row.emails.length > 1">
              <v-select
                id="email"
                v-model="props.row.email"
                :options="props.row.emails"
                :clearable="false"
                placeholder="Select"
                @input="emailChanged(props.row)"
              />
            </span>
          </span>

          <!-- Column: Select Phone -->
          <span v-if="props.column.field === 'cellRendererPhoneSelect'">
            <span v-if="props.row.phone_numbers.length === 1">{{ props.row.phone_number }}</span>
            <span v-if="props.row.phone_numbers.length > 1">
              <v-select
                id="phone_number"
                v-model="props.row.phone_number"
                :options="props.row.phone_numbers"
                :clearable="false"
                placeholder="Select"
                @input="phoneChanged(props.row)"
              />
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>

      <b-row
        v-if="selectedPhoneNumbers.length > 0 || selectedEmails.length > 0"
        class="mt-1"
      >
        <b-col class="text-right">
          <b-button
            variant="primary"
            type="button"
            class="mr-1"
            @click="sendMessage"
          >
            Send Message
          </b-button>
          <b-button
            variant="primary"
            type="button"
            @click="sendEmail"
          >
            Send Email
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="isNextClicked && isEmail">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Send Email</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              cols="10"
              offset="1"
              class="col-xxl-8 offset-xxl-2"
            >
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-form-group>
                    <label>To</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <v-select
                        id="emails"
                        v-model="mass_communication.emails"
                        :options="selectedEmails"
                        :reduce="item => item.id"
                        multiple
                        :clearable="false"
                        placeholder="Select"
                        @input="onEmployeeSelected()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label>Subject</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Subject"
                      rules="required"
                    >
                      <b-form-input
                        id="subject"
                        v-model="mass_communication.subject"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label>Body</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Body"
                      rules="required"
                    >
                      <b-form-textarea
                        id="message"
                        v-model="mass_communication.message"
                        rows="2"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Signature"
                    class="text-left"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Signature"
                      rules="required"
                    >
                      <b-form-textarea
                        id="signature"
                        v-model="mass_communication.signature"
                        rows="2"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="ml-2">
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="submitForm"
                  >
                    Send
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

    <div v-if="isNextClicked && isMessage">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Send Message</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>

      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              cols="10"
              offset="1"
              class="col-xxl-8 offset-xxl-2"
            >
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-form-group>
                    <label>To</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <v-select
                        id="emails"
                        v-model="mass_communication.emails"
                        :options="selectedPhones"
                        :reduce="item => item.id"
                        multiple
                        :clearable="false"
                        placeholder="Select"
                        @input="onEmployeeSelected()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label>Body</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Body"
                      rules="required"
                    >
                      <b-form-textarea
                        id="message"
                        v-model="mass_communication.message"
                        rows="2"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="ml-2">
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="submitForm"
                  >
                    Send
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormInput, BFormTextarea, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import Constants from '@/js/constant'
import moment from 'moment'
import MCommunication from '@/js/masscommunication'
import Reports from '@/js/reports'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'

export default {
  name: 'MissingInvoicesReport',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BButton,
    flatPickr,

    vSelect,
  },
  data() {
    return {
      required,
      report: {},
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      missing_status: '',
      month: moment().subtract(1, 'months').format('MMM-YYYY'),
      reports: {
        invoices: [],
      },
      type: null,
      types: [],
      selectedUserEmails: [],
      selectedEmails: [],
      selectedPhoneNumbers: [],
      selectedUserPhoneNumbers: [],
      parent_type_id: null,
      mass_communication: {
        parent_type_id: null,
        subject: '',
        message: '',
        signature: 'Thanks,\n Medforce Team',
        emails: [],
      },
      selectedstatus: null,
      status: [],
      selectedUsers: [],
      selectedPhones: [],
      isNextClicked: false,
      selectAll: false,
      isMessage: false,
      isEmail: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Employer',
          field: 'employer_name',
          sortable: false,
        },
        {
          label: 'Check #',
          field: 'check_number',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Check Date',
          field: 'check_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Invoice #',
          field: 'invoice_number',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '130px',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'cellRendererEmailSelect',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'cellRendererPhoneSelect',
          width: '180px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getFABusinessPartners(this)
      },
    },
  },
  created() {
    LIST.getFABusinessPartners(this)
    Constants.getMissingInvoicesStatus(this)
    if (this.type !== null) {
      Reports.getMissingInvoices(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.missing_status, this.type)
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          MCommunication.sendMails(this, this.mass_communication)
        }
      })
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    onEmployeeSelected() {
      if (this.mass_communication.emails.length === 0) {
        this.mass_communication.emails = null
      }
    },
    monthDateChanged(config, month) {
      this.month = month
      this.reports = []
      this.selectAll = false
      this.selectedEmails = []
      this.selectedPhoneNumbers = []
      this.selectedUserEmails = []
      this.selectedUserPhoneNumbers = []
      if (this.type !== null) {
        Reports.getMissingInvoices(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.missing_status, this.type)
      }
    },
    onTypeChanged() {
      this.reports = []
      this.selectAll = false
      this.selectedEmails = []
      this.selectedPhoneNumbers = []
      this.selectedUserEmails = []
      this.selectedUserPhoneNumbers = []
      if (this.type !== null) {
        Reports.getMissingInvoices(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.missing_status, this.type)
      }
    },
    emailChanged(row) {
      for (let i = 0; i < this.selectedEmails.length; i += 1) {
        if (this.selectedEmails[i].id === row.id) {
          this.selectedUserEmails.splice(this.selectedUserEmails.indexOf(this.selectedUserEmails[i].label))
          this.selectedEmails[i].label = row.email
          this.selectedUserEmails.push(row.email)
        }
      }
    },
    phoneChanged(row) {
      for (let i = 0; i < this.selectedPhoneNumbers.length; i += 1) {
        if (this.selectedPhoneNumbers[i].id === row.id) {
          this.selectedUserPhoneNumbers.splice(this.selectedUserPhoneNumbers.indexOf(this.selectedUserPhoneNumbers[i].label))
          this.selectedPhoneNumbers[i].label = row.phone_number
          this.selectedUserPhoneNumbers.push(row.phone_number)
        }
      }
    },
    selectionChanged(params) {
      const rows = params.selectedRows
      this.selectedUserEmails = []
      this.selectedPhoneNumbers = []
      this.selectedUserPhoneNumbers = []
      this.selectedEmails = []
      this.consultants = []
      for (let i = 0; i < rows.length; i += 1) {
        if (!this.selectedUserEmails.includes(rows[i].email)) {
          this.selectedEmails.push({ id: rows[i].id, label: rows[i].email })
          this.selectedUserEmails.push(rows[i].email)
        }
        if (!this.selectedUserPhoneNumbers.includes(rows[i].phone_number)) {
          this.selectedPhoneNumbers.push({ id: rows[i].id, label: rows[i].phone_number })
          this.selectedUserPhoneNumbers.push(rows[i].phone_number)
        }
      }
    },
    onStatusChanged() {
      this.reports = []
      this.selectAll = false
      this.selectedEmails = []
      this.selectedPhoneNumbers = []
      this.selectedUserEmails = []
      this.selectedUserPhoneNumbers = []
      if (this.type !== null) {
        Reports.getMissingInvoices(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.missing_status, this.type)
      }
    },
    sendMessage() {
      if (!this.submitted) {
        this.mass_communication.type = 'message'
        this.mass_communication.parent_type_id = this.type
        this.mass_communication.subject = ' '
        this.mass_communication.emails = this.selectedPhoneNumbers
        this.isNextClicked = true
        this.isMessage = true
        this.isEmail = false
        this.submitted = true
      }
    },
    sendEmail() {
      if (!this.submitted) {
        this.mass_communication.type = 'email'
        this.mass_communication.parent_type_id = this.type
        this.mass_communication.subject = ''
        this.mass_communication.emails = this.selectedEmails
        this.isNextClicked = true
        this.isMessage = false
        this.isEmail = true
        this.submitted = true
      }
    },
    setReport(reports) {
      this.reports = reports
    },
    communicationAdded(data) {
      this.selectedEmails = []
      this.selectedPhoneNumbers = []
      this.selectedUserEmails = []
      this.selectedUserPhoneNumbers = []
      this.back()
      if (this.type !== null) {
        Reports.getMissingInvoices(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.missing_status, this.type)
      }
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    communicationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    allBack() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
    back() {
      this.isNextClicked = false
      this.submitted = false
      this.mass_communication.subject = ''
      this.mass_communication.message = ''
      this.mass_communication.signature = 'Thanks,\n Medforce Team'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
