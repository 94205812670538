<template>
  <div class="container container-custome">
    <b-card class="vh-100">
      <div class="px-2 pt-1">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>
      </div>

      <div class="misc-inner p-2">
        <div class="w-100">
          <h2 class="mb-4 text-center">
            Candidate Interview Consent
          </h2>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <h4 class="card-title mb-0 pt-50">
                Candidate Information</h4>
            </b-col>
            <b-col
              cols="12"
              md="6 ml-auto"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="outline-secondary"
                  @click="back"
                >
                  Back
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Name
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="interview.name"
                  />
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Email
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="interview.email"
                  />
                </dd>
              </dl>
            </b-col>
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Phone
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="interview.phone"
                  />
                </dd>
              </dl>
            </b-col>
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Interview Date
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="interview.date"
                  />
                </dd>
              </dl>
            </b-col>
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Interview Time
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="interview.time"
                  />
                </dd>
              </dl>
            </b-col>
          </b-row>
          <hr>
          <h4>Consent For Technical Interview</h4>
          <b-row class="mt-1">
            <b-col cols="8">
              <p class="mb-2">
                Thank you participating in the technical interview with {{ interview.company }}.
              </p>
              <p class="mb-2">
                I <strong>{{ interview.name }}</strong> confirm that for the above stated interview I am using my own voice and video. I am ready to accept the consequences if i cheated in the interview under the laws of "Fake Identity".
              </p>
            </b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <b-button
                variant="primary"
                class="mb-1 btn-sm-block"
                @click="update"
              >I Agree</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BLink, BButton, BRow, BCol,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Interview from '@/js/interviews'
import User from '@/js/user'
import Constants from '@/js/constant'
import moment from 'moment'

export default {
  name: 'CandidateInterviewConsent',
  components: {
    BCard, BLink, BButton, VuexyLogo, BRow, BCol,
  },
  data() {
    return {
      interview: {},
      updateConsent: {
        date: Constants.getEprmMonthDateFromString(moment()),
      },
    }
  },
  computed: {
  },
  created() {
    if (!User.isAdmin() && !User.isInterviewsAvailable()) {
      window.location.replace('https://medforce.co')
      return
    }
    Interview.getInterviewByToken(this, this.$route.params.token)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    setInterview(interview) {
      this.interview = interview
    },
    setNoInterview(message) {
      this.reloadPage(message)
    },
    update() {
      this.updateConsent.date = Constants.getEprmMonthDateFromString(moment())
      Interview.updateInterviewConsentByToken(this, this.$route.params.token)
    },
    interviewUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    interviewNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    // reloadPage(message) {
    //   let dis = this
    //   this.$dialog.alert(message).then(function () {
    //     window.location.replace('https://medforce.co')
    //   }).catch(function (val) {
    //   // This will be triggered when user clicks on cancel
    //   })
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.container-custome {
  width: 1140px;
}
</style>
