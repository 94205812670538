<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Prospective Candidate {{ candidate.first_name }} {{ candidate.last_name }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            class="mr-2"
            :to="{ name: 'human-resources-candidates-inactive-candidate-apply' }"
          >
            Apply Job
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Candidate Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Employer
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.supplier_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                First Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.first_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Last Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.last_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="candidate.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.phone_number"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Experience / Rating</h6>
        </div>
        <b-table-simple
          hover
          bordered
          responsive
          class="mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>
                Skill
              </b-th>
              <b-th>
                Experience
              </b-th>
              <b-th>
                Rating
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="experience in candidate.experiences"
              :key="experience"
              :value="experience"
            >
              <b-td>{{ experience.name }}</b-td>
              <b-td>{{ experience.years }} Years</b-td>
              <b-td>{{ experience.rating }}</b-td>
            </b-tr>
            <b-tr v-if="candidate.experiences.length == 0">
              <b-td
                colspan="3"
                class="text-center"
              >
                Experience on technology not available
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row v-if="candidate.documents !== null">
          <b-col cols="12">
            <b-link
              v-for="option in candidate.documents"
              :key="option.id"
              :value="option.id"
              :href="option.attachment_link"
              :title="option.name"
              target="_blank"
              :class="{'mt-75': option}"
            >
              <b-badge
                variant="primary"
                class="mr-1 mb-50"
              >
                <span>{{ option.name }}</span>
              </b-badge>
            </b-link>
          </b-col>
        </b-row>
        <div
          v-if="candidate.documents.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Documents available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Previous Tasks</h6>
        </div>
        <b-table-simple
          hover
          bordered
          responsive
          class="mb-1"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>
                Task Name
              </b-th>
              <b-th>
                Schedule Date
              </b-th>
              <b-th>
                Complete Date
              </b-th>
              <b-th>
                Status
              </b-th>
              <b-th>
                Result
              </b-th>
              <b-th>
                Comments
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="task in candidate.view_tasks"
              :key="task"
              :value="task"
            >
              <b-td> {{ task.task_name }}</b-td>
              <b-td> {{ task.scheduled_date }}</b-td>
              <b-td> {{ task.completed_date }}</b-td>
              <b-td> {{ task.status }}</b-td>
              <b-td> {{ task.status }}</b-td>
              <b-td> {{ task.comments }}</b-td>
            </b-tr>
            <b-tr
              v-if="candidate.view_tasks.length === 0"
            >
              <b-td
                colspan="6"
                class="text-center"
              >
                Previous tasks not available
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Process Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.process_status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="candidate.reject_reason !== null"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Reject Reason
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.reject_reason"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-show="candidate.user_defined_fields.length !== 0"
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in candidate.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
    <hr>

    <b-row>
      <b-col
        cols="12"
        class="text-right mt-1"
      >
        <b-button
          variant="primary"
          class="mr-2"
          :to="{ name: 'human-resources-candidates-inactive-candidate-apply' }"
        >
          Apply
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Candidate from '@/js/candidates'

import {
  BCard, BRow, BCol, BButton, BBadge, BLink, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'InactiveCandidateView',
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      candidate: {},
    }
  },
  mounted() {
  },
  created() {
    Candidate.getCandidateById(this, this.$route.params.id)
  },
  methods: {
    setCandidate(candidate) {
      this.candidate = candidate
      if (candidate.visa == null) {
        this.candidate.visa = {
          type_id: null,
          expiration_date: null,
          validated: false,
          visa_type: {},
        }
      }
      if (candidate.address == null) {
        this.candidate.address = {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: '',
        }
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
