import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const po = `${HOST}po`

export default {
  getEmployerPoById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${po}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployerDetails(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employerNotFound(error.response.data.return_message)
    })
  },
  getPODocumentSentById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${po}/${id}/document-sent`).then(response => {
      context.$vs.loading.close()
      context.setDocumentDetails(response.data)
    }, () => {
      context.$vs.loading.close()
      context.$router.go(-1)
    })
  },
  sendEmployerPOForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${po}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployerPOPdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${po}/${id}/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getEmployerPOTemplateById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${po}/${id}/update-content`, body).then(response => {
      context.$vs.loading.close()
      context.poTemplate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  sendPOUpdatedContentForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${po}/${id}/sign`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getPOPdfByIdAndUpdatedContent(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${po}/${id}/update-content/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
}
