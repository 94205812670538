<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Interviewer</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Interviewer Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Interviewer Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Interviewer Name"
                    rules="required"
                  >
                    <b-form-input
                      id="interviewer_name"
                      v-model="addInterviewer.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email Address</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email Address"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email_address"
                      v-model="addInterviewer.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      v-model="addInterviewer.phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="addInterviewer.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Time Slots</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group id="start_date">
                  <label>Start Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Start Date"
                  >
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      :config="startDateConfig"
                      placeholder="MM-DD-YYYY"
                      @on-change="availableStartDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group id="end_date">
                  <label>End Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Date"
                  >
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      :config="endDateConfig"
                      placeholder="MM-DD-YYYY"
                      @on-change="availableEndDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
                lg="2"
              >
                <b-form-group>
                  <label>Timings From</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Timings From"
                  >
                    <flat-pickr
                      id="timings_from"
                      v-model="addInterviewer.available_start_time"
                      class="form-control"
                      :config="fromTimeConfig"
                      placeholder="00:00"
                      @on-change="availableStartTimeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
                lg="2"
              >
                <b-form-group>
                  <label>Timings To</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="To"
                  >
                    <flat-pickr
                      id="timings_to"
                      v-model="addInterviewer.available_end_time"
                      class="form-control"
                      :config="toTimeConfig"
                      placeholder="00:00"
                      @on-change="availableEndTimeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Duration</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Duration"
                  >
                    <v-select
                      id="duration"
                      v-model="addInterviewer.duration_id"
                      :options="durations"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Recurring</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Recurring"
                  >
                    <v-select
                      id="recurring"
                      v-model="recurring"
                      :options="frequencies"
                      :reduce="item => item"
                      :clearable="false"
                      placeholder="Select"
                      @input="frequencyChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="showWeeks"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Week Days</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Week Days"
                    :rules="showWeeks? 'required' : ''"
                  >
                    <v-select
                      id="week_days"
                      v-model="addInterviewer.week_days_id"
                      :options="weekDays"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
                lg="2"
              >
                <b-form-group>
                  <label>Block Time From</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Timings From"
                  >
                    <flat-pickr
                      id="block_time_from"
                      v-model="addInterviewer.block_start_time"
                      class="form-control"
                      :config="blockFromTimeConfig"
                      placeholder="00:00"
                      @on-change="blockStartTimeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
                lg="2"
              >
                <b-form-group>
                  <label>Block Time To</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="To"
                  >
                    <flat-pickr
                      id="block_time_to"
                      v-model="addInterviewer.block_end_time"
                      class="form-control"
                      :config="blockToTimeConfig"
                      placeholder="00:00"
                      @on-change="blockEndTimeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Weekends</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Weekends"
                  >
                    <v-select
                      id="weekend_id"
                      v-model="addInterviewer.weekend_ids"
                      :options="weekDays"
                      multiple
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Holidays</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Holidays"
                  >
                    <v-select
                      id="holiday_id"
                      v-model="addInterviewer.holiday_ids"
                      :options="holidays"
                      multiple
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Communication Mode Details</h6>
            </div>
            <div
              v-for="(communicationInfo, index) in addInterviewer.communications"
              :id="communicationInfo.id"
              :key="communicationInfo.id"
              class="d-flex"
            >
              <div class="left w-100">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Type</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Type"
                        rules="required"
                      >
                        <v-select
                          id="communication_type"
                          v-model="communicationInfo.type_id"
                          :options="comTypes"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                          @input="communicationTypeMatch(communicationInfo.type_id, index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Type' + index, 'Type') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Value</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Value"
                        rules="required"
                      >
                        <b-form-input
                          id="communication_value"
                          v-model="communicationInfo.value"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Value' + index, 'Value') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="mt-0 mb-50">
              </div>
              <div class="right mt-2 ml-1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeCommunication(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
            </div>
            <div v-if="comTypes.length !== addInterviewer.communications.length">
              <div class="mt-50">
                <b-button
                  id="add_communication"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="addCommunication"
                >
                  <feather-icon icon="PlusIcon" />
                  Add Communication
                </b-button>
              </div>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Interviewer Skills</h6>
            </div>
            <div class="d-flex mt-1">
              <h5 class="mb-2 ml-2">
                Main Areas
              </h5>
            </div>
            <b-row>
              <b-col
                cols="12"
                class="pl-5"
              >
                <b-row>
                  <b-col
                    v-for="(option, index) in skills"
                    :key="option.id"
                    :value="option.id"
                    cols="6"
                    md="3"
                    class="col-xxl-2 mb-1"
                  >
                    <b-form-checkbox
                      :id="'skill_' + index"
                      :checked="technologySelected(option.id)"
                      @change="checked(option.id)"
                    >
                      {{ option.label }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Vacations</h6>
            </div>
            <div
              v-for="(vacationInfo, index) in addInterviewer.vacations"
              :id="vacationInfo.id"
              :key="vacationInfo.id"
              class="d-flex"
            >
              <div class="left w-100">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group id="vacation_from">
                      <label>From</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="From"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="vacationInfo.vacation_start_date_text"
                          class="form-control"
                          :config="vacationConfig.from"
                          placeholder="MM-DD-YYYY"
                          @on-change="startDateChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('From' + index, 'From') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group id="vacation_to">
                      <label>To</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="To"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="vacationInfo.vacation_end_date_text"
                          class="form-control"
                          :config="vacationConfig.to"
                          placeholder="MM-DD-YYYY"
                          @on-change="endDateChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('To' + index, 'To') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                    class="mt-md-2"
                  >
                    <b-form-checkbox
                      id="all_day"
                      v-model="vacationInfo.is_all_day"
                    >
                      All Day
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    v-show="!vacationInfo.is_all_day"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Duration From</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Timings From"
                        :rules="vacationInfo.is_all_day ? '' : 'required'"
                      >
                        <flat-pickr
                          id="duration_from"
                          v-model="vacationInfo.vacation_start_time"
                          class="form-control"
                          :config="vacationConfig.fromTime"
                          placeholder="00:00"
                          @on-change="startTimeChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Timings From' + index, 'Timings From') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-show="!vacationInfo.is_all_day"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Duration To</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Timings To"
                        :rules="vacationInfo.is_all_day ? '' : 'required'"
                      >
                        <flat-pickr
                          id="duration_to"
                          v-model="vacationInfo.vacation_end_time"
                          class="form-control"
                          :config="vacationConfig.toTime"
                          placeholder="00:00"
                          @on-change="endTimeChanged(index)"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('To' + index, 'To') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <hr class="mt-0 mb-50">
                  </b-col>
                </b-row>
              </div>
              <div class="right ml-1 mt-2">
                <b-button
                  id="all_day"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeVacation(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
            </div>
            <div class="mt-50">
              <b-button
                id="add_vacation"
                variant="outline-primary"
                class="btn-icon"
                @click="addVacation"
              >
                <feather-icon icon="PlusIcon" />
                Add Vacation
              </b-button>
            </div>
            <hr>
          </app-timeline-item>

          <app-timeline-item
            v-show="addInterviewer.user_defined_fields.length !== 0"
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addInterviewer.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Interviewer
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  // BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BButton,
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Interviewers from '@/js/interviewers'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'InterviewerEdit',
  components: {
    BCard,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormTextarea,
    BFormCheckbox,
    BButton,
    vSelect,
    flatPickr,
  },
  setup() {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      existing_user: null,
      recurring: { id: null, label: null },
      addInterviewer: {
        employee_id: null,
        status_id: null,
        name: '',
        email: '',
        phone: '',
        duration_id: null,
        recurring_id: null,
        weekend_ids: null,
        block_start_time: null,
        block_end_time: null,
        weekdays_id: null,
        holiday_ids: null,
        skype_id: '',
        webex: '',
        zoom_info: '',
        start_date: null,
        end_date: null,
        microsoft_meetings: '',
        available_start_time: null,
        available_end_time: null,
        vacations: [],
        communications: [],
        skill_ids: [],
        user_defined_fields: [],
      },
      from_date: null,
      to_date: null,
      start_date: null,
      end_date: null,
      vacation_start_date: null,
      vacation_end_date: null,
      fromTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
      endDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
      toTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      blockFromTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      blockToTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      vacationConfig: {
        from: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: 'm-d-Y',
          altInput: true,
          dateFormat: 'm-d-Y',
          minDate: new Date(2000, 1, 1),
        },
        to: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: 'm-d-Y',
          altInput: true,
          dateFormat: 'm-d-Y',
          minDate: new Date(2000, 1, 1),
        },
        fromTime: {
          enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        },
        toTime: {
          enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        },
      },
      vacationConfigs: [],
      showWeeks: false,
      skills: [],
      status: [],
      employees: [],
      durations: [],
      frequencies: [],
      weekDays: [],
      weekends: [],
      holidays: [],
      comTypes: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getINterviewerDurationFullList(this)
        LIST.getWeekDaysFullList(this)
        LIST.getFrequencyFullList(this)
        LIST.getHolidaysFullList(this)
        LIST.getSkillsFullList(this)
        LIST.getCommunicationTypesFullList(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getInerviewerStatusFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    Interviewers.getInterviewerById(this, this.$route.params.id)
    LIST.getINterviewerDurationFullList(this)
    LIST.getSkillsFullList(this)
    LIST.getInerviewerStatusFullList(this)
    LIST.getWeekDaysFullList(this)
    LIST.getFrequencyFullList(this)
    LIST.getHolidaysFullList(this)
    LIST.getCommunicationTypesFullList(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.start_date !== null) {
            this.addInterviewer.start_date = Constants.getEprmMonthDateFromString(this.start_date)
          }
          if (this.end_date !== null) {
            this.addInterviewer.end_date = Constants.getEprmMonthDateFromString(this.end_date)
          }
          for (let index = 0; index < this.addInterviewer.vacations.length; index += 1) {
            this.addInterviewer.vacations[index].vacation_start_date = Constants.getEprmMonthDateFromString(this.addInterviewer.vacations[index].vacation_start_date_text)
            this.addInterviewer.vacations[index].vacation_end_date = Constants.getEprmMonthDateFromString(this.addInterviewer.vacations[index].vacation_end_date_text)
          }
          Interviewers.updateInterviewer(this, this.$route.params.id, this.addInterviewer)
        }
      })
    },
    setInterviewer(interviewer) {
      this.addInterviewer = interviewer
      if (this.recurring === null) {
        this.recurring = {}
      }
      this.recurring.id = interviewer.recurring_id
      this.recurring.label = interviewer.recurring
      if (this.addInterviewer.available_start_time !== null) {
        this.$set(this.toTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
        this.$set(this.blockFromTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.available_end_time !== null) {
        this.$set(this.fromTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
        this.$set(this.blockToTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.block_start_time !== null) {
        this.$set(this.blockToTimeConfig, 'minTime', moment(this.addInterviewer.block_start_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.block_end_time !== null) {
        this.$set(this.blockFromTimeConfig, 'maxTime', moment(this.addInterviewer.block_end_time, 'hh:mm a').format('HH:mm'))
      }
      if (this.addInterviewer.start_date !== null) {
        this.start_date = this.addInterviewer.start_date
        this.$set(this.endDateConfig, 'minDate', new Date(moment(this.start_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
      }
      if (this.addInterviewer.end_date !== null) {
        this.end_date = this.addInterviewer.end_date
        this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.end_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
      }
      for (let index = 0; index < this.addInterviewer.vacations.length; index += 1) {
        const vacationConfig = {
          from: {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'm-d-Y',
            altInput: true,
            dateFormat: 'm-d-Y',
            minDate: new Date(2000, 1, 1),
          },
          to: {
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'm-d-Y',
            altInput: true,
            dateFormat: 'm-d-Y',
            minDate: new Date(2000, 1, 1),
          },
          fromTime: {
            enableTime: true, noCalendar: true, dateFormat: 'h:i K',
          },
          toTime: {
            enableTime: true, noCalendar: true, dateFormat: 'h:i K',
          },
        }
        this.vacationConfigs.push(vacationConfig)
        this.$set(this.vacationConfigs[index].to, 'minDate', new Date(moment(this.addInterviewer.vacations[index].vacation_start_date_text, 'MM-DD-YYYY').format('MM-DD-YYYY')))
        if (this.addInterviewer.vacations[index].vacation_start_time !== null) {
          this.$set(this.vacationConfigs[index].toTime, 'minTime', moment(this.addInterviewer.vacations[index].vacation_start_time, 'hh:mm a').format('HH:mm'))
        }
        if (this.addInterviewer.vacations[index].vacation_end_time !== null) {
          this.$set(this.vacationConfigs[index].fromTime, 'maxTime', moment(this.addInterviewer.vacations[index].vacation_end_time, 'hh:mm a').format('HH:mm'))
        }
        this.$set(this.vacationConfigs[index].from, 'maxDate', new Date(moment(this.addInterviewer.vacations[index].vacation_end_date_text, 'MM-DD-YYYY').format('MM-DD-YYYY')))
      }
    },
    frequencyChanged() {
      this.addInterviewer.recurring_id = this.recurring.id
      this.showWeeks = this.recurring.show_weeks
    },
    employeeChanged() {
      this.addInterviewer.employee_id = this.existing_user.id
      this.addInterviewer.email = this.existing_user.email
      this.addInterviewer.name = this.existing_user.label
    },
    addVacation() {
      const vacationInfo = {
        vacation_start_date_text: null,
        vacation_end_date_text: null,
        vacation_start_time: null,
        vacation_end_time: null,
        is_all_day: false,
      }
      this.addInterviewer.vacations.push(vacationInfo)
      const vacationConfig = {
        from: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: 'm-d-Y',
          altInput: true,
          dateFormat: 'm-d-Y',
          minDate: new Date(2000, 1, 1),
        },
        to: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: 'm-d-Y',
          altInput: true,
          dateFormat: 'm-d-Y',
          minDate: new Date(2000, 1, 1),
        },
        fromTime: {
          enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        },
        toTime: {
          enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        },
      }
      this.vacationConfigs.push(vacationConfig)
    },
    startDateChanged(index) {
      this.$set(this.vacationConfigs[index].to, 'minDate', new Date(moment(this.addInterviewer.vacations[index].vacation_start_date_text, 'MM-DD-YYYY').format('MM-DD-YYYY')))
    },
    startTimeChanged(index) {
      this.$set(this.vacationConfigs[index].toTime, 'minTime', moment(this.addInterviewer.vacations[index].vacation_start_time, 'hh:mm a').format('HH:mm'))
    },
    endTimeChanged(index) {
      this.$set(this.vacationConfigs[index].fromTime, 'maxTime', moment(this.addInterviewer.vacations[index].vacation_end_time, 'hh:mm a').format('HH:mm'))
    },
    endDateChanged(index) {
      this.$set(this.vacationConfigs[index].from, 'maxDate', new Date(moment(this.addInterviewer.vacations[index].vacation_end_date_text, 'MM-DD-YYYY').format('MM-DD-YYYY')))
    },
    availableStartDateChanged() {
      this.$set(this.endDateConfig, 'minDate', new Date(moment(this.start_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
    },
    availableEndDateChanged() {
      this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.end_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
    },
    availableStartTimeChanged() {
      this.$set(this.toTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockToTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockFromTimeConfig, 'minTime', moment(this.addInterviewer.available_start_time, 'hh:mm a').format('HH:mm'))
    },
    availableEndTimeChanged() {
      this.$set(this.fromTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockToTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
      this.$set(this.blockFromTimeConfig, 'maxTime', moment(this.addInterviewer.available_end_time, 'hh:mm a').format('HH:mm'))
    },
    blockStartTimeChanged() {
      this.$set(this.blockToTimeConfig, 'minTime', moment(this.addInterviewer.block_start_time, 'hh:mm a').format('HH:mm'))
    },
    blockEndTimeChanged() {
      this.$set(this.blockFromTimeConfig, 'maxTime', moment(this.addInterviewer.block_end_time, 'hh:mm a').format('HH:mm'))
    },
    removeVacation(index) {
      this.addInterviewer.vacations.splice(index, 1)
      this.vacationConfigs.splice(index, 1)
    },
    setEmployees(employee) {
      this.employees = employee
    },
    setStatus(status) {
      this.status = status
    },
    setDuration(duration) {
      this.durations = duration
    },
    setFrequencies(frequencies) {
      this.frequencies = frequencies
    },
    setWeekDays(days) {
      this.weekDays = days
    },
    setHolidays(days) {
      this.holidays = days
    },
    setCommunicationTypes(types) {
      this.comTypes = types
    },
    setSkills(skills) {
      this.skills = skills
    },
    addCommunication() {
      const communicationType = {
        type: null,
        typeId: null,
        value: '',
      }
      this.addInterviewer.communications.push(communicationType)
    },
    removeCommunication(index) {
      this.addInterviewer.communications.splice(index, 1)
    },
    technologySelected(id) {
      return this.addInterviewer.skill_ids.includes(id)
    },
    checked(id) {
      if (this.addInterviewer.skill_ids.includes(id)) {
        this.addInterviewer.skill_ids.splice(this.addInterviewer.skill_ids.indexOf(id), 1)
      } else {
        this.addInterviewer.skill_ids.push(id)
      }
    },
    removeTechnology(index) {
      this.addInterviewer.skill_ids.splice(index, 1)
    },
    interviewerUpdated(data) {
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    interviewerNotUpdated(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    communicationTypeMatch(typeId, index) {
      if (this.addInterviewer.communications.filter(communicationType => communicationType.type_id === typeId).length > 1) {
        this.addInterviewer.communications[index].type_id = null
      }
    },
    back() {
      this.$router.push('/administration/interviewers').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
