import TimesheetInvitations from '@/views/administration/timesheet-invitations/TimesheetInvitations.vue'
import TimesheetInvitationNew from '@/views/administration/timesheet-invitations/actions/New.vue'
import TimesheetInvitationEdit from '@/views/administration/timesheet-invitations/actions/Edit.vue'
import TimesheetInvitationView from '@/views/administration/timesheet-invitations/actions/View.vue'

import ProfileInvitations from '@/views/administration/profile-invitations/ProfileInvitations.vue'
import ProfileInvitationNew from '@/views/administration/profile-invitations/actions/New.vue'
import ProfileInvitationEdit from '@/views/administration/profile-invitations/actions/Edit.vue'
import ProfileInvitationView from '@/views/administration/profile-invitations/actions/View.vue'

import InvoiceInvitations from '@/views/administration/invoice-invitations/InvoiceInvitations.vue'
import InvoiceInvitationNew from '@/views/administration/invoice-invitations/actions/New.vue'
import InvoiceInvitationEdit from '@/views/administration/invoice-invitations/actions/Edit.vue'
import InvoiceInvitationView from '@/views/administration/invoice-invitations/actions/View.vue'
import JobInvitations from '@/views/administration/job-invitations/JobInvitations.vue'
import JobInvitationNew from '@/views/administration/job-invitations/actions/New.vue'
import JobInvitationEdit from '@/views/administration/job-invitations/actions/Edit.vue'
import JobInvitationView from '@/views/administration/job-invitations/actions/View.vue'
import MassCommunication from '@/views/administration/mass-communication/MassCommunication.vue'
import BatchprocessingReport from '@/views/administration/batchprocessing-report/BatchprocessingReport.vue'
import SkillsTechnologies from '@/views/administration/skills-technologies/SkillsTechnologies.vue'
import SkillsTechnologiesNew from '@/views/administration/skills-technologies/actions/New.vue'
import SkillsTechnologiesEdit from '@/views/administration/skills-technologies/actions/Edit.vue'
import SkillsTechnologiesView from '@/views/administration/skills-technologies/actions/View.vue'
import Interviewers from '@/views/administration/interviewers/Interviewers.vue'
import InterviewersDashboard from '@/views/administration/interviewers/InterviewersDashboard.vue'
import InterviewersNew from '@/views/administration/interviewers/actions/New.vue'
import InterviewersEdit from '@/views/administration/interviewers/actions/Edit.vue'
import InterviewersView from '@/views/administration/interviewers/actions/View.vue'
import Documents from '@/views/administration/documents/Documents.vue'
import DocumentsNew from '@/views/administration/documents/actions/New.vue'
import DocumentsEdit from '@/views/administration/documents/actions/Edit.vue'
import DocumentsView from '@/views/administration/documents/actions/View.vue'
import Workflow from '@/views/administration/workflow/Workflow.vue'
import WorkflowNew from '@/views/administration/workflow/actions/New.vue'
import WorkflowEdit from '@/views/administration/workflow/actions/Edit.vue'
import WorkflowView from '@/views/administration/workflow/actions/View.vue'
import WorkflowTasks from '@/views/administration/workflow-tasks/WorkflowTasks.vue'
import WorkflowTaskNew from '@/views/administration/workflow-tasks/actions/New.vue'
import WorkflowTaskEdit from '@/views/administration/workflow-tasks/actions/Edit.vue'
import WorkflowTaskClone from '@/views/administration/workflow-tasks/actions/Clone.vue'
import WorkflowTaskView from '@/views/administration/workflow-tasks/actions/View.vue'
import QboCategories from '@/views/administration/qbo-categories/qbo-categories.vue'
import QboCategoriesNew from '@/views/administration/qbo-categories/actions/New.vue'
import QboCategoriesEdit from '@/views/administration/qbo-categories/actions/Edit.vue'
import QboCategoriesView from '@/views/administration/qbo-categories/actions/View.vue'
import UserDefinedFields from '@/views/administration/user-defined-fields/UserDefinedFields.vue'
import SalaryCalculator from '@/views/administration/salary-calculator/SalaryCalculator.vue'

import H1BAdministration from '@/views/administration/h-1b-administration/H1BAdministration.vue'
import H1BAdministrationEdit from '@/views/administration/h-1b-administration/actions/Edit.vue'
import H1BAdministrationView from '@/views/administration/h-1b-administration/actions/View.vue'

import RoleUsers from '@/views/administration/role-users/RoleUsers.vue'
import RoleUsersNew from '@/views/administration/role-users/actions/New.vue'
import RoleUsersEdit from '@/views/administration/role-users/actions/Edit.vue'
import RoleUsersView from '@/views/administration/role-users/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Administration -------------------------------*
  // *===============================================---*
  // *------------ Profile Invitations --------------------*
  {
    path: '/administration/profile-invitations',
    name: 'administration-profile-invitations',
    component: ProfileInvitations,
  },
  {
    path: '/administration/profile-invitations/new',
    name: 'administration-profile-invitations-new',
    component: ProfileInvitationNew,
    meta: {
      navActiveLink: 'administration-profile-invitations',
    },
  },
  {
    path: '/administration/profile-invitations/edit/:id',
    name: 'administration-profile-invitations-edit',
    component: ProfileInvitationEdit,
    meta: {
      navActiveLink: 'administration-profile-invitations',
    },
  },
  {
    path: '/administration/profile-invitations/view/:id',
    name: 'administration-profile-invitations-view',
    component: ProfileInvitationView,
    meta: {
      navActiveLink: 'administration-profile-invitations',
    },
  },
  // *------------ Timesheet Invitations --------------------*
  {
    path: '/administration/timesheet-invitations',
    name: 'administration-timesheet-invitations',
    component: TimesheetInvitations,
  },
  {
    path: '/administration/timesheet-invitations/new',
    name: 'administration-timesheet-invitations-new',
    component: TimesheetInvitationNew,
    meta: {
      navActiveLink: 'administration-timesheet-invitations',
    },
  },
  {
    path: '/administration/timesheet-invitations/edit/:id',
    name: 'administration-timesheet-invitations-edit',
    component: TimesheetInvitationEdit,
    meta: {
      navActiveLink: 'administration-timesheet-invitations',
    },
  },
  {
    path: '/administration/timesheet-invitations/view/:id',
    name: 'administration-timesheet-invitations-view',
    component: TimesheetInvitationView,
    meta: {
      navActiveLink: 'administration-timesheet-invitations',
    },
  },
  // *------------ Invoice Invitations --------------------*
  {
    path: '/administration/invoice-invitations',
    name: 'administration-invoice-invitations',
    component: InvoiceInvitations,
  },
  {
    path: '/administration/invoice-invitations/new',
    name: 'administration-invoice-invitations-new',
    component: InvoiceInvitationNew,
    meta: {
      navActiveLink: 'administration-invoice-invitations',
    },
  },
  {
    path: '/administration/invoice-invitations/edit/:id',
    name: 'administration-invoice-invitations-edit',
    component: InvoiceInvitationEdit,
    meta: {
      navActiveLink: 'administration-invoice-invitations',
    },
  },
  {
    path: '/administration/invoice-invitations/view/:id',
    name: 'administration-invoice-invitations-view',
    component: InvoiceInvitationView,
    meta: {
      navActiveLink: 'administration-invoice-invitations',
    },
  },
  // *------------ Job Invitations --------------------*
  {
    path: '/administration/job-invitations',
    name: 'administration-job-invitations',
    component: JobInvitations,
  },
  {
    path: '/administration/job-invitations/new',
    name: 'administration-job-invitations-new',
    component: JobInvitationNew,
    meta: {
      navActiveLink: 'administration-job-invitations',
    },
  },
  {
    path: '/administration/job-invitations/edit/:id',
    name: 'administration-job-invitations-edit',
    component: JobInvitationEdit,
    meta: {
      navActiveLink: 'administration-job-invitations',
    },
  },
  {
    path: '/administration/job-invitations/view/:id',
    name: 'administration-job-invitations-view',
    component: JobInvitationView,
    meta: {
      navActiveLink: 'administration-job-invitations',
    },
  },
  // *------------ Mass Communication --------------------*
  {
    path: '/administration/mass-communication',
    name: 'administration-mass-communication',
    component: MassCommunication,
  },
  // *------------ Batch Processing Report --------------------*
  {
    path: '/administration/batch-process',
    name: 'administration-batch-process',
    component: BatchprocessingReport,
  },
  // *------------ Skills and Technologies --------------------*
  {
    path: '/administration/skills-and-technologies',
    name: 'administration-skills-and-technologies',
    component: SkillsTechnologies,
  },
  {
    path: '/administration/skills-and-technologies/new',
    name: 'administration-skills-and-technologies-new',
    component: SkillsTechnologiesNew,
    meta: {
      navActiveLink: 'administration-skills-and-technologies',
    },
  },
  {
    path: '/administration/skills-and-technologies/edit/:id',
    name: 'administration-skills-and-technologies-edit',
    component: SkillsTechnologiesEdit,
    meta: {
      navActiveLink: 'administration-skills-and-technologies',
    },
  },
  {
    path: '/administration/skills-and-technologies/view/:id',
    name: 'administration-skills-and-technologies-view',
    component: SkillsTechnologiesView,
    meta: {
      navActiveLink: 'administration-skills-and-technologies',
    },
  },
  // *------------ Interviewers --------------------*
  {
    path: '/administration/interviewers',
    name: 'administration-interviewers',
    component: Interviewers,
  },
  {
    path: '/administration/interviewers-dashboard',
    name: 'administration-interviewers-dashboard',
    component: InterviewersDashboard,
    meta: {
      navActiveLink: 'administration-interviewers',
    },
  },
  {
    path: '/administration/interviewers/new',
    name: 'administration-interviewers-new',
    component: InterviewersNew,
    meta: {
      navActiveLink: 'administration-interviewers',
    },
  },
  {
    path: '/administration/interviewers/edit/:id',
    name: 'administration-interviewers-edit',
    component: InterviewersEdit,
    meta: {
      navActiveLink: 'administration-interviewers',
    },
  },
  {
    path: '/administration/interviewers/view/:id',
    name: 'administration-interviewers-view',
    component: InterviewersView,
    meta: {
      navActiveLink: 'administration-interviewers',
    },
  },
  // *------------ Banks --------------------*
  // {
  //   path: '/administration/banks',
  //   name: 'administration-banks',
  //   component: Banks,
  // },
  // {
  //   path: '/administration/banks/new',
  //   name: 'administration-banks-new',
  //   component: BankNew,
  //   meta: {
  //     navActiveLink: 'administration-banks',
  //   },
  // },
  // {
  //   path: '/administration/banks/edit/:id',
  //   name: 'administration-banks-edit',
  //   component: BankEdit,
  //   meta: {
  //     navActiveLink: 'administration-banks',
  //   },
  // },
  // {
  //   path: '/administration/banks/view/:id',
  //   name: 'administration-banks-view',
  //   component: BankView,
  //   meta: {
  //     navActiveLink: 'administration-banks',
  //   },
  // },
  // *------------ Documents --------------------*
  {
    path: '/administration/documents',
    name: 'administration-documents',
    component: Documents,
  },
  {
    path: '/administration/documents/new',
    name: 'administration-documents-new',
    component: DocumentsNew,
    meta: {
      navActiveLink: 'administration-documents',
    },
  },
  {
    path: '/administration/documents/edit/:id',
    name: 'administration-documents-edit',
    component: DocumentsEdit,
    meta: {
      navActiveLink: 'administration-documents',
    },
  },
  {
    path: '/administration/documents/view/:id',
    name: 'administration-documents-view',
    component: DocumentsView,
    meta: {
      navActiveLink: 'administration-documents',
    },
  },
  // *------------ Workflow --------------------*
  {
    path: '/administration/workflow',
    name: 'administration-workflow',
    component: Workflow,
  },
  {
    path: '/administration/workflow/new',
    name: 'administration-workflow-new',
    component: WorkflowNew,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow/edit/:id',
    name: 'administration-workflow-edit',
    component: WorkflowEdit,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow/view/:id',
    name: 'administration-workflow-view',
    component: WorkflowView,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  // *------------ Workflow Tasks --------------------*
  {
    path: '/administration/workflow-tasks',
    name: 'administration-workflow-tasks',
    component: WorkflowTasks,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/new',
    name: 'administration-workflow-tasks-new',
    component: WorkflowTaskNew,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/edit/:id',
    name: 'administration-workflow-tasks-edit',
    component: WorkflowTaskEdit,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/clone/:id',
    name: 'administration-workflow-tasks-clone',
    component: WorkflowTaskClone,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/view/:id',
    name: 'administration-workflow-tasks-view',
    component: WorkflowTaskView,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  // *------------ Qbo Category --------------------*
  {
    path: '/administration/qbo-categories',
    name: 'administration-qbo-categories',
    component: QboCategories,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  {
    path: '/administration/qbo-categories/new',
    name: 'administration-qbo-categories-new',
    component: QboCategoriesNew,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  {
    path: '/administration/qbo-categories/edit/:id',
    name: 'administration-qbo-categories-edit',
    component: QboCategoriesEdit,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  {
    path: '/administration/qbo-categories/view/:id',
    name: 'administration-qbo-categories-view',
    component: QboCategoriesView,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  // *------------ Banks --------------------*
  // {
  //   path: '/administration/banks/new',
  //   name: 'administration-banks-new',
  //   component: BankNew,
  //   meta: {
  //     navActiveLink: 'administration-banks',
  //   },
  // },
  // {
  //   path: '/administration/banks/edit/:id',
  //   name: 'administration-banks-edit',
  //   component: BankEdit,
  //   meta: {
  //     navActiveLink: 'administration-banks',
  //   },
  // },
  // {
  //   path: '/administration/banks/view/:id',
  //   name: 'administration-banks-view',
  //   component:BankView,
  //   meta: {
  //     navActiveLink: 'administration-banks',
  //   },
  // },
  // *------------ User Defined Fields --------------------*
  {
    path: '/administration/user-defined-fields',
    name: 'administration-user-defined-fields',
    component: UserDefinedFields,
  },
  // *------------ Salary Calculator --------------------*
  {
    path: '/administration/salary-calculator',
    name: 'administration-salary-calculator',
    component: SalaryCalculator,
  },
  // *===============================================---*
  // *--------- H-1B Administration -------------------------------*
  // *===============================================---*
  // *------------ Administration --------------------*
  {
    path: '/administration/h-1b-administration',
    name: 'administration-h-1b-administration',
    component: H1BAdministration,
    meta: {
      navActiveLink: 'administration-h-1b-administration',
    },
  },
  {
    path: '/administration/h-1b-administration/edit/:id',
    name: 'administration-h-1b-administration-edit',
    component: H1BAdministrationEdit,
    meta: {
      navActiveLink: 'administration-h-1b-administration',
    },
  },
  {
    path: '/administration/h-1b-administration/view/:id',
    name: 'administration-h-1b-administration-view',
    component: H1BAdministrationView,
    meta: {
      navActiveLink: 'administration-h-1b-administration',
    },
  },
  // *------------ Role Users --------------------*
  {
    path: '/administration/role-users',
    name: 'administration-role-users',
    component: RoleUsers,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  {
    path: '/administration/role-users/new',
    name: 'administration-role-users-new',
    component: RoleUsersNew,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  {
    path: '/administration/role-users/edit/:id',
    name: 'administration-role-users-edit',
    component: RoleUsersEdit,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  {
    path: '/administration/role-users/view/:id',
    name: 'administration-role-users-view',
    component: RoleUsersView,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
]
