<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row class="mb-md-1">
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-md-flex align-items-center justify-content-end">
            <h4 class="card-title w-75 mb-0 pt-50">
              Invoices From Suppliers</h4>
            <b-form-group class="mb-md-0">
              <b-input-group>
                <flat-pickr
                  v-model="month"
                  class="form-control"
                  :config="$store.state.monthConfig"
                  placeholder="Select Month"
                  :clear-button="true"
                  @on-change="monthDateChanged"
                />
                <b-input-group-append>
                  <b-button
                    class="px-1 outline-grey"
                    variant="outline-secondary"
                    @click="removeMonthDate"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-130"
              :to="{ name: 'finance-and-accounting-invoices-from-suppliers-new' }"
            >
              Add Invoice
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="7 ml-auto"
          class="col-xxl-6"
        >
          <div class="business-card mb-1">
            <div class="business-items">
              <div class="business-item">
                <div class="budget-wrapper">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="mr-50">Bank Balance:</span>
                    <h2 class="mb-25 mr-2 text-primary">
                      {{ invoicesResponse.account_balance | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                    </h2>
                    <span class="mr-1">as of {{ invoicesResponse.balance_date }}</span>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      @click="fetchBalance()"
                    >
                      <feather-icon
                        size="21"
                        icon="RefreshCwIcon"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="invoicesResponse.invoice"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template slot="table-actions-bottom">
              <table
                v-if="invoicesResponse.show_total_amount && !(invoicesResponse.invoice.length !== 0 && page === 0)"
                class="vgt-table bordered"
              >
                <tfoot>
                  <tr>
                    <td class="text-right">
                      Total
                    </td>
                    <td
                      style="width:160px"
                    >
                      {{ invoicesResponse.total_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Currency -->
              <span v-if="props.column.field === 'cellRendererCurrency'">
                <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status.name)"
                  class="custom-color"
                >
                  {{ props.row.status.name }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-action"
              >
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item :to="{ name: 'finance-and-accounting-invoices-from-suppliers-edit', params: { id: props.row.id } }">
                      <feather-icon icon="Edit2Icon" />
                      <span class="ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-link
                    id="view"
                    :to="{ name: 'finance-and-accounting-invoices-from-suppliers-view', params: { id: props.row.id } }"
                  >
                    <b-badge
                      pill
                      variant="light-primary"
                    ><feather-icon icon="EyeIcon" />
                    </b-badge>
                  </b-link>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="invoicesResponse.count"
                    first-number
                    last-number
                    align="right"
                    :per-page="1"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BInputGroup, BFormInput, BFormGroup, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import moment from 'moment'
import SupplierInvoices from '@/js/invoicesupplier'
import Ripple from 'vue-ripple-directive'
import LIST from '@/js/lists'

export default {
  name: 'InvoicesSuppliers',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      month: moment().subtract(1, 'months').format('MMM-YYYY'),
      invoicesResponse: {
        invoices: [],
        count: 1,
        account_balance: 0.00,
        balance_date: '',
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Invoice#',
          field: 'invoice',
          sortable: false,
        },
        {
          label: 'Supplier',
          field: 'employer_name',
          sortable: false,
        },
        {
          label: 'Name Of Consultant',
          field: 'user_name',
          sortable: false,
        },
        {
          label: 'Period',
          field: 'period',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        SupplierInvoices.getInvoices(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Submitted  : 'light-primary',
        Approved   : 'light-success',
        Rejected   : 'light-danger',
        Corrected  : 'light-primary',
        Paid       : 'light-success',
        Partially  : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (this.$store.state.query.month === null) {
      this.month = moment().subtract(1, 'months').format('MMM-YYYY')
      this.$store.state.query.month = this.month
    } else {
      this.month = this.$store.state.query.month
    }
    this.searchQuery = this.$store.state.query.query
    this.invoicesResponse.count = this.$store.state.query.page
    SupplierInvoices.getInvoices(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.invoice}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          SupplierInvoices.delete(this, obj.id)
        }
      })
    },
    fetchBalance() {
      LIST.getBalance(this)
    },
    setBalance(balanceResponse) {
      this.invoicesResponse.account_balance = balanceResponse.balance
      this.invoicesResponse.balance_date = balanceResponse.date
    },
    invoiceDeleted() {
      SupplierInvoices.getInvoices(this)
      this.$swal({
        icon: 'success',
        title: 'Supplier Invoice Deleted!',
        text: 'Your Supplier Invoice has been deleted',
      })
    },
    invoiceNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Emoloyer Invoice Not Deleted!',
        text: 'Your Supplier Invoice has been not deleted',
      })
    },
    monthDateChanged(config, date) {
      this.month = date
      this.$store.state.query.month = this.month
      this.currentPage = 1
    },
    removeMonthDate() {
      this.month = null
      this.$store.state.query.month = this.month
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setInvoices(invoicesResponse) {
      this.invoicesResponse = invoicesResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-color.badge-secondary {
    background-color: rgba(40, 199, 111, 0.12);
    color: #28c76f !important;
}
</style>
