<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Schedule Interview</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Interviewer</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <v-select
                      id="interviewer_name"
                      v-model="addInterview.interviewer_id"
                      :options="interviewers"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="interviewerSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="inetrview_date"
                >
                  <label>Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="interview_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      @on-change="dateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Available Time</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Available Time"
                    rules="required"
                  >
                    <v-select
                      id="avilable_time"
                      v-model="sloat"
                      :options="availableSloats"
                      :reduce="item => item"
                      :clearable="false"
                      placeholder="Select"
                      @input="sloatSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="2"
              >
                <b-form-group>
                  <label>Custom Time From</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Time From"
                    rules="required"
                  >
                    <flat-pickr
                      id="custom_time_from"
                      v-model="addInterview.from_time"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K',}"
                      placeholder="00:00"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="2"
              >
                <b-form-group>
                  <label>Custom Time To</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Time To"
                    rules="required"
                  >
                    <flat-pickr
                      id="custom_time_to"
                      v-model="addInterview.to_time"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K',}"
                      placeholder="00:00"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Communication Mode</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Communication Mode"
                    rules="required"
                  >
                    <v-select
                      id="communication_mode"
                      v-model="addInterview.communication_mode_id"
                      :options="comTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-2"
              >
                <b-form-checkbox
                  id="send_invite"
                  v-model="addInterview.send_invite"
                >
                  Send Invite
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Reminder</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Reminder"
                    rules="required"
                  >
                    <v-select
                      id="reminder_id"
                      v-model="addInterview.reminder_id"
                      :options="reminders"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Reminder Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Reminder Type"
                    rules="required"
                  >
                    <v-select
                      id="reminder_type_id"
                      v-model="addInterview.reminder_type_id"
                      :options="reminderTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Candidate</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      id="employer_type"
                      v-model="addInterview.employer_type_id"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employer Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Employer Name"
                    rules="required"
                  >
                    <v-select
                      id="employer_name"
                      v-model="addInterview.employer_id"
                      :options="employers"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onParentChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <b-form-group>
                  <label>Candidate Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Candidate Name"
                    rules="required"
                  >
                    <v-select
                      id="candidate_name"
                      v-model="addInterview.employee_id"
                      :options="employees"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status_id"
                      v-model="addInterview.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="2"
                class="mt-2"
              >
                <b-form-checkbox
                  id="is_consent"
                  v-model="addInterview.is_consent"
                >
                  Consent
                </b-form-checkbox>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    id="document_id"
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addInterview.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1"
                >
                  <b-badge
                    :href="option.document_location"
                    :title="option.name"
                    target="_blank"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addInterview.comments"
                :key="index"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addInterview.user_defined_fields.length !== 0"
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addInterview.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      id="option"
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :rules="field.required ? 'required' : ''"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Interview
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormTextarea, BFormCheckbox, BButtonGroup, BButton, BBadge, BFormInput,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Interview from '@/js/interviews'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import Interviewers from '@/js/interviewers'

export default {
  name: 'InterviewNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormInput,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addInterview: {
        employee_id: null,
        employer_type_id: null,
        employer_id: null,
        date: null,
        interviewer_id: null,
        reminder_id: null,
        reminder_type_id: null,
        is_consent: false,
        status_id: null,
        from_time: null,
        to_time: null,
        communication_mode_id: null,
        send_invite: false,
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        end: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          howClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      sloat: {},
      url: null,
      interview_date: null,
      documentName: null,
      documentNameId: null,
      document_name: '',
      selectedFile: null,
      interviewers: [],
      types: [],
      employees: [],
      employers: [],
      status: [],
      comTypes: [],
      documentNames: [],
      availableSloats: [],
      reminders: [],
      reminderTypes: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getInterviewEmployeesFullList(this)
        LIST.getRemindersList(this)
        LIST.getReminderTypesList(this)
        LIST.getCommunicationTypesFullList(this)
        LIST.getInterviewersList(this)
        LIST.getHRBusinessPartnerParentTypes(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getUserDefinedFieldsExceptions(this)
        LIST.getInterviewStatusFullList(this)
        LIST.getInterviewDocumentNameFullList(this)
        LIST.getUserDefinedFieldsInterviews(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    if (this.$store.state.scheduleCandidateInteview.prospectId !== null && this.$store.state.scheduleCandidateInteview.prospectId !== undefined) {
      this.addInterview.employee_id = this.$store.state.scheduleCandidateInteview.prospectId
    }
    LIST.getHRBusinessPartnerParentTypes(this)
    LIST.getInterviewEmployeesFullList(this)
    LIST.getRemindersList(this)
    LIST.getReminderTypesList(this)
    LIST.getInterviewStatusFullList(this)
    LIST.getInterviewDocumentNameFullList(this)
    LIST.getInterviewersList(this)
    LIST.getCommunicationTypesFullList(this)
    LIST.getUserDefinedFieldsInterviews(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addInterview.documents.push({
              document_name_id: this.documentNameId,
              document_location: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.showLoading = true
          if (this.interview_date != null) {
            this.addInterview.date = Constants.getEprmMonthDateFromString(this.interview_date)
          }
          Interview.addInterview(this, this.addInterview)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addInterview.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addInterview.documents.splice(position, 1)
    },
    setCommunicationTypes(types) {
      this.comTypes = types
    },
    setRemindersList(reminders) {
      this.reminders = reminders
    },
    setReminderTypesList(reminderTypes) {
      this.reminderTypes = reminderTypes
    },
    setInterviewersList(interviewers) {
      this.interviewers = interviewers
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addInterview.documents.push({
          document_name_id: this.documentNameId, document_location: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setInterviewEmployees(employees) {
      this.employees = employees
    },
    setEmployers(vendors) {
      this.employers = vendors
    },
    setSuppliers(vendors) {
      this.employers = vendors
    },
    setReferrers(referrers) {
      this.employers = referrers
    },
    setVendors(vendors) {
      this.employers = vendors
    },
    setSubVendors(vendors) {
      this.employers = vendors
    },
    setAvailableTimeSloats(availableSloats) {
      this.availableSloats = availableSloats
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    sloatSelected() {
      this.addInterview.from_time = this.sloat.from_time
      this.addInterview.to_time = this.sloat.to_time
    },
    onParentChanged() {
      LIST.getEmployeeListByParentTypeAndParentId(this, this.addInterview.employer_type_id, this.addInterview.employer_id)
    },
    onTypeChanged() {
      this.addInterview.employer_id = null
      this.addInterview.employees = []
      LIST.getEmployeeListByParentType(this, this.addInterview.employer_type_id)
      if (this.addInterview.employer_type_id !== null) {
        const employerType = this.types.find(type => type.parent_type_id === this.addInterview.employer_type_id)
        switch (employerType.id) {
          case 'employer':
            LIST.getEmployersFullList(this)
            break
          case 'vendors':
            LIST.getVendorsFullList(this)
            break
          case 'suppliers':
            LIST.getSuppliersFullList(this)
            break
          case 'referrer':
            LIST.getReferrersFullList(this)
            break
          case 'subvendor':
            LIST.getSubVendorsFullList(this)
            break
          default:
            break
        }
      }
    },
    interviewDateChanged() {
      const start = moment(this.addInterview.interview_date)
      const end = moment(this.addInterview.interview_date)
      if (start.isAfter(end, 'day')) {
        this.addInterview.interview_date = start
      }
    },
    interviewerSelected() {
      if (this.addInterview.interviewer_id !== null && this.interview_date !== null) {
        Interviewers.getAvailableTimeSloatsByInterviewerIdAndDate(this, this.addInterview.interviewer_id, moment(this.interview_date, 'MM-DD-YYYY').format('DD'), moment(this.interview_date, 'MM-DD-YYYY').format('MM'), moment(this.interview_date, 'MM-DD-YYYY').format('YYYY'))
      }
    },
    dateChanged(selectedDate, dateStr) {
      this.interview_date = dateStr
      if (this.addInterview.interviewer_id !== null && this.interview_date !== null) {
        Interviewers.getAvailableTimeSloatsByInterviewerIdAndDate(this, this.addInterview.interviewer_id, moment(this.interview_date, 'MM-DD-YYYY').format('DD'), moment(this.interview_date, 'MM-DD-YYYY').format('MM'), moment(this.interview_date, 'MM-DD-YYYY').format('YYYY'))
      }
    },
    interviewTimeChanged() {
      const start = moment(this.addInterview.interview_time)
      const end = moment(this.addInterview.interview_time)
      if (start.isAfter(end, 'day')) {
        this.addInterview.interview_time = start
      }
    },
    interviewAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    interviewNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addInterview.comments.push(comment)
    },
    removeComment(index) {
      this.addInterview.comments.splice(index, 1)
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    back() {
      this.$store.state.scheduleCandidateInteview.prospectId = null
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
