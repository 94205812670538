import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.vjmsUrl
const vendorRecruiter = `${HOST}vendor-recruiter`

export default {
  getVendorRecruiters(context1) {
    const context = context1
    const params = { params: { status_id: context.$store.state.query.status_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(vendorRecruiter, params).then(response => {
      context.setVendorRecruiters(response.data)
    }, () => {
    })
  },
  addVendorRecruiter(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(vendorRecruiter, body).then(response => {
      context.$vs.loading.close()
      context.recruiterAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.recruiterNotAdded(error.response.data.return_message)
    })
  },
  updateRecruiter(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${vendorRecruiter}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.recruiterUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.recruiterNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${vendorRecruiter}/${id}`).then(response => {
      context.$vs.loading.close()
      context.recruiterDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.recruiterNotDeleted(error.response.data.return_message)
    })
  },
  getRecruiterById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${vendorRecruiter}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setRecruiter(response.data)
    }, error => {
      context.$vs.loading.close()
      context.recruiterNotFound(error.response.data.return_message)
    })
  },

}
