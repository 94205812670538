var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Apply for "+_vm._s(_vm.title))])]),_c('b-col',{attrs:{"cols":"12","md":"4 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Type")]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.types,"reduce":function (item) { return item.parent_type_id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.onTypeChanged()}},model:{value:(_vm.addCandidate.employer_type_id),callback:function ($$v) {_vm.$set(_vm.addCandidate, "employer_type_id", $$v)},expression:"addCandidate.employer_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Employer Name")]),_c('validation-provider',{attrs:{"name":"Employer Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.employers,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select","disabled":_vm.addCandidate.employer_type_id == null},model:{value:(_vm.addCandidate.employer_id),callback:function ($$v) {_vm.$set(_vm.addCandidate, "employer_id", $$v)},expression:"addCandidate.employer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("First Name")]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.addCandidate.first_name),callback:function ($$v) {_vm.$set(_vm.addCandidate, "first_name", $$v)},expression:"addCandidate.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.addCandidate.last_name),callback:function ($$v) {_vm.$set(_vm.addCandidate, "last_name", $$v)},expression:"addCandidate.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.addCandidate.email_address),callback:function ($$v) {_vm.$set(_vm.addCandidate, "email_address", $$v)},expression:"addCandidate.email_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Phone")]),_c('validation-provider',{attrs:{"name":"Phone","rules":"required|integer|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.addCandidate.phone_number),callback:function ($$v) {_vm.$set(_vm.addCandidate, "phone_number", $$v)},expression:"addCandidate.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.addCandidate.user_defined_fields.length !== 0),expression:"addCandidate.user_defined_fields.length !== 0"}],staticClass:"pb-1",attrs:{"variant":"danger"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("User Defined Fields")])]),_c('b-row',_vm._l((_vm.addCandidate.user_defined_fields),function(field){return _c('b-col',{key:field.id,attrs:{"id":field.id,"cols":"12","md":"6","lg":"4"}},[(field.type.toLowerCase() === 'input')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'dropdown')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":field.options,"label":"option","reduce":function (item) { return item.option; },"clearable":!field.required,"placeholder":"Select"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'textarea')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Apply ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }