<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-50 mb-md-0 pt-1 pt-md-50">
          Add Consultant</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Personal Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="addConsultant.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Middle Name</label>
                  <b-form-input
                    id="middle_name"
                    v-model="addConsultant.middle_name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="addConsultant.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="addConsultant.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="phone"
                      v-model="addConsultant.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Gender</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Gender"
                    rules="required"
                  >
                    <v-select
                      id="gender"
                      v-model="addConsultant.gender_id"
                      :options="genders"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="date_of_birth"
                >
                  <label>Date of Birth</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date of Birth"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="date_of_birth"
                      class="form-control"
                      :config="$store.state.notGraterThanToday"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>SSN</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="SSN"
                    rules="required|integer|digits:9"
                  >
                    <b-form-input
                      id="ssn"
                      v-model="addConsultant.ssn"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="consultant_start_date"
                >
                  <label>Consultant Start Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Consultant Start Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="consultant_start_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="consultant_end_date"
                >
                  <label>Consultant End Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="consultant_end_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      :clear-button="true"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeEmployeeEndDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="contract_start_date"
                >
                  <label>Contract Start Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="contract_start_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      :clear-button="true"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeContractStartDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="contract_end_date"
                >
                  <label>Contract End Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="contract_end_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      :clear-button="true"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeContractEndDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="addConsultant.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>End Client</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Client"
                  >
                    <v-select
                      id="end_client"
                      v-model="addConsultant.client_id"
                      :options="endClients"
                      :reduce="item => item.id"
                      :clearable="true"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Main Skill</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Main Skill"
                    rules="required"
                  >
                    <v-select
                      id="role"
                      v-model="addConsultant.skill_ids"
                      :options="skills"
                      :reduce="item => item.id"
                      multiple
                      :clearable="false"
                      placeholder="Select"
                      @input="skillSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Passport Number</label>
                  <b-form-input
                    id="passport_number"
                    v-model="addConsultant.passport_number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Secondary Skills</h6>
            </div>
            <div
              v-for="(technology, index) in addConsultant.technologies"
              :id="technology.id"
              :key="technology.id"
              class="d-flex"
            >
              <div class="left w-100">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Skill</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Skill"
                        rules="required"
                      >
                        <v-select
                          v-model="technology.technology_id"
                          :options="technologies"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                          @input="technologyTypeMatch(technology.technology_id, index)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Experience</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Experience"
                        rules="required"
                      >
                        <money
                          id="experience"
                          v-model="technology.experience"
                          :min="0"
                          :step="0.5"
                          v-bind="money1"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Rating</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Rating"
                        rules="required"
                      >
                        <money
                          id="rating"
                          v-model="technology.rating"
                          :min="0"
                          :step="0.5"
                          v-bind="money1"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div class="right mt-2 ml-1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeConsultantSkill(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
            </div>
            <div>
              <b-button
                id="add_employee_skill"
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="addConsultantSkill"
              >
                <feather-icon icon="PlusIcon" />
                Add Skill
              </b-button>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Visa Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      id="visa_type"
                      v-model="addConsultant.visa.type_id"
                      :options="visaTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="visa_expairy_date"
                >
                  <label>Expiry Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="visa_expairy_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      :clear-button="true"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeVisaExpairyDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-2"
              >
                <b-form-checkbox
                  id="visa_validated"
                  v-model="addConsultant.visa.validated"
                >
                  Validated
                </b-form-checkbox>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Contact Information</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 1</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address 1"
                    rules="required"
                  >
                    <b-form-input
                      id="address1"
                      v-model="addConsultant.address.address1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 2</label>
                  <b-form-input
                    id="address2"
                    v-model="addConsultant.address.address2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="addConsultant.address.city"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>State</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-select
                      id="state"
                      v-model="addConsultant.address.state_id"
                      :options="states"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Zip Code</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    rules="required|integer|digits:5"
                  >
                    <b-form-input
                      id="zip_code"
                      v-model="addConsultant.address.zip_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Additional Contact Information</h6>
            </div>
            <div
              ref="form"
              class="repeater-form"
            >
              <b-row
                v-for="(contactInfo, index) in addConsultant.contact_information"
                :id="contactInfo.id"
                :key="contactInfo.id"
                ref="row"
              >
                <b-col cols="12">
                  <div class="d-flex">
                    <div class="left">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Contact Type</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Contact Type' + index"
                              rules="required"
                            >
                              <v-select
                                id="contact_info_type"
                                v-model="addConsultant.contact_information[index].type_id"
                                :options="contactTypes"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                @input="contactTypeMatch(addConsultant.contact_information[index].type_id, index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Contact Type' + index, 'Contact Type') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Contact Name</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Contact Name' + index"
                              rules="required"
                            >
                              <b-form-input
                                id="contact_info_contact_name"
                                v-model="contactInfo.contact_name"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Contact Name' + index, 'Contact Name') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Email</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Email' + index"
                              rules="required|email"
                            >
                              <b-form-input
                                id="contact_info_email_address"
                                v-model="contactInfo.email_address"
                                :state="errors.length > 0 ? false:null"
                                type="email"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Email' + index, 'Email') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Phone</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Phone' + index"
                              rules="required|integer|digits:10"
                            >
                              <b-form-input
                                id="contact_info_phone_number"
                                v-model="contactInfo.phone_number"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Phone' + index, 'Phone') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Address 1</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Address 1' + index"
                              rules="required"
                            >
                              <b-form-input
                                id="contact_info_address"
                                v-model="contactInfo.address.address1"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Address 1' + index, 'Address 1') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Address 2</label>
                            <b-form-input
                              id="contact_info_address_address2"
                              v-model="contactInfo.address.address2"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>City</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'City' + index"
                              rules="required"
                            >
                              <b-form-input
                                id="contact_info_address_city"
                                v-model="contactInfo.address.city"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('City' + index, 'City') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>State</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'State' + index"
                              rules="required"
                            >
                              <v-select
                                id="contact_info_address_state"
                                v-model="contactInfo.address.state_id"
                                :options="states"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('State' + index, 'State') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Zip Code</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Zip Code' + index"
                              rules="required|integer|digits:5"
                            >
                              <b-form-input
                                id="contact_info_address_zip"
                                v-model="contactInfo.address.zip_id"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Zip Code' + index, 'Zip Code') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="right mt-1 ml-1">
                      <b-button
                        id="contact_info_remove"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="btn-icon rounded-circle"
                        @click="removeContactInfo(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <hr class="mt-0 mb-1">
                </b-col>
              </b-row>
            </div>
            <div v-if="contactTypes.length !== addConsultant.contact_information.length">
              <b-button
                id="add_contact_info"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="addContactInformation"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Additional Contact</span>
              </b-button>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Business Partners</h6>
            </div>
            <b-row
              v-for="(businessPartner, businessPartnerIndex) in business_partners"
              :id="businessPartnerIndex"
              :key="businessPartnerIndex"
              ref="form"
              class="repeater-form"
            >
              <b-col cols="12">
                <div class="d-flex">
                  <div class="left w-100">
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                        lg="4"
                      >
                        <b-form-group>
                          <label>Partner Type</label>
                          <validation-provider
                            v-slot="{ errors }"
                            :name="'Partner Type' + businessPartnerIndex"
                            rules="required"
                          >
                            <v-select
                              v-model="businessPartner.parent_type_id"
                              :options="parentTypes"
                              :reduce="item => item.parent_type_id"
                              :clearable="false"
                              placeholder="Select Business Partner"
                              @input="parentTypeMatch(businessPartner.parent_type_id, businessPartnerIndex)"
                            />
                            <small
                              class="text-danger"
                              v-text="errors[0] !== undefined ? errors[0].replace('Partner Type' + businessPartnerIndex, 'Partner Type') : ''"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                        lg="4"
                      >
                        <b-form-group>
                          <label>Name</label>
                          <validation-provider
                            v-slot="{ errors }"
                            :name="businessPartner.parent_type"
                            :rules="'required'"
                          >
                            <v-select
                              :id="businessPartner.partner"
                              v-model="businessPartner.user_id"
                              :options="businessPartner.parents"
                              :reduce="item => item.id"
                              :clearable="true"
                              placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="8"
                      >
                        <b-row>
                          <b-col
                            cols="12"
                            md="6"
                            lg="4"
                          >
                            <b-form-group>
                              <label>Payment Frequency</label>
                              <validation-provider
                                v-slot="{ errors }"
                                :name="businessPartner.parent_type + ' Payment Frequency'"
                                :rules="'required'"
                              >
                                <v-select
                                  v-model="businessPartner.type_id"
                                  :options="salaryTypes"
                                  :reduce="item => item.id"
                                  :clearable="true"
                                  placeholder="Select"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="6"
                            lg="4"
                          >
                            <b-form-group>
                              <label>Pay Rate</label>
                              <validation-provider
                                v-slot="{ errors }"
                                :name="businessPartner.parent_type + ' Pay Rate'"
                                rules="required"
                              >
                                <b-input-group
                                  prepend="$"
                                >
                                  <money
                                    v-model="businessPartner.amount"
                                    :min="0"
                                    :step="0.5"
                                    v-bind="money"
                                    class="form-control"
                                  />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="6"
                            lg="4"
                          >
                            <b-form-group>
                              <label>Discount</label>
                              <validation-provider
                                v-slot="{ errors }"
                                :name="businessPartner.parent_type + ' Discount'"
                                rules="required"
                              >
                                <b-input-group
                                  prepend="%"
                                >
                                  <money
                                    id="employer_discount"
                                    v-model="businessPartner.discount"
                                    :min="0"
                                    :step="0.5"
                                    v-bind="money"
                                    class="form-control"
                                  />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeBusinessPartner(businessPartnerIndex)"
                    >
                      <feather-icon
                        icon="XIcon"
                      />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <hr class="mt-0">
              </b-col>
            </b-row>
            <div>
              <b-button
                v-show="parentTypes.length > business_partners.length"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="addBusinessPartner"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Business Partner</span>
              </b-button>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addConsultant.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addConsultant.user_defined_fields.length !== 0"
            variant="danger"
            class="pb-1"
          >

            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row
              class="mt-1"
            >
              <b-col
                v-for="field in addConsultant.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Consultant
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Consultant from '@/js/consultants'
import moment from 'moment'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import Technology from '@/js/skillstechnologies'

export default {
  name: 'ConsultantNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
    BButton,
    BFormCheckbox,
    flatPickr,
    // currency,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addConsultant: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        ssn: '',
        gender_id: null,
        date_of_birth: null,
        consultant_start_date: null,
        consultant_end_date: null,
        contract_start_date: null,
        contract_end_date: null,
        client_id: null,
        status_id: null,
        skill_ids: null,
        role_ids: null,
        passport_number: '',
        visa: {
          type_id: null,
          expiration_date: null,
          validated: false,
        },
        address: {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: null,
        },
        business_partners: [],
        contact_information: [],
        comments: [],
        documents: [],
        user_defined_fields: [],
        skills: [],
        technologies: [],
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      money1: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 1,
        masked: false,
      },
      business_partners: [],
      date_of_birth: null,
      consultant_start_date: null,
      consultant_end_date: null,
      contract_start_date: null,
      contract_end_date: null,
      visa_expairy_date: null,
      selectedSkills: null,
      selectedRoles: null,
      selectedFile: null,
      documentNameId: null,
      genders: [],
      status: [],
      endClients: [],
      skills: [],
      roles: [],
      visaTypes: [],
      states: [],
      contactTypes: [],
      salaryTypes: [],
      frequencies: [],
      employers: [],
      suppliers: [],
      referrers: [],
      vendors: [],
      subVendors: [],
      documentNames: [],
      nextTodoId: 1,
      technologies: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRBusinessPartnerParentTypes(this)
        LIST.getStatesFullList(this)
        LIST.getVisaFullList(this)
        LIST.getFrequencyFullList(this)
        LIST.getGenderFullList(this)
        LIST.getSkillsFullList(this)
        LIST.getEndClientsFullList(this)
        LIST.getEmployersFullList(this)
        LIST.getSuppliersFullList(this)
        LIST.getReferrersFullList(this)
        LIST.getVendorsFullList(this)
        LIST.getSubVendorsFullList(this)
        LIST.getContactTypesFullList(this)
        LIST.getCompensationFullList(this)
        LIST.getTechnologies(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getConsultantStatusFullList(this)
        LIST.getConsultantsDocumentNameFullList(this)
        LIST.getUserDefinedFieldsEmployee(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getHRBusinessPartnerParentTypes(this)
    LIST.getStatesFullList(this)
    LIST.getConsultantStatusFullList(this)
    LIST.getVisaFullList(this)
    LIST.getConsultantsDocumentNameFullList(this)
    LIST.getCompensationFullList(this)
    LIST.getFrequencyFullList(this)
    LIST.getGenderFullList(this)
    LIST.getSkillsFullList(this)
    LIST.getEndClientsFullList(this)
    LIST.getEmployersFullList(this)
    LIST.getSuppliersFullList(this)
    LIST.getReferrersFullList(this)
    LIST.getVendorsFullList(this)
    LIST.getSubVendorsFullList(this)
    LIST.getContactTypesFullList(this)
    LIST.getTechnologies(this)
    LIST.getUserDefinedFieldsEmployee(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.date_of_birth !== null) {
            this.addConsultant.date_of_birth = Constants.getEprmMonthDateFromString(this.date_of_birth)
          }
          if (this.consultant_start_date !== null) {
            this.addConsultant.consultant_start_date = Constants.getEprmMonthDateFromString(this.consultant_start_date)
          }
          if (this.consultant_end_date !== null) {
            this.addConsultant.consultant_end_date = Constants.getEprmMonthDateFromString(this.consultant_end_date)
          }
          if (this.contract_start_date !== null) {
            this.addConsultant.contract_start_date = Constants.getEprmMonthDateFromString(this.contract_start_date)
          }
          if (this.contract_end_date !== null) {
            this.addConsultant.contract_end_date = Constants.getEprmMonthDateFromString(this.contract_end_date)
          }
          if (this.visa_expairy_date !== null) {
            this.addConsultant.visa.expiration_date = Constants.getEprmMonthDateFromString(this.visa_expairy_date)
          }
          this.addConsultant.business_partners = []
          this.business_partners.forEach(businessPartner => {
            const parentDetails = {
              parent_type_id: businessPartner.parent_type_id,
              user_id: businessPartner.user_id,
              type_id: businessPartner.type_id,
              amount: businessPartner.amount,
              discount: businessPartner.discount,
            }
            this.addConsultant.business_partners.push(parentDetails)
          })

          this.showLoading = true
          Consultant.addConsultant(this, this.addConsultant)
        }
      })
    },
    contactTypeMatch(typeId, index) {
      if (this.addConsultant.contact_information.filter(contactInformation => contactInformation.type_id === typeId).length > 1) {
        this.addConsultant.contact_information[index].type_id = null
      }
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addConsultant.documents.splice(position, 1)
    },
    setPartners(parentTypes) {
      this.parentTypes = parentTypes
    },
    parentTypeMatch(typeId, index) {
      this.business_partners[index].user_id = null
      if (this.business_partners.filter(businessPartner => businessPartner.parent_type_id === typeId).length > 1) {
        this.business_partners[index].parent_type_id = null
        return
      }
      const partner = this.parentTypes.find(parentType => parentType.parent_type_id === typeId)
      this.business_partners[index].parent_type = partner.label
      switch (partner.id) {
        case 'employer':
          this.business_partners[index].parents = this.employers
          break
        case 'vendors':
          this.business_partners[index].parents = this.vendors
          break
        case 'suppliers':
          this.business_partners[index].parents = this.suppliers
          break
        case 'referrer':
          this.business_partners[index].parents = this.referrers
          break
        case 'subvendor':
          this.business_partners[index].parents = this.subVendors
          break
        default:
          break
      }
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addConsultant.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    addConsultantSkill() {
      const employeeSkill = {
        skill_id: null,
      }
      this.addConsultant.technologies.push(employeeSkill)
    },
    removeConsultantSkill(index) {
      this.addConsultant.technologies.splice(index, 1)
    },
    addContactInformation() {
      const contactInfo = {
        type_id: null,
        contact_name: '',
        email_address: '',
        phone_number: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: null,
        },
      }
      this.addConsultant.contact_information.push(contactInfo)
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeContactInfo(index) {
      this.addConsultant.contact_information.splice(index, 1)
    },
    skillSelected() {
      Technology.getTechnologiesBySkillIds(this, this.addConsultant.skill_ids)
    },
    setSkillTechnologies(technologies) {
      const existingTechnologyIds = []
      const newTechnologyIds = []
      for (let i = 0; i < this.addConsultant.technologies.length; i += 1) {
        existingTechnologyIds.push(this.addConsultant.technologies[i].technology_id)
      }
      for (let i = 0; i < technologies.length; i += 1) {
        newTechnologyIds.push(technologies[i].id)
      }
      this.addConsultant.technologies = this.addConsultant.technologies.filter(technology => technology.experience !== 0 || technology.rating !== 0 || newTechnologyIds.includes(technology.technology_id))

      technologies.forEach(technology => {
        if (!existingTechnologyIds.includes(technology.id)) {
          const newTechnology = {
            technology_id: technology.id,
            rating: 0,
            experience: 0,
          }
          this.addConsultant.technologies.push(newTechnology)
        }
      })
    },
    removeBusinessPartner(businessPartnerIndex) {
      this.business_partners.splice(businessPartnerIndex, 1)
    },
    addBusinessPartner() {
      const parentDetails = {
        parent_type_id: null,
        parents: [],
        parent_type: '',
        user_id: null,
        type_id: null,
        amount: '',
        discount: '',
      }
      this.business_partners.push(parentDetails)
    },
    setTechnologies(technologies) {
      this.technologies = technologies
    },
    setUserDefinedFields(userDefinedFields) {
      this.addConsultant.user_defined_fields = userDefinedFields
    },
    technologyTypeMatch(technologyId, index) {
      if (this.addConsultant.technologies.filter(technology => technology.technology_id === technologyId).length > 1) {
        this.addConsultant.technologies[index].technology_id = null
      }
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setFrequencies(frequencies) {
      this.frequencies = frequencies
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setContactTypes(contactTypes) {
      this.contactTypes = contactTypes
    },
    setStatus(status) {
      this.status = status
    },
    setSalaryTypes(salaryTypes) {
      this.salaryTypes = salaryTypes
    },
    setGenders(genders) {
      this.genders = genders
    },
    setStates(states) {
      this.states = states
    },
    setEndClients(endClients) {
      this.endClients = endClients
    },
    setSkills(skills) {
      this.skills = skills
    },
    setRoles(roles) {
      this.roles = roles
    },
    setEmployers(employers) {
      this.employers = employers
    },
    setSuppliers(suppliers) {
      this.suppliers = suppliers
    },
    setReferrers(referrers) {
      this.referrers = referrers
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setSubVendors(subVendors) {
      this.subVendors = subVendors
    },
    setPaymentFrequency(paymentFrequency) {
      this.paymentFrequency = paymentFrequency
    },
    removeEmployeeEndDate() {
      this.consultant_end_date = null
    },
    removeContractStartDate() {
      this.contract_start_date = null
    },
    removeContractEndDate() {
      this.contract_end_date = null
    },
    removeVisaExpairyDate() {
      this.visa_expairy_date = null
    },
    consultantAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        html: Constants.getParentSuccessMessage(data.return_message),
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: Constants.getUploadButtonText(),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push(`/documents/document-tracker/new?parent_id=${data.id}&parent_type_id=${data.parent_type_id}`).catch(() => {})
        }
      })
    },
    consultantNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addConsultant.comments.push(comment)
    },
    removeComment(index) {
      this.addConsultant.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  transition: .35s height;
}
</style>
