<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-1">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-2"
        >
          <h4 class="card-title mb-0 pt-50">
            Profit Amounts Per Resource</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
          class="col-xxl-4 mb-1"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="From"
            label-cols-md="4"
            class="text-right"
          >
            <b-input-group>
              <flat-pickr
                v-model="from_date"
                class="form-control"
                :config="$store.state.config"
                placeholder="MM-DD-YYYY"
                clear-button="true"
                @on-change="startDateChanged"
              />
              <b-input-group-append>
                <b-button
                  class="px-1 outline-grey"
                  variant="outline-secondary"
                  @click="removeStartDate"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="To"
            label-cols-md="4"
            class="text-right"
          >
            <b-input-group>
              <flat-pickr
                v-model="to_date"
                class="form-control"
                :config="$store.state.config"
                placeholder="MM-DD-YYYY"
                clear-button="true"
                @on-change="endDateChanged"
              />
              <b-input-group-append>
                <b-button
                  class="px-1 outline-grey"
                  variant="outline-secondary"
                  @click="removeEndDate"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Status"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="status_id"
              v-model="status_id"
              :options="status"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Status"
              @input="statusChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="reportResponse.profit"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Bill Rate -->
        <span v-if="props.column.field === 'cellRendererBillRate'">
          <span>{{ props.row.bill_rate| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Expense -->
        <span v-if="props.column.field === 'cellRendererBilledAmount'">
          <span>{{ props.row.billed_amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Expense -->
        <span v-if="props.column.field === 'cellRendererExpenseAmount'">
          <span>{{ props.row.expenses_amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Profit -->
        <span v-if="props.column.field === 'cellRendererProfitAmount'">
          <span>{{ props.row.profit| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="reportResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import Financialstatement from '@/js/financialstatement'
import LIST from '@/js/lists'
import moment from 'moment'

export default {
  name: 'ProfitAmountsPerResource',
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BPagination,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    VueGoodTable,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      status_id: null,
      status: [],
      searchQuery: '',
      pageLength: 10,
      dir: false,
      from_date: null,
      to_date: null,
      reportResponse: {
        profit: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Consultant / Employee',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Bill Rate ($/HR)',
          field: 'cellRendererBillRate',
          sortable: false,
        },
        {
          label: 'Billed Amount ($)',
          field: 'cellRendererBilledAmount',
          sortable: false,
        },
        {
          label: 'Expense Amount ($)',
          field: 'cellRendererExpenseAmount',
          sortable: false,
        },
        {
          label: 'Profit Amount ($)',
          field: 'cellRendererProfitAmount',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Financialstatement.getProfitAmount(this, this.from_date, this.to_date)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getEmployeeStatusFullList(this)
      },
    },
  },
  created() {
    this.status_id = this.$store.state.query.status_id
    this.searchQuery = this.$store.state.query.query
    if (this.$store.state.query.startdate !== null) {
      this.from_date = moment(this.$store.state.query.startdate).format('MM-DD-YYYY')
    }
    if (this.$store.state.query.endDate !== null) {
      this.to_date = moment(this.$store.state.query.endDate).format('MM-DD-YYYY')
    }
    this.reportResponse.count = this.$store.state.query.page
    LIST.getEmployeeStatusFullList(this)
    Financialstatement.getProfitAmount(this, this.from_date, this.to_date)
  },
  methods: {
    startDateChanged(config, date) {
      this.from_date = date
      this.$store.state.query.startdate = this.from_date
      this.currentPage = 1
    },
    endDateChanged(config, date) {
      this.to_date = date
      this.$store.state.query.endDate = this.to_date
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    removeEndDate() {
      this.to_date = null
      this.currentPage = 1
    },
    removeStartDate() {
      this.from_date = null
      this.currentPage = 1
    },
    setStatus(status) {
      this.status = status
    },
    statusChanged() {
      this.$store.state.query.status_id = this.status_id
      this.currentPage = 1
    },
    setReport(reportResponse) {
      this.reportResponse = reportResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
