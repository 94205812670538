import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const prospectAdministration = `${HOST}prospect-administration`

export default {
  getProspectBySkill(context1) {
    const context = context1
    axios.get(`${prospectAdministration}/by-skill`).then(response => {
      context.setProspectBySkillCount(response.data)
    }, error => {
      context.setProspectBySkillCountNotRender(error.response.data.id)
    })
  },
  getProspectsByFilter(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${prospectAdministration}/emails`, body).then(response => {
      context.$vs.loading.close()
      context.emailsFetched(response.data)
    }, error => {
      context.$vs.loading.close()
      context.emailsNotFetched(error.response.data.return_message)
    })
  },

}
