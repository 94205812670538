import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const consultant = `${HOST}consultant`

export default {
  getConsultants(context1) {
    const context = context1
    const params = { params: { status_id: context.$store.state.query.status_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(consultant, params).then(response => {
      context.setConsultants(response.data)
    }, () => {
    })
  },
  addConsultant(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(consultant, body).then(response => {
      context.$vs.loading.close()
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
      context.consultantAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.consultantNotAdded(error.response.data.return_message)
    })
  },
  updateConsultant(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${consultant}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.consultantUpdated(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.consultantNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${consultant}/${id}`).then(response => {
      context.$vs.loading.close()
      context.consultantDeleted(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.consultantNotDeleted(error.response.data.return_message)
    })
  },
  getConsultantById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${consultant}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setConsultant(response.data)
    }, error => {
      context.$vs.loading.close()
      context.consultantNotFound(error.response.data.return_message)
    })
  },

}
