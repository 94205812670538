import store from '@/store'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Companies',
    route: 'companies',
    icon: 'LayersIcon',
  },
  {
    title: 'Human Resources',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Workforce',
        route: 'human-resources-workforce',
      },
      // {
      //   title: 'Employees',
      //   route: 'human-resources-employees',
      // },
      // {
      //   title: 'Consultants',
      //   route: 'human-resources-consultants',
      // },
      // {
      //   title: 'Prospects',
      //   route: 'human-resources-prospects',
      // },
      {
        title: 'Business Partner',
        route: 'human-resources-business-partner',
      },
      // {
      //   title: 'Employers',
      //   route: 'human-resources-employers',
      // },
      // {
      //   title: 'Vendors',
      //   route: 'human-resources-vendors',
      // },
      // {
      //   title: 'Sub Vendors',
      //   route: 'human-resources-sub-vendors',
      // },
      // {
      //   title: 'Suppliers',
      //   route: 'human-resources-suppliers',
      // },
      // {
      //   title: 'Referrers':,
      //   route: 'human-resources-referrers',
      // },
      // {
      //   title: 'End Clients',
      //   route: 'human-resources-end-clients',
      // },
      {
        title: 'Onboarding',
        route: 'human-resources-onboarding',
      },
      {
        title: 'Interviews',
        route: 'human-resources-interviews',
      },
      {
        title: 'Jobs',
        route: 'human-resources-jobs',
      },
      {
        title: 'Prospect Administration',
        route: 'human-resources-prospect-administration-dashboard',
      },
      {
        title: 'Exceptions',
        route: 'human-resources-exceptions',
      },
    ],
  },
  {
    title: 'Finance & Accounting',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Timesheets',
        route: 'finance-and-accounting-timesheets',
      },
      {
        title: 'Business Partner Invoices',
        route: 'finance-and-accounting-business-partner-invoices',
      },
      // {
      //   title: 'Invoices To Vendors',
      //   route: 'finance-and-accounting-invoices-to-vendors',
      // },
      // {
      //   title: 'Invoices From Employers',
      //   route: 'finance-and-accounting-invoices-from-employers',
      // },
      // {
      //   title: 'Invoices From Sub-Vendors',
      //   route: 'finance-and-accounting-invoices-from-subvendors',
      // },
      // {
      //   title: 'Invoices From Suppliers',
      //   route: 'finance-and-accounting-invoices-from-suppliers',
      // },
      // {
      //   title: 'Invoices From Referrers',
      //   route: 'finance-and-accounting-invoices-from-referrers',
      // },
      {
        title: 'Reconcilation',
        children: [
          {
            title: 'Bank Reconcile History',
            route: 'finance-and-accounting-bank-reconcile-history',
            icon: 'CircleIcon',
          },
        ],
      },
      {
        title: 'Expenses',
        route: 'finance-and-accounting-expenses',
      },
      {
        title: 'Employee Salaries',
        route: 'finance-and-accounting-salaries',
      },
    ],
  },
  {
    title: 'Financial Statements',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'financial-statements-dashboard',
      },
      {
        title: 'Profit Rate Per Resource',
        route: 'financial-statements-profit-rate-per-resource',
      },
      {
        title: 'Profit Amounts Per Resource',
        route: 'financial-statements-profit-amounts-per-resource',
      },
      {
        title: 'Statements',
        route: 'financial-statements-statements',
      },
      {
        title: 'Alerts',
        tag: store.state.alerts,
        tagVariant: 'light-danger',
        route: 'financial-statements-alerts',
      },
      {
        title: 'Reports',
        route: 'financial-statements-reports',
      },
    ],
  },
  {
    title: 'Forecasting',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Reports',
        route: 'forecasting-reports',
      },
    ],
  },
  {
    title: 'Administration',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Profile Invitations',
        route: 'administration-profile-invitations',
      },
      {
        title: 'Timesheet Invitations',
        route: 'administration-timesheet-invitations',
      },
      {
        title: 'Invoice Invitations',
        route: 'administration-invoice-invitations',
      },
      {
        title: 'Job Invitations',
        route: 'administration-job-invitations',
      },
      {
        title: 'Mass Communication',
        route: 'administration-mass-communication',
      },
      {
        title: 'Batch Processing Report',
        route: 'administration-batch-process',
      },
      {
        title: 'Skills and Technologies',
        route: 'administration-skills-and-technologies',
      },
      // {
      //   title: 'Banks',
      //   route: 'administration-banks',
      // },
      {
        title: 'Documents',
        route: 'administration-documents',
      },
      {
        title: 'Interviewers',
        route: 'administration-interviewers',
      },
      {
        title: 'Workflow',
        route: 'administration-workflow',
      },
      {
        title: 'Qbo Category',
        route: 'administration-qbo-categories',
      },
      {
        title: 'Salary Calculator',
        route: 'administration-salary-calculator',
      },
      {
        title: 'User Defined Fields',
        route: 'administration-user-defined-fields',
      },
      {
        title: 'H-1B Administration',
        icon: 'FileTextIcon',
        route: 'administration-h-1b-administration',
      },
      {
        title: 'Role Users',
        route: 'administration-role-users',
      },
    ],
  },
  {
    title: 'Vendor Jobs Management',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'vendor-jobs-management-dashboard',
      },
      {
        title: 'Job Requests',
        route: 'vendor-jobs-management-job-requests',
      },
      {
        title: 'Vendor Recruiters',
        route: 'vendor-jobs-management-vendor-recruiters',
      },
      {
        title: 'Main Skills',
        route: 'vendor-jobs-management-main-skills',
      },
      {
        title: 'Secondary Skills',
        route: 'vendor-jobs-management-secondary-skills',
      },
    ],
  },
  {
    title: 'Prospect Administration',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'prospect-administration-dashboard',
      },
    ],
  },
  {
    title: 'Employee Administration',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Daily Updates',
        route: 'employee-daily-updates',
      },
    ],
  },
  {
    title: 'Documents',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Document Tracker',
        route: 'documents-document-tracker',
      },
      {
        title: 'Required Documents',
        route: 'documents-required-documents',
      },
      // {
      //   title: 'Templates',
      //   route: 'documents-templates',
      // },
    ],
  },
  {
    title: 'E-Signature',
    icon: 'UsersIcon',
    children: [
      {
        title: 'E-Signature',
        route: 'e-signature',
      },
      {
        title: 'Templates',
        route: 'e-signature-templates',
      },
    ],
  },
  {
    title: 'Tasks',
    route: 'tasks',
    icon: 'ListIcon',
  },
  {
    title: 'Exceptions',
    route: 'exceptions',
    icon: 'AlertTriangleIcon',
  },
]
