import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const endClient = `${HOST}end-client`

export default {
  getEndClients(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(endClient, params).then(response => {
      context.setEndClients(response.data)
    }, () => {
    })
  },
  addEndClient(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(endClient, body).then(response => {
      context.$vs.loading.close()
      context.endClientAdded(response.data)
      context.$store.state.lists.commonLists.lists['end-clients'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.endClientNotAdded(error.response.data.return_message)
    })
  },
  updateEndClient(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${endClient}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.endClientUpdated(response.data)
      context.$store.state.lists.commonLists.lists['end-clients'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.endClientNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${endClient}/${id}`).then(response => {
      context.$vs.loading.close()
      context.parentDeleted(response.data)
      context.$store.state.lists.commonLists.lists['end-clients'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.parentNotDeleted(error.response.data.return_message)
    })
  },
  getEndClientById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${endClient}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEndClient(response.data)
    }, error => {
      context.$vs.loading.close()
      context.endClientNotFound(error.response.data.return_message)
    })
  },

}
