import axios from 'axios'
import moment from 'moment'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const subvendorInvoice = `${HOST}invoice-subvendor`

export default {
  getInvoices(context1) {
    const context = context1
    const queryParams = { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 }
    if (context.$store.state.query.month !== null && context.$store.state.query.month !== '') {
      queryParams.start_date = 1
      queryParams.start_month = moment(context.$store.state.query.month, 'MMM-YYYY').format('MM')
      queryParams.start_year = moment(context.$store.state.query.month, 'MMM-YYYY').format('YYYY')
    }
    const params = { params: queryParams }
    axios.get(subvendorInvoice, params).then(response => {
      context.setInvoices(response.data)
    }, () => {
    })
  },
  addInvoice(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(subvendorInvoice, body).then(response => {
      context.$vs.loading.close()
      context.invoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotAdded(error.response.data.return_message)
    })
  },
  updateInvoice(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${subvendorInvoice}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.invoiceUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${subvendorInvoice}/${id}`).then(response => {
      context.$vs.loading.close()
      context.invoiceDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotDeleted(error.response.data.return_message)
    })
  },
  getInvoiceById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${subvendorInvoice}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setInvoice(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotFound(error.response.data.return_message)
    })
  },

}
