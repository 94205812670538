<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Vendor Job</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <b-tabs class="vue-form-wizard">
      <b-tab
        active
        class="p-2"
      >
        <template #title>
          <div class="wizard-icon-circle md square_shape">
            <i class="wizard-icon">1</i>
          </div>
          <span class="font-weight-bold">Job Details</span>
        </template>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Vendor Details</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <span
                    class="form-control h-auto"
                    v-text="jobRequest.vendor"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Job-Id / Ref-No</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.job_id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Recruiter</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.recruiter"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Position Details</h6>
            </div>
            <b-row class="mt-1">
              <b-col cols="4">
                <b-form-group>
                  <label>Title</label>
                  <span
                    class="form-control h-auto"
                    v-text="jobRequest.title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label>Main Skill</label>
                  <span
                    class="form-control h-auto"
                    v-text="jobRequest.main_skill"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Term</label>
                  <b-input-group append="Months">
                    <span
                      class="form-control"
                      v-text="jobRequest.term"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Rate</label>
                  <span class="form-control">{{ jobRequest.rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Interviews</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.rounds"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="start_date"
                >
                  <label>Start Date</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.start_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="end_date"
                >
                  <label>End Date</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.end_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Education</label>
                  <span
                    class="form-control h-auto"
                    v-text="jobRequest.qualification"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Exp Required</label>
                  <b-input-group append="Years">
                    <span
                      class="form-control"
                      v-text="jobRequest.experience"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label># Positions</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.no_positions"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City, Zip</label>
                  <span
                    class="form-control h-auto"
                    v-text="jobRequest.city"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.status"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Working Status</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.working_status"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>End Client</label>
                  <span
                    class="form-control"
                    v-text="jobRequest.client"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Visa Types</label>
                  <span class="form-control h-auto">
                    <b-badge
                      v-for="(visa_type, index) in jobRequest.visa_types"
                      :key="index"
                      variant="light-primary"
                      class="m-25"
                    >
                      {{ visa_type }}
                    </b-badge>
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Shift Days</label>
                  <b-input-group append="Days">
                    <span
                      class="form-control"
                      v-text="jobRequest.shift_days"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Shift Hours</label>
                  <b-input-group append="Hours">
                    <span
                      class="form-control"
                      v-text="jobRequest.shift_hours"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                v-for="job_start_time in jobRequest.job_start_times"
                :key="job_start_time"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Shift Start Time</label>
                  <span
                    class="form-control"
                    v-text="job_start_time.time"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label>Job Duties / Responsibilities</label>
                  <span
                    class="form-control height-auto quill-view job-description-view"
                    v-html="jobRequest.description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="comment in jobRequest.comments"
                :key="comment"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
                  <b-form-textarea
                    v-model="comment.comment"
                    rows="2"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-if="jobRequest.comments.length == 0"
              class="text-center"
            >
              <p>
                No Comments available
              </p>
            </div>
          </app-timeline-item>
        </app-timeline>
      </b-tab>

      <b-tab
        class="p-2"
      >

        <!-- title -->
        <template #title>
          <div class="wizard-icon-circle md square_shape">
            <i class="wizard-icon">2</i>
          </div>
          <span class="font-weight-bold">Job Skills</span>
        </template>
        <b-table
          :items="jobRequest.existing_skills"
          :fields="fields"
          striped
          responsive
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(reqd)="row">
            <span v-text="jobRequest.existing_skills[row.index].required ? 'Yes' : 'No'" />
          </template>
          <template #cell(preference)="row">
            <span v-text="jobRequest.existing_skills[row.index].preferred ? 'Yes' : 'No'" />
          </template>
          <template #cell(exp_years)="row">
            <span v-text="jobRequest.existing_skills[row.index].experience" />
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BButton, BTable, BFormTextarea, BBadge, BTabs, BTab,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import JobRequests from '@/js/vendorjob'

export default {
  name: 'JobRequestView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BButton,
    BBadge,
    BTabs,
    BTab,
    // currency,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      jobRequest: {},
      fields: [{ key: 'index', label: 'Index' }, { key: 'label', label: 'Skill' }, { key: 'reqd', label: 'Reqd' }, { key: 'preference', label: 'Preference' }, { key: 'exp_years', label: 'Exp Years' }],
      items: [
        {
          skill: 'Java',
          reqd: '',
          preference: '',
          exp_years: '',
        },
        {
          skill: 'HTML',
          reqd: '',
          preference: '',
          exp_years: '',
        },
        {
          skill: 'SQL',
          reqd: '',
          preference: '',
          exp_years: '',
        },
      ],
    }
  },
  created() {
    JobRequests.getVendorJobById(this, this.$route.params.id)
  },
  methods: {
    setVendorJob(vendorJob) {
      this.jobRequest = vendorJob
    },
    formSubmitted() {
      this.$toast({
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    back() {
      this.$router.push('/vendor-jobs-management/job-requests').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
