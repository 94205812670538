import Timesheets from '@/views/finance-accounting/timesheets/Timesheets.vue'
import TimesheetNew from '@/views/finance-accounting/timesheets/actions/New.vue'
import TimesheetEdit from '@/views/finance-accounting/timesheets/actions/Edit.vue'
import TimesheetView from '@/views/finance-accounting/timesheets/actions/View.vue'
import Invoices from '@/views/finance-accounting/business-partner-invoices/Invoices.vue'
import InvoicesVendors from '@/views/finance-accounting/invoices-vendors/InvoicesVendors.vue'
import InvoicesVendorsEdit from '@/views/finance-accounting/invoices-vendors/actions/Edit.vue'
import InvoicesVendorsView from '@/views/finance-accounting/invoices-vendors/actions/View.vue'
import InvoicesEmployers from '@/views/finance-accounting/invoices-employers/InvoicesEmployers.vue'
import InvoicesEmployersNew from '@/views/finance-accounting/invoices-employers/actions/New.vue'
import InvoicesEmployersEdit from '@/views/finance-accounting/invoices-employers/actions/Edit.vue'
import InvoicesEmployersView from '@/views/finance-accounting/invoices-employers/actions/View.vue'
import InvoicesSubVendors from '@/views/finance-accounting/invoices-subvendors/InvoicesSubVendors.vue'
import InvoicesSubVendorsNew from '@/views/finance-accounting/invoices-subvendors/actions/New.vue'
import InvoicesSubVendorsEdit from '@/views/finance-accounting/invoices-subvendors/actions/Edit.vue'
import InvoicesSubVendorsView from '@/views/finance-accounting/invoices-subvendors/actions/View.vue'
import InvoicesSuppliers from '@/views/finance-accounting/invoices-suppliers/InvoicesSuppliers.vue'
import InvoicesSuppliersNew from '@/views/finance-accounting/invoices-suppliers/actions/New.vue'
import InvoicesSuppliersEdit from '@/views/finance-accounting/invoices-suppliers/actions/Edit.vue'
import InvoicesSuppliersView from '@/views/finance-accounting/invoices-suppliers/actions/View.vue'
import InvoicesReferrers from '@/views/finance-accounting/invoices-referrers/InvoicesReferrers.vue'
import InvoicesReferrersNew from '@/views/finance-accounting/invoices-referrers/actions/New.vue'
import InvoicesReferrersEdit from '@/views/finance-accounting/invoices-referrers/actions/Edit.vue'
import InvoicesReferrersView from '@/views/finance-accounting/invoices-referrers/actions/View.vue'
import Expenses from '@/views/finance-accounting/expenses/Expenses.vue'
import ExpensesNew from '@/views/finance-accounting/expenses/actions/New.vue'
import ExpensesEdit from '@/views/finance-accounting/expenses/actions/Edit.vue'
import ExpensesView from '@/views/finance-accounting/expenses/actions/View.vue'
import BankReconcileHistory from '@/views/finance-accounting/bank-reconcile-history/BankReconcileHistory.vue'
import BankReconcileHistoryNew from '@/views/finance-accounting/bank-reconcile-history/actions/New.vue'
import BankReconcileHistoryEdit from '@/views/finance-accounting/bank-reconcile-history/actions/Edit.vue'
import BankReconcileHistoryView from '@/views/finance-accounting/bank-reconcile-history/actions/View.vue'
import ReconciliatonHistory from '@/views/finance-accounting/bank-reconcile-history/actions/ReconciliatonHistory.vue'
import ReconcileAnAccount from '@/views/finance-accounting/bank-reconcile-history/actions/ReconcileAnAccount.vue'
import ReconReport from '@/views/finance-accounting/bank-reconcile-history/actions/ReconReport.vue'
import ReconciliatonEdit from '@/views/finance-accounting/bank-reconcile-history/actions/ReconciliatonEdit.vue'
import ReconciliatonView from '@/views/finance-accounting/bank-reconcile-history/actions/ReconciliatonView.vue'
import BankTransactions from '@/views/finance-accounting/bank-transactions/BankTransactions.vue'
import BankTransactionsView from '@/views/finance-accounting/bank-transactions/actions/View.vue'
import Salaries from '@/views/finance-accounting/employeesalaries/Salaries.vue'
import SalariesNew from '@/views/finance-accounting/employeesalaries/actions/New.vue'
import SalariesEdit from '@/views/finance-accounting/employeesalaries/actions/Edit.vue'
import SalariesView from '@/views/finance-accounting/employeesalaries/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Finanace & Accounting -------------------------------*
  // *===============================================---*
  // *------------ Timesheets --------------------*
  {
    path: '/finance-and-accounting/timesheets',
    name: 'finance-and-accounting-timesheets',
    component: Timesheets,
  },
  {
    path: '/finance-and-accounting/timesheets/new',
    name: 'finance-and-accounting-timesheets-new',
    component: TimesheetNew,
    meta: {
      navActiveLink: 'finance-and-accounting-timesheets',
    },
  },
  {
    path: '/finance-and-accounting/timesheets/edit/:id',
    name: 'finance-and-accounting-timesheets-edit',
    component: TimesheetEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-timesheets',
    },
  },
  {
    path: '/finance-and-accounting/timesheets/view/:id',
    name: 'finance-and-accounting-timesheets-view',
    component: TimesheetView,
    meta: {
      navActiveLink: 'finance-and-accounting-timesheets',
    },
  },
  // *------------ Business Partner Invoices --------------------*
  {
    path: '/finance-and-accounting/business-partner-invoices',
    name: 'finance-and-accounting-business-partner-invoices',
    component: Invoices,
  },
  // *------------ Invoices to Vendors --------------------*
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-to-vendors',
    name: 'finance-and-accounting-invoices-to-vendors',
    component: InvoicesVendors,
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-to-vendors/edit/:id',
    name: 'finance-and-accounting-invoices-to-vendors-edit',
    component: InvoicesVendorsEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-to-vendors/view/:id',
    name: 'finance-and-accounting-invoices-to-vendors-view',
    component: InvoicesVendorsView,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  // *------------ Invoices from Employers --------------------*
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-employers',
    name: 'finance-and-accounting-invoices-from-employers',
    component: InvoicesEmployers,
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-employers/new',
    name: 'finance-and-accounting-invoices-from-employers-new',
    component: InvoicesEmployersNew,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-employers/edit/:id',
    name: 'finance-and-accounting-invoices-from-employers-edit',
    component: InvoicesEmployersEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-employers/view/:id',
    name: 'finance-and-accounting-invoices-from-employers-view',
    component: InvoicesEmployersView,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  // *------------ Invoices from Sub-Vendors --------------------*
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-subvendors',
    name: 'finance-and-accounting-invoices-from-subvendors',
    component: InvoicesSubVendors,
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-subvendors/new',
    name: 'finance-and-accounting-invoices-from-subvendors-new',
    component: InvoicesSubVendorsNew,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-subvendors/edit/:id',
    name: 'finance-and-accounting-invoices-from-subvendors-edit',
    component: InvoicesSubVendorsEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-subvendors/view/:id',
    name: 'finance-and-accounting-invoices-from-subvendors-view',
    component: InvoicesSubVendorsView,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  // *------------ Invoices from Suppliers --------------------*
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-suppliers',
    name: 'finance-and-accounting-invoices-from-suppliers',
    component: InvoicesSuppliers,
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-suppliers/new',
    name: 'finance-and-accounting-invoices-from-suppliers-new',
    component: InvoicesSuppliersNew,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-suppliers/edit/:id',
    name: 'finance-and-accounting-invoices-from-suppliers-edit',
    component: InvoicesSuppliersEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-suppliers/view/:id',
    name: 'finance-and-accounting-invoices-from-suppliers-view',
    component: InvoicesSuppliersView,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  // *------------ Invoices from Referrers --------------------*
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-referrers',
    name: 'finance-and-accounting-invoices-from-referrers',
    component: InvoicesReferrers,
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-referrers/new',
    name: 'finance-and-accounting-invoices-from-referrers-new',
    component: InvoicesReferrersNew,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-referrers/edit/:id',
    name: 'finance-and-accounting-invoices-from-referrers-edit',
    component: InvoicesReferrersEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  {
    path: '/finance-and-accounting/business-partner-invoices/invoices-from-referrers/view/:id',
    name: 'finance-and-accounting-invoices-from-referrers-view',
    component: InvoicesReferrersView,
    meta: {
      navActiveLink: 'finance-and-accounting-business-partner-invoices',
    },
  },
  // *------------ Expenses --------------------*
  {
    path: '/finance-and-accounting/expenses',
    name: 'finance-and-accounting-expenses',
    component: Expenses,
  },
  {
    path: '/finance-and-accounting/expenses/new',
    name: 'finance-and-accounting-expenses-new',
    component: ExpensesNew,
    meta: {
      navActiveLink: 'finance-and-accounting-expenses',
    },
  },
  {
    path: '/finance-and-accounting/expenses/edit/:id',
    name: 'finance-and-accounting-expenses-edit',
    component: ExpensesEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-expenses',
    },
  },
  {
    path: '/finance-and-accounting/expenses/view/:id',
    name: 'finance-and-accounting-expenses-view',
    component: ExpensesView,
    meta: {
      navActiveLink: 'finance-and-accounting-expenses',
    },
  },
  // *------------ Reconciliaton History --------------------*
  {
    path: '/finance-and-accounting/bank-reconcile-history',
    name: 'finance-and-accounting-bank-reconcile-history',
    component: BankReconcileHistory,
  },
  {
    path: '/finance-and-accounting/banks/new',
    name: 'finance-and-accounting-banks-new',
    component: BankReconcileHistoryNew,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/banks/edit/:id',
    name: 'finance-and-accounting-banks-edit',
    component: BankReconcileHistoryEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/banks/view/:id',
    name: 'finance-and-accounting-banks-view',
    component: BankReconcileHistoryView,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/reconcilation-history/:id',
    name: 'finance-and-accounting-bank-reconcile-history-reconcilation-history',
    component: ReconciliatonHistory,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/reconcile-an-account/:id',
    name: 'finance-and-accounting-bank-reconcile-history-reconcile-an-account',
    component: ReconcileAnAccount,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/recon-report/:id',
    name: 'finance-and-accounting-bank-reconcile-history-recon-report',
    component: ReconReport,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/edit/:id',
    name: 'finance-and-accounting-bank-reconcile-history-edit',
    component: ReconciliatonEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/view/:id',
    name: 'finance-and-accounting-bank-reconcile-history-view',
    component: ReconciliatonView,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  // *------------ Bank Transactions --------------------*
  {
    path: '/finance-and-accounting/bank-transactions/:id',
    name: 'finance-and-accounting-bank-transactions',
    component: BankTransactions,
  },
  {
    path: '/finance-and-accounting/bank-transactions/:bankId/view/:id',
    name: 'finance-and-accounting-bank-transactions-view',
    component: BankTransactionsView,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  // *------------ Employee Salaries --------------------*
  {
    path: '/finance-and-accounting/salaries',
    name: 'finance-and-accounting-salaries',
    component: Salaries,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
  {
    path: '/finance-and-accounting/salaries/new',
    name: 'finance-and-accounting-salaries-new',
    component: SalariesNew,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
  {
    path: '/finance-and-accounting/salaries/view/:id',
    name: 'finance-and-accounting-salaries-view',
    component: SalariesView,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
  {
    path: '/finance-and-accounting/salaries/edit/:id',
    name: 'finance-and-accounting-salaries-edit',
    component: SalariesEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
]
