<template>
  <b-card
    class="mb-0"
  >

    <div class="mb-1">
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="4"
        >
          <h4 class="card-title mb-0 pt-50">
            Document Tracker</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-160"
              :to="{ name: 'documents-document-tracker-new'}"
            >
              Add Document
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Parent Type"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="parent_type_id"
              v-model="parent_type_id"
              :options="parentTypes"
              :reduce="item => item.parent_type_id"
              :clearable="true"
              placeholder="Select"
              @input="onTypeChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Parent"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="parent_id"
              v-model="parent_id"
              :options="parents"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select"
              @input="parentChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Expiring Soon"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="expiring_soon_id"
              v-model="type"
              :options="types"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select"
              @input="onExpairyTypeChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </div>
    <b-modal
      ref="remainder_mail_modal"
      cancel-variant="outline-secondary"
      ok-title="Send"
      cancel-title="Close"
      centered
      title="Send Mail"
      :no-close-on-backdrop="true"
      @hide="closingMailPopupPopup"
    >
      <validation-observer
        ref="remainder_mail"
        tag="form"
      >
        <b-form>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-form-group
                label="To"
                label-cols-md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="To"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="remainder_mail.email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Subject"
                label-cols-md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Subject"
                  rules="required"
                >
                  <b-form-input
                    id="subject"
                    v-model="remainder_mail.subject"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Body"
                label-cols-md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Body"
                  rules="required"
                >
                  <b-form-textarea
                    id="message"
                    v-model="remainder_mail.message"
                    rows="10"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Signature"
                class="text-left"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Signature"
                  rules="required"
                >
                  <b-form-textarea
                    id="signature"
                    v-model="remainder_mail.signature"
                    rows="2"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <div />
          <b-button
            variant="primary"
            type="button"
            @click="sendRemailderMail"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>

    <vue-good-table
      :columns="columns"
      :rows="documentsResponse.parent_documents"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span
          v-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pl-50"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'documents-document-tracker-edit', params: { id: props.row.id } }">
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: 'documents-document-tracker-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
            <b-link
              id="mail"
              class="ml-1"
              @click="showRemainderMailPopup(props.row)"
            >
              <b-badge
                pill
                variant="light-warning"
              ><feather-icon icon="MailIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="documentsResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormGroup, BFormTextarea, BForm, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import LIST from '@/js/lists'
import ParentDocumentType from '@/js/parentDocument'
import Constants from '@/js/constant'

export default {
  name: 'DocumentTracker',
  components: {
    BCard,
    BRow,
    BForm,
    BCol,
    BFormTextarea,
    BFormGroup,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  data() {
    return {
      required,
      searchQuery: '',
      pageLength: 10,
      parent_type_id: null,
      parent_id: null,
      expiring_soon_id: null,
      parentTypes: [],
      type: null,
      parents: [],
      expiringSoon: [],
      documentsResponse: {
        parent_documents: [],
        count: 1,
      },
      remainder_mail: {
        email: '',
        subject: '',
        message: '',
        signature: `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`,
      },
      dir: false,
      columns: [
        {
          label: '#',
          field: 'index',
          sortable: false,
        },
        {
          label: 'Parent Type',
          field: 'parent_type',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'parent',
          sortable: false,
        },
        {
          label: 'Document Type',
          field: 'document_type',
          sortable: false,
        },
        {
          label: 'Issuer',
          field: 'issuer',
          sortable: false,
        },
        {
          label: 'Expiry Date',
          field: 'expire_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Last Sent Date',
          field: 'last_sent',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '140px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active',
        2: 'Inactive',
      },
      {
        1: 'light-success',
        2: 'light-danger',
      }],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        ParentDocumentType.getDocuments(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active       : 'light-success',
        Inactive     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRDocumentParentTypes(this)
      },
    },
    '$store.state.lists.hrLists.response': {
      immediate: true,
      handler() {
        this.onTypeChangedByWatch()
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.parent_id = this.$store.state.query.parent_id
    this.parent_type_id = this.$store.state.query.parent_type_id
    this.type = this.$store.state.query.type
    this.documentsResponse.count = this.$store.state.query.page
    Constants.getDocumentStatus(this)
    LIST.getHRDocumentParentTypes(this)
    this.onTypeChangedByWatch()
    ParentDocumentType.getDocuments(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.parent} document`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ParentDocumentType.delete(this, obj.id)
        }
      })
    },
    sendRemailderMail() {
      this.$refs.remainder_mail.validate().then(result => {
        if (result) {
          ParentDocumentType.sendMail(this, this.remainder_mail.id, this.remainder_mail)
        }
      })
    },
    showRemainderMailPopup(data) {
      this.$refs.remainder_mail_modal.show()
      this.remainder_mail.id = data.id
      this.remainder_mail.email = data.email
      this.remainder_mail.subject = data.subject
      const date = data.expire_date === null || typeof data.expire_date === 'undefined' ? 'N/A' : data.expire_date
      this.remainder_mail.message = `Hi ${data.parent}\n\nWe are writing to inform you about the upcoming expiration of an important document. As valued members of our organization, it is crucial to ensure that all necessary documents are kept up-to-date and in compliance with regulations.\n\nExpiration Date: ${date}`
    },
    closingMailPopupPopup() {
      this.remainder_mail = {
        id: null,
        email: '',
        subject: '',
        message: '',
        signature: `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`,
      }
    },
    onTypeChangedByWatch() {
      if (this.parent_type_id === null) {
        return
      }
      const employerType = this.parentTypes.find(type => type.parent_type_id === this.parent_type_id)
      switch (employerType.id) {
        case 'employer':
          LIST.getEmployersFullList(this)
          break
        case 'vendors':
          LIST.getVendorsFullList(this)
          break
        case 'suppliers':
          LIST.getSuppliersFullList(this)
          break
        case 'referrer':
          LIST.getReferrersFullList(this)
          break
        case 'subvendor':
          LIST.getSubVendorsFullList(this)
          break
        case 'employee':
          LIST.getEmployeeFullList(this)
          break
        case 'endclient':
          LIST.getEndClientsFullList(this)
          break
        default:
          break
      }
    },
    onTypeChanged() {
      this.parent_id = null
      this.$store.state.query.parent_id = null
      if (this.parent_type_id === null) {
        this.$store.state.query.parent_type_id = null
        this.parents = []
        this.currentPage = 1
        return
      }
      const employerType = this.parentTypes.find(type => type.parent_type_id === this.parent_type_id)
      switch (employerType.id) {
        case 'employer':
          LIST.getEmployersFullList(this)
          break
        case 'vendors':
          LIST.getVendorsFullList(this)
          break
        case 'suppliers':
          LIST.getSuppliersFullList(this)
          break
        case 'referrer':
          LIST.getReferrersFullList(this)
          break
        case 'subvendor':
          LIST.getSubVendorsFullList(this)
          break
        case 'employee':
          LIST.getEmployeeFullList(this)
          break
        case 'endclient':
          LIST.getEndClientsFullList(this)
          break
        default:
          break
      }
      this.$store.state.query.parent_type_id = this.parent_type_id
      this.currentPage = 1
    },
    parentChanged() {
      this.$store.state.query.parent_id = this.parent_id
      this.currentPage = 1
    },
    onExpairyTypeChanged() {
      this.$store.state.query.type = this.type
      this.currentPage = 1
    },
    setEmployers(vendors) {
      this.parents = vendors
    },
    setEndClients(endClients) {
      this.parents = endClients
    },
    setSuppliers(vendors) {
      this.parents = vendors
    },
    setReferrers(referrers) {
      this.parents = referrers
    },
    setSubVendors(vendors) {
      this.parents = vendors
    },
    setVendors(vendors) {
      this.parents = vendors
    },
    setEmployees(employees) {
      this.parents = employees
    },
    mailSent(data) {
      this.closingMailPopupPopup()
      this.$refs.remainder_mail_modal.hide()
      ParentDocumentType.getDocuments(this)
      this.$swal({
        icon: 'success',
        title: 'Mail Sent!',
        text: data.return_message,
      })
    },
    mailNotSent(message) {
      this.$swal({
        icon: 'warning',
        title: 'Mail Not Sent!',
        text: message,
      })
    },
    documentDeleted(data) {
      ParentDocumentType.getDocuments(this)
      this.$swal({
        icon: 'success',
        title: 'Document Deleted!',
        text: data.return_message,
      })
    },
    documentNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Document Not Deleted!',
        text: message,
      })
    },
    setPartners(parentTypes) {
      this.parentTypes = parentTypes
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setDocuments(documentsResponse) {
      this.documentsResponse = documentsResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
