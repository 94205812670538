<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-2"
        >
          <h4 class="card-title mb-0 pt-50">
            Job Requests</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-170"
              :to="{ name: 'vendor-jobs-management-job-requests-new'}"
            >
              Add Job Request
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="vendor_id"
              v-model="vendor_id"
              :options="vendors"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Vendor"
              @input="vendorChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="skill_id"
              v-model="skill_id"
              :options="technologies"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Main Skill"
              @input="technologyChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="location_id"
              v-model="location_id"
              :options="locations"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Location"
              @input="locationChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="working_status_id"
              v-model="working_status_id"
              :options="workingStatus"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Working Status"
              @input="workingStatusChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="jobResponse.vendor_jobs"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Currency -->
        <span v-if="props.column.field === 'cellRendererCurrency'">
          <span>{{ props.row.rate| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Link -->
        <span v-if="props.column.field === 'cellRendererLink'">
          <span><router-link :to="{ name: 'vendor-jobs-management-job-requests-prospects', params: { id: props.row.id} }">{{ props.row.prospects }}</router-link></span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pl-0"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="!props.row.is_rejected"
                id="post"
                @click="reject(props.row)"
              >
                <feather-icon icon="SlashIcon" />
                <span class="ml-50">Reject</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!props.row.is_job_posted"
                id="post"
                @click="post(props.row)"
              >
                <feather-icon icon="SearchIcon" />
                <span class="ml-50">Post Job</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.is_job_posted"
                :to="{ name: 'human-resources-jobs-edit', params: { id: props.row.job_posting_id } }"
              >
                <feather-icon icon="SearchIcon" />
                <span class="ml-50">JOb View</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="edit"
                :to="{ name: 'vendor-jobs-management-job-requests-edit', params: { id: props.row.id } }"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="delete"
                @click="confirmDeleteRecord(props.row)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: 'vendor-jobs-management-job-requests-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="jobResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="job_posted"
      ref="job_posted"
      cancel-variant="outline-secondary"
      ok-title="Assign"
      cancel-title="Cancel"
      centered
      :no-close-on-backdrop="true"
      title="Job Posted!"
    >
      <b-alert
        show
        variant="success"
      >
        <div class="alert-body">
          Success! Your Job has been posted.
        </div>
      </b-alert>
      <b-form>
        <b-form-group>
          <label>Assigned Job</label>
          <v-select
            v-model="assignUsers.assigned_user_ids"
            multiple
            :options="roleUsers"
            :reduce="item => item.id"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <div />
          <b-button
            variant="primary"
            class="ml-1 px-1"
            @click="addAssignUsers"
          >
            Assign
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BFormGroup, BBadge, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BLink, BButton, BAlert, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import JobRequests from '@/js/vendorjob'
import LIST from '@/js/vjmslists'
import FULLLIST from '@/js/lists'
import Jobs from '@/js/jobs'

export default {
  name: 'JobRequests',
  components: {
    BFormGroup,
    vSelect,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BLink,
    BButton,
    BAlert,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      assignUsers: {
        job_id: null,
        assigned_user_ids: [],
      },
      status_id: null,
      vendor_id: null,
      skill_id: null,
      location_id: null,
      working_status_id: null,
      role_users_id: null,
      status: [],
      vendors: [],
      technologies: [],
      locations: [],
      workingStatus: [],
      roleUsers: [],
      searchQuery: '',
      pageLength: 10,
      dir: false,
      employee: {},
      jobResponse: {
        vendor_jobs: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Vendor',
          field: 'vendor_name',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'title',
          sortable: false,
        },
        {
          label: 'Tech',
          field: 'tech',
          sortable: false,
        },
        {
          label: 'Rate ($)',
          field: 'cellRendererCurrency',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Location',
          field: 'location',
          sortable: false,
        },
        {
          label: 'Job-ID',
          field: 'job_id',
          sortable: false,
        },
        {
          label: 'Prospects',
          field: 'cellRendererLink',
          sortable: false,
        },
        {
          label: '# Years',
          field: 'experience',
          width: '90px',
          sortable: false,
        },
        {
          label: 'Entry Date',
          field: 'open_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        JobRequests.getVendorJobs(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getVendors(this)
        LIST.getMainSkills(this)
        LIST.getVendorJobLocations(this)
        FULLLIST.getRollUsers(this)
        FULLLIST.getWorkingStatus(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    if (this.$route.query.vendor_id !== null && this.$route.query.vendor_id !== undefined && (this.$store.state.query.vendor_id === null || this.$store.state.query.vendor_id === undefined)) {
      this.$store.state.query.vendor_id = this.$route.query.vendor_id
    }
    if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined && (this.$store.state.query.skill_id === null || this.$store.state.query.skill_id === undefined)) {
      this.$store.state.query.skill_id = this.$route.query.skill_id
    }
    if (this.$route.query.location_id !== null && this.$route.query.location_id !== undefined && (this.$store.state.query.location_id === null || this.$store.state.query.location_id === undefined)) {
      this.$store.state.query.location_id = this.$route.query.location_id
    }
    this.location_id = Number(this.$store.state.query.location_id)
    this.skill_id = Number(this.$store.state.query.skill_id)
    this.vendor_id = Number(this.$store.state.query.vendor_id)
    this.working_status_id = Number(this.$store.state.query.working_status_id)
    this.jobResponse.count = this.$store.state.query.page
    LIST.getVendors(this)
    LIST.getMainSkills(this)
    LIST.getVendorJobLocations(this)
    FULLLIST.getRollUsers(this)
    FULLLIST.getWorkingStatus(this)
    JobRequests.getVendorJobs(this)
  },
  methods: {
    addAssignUsers() {
      if (this.assignUsers.assigned_user_ids.length > 0 && this.assignUsers.job_id !== null) {
        Jobs.assignUsersToJob(this, this.assignUsers.job_id, this.assignUsers)
      }
    },
    syncProspects(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to sync prospects to this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, sync!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          JobRequests.syncVendorJobById(this, obj.id)
        }
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          JobRequests.delete(this, obj.id)
        }
      })
    },
    post(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to post this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, post it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          JobRequests.postVendorJobById(this, obj.id)
        }
      })
    },
    reject(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to reject this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reject it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          JobRequests.rejectVendorJobById(this, obj.id)
        }
      })
    },
    jobDeleted() {
      JobRequests.getVendorJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Deleted!',
        text: 'Your Job has been deleted',
      })
    },
    jobNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Deleted!',
        text: 'Your Job has been not deleted',
      })
    },
    usersAssigned(data) {
      this.$refs.job_posted.hide()
      JobRequests.getVendorJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Users Assigned!',
        text: data.return_message,
      })
    },
    usersNotAssigned(message) {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Assigned!',
        text: message,
      })
    },
    setJobRejected() {
      JobRequests.getVendorJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Rejected!',
        text: 'Your Job has been rejected',
      })
    },
    setJobNotRejected() {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Rejected!',
        text: 'Your Job has been not rejected',
      })
    },
    setJobPosted(data) {
      JobRequests.getVendorJobs(this)
      this.assignUsers.job_id = data.id
      this.$refs.job_posted.show()
    },
    setJobNotPosted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Posted!',
        text: message,
      })
    },
    setJobSynced() {
      JobRequests.getVendorJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Synced!',
        text: 'Your Job has been synced',
      })
    },
    setJobNotSynced(message) {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Synced!',
        text: message,
      })
    },
    setWorkingStatus(workingStatus) {
      this.workingStatus = workingStatus
    },
    setRollUsers(rollUsers) {
      this.roleUsers = rollUsers
    },
    vendorChanged() {
      this.$store.state.query.vendor_id = this.vendor_id
      this.currentPage = 1
    },
    technologyChanged() {
      this.$store.state.query.skill_id = this.skill_id
      this.currentPage = 1
    },
    locationChanged() {
      this.$store.state.query.location_id = this.location_id
      this.currentPage = 1
    },
    workingStatusChanged() {
      this.$store.state.query.working_status_id = this.working_status_id
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setVendorJobs(jobResponse) {
      this.jobResponse = jobResponse
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setMainSkills(technologies) {
      this.technologies = technologies
    },
    setLocations(locations) {
      this.locations = locations
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
