<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <h4 class="card-title mb-0 pt-50">
          Actual Monthly Report</h4>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Month"
          label-cols-md="3"
          class="text-right"
        >
          <flat-pickr
            id="month"
            v-model="month"
            class="form-control"
            :config="$store.state.monthConfig"
            placeholder="MM-YYYY"
            :clear-button="true"
            @on-change="monthDateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="invoice-preview">
      <b-col
        cols="12"
        class="col-xxl-8 offset-xxl-2"
      >
        <b-card-body>
          <div class="d-flex mb-1">
            <h4 class="mb-0 ml-50">
              Income
            </h4>
          </div>
          <table class="table b-table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th class="w-25 text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gross Income</td>
                <td class="text-right">
                  {{ report.total_income_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-right">
                  Total
                </th>
                <th class="text-right">
                  {{ report.total_income_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </b-card-body>

        <b-card-body>
          <div class="d-flex mb-1">
            <h4 class="mb-0 ml-50">
              Expenses
            </h4>
          </div>
          <table class="table b-table table-bordered mb-2">
            <thead>
              <tr>
                <th>Name</th>
                <th class="w-25 text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="$store.state.roles.is_employer_exist">
                <td>Employers Amount</td>
                <td class="text-right">
                  {{ report.employer_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </td>
              </tr>
              <tr v-show="$store.state.roles.is_subvendor_exist">
                <td>Sub-Vendors Amount</td>
                <td class="text-right">
                  {{ report.subvendor_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </td>
              </tr>
              <tr v-show="$store.state.roles.is_supplier_exist">
                <td>Suppliers Amount</td>
                <td class="text-right">
                  {{ report.supplier_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </td>
              </tr>
              <tr v-show="$store.state.roles.is_referrer_exist">
                <td>Referrers Amount</td>
                <td class="text-right">
                  {{ report.referrer_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-right">
                  Total Expenses
                </th>
                <th class="text-right">
                  {{ report.total_expansis_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </th>
              </tr>
            </tfoot>
          </table>
          <b-row>
            <b-col
              cols="12"
              class="mt-md-6 d-flex justify-content-end"
              order="1"
              order-md="2"
            >
              <div class="invoice-total-wrapper">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Net Income:
                  </p>
                  <p class="invoice-total-amount">
                    {{ report.net_income_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Other Expenses:
                  </p>
                  <p class="invoice-total-amount">
                    {{ report.other_expansis_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Net Profit:
                  </p>
                  <p class="invoice-total-amount">
                    {{ report.net_profit_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Net Profit %:
                  </p>
                  <p class="invoice-total-amount">
                    {{ report.net_profit_percentage | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import moment from 'moment'
import Reports from '@/js/reports'

export default {
  name: 'ActualMonthlyReport',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BCardBody,
    flatPickr,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      month: null,
      report: {},
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  mounted() {
  },
  created() {
    this.month = moment(new Date()).format('MMM-YYYY')
    Reports.getActualMonthlyReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
  },
  destroyed() {
  },
  methods: {
    setReport(report) {
      this.report = report
    },
    monthDateChanged(config, month) {
      this.month = month
      Reports.getActualMonthlyReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
    },
    back() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
