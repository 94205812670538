<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Skill & Technology</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="5"
                class="col-xxl-4"
              >
                <b-form-group v-if="showList">
                  <label>Skill Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Skill Name"
                    :rules="showList ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <v-select
                          v-model="addTechnology.skill_id"
                          :options="skills"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          @click="addNewSkill"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="showName">
                  <label>Skill Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Skill Name"
                    :rules="showName ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-input
                          v-model="addTechnology.skill_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="showListOption"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Available Technologies</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="5"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-input-group>
                    <b-form-input
                      v-model="search_text"
                      placeholder="Search"
                      @blur="search"
                      @keyup.enter="search"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="search"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          size="14"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="7 ml-auto"
                class="col-xxl-6 text-right"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <div
                    v-if="show_technology"
                    class="w-100"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-input
                          v-model="technology_name"
                          placeholder="Enter Technology Name"
                        />
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="primary"
                          @click="addNewTechnology"
                        >
                          Add
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <b-button
                      v-if="!show_technology"
                      variant="outline-primary"
                      class="ml-2 min-w-180"
                      @click="showTechnology"
                    >
                      Add Technology
                    </b-button>
                    <b-button
                      v-if="show_technology"
                      variant="outline-danger"
                      class="ml-2 min-w-180"
                      @click="hideTechnology"
                    >
                      Hide Technology
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button-group
                  v-for="(technology, index) in addTechnology.technology_names"
                  :key="index"
                  :value="index"
                  class="mr-1 mt-1"
                >
                  <b-badge
                    title="name"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ technology }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeTechnology(index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col
                    v-for="option in technologies"
                    :key="option.id"
                    :value="option.id"
                    cols="3"
                    class="col-xxl-2 mb-1"
                  >
                    <b-form-checkbox
                      :checked="technologySelected(option.id)"
                      @change="checked(option.id)"
                    >
                      {{ option.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addTechnology.user_defined_fields.length !== 0"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addTechnology.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Skill & Technology
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BFormCheckbox, BButtonGroup, BButton, BBadge, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import Technology from '@/js/skillstechnologies'
import LIST from '@/js/lists'

export default {
  name: 'SkillTechnologyEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    vSelect,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BBadge,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addTechnology: {
        skill_id: null,
        skill_name: '',
        technology_ids: [],
        technology_names: [],
        user_defined_fields: [],
      },
      technology_name: '',
      show_technology: false,
      skills: [],
      search_text: '',
      technologies: [],
      alltechnologies: [],
      showList: true,
      showName: false,
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getSkillsFullList(this)
        LIST.getTechnologiesFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getSkillsFullList(this)
    LIST.getTechnologiesFullList(this)
    Technology.getTechnologyById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.technology_name !== '') {
            this.addTechnology.technology_names.push(this.technology_name)
            this.technology_name = ''
          }
          Technology.updateTechnology(this, this.$route.params.id, this.addTechnology)
        }
      })
    },
    setSkills(skills) {
      this.skills = skills
    },
    setTechnologies(technologies) {
      this.technologies = technologies
      this.alltechnologies = technologies
    },
    addNewSkill() {
      this.addTechnology.skill_id = null
      this.showList = false
      this.showName = true
    },
    showListOption() {
      this.showList = true
      this.showName = false
    },
    checked(id) {
      if (this.addTechnology.technology_ids.includes(id)) {
        this.addTechnology.technology_ids.splice(this.addTechnology.technology_ids.indexOf(id), 1)
      } else {
        this.addTechnology.technology_ids.push(id)
      }
    },
    showTechnology() {
      this.show_technology = true
    },
    hideTechnology() {
      this.show_technology = false
    },
    search() {
      this.technologies = this.alltechnologies.filter(item => item.name.toLowerCase().includes(this.search_text.toLowerCase()))
    },
    addNewTechnology() {
      if (this.technology_name !== '') {
        this.addTechnology.technology_names.push(this.technology_name)
        this.technology_name = ''
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Please enter technology name',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    technologySelected(id) {
      return this.addTechnology.technology_ids.includes(id)
    },
    removeTechnology(index) {
      this.addTechnology.technology_names.splice(index, 1)
    },
    setTechnology(technology) {
      this.addTechnology.skill_id = technology.skill_id
      this.addTechnology.skill_name = technology.skill_name
      this.addTechnology.technology_ids = technology.technology_ids
      this.addTechnology.user_defined_fields = technology.user_defined_fields
    },
    technologyUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    technologyNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/skills-and-technologies').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
