import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const vendor = `${HOST}vendor`

export default {
  getVendors(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(vendor, params).then(response => {
      context.setVendors(response.data)
    }, () => {
    })
  },
  addVendor(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(vendor, body).then(response => {
      context.$vs.loading.close()
      context.vendorAdded(response.data)
      context.$store.state.lists.commonLists.lists['vendors-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.vendorNotAdded(error.response.data.return_message)
    })
  },
  updateVendor(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${vendor}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.vendorUpdated(response.data)
      context.$store.state.lists.commonLists.lists['vendors-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.vendorNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    axios.delete(`${vendor}/${id}`).then(response => {
      context.parentDeleted(response.data)
      context.$store.state.lists.commonLists.lists['vendors-all'] = response.data.full_list
    }, error => {
      context.parentNotDeleted(error.response.data.return_message)
    })
  },
  getVendorById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${vendor}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setVendor(response.data)
    }, error => {
      context.$vs.loading.close()
      context.vendorNotFound(error.response.data.return_message)
    })
  },

}
