<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-1 mb-md-0 pt-1 pt-md-50">
          View Consultant</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            :to="{ name: 'human-resources-consultants-client-letter', params: { id: $route.params.id }}"
            class="mr-1"
          >
            Generate Client Letter
          </b-button>
          <b-button
            variant="primary"
            :to="{ name: 'parent-task-view', params: { parent_type_id: 3, parent_id: $route.params.id }}"
            class="mr-1"
          >
            Tasks
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Personal Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                First Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.first_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Middle Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.middle_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Last Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.last_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="consultant.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Gender
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.gender"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Date of Birth
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.date_of_birth"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                SSN
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.ssn"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant Start Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.employee_start_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant End Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.employee_end_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contract Start Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.contract_start_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contract End Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.contract_end_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.status.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                End Client
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.client_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Main Skill
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                >
                  <b-badge
                    v-for="(skill_name, index) in consultant.skill_names"
                    :key="index"
                    variant="light-primary"
                    class="m-25"
                  >
                    {{ skill_name }}
                  </b-badge>
                </span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Passport Number
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.passport_number"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Secondary Skills</h6>
        </div>
        <b-row
          v-for="technology in consultant.technologies"
          :key="technology"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Skill
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="technology.technology"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Experience
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="technology.experience"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Rating
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="technology.rating"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="primary"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Visa Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.visa.visa_type.type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Expiry Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.visa.expiration_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Validated
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.visa.validated"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Contact Information</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="consultant.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="consultant.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.address.state.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="consultant.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Additional Contact Information</h6>
        </div>
        <b-row
          v-for="(contact_info, index) in consultant.contact_information"
          :key="index"
          :value="index"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.contact_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contact_info.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contact_info.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contact_info.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.state"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contact_info.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-0">
          </b-col>
        </b-row>
        <div
          v-if="consultant.contact_information.length == 0"
          class="text-center"
        >
          <p>No additional contact information available</p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Business Partners</h6>
        </div>
        <b-row
          v-for="(business_partner, businessPartnerIndex) in consultant.business_partners"
          :key="businessPartnerIndex"
          :value="businessPartnerIndex"
        >
          <b-col cols="12">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Partner Type
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control txt-capt"
                      v-text="business_partner.parent_type"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    <label>Name</label>
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="business_partner.userName"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <dl class="row">
                      <dt class="col-sm-12 dt-text">
                        <label>Payment Frequency</label>
                      </dt>
                      <dd class="col-sm-12">
                        <span
                          class="form-control"
                          v-text="business_partner.paymentType"
                        />
                      </dd>
                    </dl>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <dl class="row">
                      <dt class="col-sm-12 dt-text">
                        <label>Pay Rate</label>
                      </dt>
                      <dd class="col-sm-12">
                        <span class="form-control">{{ business_partner.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                      </dd>
                    </dl>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <dl class="row">
                      <dt class="col-sm-12 dt-text">
                        <label>Discount</label>
                      </dt>
                      <dd class="col-sm-12">
                        <span class="form-control">{{ business_partner.discount | currency('% ', 2, { thousandsSeparator: ',' }) }}</span>
                      </dd>
                    </dl>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0">
          </b-col>
        </b-row>
        <div
          v-show="consultant.business_partners.length == 0"
          class="text-center"
        >
          <p>No Business Partners available</p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th>Issuer</th>
                    <th>Name</th>
                    <th>Version</th>
                    <th class="th-minw-125">Upload Date</th>
                    <th class="th-minw-110">Exp Date</th>
                    <th>Uploaded By</th>
                    <th>Reminder</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(priviousVersion, index) in consultant.documents"
                    :key="index"
                  >
                    <td>{{ priviousVersion.document_type }}</td>
                    <td>{{ priviousVersion.issuer }}</td>
                    <td>{{ priviousVersion.document_name }}</td>
                    <td>{{ priviousVersion.version }}</td>
                    <td>{{ priviousVersion.uploaded_date }}</td>
                    <td>{{ priviousVersion.exp_date }}</td>
                    <td>{{ priviousVersion.uploaded_by }}</td>
                    <td>{{ priviousVersion.reminder }}</td>
                    <td>
                      <b-link
                        target="_blank"
                        :href="priviousVersion.document_path"
                      >
                        <b-badge
                          variant="primary"
                          class="mr-1 mb-50"
                        >
                          <span>{{ priviousVersion.document }}</span>
                        </b-badge>
                      </b-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <div
          v-if="consultant.documents.length == 0"
          class="text-center"
        >
          <p> No Documents available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in consultant.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="consultant.comments.length == 0"
          class="text-center"
        >
          <p> No Comments available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="consultant.user_defined_fields.length !== 0"
        variant="primary"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in consultant.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Consultant from '@/js/consultants'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BBadge, BLink,
} from 'bootstrap-vue'

export default {
  name: 'ConsultantView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BLink,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      consultant: {},
    }
  },
  mounted() {
  },
  created() {
    Consultant.getConsultantById(this, this.$route.params.id)
  },
  methods: {
    setConsultant(consultant) {
      this.consultant = consultant
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
