<template>
  <b-row>
    <b-col
      v-show="$store.state.roles.is_vendor_exist || $store.state.roles.is_employer_exist || $store.state.roles.is_subvendor_exist || $store.state.roles.is_supplier_exist || $store.state.roles.is_referrer_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-monthly-projection-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Monthly Projections Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_vendor_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-monthly-income-projection-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Monthly Income Projection Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_employer_exist || $store.state.roles.is_subvendor_exist || $store.state.roles.is_supplier_exist || $store.state.roles.is_referrer_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-monthly-expenses-projection-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Monthly Expenses Projection Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_vendor_exist || $store.state.roles.is_employer_exist || $store.state.roles.is_subvendor_exist || $store.state.roles.is_supplier_exist || $store.state.roles.is_referrer_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-actual-monthly-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Actual Monthly Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_vendor_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-actual-monthly-income-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Actual Monthly Income Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_employer_exist || $store.state.roles.is_subvendor_exist || $store.state.roles.is_supplier_exist || $store.state.roles.is_referrer_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-actual-monthly-expenses-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Actual Monthly Expenses Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_employer_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-employer-consultants' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Consultants By Employer
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_subvendor_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-subvendor-consultants' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Consultants By SubVendor
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_supplier_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-supplier-consultants' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Consultants By Supplier
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_referrer_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-referrer-consultants' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Consultants By Referrer
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col
      v-show="$store.state.roles.is_vendor_exist"
      cols="6"
    >
      <router-link :to="{ name: 'forecasting-reports-vendor-consultants' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Consultants By Vendor
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col cols="6">
      <router-link :to="{ name: 'forecasting-reports-missing-timesheets' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Missing Timesheets Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col cols="6">
      <router-link :to="{ name: 'forecasting-reports-missing-invoices' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            All Invoice Statuses Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col cols="6">
      <router-link :to="{ name: 'forecasting-reports-invoice-payment-due-date' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Invoice Payment Due Date Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'ForecastingReports',
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      // User: {},
    }
  },
  computed: {
  },
  created() {
    // if (!User.isAdmin() && !User.isReportsAvailable()) {
    //   window.location.hash = '/'
    // }
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
