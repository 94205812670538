import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const report = `${HOST}batch-process`

export default {
  getDateBatchProcess(context1, month, year, date) {
    const context = context1
    const params = { params: { start_month: month, start_year: year, start_date: date } }
    axios.get(report, params).then(response => {
      context.setReport(response.data)
    }, () => {
    })
  },
  sendMail(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/mail-resume/${id}`).then(response => {
      context.$vs.loading.close()
      context.updated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.notUpdated(error.response.data.return_message)
    })
  },
  sendAllMails(context1, type, date, month, year) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/mail-resume/${type}/${date}/${month}/${year}`).then(response => {
      context.$vs.loading.close()
      context.updated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.notUpdated(error.response.data.return_message)
    })
  },

}
