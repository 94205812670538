<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Salary Calculator
        </h4>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Calculator</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client/Vendor Bill Rate</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client/Vendor Bill Rate"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="client_bill_rate"
                        v-model="salary_calculator.client_bill_rate"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Salary Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Salary Type"
                    rules="required"
                  >
                    <v-select
                      id="salaryType"
                      v-model="salary_calculator.employee_salary_type"
                      :options="salaryTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="salaryTypeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Salary</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Salary"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="salary"
                        v-model="salary_calculator.salary"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Benefits</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Pay Roll</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Pay Roll"
                    rules="required"
                  >
                    <b-input-group
                      prepend="%"
                    >
                      <money
                        id="pay_roll"
                        v-model="salary_calculator.pay_roll"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Vacation Days</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Vacation Days"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="vacation_days"
                      v-model="salary_calculator.vacation_days"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Insurance</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Pay Roll"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="insurance"
                        v-model="salary_calculator.insurance"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>k401</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="k401"
                    rules="required"
                  >
                    <b-input-group
                      prepend="%"
                    >
                      <money
                        id="k401"
                        v-model="salary_calculator.k401"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-2"
              >
                <b-form-checkbox
                  id="k1099"
                  v-model="salary_calculator.k1099"
                  :disabled="salary_calculator.employee_salary_type !== 'Hourly' || salary_calculator.pay_roll !== 0"
                >
                  1099
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col class="text-right">
                <b-button
                  variant="primary"
                  type="button"
                  @click="calculate"
                >
                  Calculate
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
          <app-timeline-item
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Results</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Employee Bill Rate
                  </dt>
                  <dd class="col-sm-12">
                    <span class="form-control">{{ salary_calculator.employee_bill_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Profit
                  </dt>
                  <dd class="col-sm-12">
                    <span class="form-control">{{ salary_calculator.profit | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col class="text-right">
                <b-button
                  variant="primary"
                  type="button"
                  @click="refresh"
                >
                  Refresh
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BForm, BFormCheckbox, BInputGroup, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Constants from '@/js/constant'

export default {
  name: 'SalaryCalculator',
  components: {
    vSelect,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      required,
      salary_calculator: {
        client_bill_rate: 0.00,
        employee_bill_rate: 0.00,
        profit: 0.00,
        employee_salary_type: 'Yearly',
        salary: 0.00,
        pay_roll: 0.00,
        vacation_days: '',
        insurance: 0.00,
        k401: 0.00,
        k1099: false,
      },
      salaryTypes: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  mounted() {
  },
  created() {
    Constants.getCaliculatorSalaryTypes(this)
  },
  destroyed() {
  },
  methods: {
    refresh() {
      this.salary_calculator = {
        client_bill_rate: 0.00,
        employee_bill_rate: 0.00,
        profit: 0.00,
        employee_salary_type: 'Yearly',
        salary: 0.00,
        pay_roll: 0.00,
        vacation_days: '',
        insurance: 0.00,
        k401: 0.00,
        k1099: false,
      }
    },
    calculate() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.salary_calculator.employee_salary_type === 'Yearly') {
            this.salary_calculator.employee_bill_rate = (this.salary_calculator.salary + ((this.salary_calculator.salary * this.salary_calculator.pay_roll) / 100)) / (2080 - this.salary_calculator.vacation_days * 8)
          } else if (this.salary_calculator.k1099) {
            this.salary_calculator.employee_bill_rate = this.salary_calculator.salary
          } else {
            this.salary_calculator.employee_bill_rate = this.salary_calculator.salary + ((this.salary_calculator.salary * this.salary_calculator.pay_roll) / 100)
          }
          this.salary_calculator.profit = this.salary_calculator.client_bill_rate - this.salary_calculator.employee_bill_rate
        }
      })
    },
    salaryTypeChanged() {
      if (this.salary_calculator.employee_salary_type !== 'Hourly') {
        this.salary_calculator.k1099 = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
