<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Document Tracker</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer
      ref="documentRules"
      tag="form"
    >
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Parent Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Parent Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Parent Type"
                    rules="required"
                  >
                    <v-select
                      id="parent_type_id"
                      v-model="addDocument.parent_type_id"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Parent</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Parent"
                    rules="required"
                  >
                    <v-select
                      id="parent_id"
                      v-model="addDocument.parent_id"
                      :options="parents"
                      :reduce="item => item.id"
                      :clearable="false"
                      label="name"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Document Details</h6>
            </div>
            <b-modal
              ref="add_document_type"
              cancel-variant="outline-secondary"
              ok-title="Add"
              cancel-title="Close"
              centered
              title="Add Document Type"
              :no-close-on-backdrop="true"
              @hide="closingDocumentTypePopup"
            >
              <validation-observer
                ref="newDocumentType"
                tag="form"
              >
                <b-form>
                  <b-form-group>
                    <label>Document Type</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Document Type"
                      rules="required"
                    >
                      <b-form-input
                        id="new-document-type"
                        v-model="addDocumentTypeRequest.document_type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
              <template #modal-footer>
                <div class="d-flex justify-content-end w-100">
                  <div />
                  <b-button
                    variant="primary"
                    class="ml-1 px-1"
                    @click="submitDocumentType"
                  >
                    Add
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-modal
              ref="add_document_name"
              cancel-variant="outline-secondary"
              ok-title="Add"
              cancel-title="Close"
              centered
              title="Add Document Name"
              :no-close-on-backdrop="true"
              @hide="closingDocumentNamePopup"
            >
              <validation-observer
                ref="newDocumentName"
                tag="form"
              >
                <b-form>
                  <b-form-group>
                    <label>Document Name</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Document Name"
                      rules="required"
                    >
                      <b-form-input
                        id="new-document-name"
                        v-model="addDocumentNameRequest.document_name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
              <template #modal-footer>
                <div class="d-flex justify-content-end w-100">
                  <div />
                  <b-button
                    variant="primary"
                    class="ml-1 px-1"
                    @click="submitDocumentName"
                  >
                    Add
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-modal
              ref="add_issuer"
              cancel-variant="outline-secondary"
              ok-title="Add"
              cancel-title="Close"
              centered
              title="Add Issuer"
              :no-close-on-backdrop="true"
              @hide="closingIssuerPopup"
            >
              <validation-observer
                ref="newIssuer"
                tag="form"
              >
                <b-form>
                  <b-form-group>
                    <label>Issuer</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Issuer"
                      rules="required"
                    >
                      <b-form-input
                        id="new-issuer"
                        v-model="addIssuerRequest.issuer"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
              <template #modal-footer>
                <div class="d-flex justify-content-end w-100">
                  <div />
                  <b-button
                    variant="primary"
                    class="ml-1 px-1"
                    @click="submitIsssuer"
                  >
                    Add
                  </b-button>
                </div>
              </template>
            </b-modal>

            <div class="repeat-border">
              <div
                v-for="(document, index) in documents"
                :key="index"
                class="d-flex"
              >
                <div class="left w-100">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Document Type</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Document Type' + index"
                          rules="required"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="document.document_type_id"
                                :options="documentTypes"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                @input="documentTypeChanged(index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Document Type' + index, 'Document Type') : ''"
                              />
                            </div>
                            <div class="right ml-50">
                              <b-button
                                variant="outline-primary"
                                class="px-1"
                                @click="showDocumentType(index)"
                              >
                                <feather-icon icon="PlusIcon" />
                              </b-button>
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Document Name</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Document Name' + index"
                          rules="required"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="document.document_name_id"
                                :options="document.documentNames"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                @input="documentNameChanged(index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Document Name' + index, 'Document Name') : ''"
                              />
                            </div>
                            <div
                              v-show="document.document_type_id !== null"
                              class="right ml-50"
                            >
                              <b-button
                                variant="outline-primary"
                                class="px-1"
                                @click="showDocumentName(index)"
                              >
                                <feather-icon icon="PlusIcon" />
                              </b-button>
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Issuer</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Issuer' + index"
                          rules="required"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="document.issuer_id"
                                :options="document.issuers"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Issuer' + index, 'Issuer') : ''"
                              />
                            </div>
                            <div
                              v-show="document.document_name_id !== null"
                              class="right ml-50"
                            >
                              <b-button
                                variant="outline-primary"
                                class="px-1"
                                @click="showIssuer(index)"
                              >
                                <feather-icon icon="PlusIcon" />
                              </b-button>
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="mt-2"
                    >
                      <b-form-checkbox
                        v-model="document.no_expire"
                        @change="checkChanged(index)"
                      >
                        No Expire
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      v-show="!document.no_expire"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Exp Date</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Exp Date' + index"
                          :rules="document.no_expire ? '' : 'required'"
                        >
                          <flat-pickr
                            v-model="document.exp_date"
                            class="form-control"
                            :config="exp_date_config"
                            placeholder="MM-DD-YYYY"
                          />
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined ? errors[0].replace('Exp Date' + index, 'Exp Date') : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-show="!document.no_expire"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Reminder</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Reminder' + index"
                          :rules="document.no_expire ? '' : 'required'"
                        >
                          <v-select
                            v-model="document.reminder_id"
                            :options="reminders"
                            :reduce="item => item.id"
                            :clearable="false"
                            placeholder="Select"
                          />
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined ? errors[0].replace('Reminder' + index, 'Reminder') : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Version</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Version' + index"
                          rules="required"
                        >
                          <v-select
                            v-model="document.version_id"
                            :options="versions"
                            :reduce="item => item.id"
                            :clearable="false"
                            placeholder="Select"
                          />
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined ? errors[0].replace('Version' + index, 'Version') : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Document</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Document' + index"
                          :rules="document.document === null ? 'required' : ''"
                        >
                          <input
                            :ref="'documentFileInput' + index"
                            type="file"
                            placeholder="Choose a file or drop it here..."
                            class="form-control text-left"
                            @change="attahamentFileSelected($event, index)"
                          >
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined && document.document === null ? errors[0].replace('Document' + index, 'Document') : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="right mt-2 ml-1">
                  <b-button
                    v-show="index === 0"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    @click="addNewDocument"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-show="index !== 0"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                    @click="removeNewDocument(index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Notes</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Notes</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Notes"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="addDocument.notes"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Document Tracker
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BModal, VBModal, BForm, BFormGroup, BFormInput, BButton, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import ParentDocumentType from '@/js/parentDocument'

export default {
  name: 'DocumentTrackerNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    vSelect,
    flatPickr,
    // currency,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      addDocumentTypeRequest: {
        document_type: '',
      },
      addDocumentNameRequest: {
        document_name: '',
        document_type_id: null,
      },
      addIssuerRequest: {
        issuer: '',
        addDocumentNameRequest: null,
      },
      addDocument: {
        parent_type_id: null,
        parent_id: null,
        notes: null,
        documents: [],
      },
      documents: [],
      exp_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
        maxDate: null,
      },
      documentTpyeIndex: null,
      documentNameIndex: null,
      issuerIndex: null,
      exp_date: null,
      types: [],
      parents: [],
      documentTypes: [],
      documentNames: [],
      issuers: [],
      reminders: [],
      versions: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRDocumentParentTypes(this)
        LIST.getDocumentTypes(this)
        LIST.getDocumentReminders(this)
        LIST.getDocumentVersions(this)
        this.onStateTypeChanged()
        this.documents.forEach((document, index) => {
          LIST.getDocumentNames(this, document.document_type_id, index)
          LIST.getDocumentIssuers(this, this.documents[index].document_name_id, index)
        })
      },
    },
    '$store.state.lists.hrLists.response': {
      immediate: true,
      handler() {
        this.onStateTypeChanged()
      },
    },
  },
  created() {
    this.addNewDocument()
    LIST.getHRDocumentParentTypes(this)
    LIST.getDocumentTypes(this)
    LIST.getDocumentReminders(this)
    LIST.getDocumentVersions(this)
    this.addDocument.parent_type_id = Number(this.$route.query.parent_type_id)
    if (Number.isNaN(this.addDocument.parent_type_id)) this.addDocument.parent_type_id = null
    if (this.addDocument.parent_type_id !== null) {
      this.onTypeChanged()
    }
    this.documents[0].document_type_id = Number(this.$route.query.document_type_id)
    if (Number.isNaN(this.documents[0].document_type_id)) this.documents[0].document_type_id = null
    this.documents[0].document_name_id = Number(this.$route.query.document_name_type_id)
    if (Number.isNaN(this.documents[0].document_name_id)) this.documents[0].document_name_id = null
    if (this.documents[0].document_type_id !== null) {
      LIST.getDocumentNames(this, this.documents[0].document_type_id, 0)
    }
    this.addDocument.parent_id = Number(this.$route.query.parent_id)
    if (Number.isNaN(this.addDocument.parent_id)) {
      this.addDocument.parent_id = null
    }
    this.documentNameChanged(0)
  },
  methods: {
    submitForm() {
      this.$refs.documentRules.validate().then(result => {
        if (result) {
          this.addDocument.documents = []
          this.documents.forEach(document => {
            const newDocument = {
              document_type_id: document.document_type_id,
              document_name_id: document.document_name_id,
              issuer_id: document.issuer_id,
              no_expire: document.no_expire,
              reminder_id: document.reminder_id,
              version_id: document.version_id,
              document: document.document,
              file_name: document.fileName,
            }
            if (document.exp_date !== null) {
              newDocument.exp_date = Constants.getEprmMonthDateFromString(document.exp_date)
            }
            this.addDocument.documents.push(newDocument)
          })
          ParentDocumentType.addDocument(this, this.addDocument)
        }
      })
    },
    onTypeChanged() {
      this.addDocument.parent_id = null
      this.onStateTypeChanged()
    },
    onStateTypeChanged() {
      if (this.addDocument.parent_type_id === null) {
        this.employees = []
        return
      }
      const employerType = this.types.find(type => type.parent_type_id === this.addDocument.parent_type_id)
      if (employerType === null) return
      switch (employerType.id) {
        case 'employer':
          LIST.getEmployersFullList(this)
          break
        case 'vendors':
          LIST.getVendorsFullList(this)
          break
        case 'suppliers':
          LIST.getSuppliersFullList(this)
          break
        case 'referrer':
          LIST.getReferrersFullList(this)
          break
        case 'subvendor':
          LIST.getSubVendorsFullList(this)
          break
        case 'employee':
          LIST.getEmployeeFullList(this)
          break
        case 'endclient':
          LIST.getEndClientsFullList(this)
          break
        default:
          break
      }
    },
    documentTypeChanged(index) {
      this.documents[index].document_name_id = null
      this.documents[index].issuer_id = null
      LIST.getDocumentNames(this, this.documents[index].document_type_id, index)
    },
    documentNameChanged(index) {
      this.documents[index].issuer_id = null
      LIST.getDocumentIssuers(this, this.documents[index].document_name_id, index)
    },
    addNewDocument() {
      const document = {
        document_type_id: null,
        document_name_id: null,
        issuer_id: null,
        no_expire: false,
        reminder_id: null,
        version_id: null,
        exp_date: null,
        documentNames: [],
        issuers: [],
        fileName: null,
        url: null,
        document: null,
      }
      this.documents.push(document)
    },
    removeNewDocument(index) {
      this.documents.splice(index, 1)
    },
    showDocumentType(index) {
      this.$refs.add_document_type.show()
      this.documentTpyeIndex = index
    },
    showDocumentName(index) {
      this.documentNameIndex = index
      this.$refs.add_document_name.show()
      this.addDocumentNameRequest.document_type_id = this.documents[index].document_type_id
    },
    showIssuer(index) {
      this.issuerIndex = index
      this.addIssuerRequest.document_name_type_id = this.documents[index].document_name_id
      this.$refs.add_issuer.show()
    },
    submitDocumentType() {
      this.$refs.newDocumentType.validate().then(result => {
        if (result) {
          this.showLoading = true
          ParentDocumentType.addDocumentType(this, this.addDocumentTypeRequest)
        }
      })
    },
    submitDocumentName() {
      this.$refs.newDocumentName.validate().then(result => {
        if (result) {
          this.showLoading = true
          ParentDocumentType.addDocumentName(this, this.addDocumentNameRequest)
        }
      })
    },
    submitIsssuer() {
      this.$refs.newIssuer.validate().then(result => {
        if (result) {
          this.showLoading = true
          ParentDocumentType.addIssuer(this, this.addIssuerRequest)
        }
      })
    },
    closingDocumentTypePopup() {
      this.documentTpyeIndex = null
      this.addDocumentTypeRequest = {
        document_type: '',
      }
    },
    closingDocumentNamePopup() {
      this.documentNameIndex = null
      this.addDocumentNameRequest = {
        document_name: '',
        document_type_id: null,
      }
    },
    closingIssuerPopup() {
      this.issuerIndex = null
      this.addIssuerRequest = {
        issuer: '',
        document_name_type_id: null,
      }
    },
    documentAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentTypeAdded(data) {
      this.documents[this.documentTpyeIndex].document_type_id = data.id
      this.$refs.add_document_type.hide()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentTypeNotAdded(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentNameAdded(data) {
      this.documents[this.documentNameIndex].document_name_id = data.id
      this.$refs.add_document_name.hide()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentNameNotAdded(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    issuerAdded(data) {
      this.documents[this.issuerIndex].issuer_id = data.id
      this.$refs.add_issuer.hide()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    issuerNotAdded(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    checkChanged(index) {
      this.documents[index].exp_date = null
      this.documents[index].reminder_id = null
    },
    attahamentFileSelected(obj, index) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.documents[index].document = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.documents[index].url = URL.createObjectURL(obj.target.files[0])
      this.documents[index].fileName = obj.target.files[0].name
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    setEndClients(endClients) {
      this.parents = endClients
    },
    setDocumentTypes(documentTypes) {
      this.documentTypes = documentTypes
    },
    setDocumentNames(documentNames, index) {
      this.documents[index].documentNames = documentNames
    },
    setDocumentIssuers(issuers, index) {
      this.documents[index].issuers = issuers
    },
    setDocumentReminders(reminders) {
      this.reminders = reminders
    },
    setDocumentVersions(versions) {
      this.versions = versions
    },
    setEmployers(vendors) {
      this.parents = vendors
    },
    setSuppliers(vendors) {
      this.parents = vendors
    },
    setReferrers(referrers) {
      this.parents = referrers
    },
    setSubVendors(vendors) {
      this.parents = vendors
    },
    setVendors(vendors) {
      this.parents = vendors
    },
    setEmployees(employees) {
      this.parents = employees
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
