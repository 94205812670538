<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Schedule Interview for {{ userName }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Interviewer
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Name"
                rules="required"
              >
                <v-select
                  v-model="addInterview.interviewer_id"
                  :options="interviewers"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  @input="interviewerSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Date"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="interview_date"
                  class="form-control"
                  :config="$store.state.config"
                  placeholder="MM-DD-YYYY"
                  @on-change="dateChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Available Time"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Available Time"
                rules="required"
              >
                <v-select
                  v-model="sloat"
                  :options="availableSloats"
                  :reduce="item => item"
                  :clearable="false"
                  placeholder="Select"
                  @input="sloatSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Custom Time From"
              label-cols-md="4"
              class="text-right"
            >
              <b-row>
                <b-col cols="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Timings From"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="addInterview.from_time"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K',}"
                      placeholder="00:00"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="7">
                  <b-form-group
                    label="To"
                    label-cols-md="3"
                    class="text-right"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="addInterview.to_time"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K',}"
                        placeholder="00:00"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Communication Mode"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Communication Mode"
                rules="required"
              >
                <v-select
                  v-model="addInterview.communication_mode_id"
                  :options="comTypes"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            offset="2"
          >
            <b-form-checkbox
              v-model="addInterview.send_invite"
            >
              Send Invite
            </b-form-checkbox>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Reminder"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Reminder"
                rules="required"
              >
                <v-select
                  v-model="addInterview.reminder_id"
                  :options="reminders"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Reminder Type"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Reminder Type"
                rules="required"
              >
                <v-select
                  v-model="addInterview.reminder_type_id"
                  :options="reminderTypes"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Status"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  v-model="addInterview.status_id"
                  :options="status"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            offset="2"
          >
            <b-form-checkbox
              v-model="addInterview.is_consent"
            >
              Consent
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Documents
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                v-model="documentNameId"
                :clearable="true"
                placeholder="Select"
                :options="documentNames"
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group
              label="Attachment"
              label-cols-md="4"
              class="text-right"
            >
              <div class="d-flex">
                <input
                  ref="myFileInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  class="form-control text-left"
                  @change="attahamentFileSelected"
                >
                <b-button
                  variant="outline-primary"
                  class="ml-1 px-1"
                  @click="addFile"
                >
                  Upload
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-button-group
              v-for="(option, index) in addInterview.documents"
              :key="option.id"
              :value="option.id"
              class="mr-1"
            >
              <b-badge
                :href="option.document_location"
                :title="option.name"
                target="_blank"
                variant="light-primary"
                class="rounded-right-0 line-height-inherit"
              >
                <span>{{ option.name }}</span>
              </b-badge>
              <b-button
                variant="danger"
                class="px-50 py-25"
                @click="removeFile(option, index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Comments
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="(comment, index) in addInterview.comments"
            :key="index"
            cols="6"
          >
            <div class="d-flex">
              <div class="left w-100">
                <b-form-group
                  label="Comment"
                  label-cols-md="4"
                  class="text-right"
                >
                  <b-form-textarea
                    v-model="comment.comment"
                    rows="2"
                  />
                </b-form-group>
              </div>
              <div class="right ml-1">
                <b-button
                  v-show="index === 0"
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  @click="addComment"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  v-show="index !== 0"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeComment(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Interview
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormCheckbox, BButton, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Candidate from '@/js/candidates'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import Interviewers from '@/js/interviewers'

export default {
  name: 'JobPostingInterview',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BBadge,
    BCol,
    BForm,
    BFormGroup,
    vSelect,
    BFormCheckbox,
    BFormTextarea,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addInterview: {
        interviewer_id: null,
        reminder_id: null,
        reminder_type_id: null,
        date: null,
        from_time: null,
        to_time: null,
        send_invite: false,
        status_id: null,
        comments: [],
        documents: [],
      },
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        end: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          howClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      sloat: {},
      userName: '',
      interview_date: null,
      documentName: null,
      documentNameId: null,
      document_name: '',
      selectedFile: null,
      interviewers: [],
      status: [],
      comTypes: [],
      documentNames: [],
      availableSloats: [],
      reminders: [],
      reminderTypes: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getInterviewersList(this)
        LIST.getRemindersList(this)
        LIST.getReminderTypesList(this)
        LIST.getCommunicationTypesFullList(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getInterviewStatusFullList(this)
        LIST.getInterviewDocumentNameFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getInterviewStatusFullList(this)
    LIST.getInterviewDocumentNameFullList(this)
    LIST.getInterviewersList(this)
    LIST.getRemindersList(this)
    LIST.getReminderTypesList(this)
    LIST.getCommunicationTypesFullList(this)
    this.userName = this.$route.query.name
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addInterview.documents.push({
              document_name_id: this.documentNameId,
              document_location: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          if (this.interview_date !== null) {
            this.addInterview.date = Constants.getEprmMonthDateFromString(this.interview_date)
          }
          this.showLoading = true
          Candidate.scheduleInterview(this, this.$route.params.job_candidate, this.addInterview)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addInterview.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addInterview.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setCommunicationTypes(types) {
      this.comTypes = types
    },
    setRemindersList(reminders) {
      this.reminders = reminders
    },
    setReminderTypesList(reminderTypes) {
      this.reminderTypes = reminderTypes
    },
    setInterviewersList(interviewers) {
      this.interviewers = interviewers
    },
    setAvailableTimeSloats(availableSloats) {
      this.availableSloats = availableSloats
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    sloatSelected() {
      this.addInterview.from_time = this.sloat.from_time
      this.addInterview.to_time = this.sloat.to_time
    },
    interviewDateChanged() {
      const start = moment(this.addInterview.interview_date)
      const end = moment(this.addInterview.interview_date)
      if (start.isAfter(end, 'day')) {
        this.addInterview.interview_date = start
      }
    },
    interviewerSelected() {
      if (this.addInterview.interviewer_id !== null && this.interview_date !== null) {
        Interviewers.getAvailableTimeSloatsByInterviewerIdAndDate(this, this.addInterview.interviewer_id, moment(this.interview_date, 'MM-DD-YYYY').format('DD'), moment(this.interview_date, 'MM-DD-YYYY').format('MM'), moment(this.interview_date, 'MM-DD-YYYY').format('YYYY'))
      }
    },
    dateChanged(selectedDate, dateStr) {
      this.interview_date = dateStr
      if (this.addInterview.interviewer_id !== null && this.interview_date !== null) {
        Interviewers.getAvailableTimeSloatsByInterviewerIdAndDate(this, this.addInterview.interviewer_id, moment(this.interview_date, 'MM-DD-YYYY').format('DD'), moment(this.interview_date, 'MM-DD-YYYY').format('MM'), moment(this.interview_date, 'MM-DD-YYYY').format('YYYY'))
      }
    },
    interviewTimeChanged() {
      const start = moment(this.addInterview.interview_time)
      const end = moment(this.addInterview.interview_time)
      if (start.isAfter(end, 'day')) {
        this.addInterview.interview_time = start
      }
    },
    scheduleAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    scheduleNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addInterview.comments.push(comment)
    },
    removeComment(index) {
      this.addInterview.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
