<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Vendor Recruiter</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Recruiter Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addRecruiter.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Vendor</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Vendor"
                    rules="required"
                  >
                    <v-select
                      id="vendor_id"
                      v-model="addRecruiter.vendor_id"
                      :options="vendors"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="addRecruiter.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="phone"
                      v-model="addRecruiter.phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Additional Contact Information</h6>
            </div>
            <div
              ref="form"
              class="repeater-form"
            >
              <b-row
                v-for="(contactInfo, index) in addRecruiter.contact_information"
                :id="contactInfo.id"
                :key="contactInfo.id"
                ref="row"
              >
                <b-col cols="12">
                  <div class="d-flex">
                    <div class="left w-100">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Contact Type</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Contact Type' + index"
                              rules="required"
                            >
                              <v-select
                                :id="'contact_type' + index"
                                v-model="addRecruiter.contact_information[index].contact_type_id"
                                :options="contactTypes"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                @input="contactTypeMatch(addRecruiter.contact_information[index].contact_type_id, index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Contact Type' + index, 'Contact Type') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Email</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Email' + index"
                              rules="required|email"
                            >
                              <b-form-input
                                :id="'contact_info_email'+ index"
                                v-model="contactInfo.email"
                                :state="errors.length > 0 ? false:null"
                                type="email"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Email' + index, 'Email') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Phone</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Phone' + index"
                              rules="required|integer|digits:10"
                            >
                              <b-form-input
                                :id="'contact_info_phone' + index"
                                v-model="contactInfo.phone"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Phone' + index, 'Phone') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="right mt-2 ml-1">
                      <b-button
                        id="contact_info_remove"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="btn-icon rounded-circle"
                        @click="removeContactInfo(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <hr class="mt-0">
                </b-col>
              </b-row>
            </div>
            <div v-if="contactTypes.length !== addRecruiter.contact_information.length">
              <b-button
                id="add_contact_info"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="ml-0"
                @click="addContactInformation"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Additional Contact</span>
              </b-button>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addRecruiter.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Recruiter
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VendorRecruiter from '@/js/vendorrecruiter'
import LIST from '@/js/vjmslists'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  name: 'VendorRecruiterNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
    // currency,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      addRecruiter: {
        name: '',
        vendor_id: null,
        email: '',
        phone: '',
        contact_information: [],
        comments: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      vendors: [],
      contactTypes: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getVendors(this)
        LIST.getContactTypes(this)
      },
    },
  },
  created() {
    this.addComment()
    LIST.getVendors(this)
    LIST.getContactTypes(this)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          VendorRecruiter.addVendorRecruiter(this, this.addRecruiter)
        }
      })
    },
    contactTypeMatch(typeId, index) {
      if (this.addRecruiter.contact_information.filter(contactInformation => contactInformation.contact_type_id === typeId).length > 1) {
        this.addRecruiter.contact_information[index].contact_type_id = null
      }
    },
    addContactInformation() {
      const contactInfo = {
        contact_type_id: null,
        email: '',
        phone: '',
      }
      this.addRecruiter.contact_information.push(contactInfo)
    },
    removeContactInfo(index) {
      this.addRecruiter.contact_information.splice(index, 1)
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setContactTypes(contactTypes) {
      this.contactTypes = contactTypes
    },
    recruiterAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    recruiterNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addRecruiter.comments.push(comment)
    },
    removeComment(index) {
      this.addRecruiter.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/vendor-jobs-management/vendor-recruiters').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
