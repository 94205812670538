<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item
            v-if="task.task_type === 'invoice'"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Invoice Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    {{ task.invoice_item.parent }}
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.invoice_item.parent_name"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Consultant
                  </dt>
                  <dd class="col-sm-12">
                    <div
                      v-for="(option, index) in task.invoice_item.employee_names"
                      :key="index"
                    >
                      <span
                        :key="index"
                        class="form-control"
                        v-text="option"
                      />
                    </div>
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Invoice #
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.invoice_item.number"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Received Date
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.invoice_item.date"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Invoice Period
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.invoice_item.period"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="task.task_type === 'timesheet'"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Timesheet Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Name of Employee Consultant
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.timesheet_item.employee_name"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Timesheet Frequency
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.timesheet_item.frequency"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Period
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.timesheet_item.period"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="task.task_type === 'job'"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Job Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Job Title
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.job_item.job_title"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Vendor
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.job_item.vendor"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="task.task_type === 'parent'"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Parent Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    {{ task.parent_item.parent_type }} Name
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.parent_item.parent_name"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Email
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control h-auto"
                      v-text="task.parent_item.parent_email"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Phone
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.parent_item.parent_phone"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="task.task_type === 'interview'"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Interview Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Employer Name
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.interview_item.employer_name"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Candidate Name
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.interview_item.candidate_name"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Interview Date
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.interview_item.date"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Interview Time
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.interview_item.time"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Interviewer Name
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="task.interview_item.interviewer_name"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Task Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Task Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addTask.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group id="due_date">
                  <label>Due Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Due Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="due_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group>
                  <label>Task Description</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Description"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="addTask.description"
                      rows="2"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="addTask.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addTask.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addTask.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1"
          >

            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addTask.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Task
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Task from '@/js/tasks'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'TaskEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      task: {},
      required,
      addTask: {
        name: '',
        status_id: null,
        description: '',
        comments: [],
        user_defined_fields: [],
      },
      url: null,
      due_date: null,
      status: [],
    }
  },
  watch: {
    '$store.state.lists.moduleLists': {
      immediate: true,
      handler() {
        LIST.getTaskStatusFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getTaskStatusFullList(this)
    Task.getTaskById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.due_date != null) {
            this.addTask.due_date = Constants.getEprmMonthDateFromString(this.due_date)
          }
          this.showLoading = true
          Task.updateTask(this, this.$route.params.id, this.addTask)
        }
      })
    },
    setStatus(status) {
      this.status = status
    },
    taskUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    taskNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setTask(task) {
      this.task = task
      this.addTask.comments = task.task.comments
      this.addTask.user_defined_fields = task.user_defined_fields
      this.addTask.due_date = task.task.due_date
      this.due_date = task.task.due_date
      this.addTask.name = task.task.name
      this.addTask.description = task.task.description
      this.addTask.status_id = task.task.status_id
      this.addTask.comments = task.comments
      if (this.addTask.comments.length === 0) {
        this.addComment()
      }
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addTask.comments.push(comment)
    },
    removeComment(index) {
      this.addTask.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/tasks').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
