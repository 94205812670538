<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Batch Process Sent Mails Report</h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="col-xxl-4"
        >
          <b-form-group
            id="month"
            label="Month"
            label-cols-md="3"
            class="text-right mb-0"
          >
            <flat-pickr
              v-model="month"
              class="form-control"
              :config="$store.state.config"
              placeholder="MM-DD-YYYY"
              :clear-button="true"
              @on-change="monthDateChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="col-xxl-10 offset-xxl-1 mb-1"
        >
          <b-row class="mb-1">
            <b-col
              cols="12"
              md="6"
            >
              <h4 class="card-title mb-0 pt-50">
                Before Timesheets Notifications</h4>
            </b-col>
            <b-col
              cols="12"
              md="6 ml-auto"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="sendAllBeforeTimesheetsMails"
                >
                  Send All Before Timesheets Notifications
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- <template> -->
          <b-table
            ref="refBeforeTimesheets"
            class="position-relative"
            :items="report.before_timesheet_mails"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="No Email Sent"
          >
            <!-- Column: User -->
            <template #cell(email_resumed)="data">
              <b-button
                variant="primary"
                type="button"
                @click="toggleStatus(data.item.mail_queue_id, data.item.resumed)"
              >
                {{ data.item.resumed }}
              </b-button>
            </template>
          </b-table>
          <!-- </template> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="col-xxl-10 offset-xxl-1 mb-1"
        >
          <b-row class="mb-1">
            <b-col
              cols="12"
              md="6"
            >
              <h4 class="card-title mb-0 pt-50">
                After Timesheets Notifications</h4>
            </b-col>
            <b-col
              cols="12"
              md="6 ml-auto"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="sendAllAfterTimesheetsMails"
                >
                  Send All After Timesheets Notifications
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- <template> -->
          <b-table
            ref="refAfterTimesheets"
            class="position-relative"
            :items="report.after_timesheet_mails"
            responsive
            :fields="tableColumns2"
            primary-key="id"
            show-empty
            empty-text="No Email Sent"
          >
            <!-- Column: User -->
            <template #cell(email_resumed)="data">
              <b-button
                variant="primary"
                type="button"
                @click="toggleStatus(data.item.mail_queue_id, data.item.resumed)"
              >
                {{ data.item.resumed }}
              </b-button>
            </template>
          </b-table>
          <!-- </template> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="col-xxl-10 offset-xxl-1 mb-1"
        >
          <b-row class="mb-1">
            <b-col
              cols="12"
              md="6"
            >
              <h4 class="card-title mb-0 pt-50">
                Before Invoice Notifications</h4>
            </b-col>
            <b-col
              cols="12"
              md="6 ml-auto"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="warning"
                  @click="sendAllBeforeInvoiceMails"
                >
                  Send All Before Invoice Notifications
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- <template> -->
          <b-table
            ref="refBeforeInvoice"
            class="position-relative"
            :items="report.before_invoice_mails"
            responsive
            :fields="tableColumns3"
            primary-key="id"
            show-empty
            empty-text="No Email Sent"
          >
            <!-- Column: User -->
            <template #cell(email_resumed)="data">
              <b-button
                variant="primary"
                type="button"
                @click="toggleStatus(data.item.mail_queue_id, data.item.resumed)"
              >
                {{ data.item.resumed }}
              </b-button>
            </template>
          </b-table>
          <!-- </template> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="col-xxl-10 offset-xxl-1 mb-1"
        >
          <b-row class="mb-1">
            <b-col
              cols="12"
              md="6"
            >
              <h4 class="card-title mb-0 pt-50">
                After Invoice Notifications</h4>
            </b-col>
            <b-col
              cols="12"
              md="6 ml-auto"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="sendAllAfterInvoiceMails"
                >
                  Send All After Invoice Notifications
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- <template> -->
          <b-table
            ref="refAfterInvoice"
            class="position-relative"
            :items="report.after_invoice_mails"
            responsive
            :fields="tableColumns4"
            primary-key="id"
            show-empty
            empty-text="No Email Sent"
          >
            <!-- Column: User -->
            <template #cell(email_resumed)="data">
              <b-button
                variant="primary"
                type="button"
                @click="toggleStatus(data.item.mail_queue_id, data.item.resumed)"
              >
                {{ data.item.resumed }}
              </b-button>
            </template>
          </b-table>
          <!-- </template> -->
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BTable, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// import store from '@/store/index'
// import Constant from '@/js/constant'
import moment from 'moment'
import BatchProcess from '@/js/batchprocess'

export default {
  name: 'BatchProcessingReport',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    BTable,
    BButton,

  },
  data() {
    return {
      report: {
        before_timesheet_mails: [],
        after_timesheet_mails: [],
        before_invoice_mails: [],
        after_invoice_mails: [],
      },
      dir: false,
      month: moment().format('MM-DD-YYYY'),
      fields: [
        'id',
        'Consultant Name',
        'email',
        'Email Resumed',
      ],
      items: [],
      tableColumns: [
        {
          key: 'index', label: '#',
        },
        {
          key: 'name', label: 'Consultant Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'email_resumed', label: 'Email Resumed',
        },
      ],
      tableColumns2: [
        {
          key: 'index', label: '#',
        },
        {
          key: 'name', label: 'Consultant Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'email_resumed', label: 'Email Resumed',
        },
      ],
      tableColumns3: [
        {
          key: 'index', label: '#',
        },
        {
          key: 'name', label: 'Consultant Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'email_resumed', label: 'Email Resumed',
        },
      ],
      tableColumns4: [
        {
          key: 'index', label: '#',
        },
        {
          key: 'name', label: 'Consultant Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'email_resumed', label: 'Email Resumed',
        },
      ],
    }
  },
  created() {
    BatchProcess.getDateBatchProcess(this, moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'), moment(this.month, 'MM-DD-YYYY').format('DD'))
  },
  methods: {
    monthDateChanged() {
      BatchProcess.getDateBatchProcess(this, moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'), moment(this.month, 'MM-DD-YYYY').format('DD'))
    },
    sendAllBeforeTimesheetsMails() {
      BatchProcess.sendAllMails(this, 'beforetimesheet', moment(this.month, 'MM-DD-YYYY').format('DD'), moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'))
    },
    sendAllAfterTimesheetsMails() {
      BatchProcess.sendAllMails(this, 'aftertimesheet', moment(this.month, 'MM-DD-YYYY').format('DD'), moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'))
    },
    sendAllBeforeInvoiceMails() {
      BatchProcess.sendAllMails(this, 'beforeinvoice', moment(this.month, 'MM-DD-YYYY').format('DD'), moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'))
    },
    sendAllAfterInvoiceMails() {
      BatchProcess.sendAllMails(this, 'afterinvoice', moment(this.month, 'MM-DD-YYYY').format('DD'), moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'))
    },
    toggleStatus(id, status) {
      if (!status) {
        this.$swal({
          title: 'Are you sure?',
          text: 'Are you sure want send mail',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            BatchProcess.sendMail(this, id)
          }
        })
      }
    },
    updated(message) {
      BatchProcess.getDateBatchProcess(this, moment(this.month, 'MM-DD-YYYY').format('MM'), moment(this.month, 'MM-DD-YYYY').format('YYYY'), moment(this.month, 'MM-DD-YYYY').format('DD'))
      this.$swal({
        title: 'Success!',
        text: message.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setReport(report) {
      this.report = report
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
