<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-2"
        >
          <h4 class="card-title mb-0 pt-50">
            Prospects</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
          class="col-xxl-6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="prospectsResponse.prospects"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action ml-2"
        >
          <span>
            <b-link
              id="view"
              :to="{ name: 'human-resources-quick-prospects-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="prospectsResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BBadge, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BLink, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import JobRequests from '@/js/vendorjob'

export default {
  name: 'JobRequestProspects',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BLink,
    BButton,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      prospectsResponse: {
        prospects: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email_address',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.innerpage
      },
      set(val) {
        this.$store.state.query.innerpage = val
        JobRequests.getVendorJobProspectsById(this, this.$route.params.id)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (this.$store.state.query.innerpage === 0 || this.$store.state.query.innerpage === null || this.$store.state.query.innerpage === undefined) {
      this.$store.state.query.innerpage = 1
    }
    this.searchQuery = this.$store.state.query.innerQuery
    this.prospectsResponse.count = this.$store.state.query.innerpage
    JobRequests.getVendorJobProspectsById(this, this.$route.params.id)
  },
  methods: {
    updateSearchQuery() {
      this.$store.state.query.innerQuery = this.searchQuery
      this.currentPage = 1
    },
    setVendorJobProspects(prospectsResponse) {
      this.prospectsResponse = prospectsResponse
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
