<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Expense</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="showList">
                  <label>Expenses Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expenses Type"
                    :rules="showList ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <v-select
                          id="expenses_type_id"
                          v-model="addExpense.expenses_type_id"
                          :options="expensesTypes"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          @click="addNewName"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="showName">
                  <label>Expenses Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expenses Name"
                    :rules="showName ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-input
                          id="expenses_name"
                          v-model="addExpense.expenses_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="showListOption"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Amount</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Amount"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="amount"
                        v-model="addExpense.amount"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Paid Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Paid Date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      v-model="date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-checkbox
                  id="recurring"
                  v-model="addExpense.recurring"
                  class="mt-md-2"
                >
                  Recurring
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Payment Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Type"
                    rules="required"
                  >
                    <v-select
                      id="payment_type_id"
                      v-model="addExpense.payment_type_id"
                      :options="paymentTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="showCheck"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Check #</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Check"
                    :rules="showCheck ? 'required' : ''"
                  >
                    <b-form-input
                      id="check_number"
                      v-model="addExpense.check_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Receipts</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    id="documentNameId"
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addExpense.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.document_location"
                    :title="option.name"
                    target="_blank"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addExpense.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        id="comment"
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addExpense.user_defined_fields.length !== 0"
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addExpense.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Expense
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BFormTextarea, BFormCheckbox, BButtonGroup, BButton, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Expense from '@/js/expenses'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'ExpenseNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addExpense: {
        expenses_name: '',
        check_number: '',
        amount: '',
        date: null,
        expenses_type_id: null,
        payment_type_id: null,
        recurring: false,
        documents: [],
        comments: [],
        user_defined_fields: [],
      },
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        period: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          howClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      url: null,
      date: null,
      documentNameId: null,
      selectedFile: null,
      showList: true,
      showName: false,
      showCheck: false,
      expensesTypes: [],
      paymentTypes: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getExpensesTypes(this)
        LIST.getPaymentTypes(this)
      },
    },
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getInvoiceEmployerDocumentNameFullList(this)
        LIST.getUserDefinedFieldsExpenses(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getExpensesTypes(this)
    LIST.getPaymentTypes(this)
    LIST.getInvoiceEmployerDocumentNameFullList(this)
    LIST.getUserDefinedFieldsExpenses(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addExpense.documents.push({
              document_name_id: this.documentNameId,
              document_location: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          if (this.date !== null) {
            this.addExpense.date = Constants.getEprmMonthDateFromString(this.date)
          }
          // this.showLoading = true
          Expense.addExpense(this, this.addExpense)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addExpense.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addExpense.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addExpense.documents.push({
          document_name_id: this.documentNameId, document_location: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setExpensesTypes(expensesTypes) {
      this.expensesTypes = expensesTypes
    },
    setPaymentTypes(paymentTypes) {
      this.paymentTypes = paymentTypes
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    onTypeChanged() {
      this.showCheck = false
      this.paymentTypes.forEach(paymentType => {
        if (paymentType.id === this.addExpense.payment_type_id) {
          this.showCheck = paymentType.name.toLowerCase() === 'check'
        }
      })
    },
    addNewName() {
      this.addExpense.expenses_type_id = null
      this.showList = false
      this.showName = true
    },
    showListOption() {
      this.showList = true
      this.showName = false
    },
    expenseAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    expenseNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addExpense.comments.push(comment)
    },
    removeComment(index) {
      this.addExpense.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/finance-and-accounting/expenses').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
