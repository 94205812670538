<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Exception</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Parent Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Parent Type"
                    rules="required"
                  >
                    <v-select
                      id="parent_type"
                      v-model="addException.parent_type"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Parent</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Parent"
                    rules="required"
                  >
                    <v-select
                      id="parent_id"
                      v-model="addException.parent_id"
                      :options="employees"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Parent Level</label>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      id="is_parent_level"
                      v-model="addException.is_parent_level"
                      class="mt-50"
                      @change="checkYes"
                    >
                      Yes
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="parent_level_no"
                      v-model="addException.parent_level_no"
                      class="mt-50"
                      @change="checkNo"
                    >
                      No
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!addException.is_parent_level"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="month_year"
                >
                  <label>Select Month/Year</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Select Month/Year"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="month_year"
                      class="form-control"
                      :config="$store.state.monthConfig"
                      placeholder="MM-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mb-1"
              >
                <b-form-checkbox
                  id="is_bypass_invoice"
                  v-model="addException.is_bypass_invoice"
                >
                  Bypass Invoice
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mb-1"
              >
                <b-form-checkbox
                  id="is_bypass_timesheet"
                  v-model="addException.is_bypass_timesheet"
                >
                  Bypass Timesheet
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Exception Reason</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Exception Reason"
                    rules="required"
                  >
                    <b-form-textarea
                      id="reason"
                      v-model="addException.reason"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addException.user_defined_fields.length !== 0"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addException.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Exception
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormTextarea, BFormCheckbox, BButton, BFormInput,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import Exception from '@/js/exceptions'
import Constants from '@/js/constant'
import moment from 'moment'
import LIST from '@/js/lists'

export default {
  name: 'ExceptionNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addException: {
        parent_type: null,
        parent_id: null,
        parent_type_id: null,
        is_parent_level: true,
        parent_level_no: false,
        is_bypass_timesheet: false,
        is_bypass_invoice: false,
        reason: '',
        user_defined_fields: [],
      },
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      url: null,
      types: [],
      employees: [],
      month_year: null,
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRExceptionParentTypes(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getUserDefinedFieldsExceptions(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getHRExceptionParentTypes(this)
    LIST.getUserDefinedFieldsExceptions(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (!this.addException.is_parent_level) {
            this.addException.month = Constants.getEprmMonthDateFromString(`${moment(this.month_year, 'MMM-YYYY').format('MM')}-01-${moment(this.month_year, 'MMM-YYYY').format('YYYY')}`)
          } else {
            this.addException.month = null
          }
          this.showLoading = true
          this.addException.parent_type_id = this.addException.parent_type
          Exception.addException(this, this.addException)
        }
      })
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    setUserDefinedFields(userDefinedFields) {
      this.addException.user_defined_fields = userDefinedFields
    },
    onTypeChanged() {
      this.addException.parent_id = null
      this.addException.employees = []
      if (this.addException.parent_type !== null) {
        const employerType = this.types.find(type => type.parent_type_id === this.addException.parent_type)
        switch (employerType.id) {
          case 'employer':
            LIST.getEmployersFullList(this)
            break
          case 'vendors':
            LIST.getVendorsFullList(this)
            break
          case 'suppliers':
            LIST.getSuppliersFullList(this)
            break
          case 'referrer':
            LIST.getReferrersFullList(this)
            break
          case 'subvendor':
            LIST.getSubVendorsFullList(this)
            break
          case 'employee':
            LIST.getEmployeeFullList(this)
            break
          default:
            break
        }
      }
    },
    setEmployers(vendors) {
      this.employees = vendors
    },
    setSuppliers(vendors) {
      this.employees = vendors
    },
    setReferrers(referrers) {
      this.employees = referrers
    },
    setSubVendors(vendors) {
      this.employees = vendors
    },
    setVendors(vendors) {
      this.employees = vendors
    },
    setEmployees(employees) {
      this.employees = employees
    },
    checkYes() {
      this.addException.parent_level_no = !this.addException.is_parent_level
    },
    checkNo() {
      this.addException.is_parent_level = !this.addException.parent_level_no
    },
    setExceptionEmployees(employees) {
      this.employees = employees
    },
    exceptionAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    exceptionNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/human-resources/exceptions').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
