import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const interview = `${HOST}interview`

export default {
  getInterviews(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(interview, params).then(response => {
      context.setInterviews(response.data)
    }, () => {
    })
  },
  toggleStatus(context1, id, statusId) {
    const context = context1
    context.$vs.loading()
    axios.put(`${interview}/update-status/${id}/${statusId}`, {}).then(response => {
      context.$vs.loading.close()
      context.updated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.updateError(error.response.data.return_message)
    })
  },
  addInterview(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(interview, body).then(response => {
      context.$vs.loading.close()
      context.interviewAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewNotAdded(error.response.data.return_message)
    })
  },
  updateInterviewConsentByToken(context1, token) {
    const context = context1
    context.$vs.loading()
    axios.put(`${interview}/token/${token}`, context.updateConsent).then(response => {
      context.$vs.loading.close()
      context.interviewUpdated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.interviewNotUpdated(error.response.data.return_message)
    })
  },
  updateInterview(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${interview}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.interviewUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${interview}/${id}`).then(response => {
      context.$vs.loading.close()
      context.interviewDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewNotDeleted(error.response.data.return_message)
    })
  },
  getInterviewByToken(context1, token) {
    const context = context1
    context.$vs.loading()
    axios.get(`${interview}/token/${token}`).then(response => {
      context.$vs.loading.close()
      context.setInterview(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewNotFound(error.response.data.return_message)
    })
  },
  getInterviewById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${interview}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setInterview(response.data)
    }, error => {
      context.$vs.loading.close()
      context.interviewNotFound(error.response.data.return_message)
    })
  },

}
