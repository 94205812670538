import { render, staticRenderFns } from "./Workforce.vue?vue&type=template&id=78a0d974&"
import script from "./Workforce.vue?vue&type=script&lang=js&"
export * from "./Workforce.vue?vue&type=script&lang=js&"
import style0 from "./Workforce.vue?vue&type=style&index=0&id=78a0d974&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports