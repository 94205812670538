<template>
  <div class="ex-large">
    <b-row class="match-height">
      <b-col
        v-show="$store.state.roles.is_employee_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :key="counts.employees_count"
          :statistic="counts.employees_count"
          icon="UsersIcon"
          statistic-title="Employees"
          color="primary"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_consultant_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.consultants_count"
          icon="HeadphonesIcon"
          statistic-title="Consultants"
          color="info"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_prospect_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.prospects_count"
          icon="SearchIcon"
          statistic-title="Prospects"
          color="warning"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_employer_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.employers_count"
          icon="UsersIcon"
          statistic-title="Employers"
          color="success"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_supplier_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.suppliers_count"
          icon="UsersIcon"
          statistic-title="Suppliers"
          color="danger"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_referrer_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.referrers_count"
          icon="UsersIcon"
          statistic-title="Referrers"
          color="primary"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_vendor_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.vendors_count"
          icon="UsersIcon"
          statistic-title="Vendors"
          color="info"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_subvendor_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.subvendors_count"
          icon="UsersIcon"
          statistic-title="Sub-Vendors"
          color="warning"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_endclient_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.endclients_count"
          icon="UsersIcon"
          statistic-title="End Clients"
          color="success"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_payable_invoice_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.payable_pending_count"
          icon="FileTextIcon"
          statistic-title="Payable Invoices Pending"
          color="danger"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_receivable_invoice_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.receivable_pending_count"
          icon="FileTextIcon"
          statistic-title="Receivable Invoices Pending"
          color="info"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_timesheets_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.timesheets_waiting_count"
          icon="ClockIcon"
          statistic-title="Timesheets Waiting"
          color="danger"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_timesheets_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.timesheets_received_count"
          icon="ClockIcon"
          statistic-title="Timesheets Received"
          color="success"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_jobs_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.jobs_count"
          icon="SearchIcon"
          statistic-title="Jobs"
          color="primary"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_interview_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.upcomming_interviews_count"
          icon="EyeIcon"
          statistic-title="Upcoming Interviews"
          color="info"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_tasks_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          :statistic="counts.pending_tasks_count"
          icon="ListIcon"
          statistic-title="Pending Tasks"
          color="warning"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import List from '@/js/lists'
import User from '@/js/user'

export default {
  name: 'AppDashboard',
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  data() {
    return {
      user: User,
    }
  },
  computed: {
    counts: {
      get() {
        return this.$store.state.counts
      },
      set(val) {
        this.$store.state.counts = val
      },
    },
  },
  created() {
    List.getDashboardCount(this)
  },
  methods: {
    setCounts(counts) {
      this.counts = counts
      this.$store.commit('ALERTS_COUNT', counts.alerts_count)
    },
  },
}
</script>

<style>

</style>
