<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Invoice From Referrer</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Referrer</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Referrer"
                    rules="required"
                  >
                    <v-select
                      id="employer_id"
                      v-model="addInvoice.employer_id"
                      :options="referrers"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onReferrerChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Consultant</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Consultant"
                    rules="required"
                  >
                    <v-select
                      id="employee_ids"
                      v-model="addInvoice.employee_ids"
                      :options="employees"
                      :reduce="item => item.id"
                      multiple
                      :clearable="false"
                      :disabled="addInvoice.employer_id == null"
                      placeholder="Select"
                      @input="onEmployeeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice#</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice"
                    rules="required"
                  >
                    <b-form-input
                      id="invoice"
                      v-model="addInvoice.invoice"
                      :state="errors.length > 0 ? false:null"
                      :disabled="addInvoice.employer_id === null || addInvoice.employee_ids.length === 0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Received Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Received Date"
                    rules="required"
                  >
                    <flat-pickr
                      id="received_date"
                      v-model="received_date"
                      class="form-control"
                      :config="$store.state.config"
                      :disabled="addInvoice.employer_id == null || addInvoice.employee_ids.length === 0"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice Period</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Period"
                    rules="required"
                  >
                    <flat-pickr
                      id="period_date"
                      v-model="period_date"
                      class="form-control"
                      :config="$store.state.maxMonthConfig"
                      :disabled="addInvoice.employer_id == null || addInvoice.employee_ids.length === 0"
                      placeholder="MMM-YYYY"
                      @on-change="periodChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice Amount</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Amount"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="invoice_amount"
                        v-model="addInvoice.invoice_amount"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                        :disabled="addInvoice.employer_id == null || addInvoice.employee_ids.length === 0"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="6">
                <b-form-group
                  label="Time Sheets"
                  label-cols-md="4"
                  class="text-right"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Time Sheets"
                    rules="required"
                  >
                    <v-select
                      id="selected_timeSheets"
                      v-model="selected_timeSheets"
                      :options="timeSheets"
                      label="name"
                      multiple
                      :clearable="false"
                      placeholder="Select"
                      :disabled="addInvoice.employer_id == null || addInvoice.employee_ids.length === 0"
                      @input="timesheetChanges"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status_id"
                      v-model="addInvoice.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-checkbox
                  id="is_corrected"
                  v-model="addInvoice.is_corrected"
                  :disabled="addInvoice.employer_id == null || period_date == null"
                  class="mt-md-2"
                  @change="correctedChanged()"
                >
                  Corrected Invoice
                </b-form-checkbox>
              </b-col>
              <b-col
                v-if="addInvoice.is_corrected"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoices</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoices"
                    :rules="addInvoice.is_corrected ? 'required' : ''"
                    :required="addInvoice.is_corrected ? true : false"
                  >
                    <v-select
                      id="corrected_invoice_id"
                      v-model="addInvoice.corrected_invoice_id"
                      :options="correctedInvoices"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    id="documentNameId"
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addInvoice.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="option.attachment_link"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addInvoice.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        id="comment"
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addInvoice.user_defined_fields.length !== 0"
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addInvoice.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Invoice
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BFormTextarea, BFormCheckbox, BButtonGroup, BButton, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import moment from 'moment'
import Constants from '@/js/constant'
import Invoice from '@/js/invoicesreferrer'
import LIST from '@/js/lists'

export default {
  name: 'InvoicesReferrerNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addInvoice: {
        employer_id: null,
        status_id: null,
        employee_ids: [],
        invoice: '',
        received_date: null,
        period: null,
        hours: 0,
        invoice_amount: '',
        timesheet_ids: [],
        is_corrected: false,
        corrected_invoice_id: null,
        documents: [],
        comments: [],
        user_defined_fields: [],
      },
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        period: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          howClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      url: null,
      received_date: null,
      period_date: null,
      documentNameId: null,
      selectedFile: null,
      selected_timeSheets: null,
      cal_total_amount: null,
      referrers: [],
      employees: [],
      employee: [],
      correctedInvoices: [],
      status: [],
      timeSheets: [],
      companies: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getReferrersFullList(this)
      },
    },
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getInvoiceReferrerStatusFullList(this)
        LIST.getInvoiceReferrerDocumentNameFullList(this)
        LIST.getUserDefinedFieldsInvoiceReferrer(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getReferrersFullList(this)
    LIST.getInvoiceReferrerStatusFullList(this)
    LIST.getInvoiceReferrerDocumentNameFullList(this)
    LIST.getUserDefinedFieldsInvoiceReferrer(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addInvoice.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.showLoading = true
          if (this.period_date !== null) {
            const periodDateMonth = this.period_date.split('-')
            this.addInvoice.period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMMM').format('MM')}-01-${periodDateMonth[1]}`)
          }
          if (this.received_date !== null) {
            this.addInvoice.received_date = Constants.getEprmMonthDateFromString(this.received_date)
          }
          if (Number(this.cal_total_amount) === Number(this.addInvoice.invoice_amount)) {
            Invoice.addInvoice(this, this.addInvoice)
          } else {
            this.amountChanged()
          }
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addInvoice.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addInvoice.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addInvoice.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setCorrectedInvoices(correctedInvoices) {
      this.correctedInvoices = correctedInvoices
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setReferrers(referrers) {
      this.referrers = referrers
    },
    setEmployees(employees) {
      this.employees = employees
    },
    setReferrerTimeSheets(timeSheets) {
      this.timeSheets = timeSheets
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    amountChanged() {
      this.$swal({
        title: 'proceed',
        text: 'You had changed caliculated amount are you sure want to continue',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const dis = this
        if (result.value) {
          Invoice.addInvoice(dis, dis.addInvoice)
        }
      })
    },
    timesheetChanges() {
      if (this.selected_timeSheets.length === 0) {
        this.selected_timeSheets = null
      }
      let totalAmount = 0
      let totalHours = 0
      this.addInvoice.timesheet_ids = []
      if (this.selected_timeSheets !== null) {
        for (let i = 0; i < this.selected_timeSheets.length; i += 1) {
          totalAmount = `${totalAmount}${this.selected_timeSheets[i]}`.total_amount
          totalHours = `${totalHours}${this.selected_timeSheets[i]}`.total_hours
          this.addInvoice.timesheet_ids.push(this.selected_timeSheets[i].id)
        }
      }
      this.cal_total_amount = totalAmount
      this.addInvoice.invoice_amount = totalAmount
      this.addInvoice.hours = totalHours
    },
    periodChanged(config, dateStr) {
      this.period_date = dateStr
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      if (this.period_date !== null) {
        if (this.addInvoice.is_corrected) {
          LIST.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMMM-YYYY').format('MM'), moment(this.period_date, 'MMMM-YYYY').format('YYYY'))
        }
        const periodDateMonth = this.period_date.split('-')
        const period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMMM').format('MM')}-01-${periodDateMonth[1]}`)
        LIST.getInvoiceAmount(this, this.addInvoice.employee_ids, period, Constants.ReferrerTypeId)
      }
    },
    correctedChanged() {
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      if (this.addInvoice.is_corrected) {
        LIST.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMMM-YYYY').format('MM'), moment(this.period_date, 'MMMM-YYYY').format('YYYY'))
      }
    },
    setVendorAmount(amount) {
      this.addInvoice.invoice_amount = amount.amount
      this.cal_total_amount = amount.amount
      this.addInvoice.timesheet_ids = amount.timesheet_ids
    },
    onReferrerChanged() {
      this.addInvoice.employee_ids = []
      this.employee = []
      this.addInvoice.employee_id = null
      if (this.addInvoice.employer_id == null) {
        this.addInvoice.employer_id = null
        this.addInvoice.invoice = null
        this.addInvoice.submitted_date = null
        this.addInvoice.period_date = null
        this.addInvoice.invoice_amount = ''
        this.submitted_date = null
        this.period_date = null
      } else {
        if (this.period_date !== null) {
          if (this.addInvoice.is_corrected) {
            LIST.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMMM-YYYY').format('MM'), moment(this.period_date, 'MMMM-YYYY').format('YYYY'))
          }
        }
        LIST.getReferrerInvoicesFullList(this, this.addInvoice.employer_id)
        this.addInvoice.invoice_amount = ''
      }
    },
    onEmployeeChanged() {
      if (this.addInvoice.employee_ids.length === 0) {
        this.employee = null
        this.addInvoice.invoice = null
        this.addInvoice.submitted_date = null
        this.addInvoice.period_date = null
        this.addInvoice.invoice_amount = ''
        this.submitted_date = null
        this.period_date = null
      } else {
        this.addInvoice.invoice_amount = ''
        if (this.period_date !== null) {
          if (this.addInvoice.is_corrected) {
            LIST.getReferrerCorrectedInvoices(this, this.addInvoice.employer_id, moment(this.period_date, 'MMMM-YYYY').format('MM'), moment(this.period_date, 'MMMM-YYYY').format('YYYY'))
          }
          const periodDateMonth = this.period_date.split('-')
          const period = Constants.getEprmMonthDateFromString(`${moment(periodDateMonth[0], 'MMMM').format('MM')}-01-${periodDateMonth[1]}`)
          LIST.getInvoiceAmount(this, this.addInvoice.employee_ids, period, Constants.ReferrerTypeId)
        }
      }
    },
    receivedDateChanged() {
      const start = moment(this.addInvoice.received_date)
      const end = moment(this.addInvoice.received_date)
      if (start.isAfter(end, 'day')) {
        this.addInvoice.received_date = start
      }
    },
    periodDateChanged() {
      this.correctedInvoices = []
      this.addInvoice.corrected_invoice_id = null
      const start = moment(this.addInvoice.period_date)
      const end = moment(this.addInvoice.period_date)
      if (start.isAfter(end, 'day')) {
        this.addInvoice.period_date = start
      }
    },
    invoiceAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    invoiceNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addInvoice.comments.push(comment)
    },
    removeComment(index) {
      this.addInvoice.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
