<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <h4 class="card-title mb-0 pt-50">
          Apply for {{ title }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="4 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="addCandidate.employer_type_id"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employer Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Employer Name"
                    rules="required"
                  >
                    <v-select
                      v-model="addCandidate.employer_id"
                      :options="employers"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      :disabled="addCandidate.employer_type_id == null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      v-model="addCandidate.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      v-model="addCandidate.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="addCandidate.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      v-model="addCandidate.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addCandidate.user_defined_fields.length !== 0"
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addCandidate.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Apply
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
// import flatPickr from 'vue-flatpickr-component'
import Candidates from '@/js/candidates'
import Constants from '@/js/constant'
import moment from 'moment'
import LIST from '@/js/lists'

export default {
  name: 'CandidateNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      addCandidate: {
        job_id: this.$route.params.job_id,
        employer_type_id: null,
        employer_id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        skype_id: '',
        date_of_birth: null,
        submitted_date: null,
        experiences: [],
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      url: null,
      title: '',
      documentNameId: null,
      type: null,
      date_of_birth: null,
      selectedFile: null,
      types: [],
      employers: [],
      documentNames: [],
      technologies: [],
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        end: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getJobInvitationEmployerTypesFullList(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getProspectsDocumentNameFullList(this)
        LIST.getUserDefinedFieldsCandidate(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    this.title = this.$route.query.name
    this.addCandidate.job_id = this.$route.params.job_id
    LIST.getTechnologiesListByJobId(this, this.$route.params.job_id)
    LIST.getProspectsDocumentNameFullList(this)
    LIST.getJobInvitationEmployerTypesFullList(this)
    LIST.getUserDefinedFieldsCandidate(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.date_of_birth !== null) {
            this.addCandidate.date_of_birth = Constants.getEprmMonthDateFromString(this.date_of_birth)
          }
          this.addCandidate.experiences = this.technologies
          this.addCandidate.job_id = this.$route.params.job_id
          this.addCandidate.submitted_date = Constants.getEprmMonthDateFromString(moment())
          this.showLoading = true
          Candidates.addCandidate(this, this.addCandidate)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addCandidate.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addCandidate.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addCandidate.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setTypes(types) {
      this.types = types
    },
    setEmployers(employers) {
      this.employers = employers
    },
    setSuppliers(suppliers) {
      this.employers = suppliers
    },
    setVendors(vendors) {
      this.employers = vendors
    },
    setSubVendors(subVendors) {
      this.employers = subVendors
    },
    setReferrers(referrers) {
      this.employers = referrers
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setTechnologies(technologies) {
      this.technologies = technologies
      for (let i = 0; i < this.technologies.length; i += 1) {
        this.technologies[i].years = 0.00
      }
    },
    onTypeChanged() {
      if (this.addCandidate.employer_type_id == null) {
        this.addCandidate.employers = []
      } else {
        this.addCandidate.employer_id = null
        const employerType = this.types.find(type => type.parent_type_id === this.addCandidate.employer_type_id)
        switch (employerType.id) {
          case 'employer':
            LIST.getEmployersFullList(this)
            break
          case 'vendors':
            LIST.getVendorsFullList(this)
            break
          case 'suppliers':
            LIST.getSuppliersFullList(this)
            break
          case 'referrer':
            LIST.getReferrersFullList(this)
            break
          case 'subvendor':
            LIST.getSubVendorsFullList(this)
            break
          default:
            break
        }
      }
    },
    candidateAdded(data) {
      this.$refs.showLoading = false
      this.$router.push('/human-resources/jobs').catch(() => {})
      this.$swal({
        title: 'Success!',
        html: Constants.getParentSuccessMessage(data.return_message),
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: Constants.getUploadButtonText(),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push(`/documents/document-tracker/new?parent_id=${data.id}&parent_type_id=${data.parent_type_id}`).catch(() => {})
        }
      })
    },
    candidateNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addCandidate.comments.push(comment)
    },
    removeComment(index) {
      this.addCandidate.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  transition: .35s height;
}
</style>
