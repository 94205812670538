import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
// Routes
import pages from './routes/pages'
import company from './routes/company'
import humanResources from './routes/humanResources'
import financeAndAccounts from './routes/finanaceAndAccounts'
import forecasting from './routes/forecasting'
import administration from './routes/administration'
import vendorJobsManagement from './routes/vendorJobsManagement'
import employeeAdministration from './routes/employeeAdministration'
import financialStatements from './routes/financialStatements'
import prospectAdministration from './routes/prospectAdministration'
import documentTracker from './routes/documentTracker'
import eSignature from './routes/eSignature'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/Home.vue'),
    //   meta: {
    //     pageTitle: 'Home',
    //     breadcrumb: [
    //       {
    //         text: 'Home',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    ...pages,
    ...company,
    ...humanResources,
    ...financeAndAccounts,
    ...forecasting,
    ...administration,
    ...vendorJobsManagement,
    ...employeeAdministration,
    ...financialStatements,
    ...prospectAdministration,
    ...documentTracker,
    ...eSignature,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
