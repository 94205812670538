<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="card-title mb-0 pt-50">Advanced Search</h4>
          <strong>Total Emails: {{ emails_count }} / {{ prospectsResponse.quick_prospects.length }}</strong>
          <b-button
            v-show="emails_count > 0"
            variant="primary"
            size="sm"
            class="ml-2"
            @click="copyText"
          >
            Copy Emails
          </b-button>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-input-group>
            <b-form-input
              v-model="request.query"
              placeholder="Search"
              @keyup.enter="updateSearchQuery"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="updateSearchQuery"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="14"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            variant="outline-secondary"
            @click="back"
            class="ml-2"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <div class="table-filters">
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Primary Skill</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Primary Skill"
                >
                  <v-select
                    v-model="request.skill_id"
                    :options="skills"
                    @input="mainSkillChanged"
                    :reduce="item => item.id"
                    placeholder="Select"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Secondary Skill</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Secondary Skill"
                >
                  <v-select
                    v-model="request.secondary_skill_ids"
                    :options="secondarySkills"
                    :reduce="item => item.id"
                    :clearable="true"
                    placeholder="Select"
                    multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label>Exp Min Years</label>
                    <b-form-spinbutton
                      v-model="request.min_experience"
                      min="0"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label>Exp Max Years</label>
                    <b-form-spinbutton
                      v-model="request.max_experience"
                      min="0"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Resident State</label>
                <v-select
                  v-model="request.resident_state_ids"
                  :options="residentStates"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Preferred State</label>
                <v-select
                  v-model="request.preferred_state_ids"
                  :options="preferredStates"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2 ml-auto"
              class="mt-2 text-right"
            >
              <b-button
                variant="primary"
                @click="getEmails"
              >
                Search
              </b-button>
            </b-col>
          </b-row>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="prospectsResponse.quick_prospects"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:500
          }"
          @on-selected-rows-change="selectionChanged"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'name'"
              class="d-flex"
            >
              <span>{{ props.row.name }}</span>
              <b-form-checkbox
                v-show="props.row.is_verified"
                checked="props.row.is_verified"
                v-model="props.row.is_verified"
                disabled
                class="custom-control-success ml-50"
              />
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <!-- <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none pl-0 pr-50"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'human-resources-prospect-administration-email-generator-quick-prospects-edit', params: { id: props.row.id } }">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item> -->
                  <!-- <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown> -->
                <b-link
                  id="view"
                  :to="{ name: 'human-resources-prospect-administration-email-generator-quick-prospects-view', params: { id: props.row.id } }"
                  class="mr-1"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
                <!-- <b-link
                  v-show="props.row.linkedin_profile !== null && props.row.linkedin_profile !== ''"
                  :href="props.row.linkedin_profile"
                  target="_blank"
                  :class="{'mt-75': option}"
                >
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    class="btn-icon py-50"
                  >
                    <feather-icon icon="LinkedinIcon" />
                  </b-button>
                </b-link> -->
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="prospectsResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <span class="d-inline-block">
          <strong>Total Emails: {{ emails_count }} / {{ prospectsResponse.quick_prospects.length }}</strong>
          <b-button
            v-show="emails_count > 0"
            variant="primary"
            size="sm"
            class="ml-2"
            @click="copyText"
          >
            Copy Emails
          </b-button>
        </span>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BPagination, BInputGroup, BFormInput, BBadge, BFormCheckbox, BInputGroupAppend, BFormSpinbutton, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import store from '@/store/index'
import LIST from '@/js/lists'
import ProspectAdministration from '@/js/prospectAdministration'

export default {
  name: 'EmailGenerate',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupAppend,
    BFormSpinbutton,
    BButton,
    BLink,
    VueGoodTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      is_verified: true,
      pageLength: 500,
      dir: false,
      prospectsResponse: {
        quick_prospects: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '90px',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Email Address',
          field: 'email',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
        },
        {
          label: 'Main Skill',
          field: 'skill',
          sortable: false,
        },
        {
          label: 'Experience',
          field: 'years',
          width: '115px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '125px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      request: {
        query: '',
        page: 1,
        skill_id: null,
        secondary_skill_ids: [],
        resident_state_ids: [],
        preferred_state_ids: [],
        min_experience: 0,
        max_experience: 0,
      },
      emails_count: 0,
      emails: '',
      selectedprospects: [],
      skills: [],
      secondarySkills: [],
      residentStates: [],
      preferredStates: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.request.page + 1
      },
      set(val) {
        this.request.page = val - 1
        this.getQuickProspectsData(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getSkillsFullList(this)
        LIST.getVisaFullList(this)
        LIST.getQuickProspectSources(this)
        LIST.getQuickProspectStatus(this)
        LIST.getStatesFullList(this)
      },
    },
  },
  created() {
    this.request = this.$store.state.padvreq
    if (Number.isNaN(this.request.page)) {
      this.request.page = 0
    }
    this.currentPage = this.request.page + 1
    LIST.getSkillsFullList(this)
    LIST.getVisaFullList(this)
    LIST.getQuickProspectSources(this)
    LIST.getQuickProspectStatus(this)
    LIST.getStatesFullList(this)
    if (this.request.skill_id !== null && this.request.skill_id !== undefined) {
      LIST.getSecondarySkillsByMainSkillId(this, this.request.skill_id)
    }
  },
  methods: {
    selectionChanged(params) {
      const prospects = params.selectedRows
      this.selectedprospects = []
      for (let i = 0; i < prospects.length; i += 1) {
        if (!this.selectedprospects.includes(prospects[i].email)) {
          this.selectedprospects.push(prospects[i].email)
        }
      }
      this.emails_count = this.selectedprospects.length
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    getEmailsOnCreated() {
      this.getQuickProspectsData()
    },
    setStates(states) {
      this.residentStates = states
      this.preferredStates = states
    },
    mainSkillChanged() {
      this.request.secondary_skill_ids = []
      this.secondarySkills = []
      if (this.request.skill_id !== null) {
        LIST.getSecondarySkillsByMainSkillId(this, this.request.skill_id)
      }
    },
    setSecondarySkills(secondarySkills) {
      this.secondarySkills = secondarySkills
    },
    getQuickProspectsData() {
      if (!(this.request.skill_id === null && (this.request.query === '' || this.request.query === undefined) && this.request.secondary_skill_ids.length === 0 && this.request.min_experience === 0 && this.request.max_experience === 0 && this.request.resident_state_ids.length === 0 && this.request.preferred_state_ids.length === 0)) {
        if (this.request.page < 0) {
          this.request.page = 0
          this.currentPage = 1
        }
        this.$store.state.padvreq = this.request
        ProspectAdministration.getProspectsByFilter(this, this.request)
      }
    },
    getEmails() {
      if (this.request.skill_id === null && (this.request.query === '' || this.request.query === undefined) && this.request.secondary_skill_ids.length === 0 && this.request.min_experience === 0 && this.request.max_experience === 0 && this.request.resident_state_ids.length === 0 && this.request.preferred_state_ids.length === 0) {
        this.prospectsResponse.quick_prospects = []
        this.prospectsResponse.count = 1
        this.$swal({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select search criteria',
        })
        return
      }
      this.getQuickProspectsData()
    },
    emailsFetched(data) {
      this.prospectsResponse = data
    },
    setQuickProspectStatus(status) {
      this.status = status
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    setSkills(skills) {
      this.skills = skills
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.request.query
      this.getEmails()
    },
    setQuickProspectSources(sources) {
      this.sources = sources
    },
    copyText() {
      this.$bvToast.toast(`${this.emails_count} emails copied`, {
        title: 'Copied!',
        variant: 'primary',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
