import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const job = `${HOST}jobs`

export default {
  getJobs(context1) {
    const context = context1
    const params = { params: { status_id: context.$store.state.query.status_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(job, params).then(response => {
      context.setJobs(response.data)
    }, () => {
    })
  },
  addJob(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(job, body).then(response => {
      context.$vs.loading.close()
      context.jobAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotAdded(error.response.data.return_message)
    })
  },
  updateJob(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${job}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.jobUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotUpdated(error.response.data.return_message)
    })
  },
  assignUsersToJob(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${job}/${id}/assign`, body).then(response => {
      context.$vs.loading.close()
      context.usersAssigned(response.data)
    }, error => {
      context.$vs.loading.close()
      context.usersNotAssigned(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${job}/${id}`).then(response => {
      context.$vs.loading.close()
      context.jobDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotDeleted(error.response.data.return_message)
    })
  },
  getJobsFullList(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/external/fulllist`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },
  getJobsFullListByProspectId(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/prospect/${id}/fulllist`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },
  getJobsFullListByCandidateId(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}/fulllist`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },
  getJobById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setJob(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },

}
