<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          PO</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer
      v-show="!showTemplate"
      ref="simpleRules"
    >
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <b-form-input
                      id="employer_company_name"
                      v-model="addPO.employer_company_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Name"
                    rules="required"
                  >
                    <b-form-input
                      id="contact_name"
                      v-model="addPO.contact_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Federal Tax Id</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Federal Tax Id"
                    rules="required|regex:^(\d){2}-(\d){7}$"
                  >
                    <b-form-input
                      id="federal_tax_id"
                      v-model="addPO.federal_tax_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="addPO.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contractor’s Professional</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contractor’s Professional"
                    rules="required"
                  >
                    <v-select
                      id="contractors_professional"
                      v-model="employee"
                      :options="employees"
                      :clearable="false"
                      placeholder="Select"
                      @input="emplyeeSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Start Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Start Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Rate</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Rate"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="rate"
                        v-model="addPO.rate"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Hours/Week</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hours/Week"
                    rules="required|integer|between:1,50"
                  >
                    <b-form-input
                      id="hours_week"
                      v-model="addPO.hours"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <v-select
                      id="contractors_professional"
                      v-model="addPO.client_id"
                      :options="clients"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Location</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Location"
                    rules="required"
                  >
                    <b-form-input
                      id="location"
                      v-model="addPO.location"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contract Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contract Type"
                    rules="required"
                  >
                    <b-form-input
                      id="contract_type"
                      v-model="addPO.contract_type"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice Terms</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Term"
                    rules="required"
                  >
                    <b-form-input
                      id="invoice_terms"
                      v-model="addPO.invoice_terms"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Payment Terms</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Term"
                    rules="required"
                  >
                    <b-form-input
                      id="invoice_terms"
                      v-model="addPO.payment_terms"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Responsibilities</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Responsibilities"
                    rules="required"
                  >
                    <b-form-textarea
                      id="responsibilities"
                      v-model="addPO.responsibilities"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="updateContent"
            >
              Update Content
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="generatePdf"
            >
              Generate Pdf
            </b-button>
            <b-button
              variant="primary"
              type="button"
              @click="sentForSign"
            >
              Send for E-Sign
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <b-modal
      ref="validFor"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      size="sm"
      centered
      title="Valid For"
    >
      <validation-observer
        ref="valid_form"
        tag="form"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Valid For</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Valid For"
                  rules="required|integer|between:1,11"
                >
                  <b-form-input
                    v-model="addPO.valid_for"
                    placeholder="Valid For"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-primary"
            class="px-1"
            @click="closeValidFor()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="ml-1 px-1"
            @click="sendForSignature()"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="updateContentValidFor"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      size="sm"
      centered
      title="Valid For"
    >
      <validation-observer
        ref="update_valid_form"
        tag="form"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Valid For</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Valid For"
                  rules="required|integer|between:1,11"
                >
                  <b-form-input
                    v-model="template.valid_for"
                    placeholder="Valid For"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-primary"
            class="px-1"
            @click="closeUpdateValidFor()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="ml-1 px-1"
            @click="sendForUpdateContentSignature()"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>

    <validation-observer
      v-show="showTemplate"
      ref="templateRules"
    >
      <b-form class="ex-large">
        <b-row>
          <b-col
            cols="12"
            md="10 ml-auto mr-auto"
          >
            <b-form-group>
              <div class="msa-form">
                <div class="mb-4">
                  <b-img
                    fluid
                    :src="template.logo"
                    alt="Company Logo"
                  />
                </div>
                <h4 class="text-center mb-2">EXHIBIT A ATTACHED TO CONTRACTOR AGREEMENT</h4>
                <h2 class="text-underline text-center mb-1">PURCHASE ORDER</h2>
                <p
                  id="template_content"
                  v-html="template.template_response"
                />
                <div class="content-footer mt-4">
                  <b-row>
                    <b-col cols="6">
                      <h5>{{ template.company_name }}</h5>
                      <div class="mt-2 mb-50">
                        ____________________________________
                        <span class="small d-block">(Signature)</span>
                      </div>
                      <h6>Print Name: {{ template.company_user_name }}</h6>
                      <h6>Title: {{ template.company_user_title }}</h6>
                      <h6>Email: {{ template.company_user_email }}</h6>
                      <h6>FED ID: {{ template.company_federal_tax_id }}</h6>
                    </b-col>
                    <b-col cols="6">
                      <h5>Contractor: {{ template.employer_company_name }}</h5>
                      <div class="mt-2 mb-50">
                        ____________________________________
                        <span class="small d-block">(Signature)</span>
                      </div>
                      <h6>Print Name: {{ template.contact_name }}</h6>
                      <div style="display: flex;">
                        <h6 style="line-height: 24px; margin-bottom: 0;">Title: </h6>
                        <input type="text" v-model="template.title" style="width: 100%; height: 24px; line-height: 24px; padding: 0 10px; background: none; border: none; outline: none;" />
                      </div>
                      <h6>Email: {{ template.email }}</h6>
                      <h6>FED ID: {{ template.federal_tax_id }}</h6>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="back"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="generatePdfWithUpdatedContent"
            >
              Generate Pdf
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="sendForESignWithUpdatedContent"
            >
              Send for E-Sign
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BModal, BFormGroup, BImg, BFormInput, BInputGroup, BButton, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Constants from '@/js/constant'
import PO from '@/js/po'

export default {
  name: 'EmployerPO',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BImg,
    BModal,
    BCol,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    flatPickr,
    vSelect,
    // currency,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addPO: {
        compan_name: '',
        federal_tax_id: '',
        email: '',
        contact_name: '',
        contractors_professional: '',
        responsibilities: '',
        rate: '',
        hours: '',
        client: '',
        start_date: '',
        location: '',
        invoice_terms: '',
        payment_terms: '',
      },
      template: {
        template_response: '',
        logo: '',
        name: '',
        title: '',
        email: '',
        valid_for: 5,
      },
      employee: null,
      employees: [],
      clients: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      showTemplate: false,
      updateMsa: {
        template_response: '',
      },
      template_response: '',
      start_date: null,
      formstate: {},
      submitted: false,
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    PO.getEmployerPoById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    sendForSignature() {
      this.$refs.valid_form.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.addPO.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          this.showLoading = true
          PO.sendEmployerPOForSign(this, this.$route.params.id, this.addPO)
        }
      })
    },
    sentForSign() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.$refs.validFor.show()
        }
      })
    },
    closeValidFor() {
      this.$refs.validFor.hide()
    },
    generatePdf() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.addPO.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          this.showLoading = true
          PO.getEmployerPOPdfById(this, this.$route.params.id, this.addPO)
        }
      })
    },
    updateContent() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.addPO.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          this.showLoading = true
          PO.getEmployerPOTemplateById(this, this.$route.params.id, this.addPO)
        }
      })
    },
    generatePdfWithUpdatedContent() {
      this.$refs.templateRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.template.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.showLoading = true
          PO.getPOPdfByIdAndUpdatedContent(this, this.$route.params.id, this.template)
        }
      })
    },
    closeUpdateValidFor() {
      this.$refs.updateContentValidFor.hide()
    },
    sendForUpdateContentSignature() {
      this.$refs.update_valid_form.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.template.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.showLoading = true
          PO.sendPOUpdatedContentForSign(this, this.$route.params.id, this.template)
        }
      })
    },
    sendForESignWithUpdatedContent() {
      this.$refs.templateRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.template.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.$refs.updateContentValidFor.show()
        }
      })
    },
    emplyeeSelected() {
      this.start_date = this.employee.start_date
      this.addPO.employee_id = this.employee.id
      this.addPO.rate = this.employee.rate
    },
    setEmployerDetails(poDetails) {
      this.addPO = poDetails
      this.employees = poDetails.employees
      this.clients = poDetails.clients
      this.addPO.valid_for = 5
    },
    pdfPath(data) {
      window.open(data.pdf_path, '_blank')
      this.back()
    },
    poTemplate(data) {
      this.showTemplate = true
      this.template = data
      this.template.valid_for = 5
    },
    pdfNotFound(message) {
      this.$swal({
        icon: 'warning',
        title: 'PO!',
        text: message,
      })
    },
    sentSuccess(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    sentFailure(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
