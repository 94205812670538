<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Vendor Job</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- accoint details tab -->
      <tab-content
        title="Job Details"
        :before-change="submitSpecificForm"
      >
        <validation-observer
          ref="jobRules"
          tag="form"
        >
          <b-form>
            <app-timeline>
              <app-timeline-item class="pb-1">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Vendor Details</h6>
                </div>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Name</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-select
                          id="name"
                          v-model="addJobRequest.vendor_id"
                          :options="vendors"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                          @input="vendorSelected()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Job-Id / Ref-No</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Job-Id / Ref-No"
                        rules="required"
                      >
                        <b-form-input
                          id="job_id"
                          v-model="addJobRequest.job_id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Recruiter</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Recruiter"
                        rules="required"
                      >
                        <div class="d-flex">
                          <div class="left w-100">
                            <v-select
                              id="name"
                              v-model="addJobRequest.recruiter_id"
                              :options="recruiters"
                              :reduce="item => item.id"
                              :clearable="false"
                              placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                          <div class="right ml-50">
                            <b-button
                              variant="outline-primary"
                              class="px-1"
                              @click="showAddRecruiter()"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </validation-provider>
                    </b-form-group>
                    <b-modal
                      ref="add_recruiter"
                      cancel-variant="outline-secondary"
                      ok-title="Add1"
                      cancel-title="Close"
                      centered
                      title="Add Recruiter"
                      :no-close-on-backdrop="true"
                      @hide="closingRecruiterPopup"
                    >
                      <validation-observer
                        ref="newRecruiter"
                        tag="form"
                      >
                        <b-form>
                          <b-form-group>
                            <label>Name</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-form-input
                                id="new_recruiter"
                                v-model="addRecruiter.name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group>
                            <label>Email</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <b-form-input
                                id="new_recruiter_email"
                                v-model="addRecruiter.email"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group>
                            <label>Phone</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Phone"
                              rules="required|integer|digits:10"
                            >
                              <b-form-input
                                id="new_recruiter_phone"
                                v-model="addRecruiter.phone"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group>
                            <label>Vendor</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Vendor"
                              rules="required"
                            >
                              <v-select
                                id="vendor_id"
                                v-model="addRecruiter.vendor_id"
                                :options="vendors"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form>
                      </validation-observer>
                      <template #modal-footer>
                        <div class="d-flex justify-content-end w-100">
                          <div />
                          <b-button
                            variant="primary"
                            class="ml-1 px-1"
                            @click="submitRecruiter"
                          >
                            Add
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                </b-row>
              </app-timeline-item>

              <app-timeline-item
                variant="info"
                class="pb-1"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Position Details</h6>
                </div>
                <b-row class="mt-1">
                  <b-col cols="4">
                    <b-form-group label="Title">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Title"
                        rules="required"
                      >
                        <div class="d-flex">
                          <div class="left w-100">
                            <v-select
                              id="title_id"
                              v-model="addJobRequest.title_id"
                              :options="jobTitles"
                              :reduce="item => item.id"
                              :clearable="false"
                              placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                          <div class="right ml-50">
                            <b-button
                              variant="outline-primary"
                              class="px-1"
                              @click="showTitle()"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </validation-provider>
                    </b-form-group>
                    <b-modal
                      ref="add_title"
                      cancel-variant="outline-secondary"
                      ok-title="Add"
                      cancel-title="Close"
                      centered
                      title="Add Title"
                      :no-close-on-backdrop="true"
                      @hide="closingTitlePopup"
                    >
                      <validation-observer
                        ref="newTitle"
                        tag="form"
                      >
                        <b-form>
                          <b-form-group>
                            <label>Title</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Title"
                              rules="required"
                            >
                              <b-form-input
                                id="new-title"
                                v-model="addTitleRequest.title"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form>
                      </validation-observer>
                      <template #modal-footer>
                        <div class="d-flex justify-content-end w-100">
                          <div />
                          <b-button
                            variant="primary"
                            class="ml-1 px-1"
                            @click="submitTitle"
                          >
                            Add
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Main Skill">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Main Skill"
                        rules="required"
                      >
                        <div class="d-flex">
                          <div class="left w-100">
                            <v-select
                              id="main_skill_id"
                              v-model="addJobRequest.main_skill_id"
                              :options="technologies"
                              :reduce="item => item.id"
                              :clearable="false"
                              placeholder="Select"
                              @input="technologyChanged"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                          <div class="right ml-50">
                            <b-button
                              variant="outline-primary"
                              class="px-1"
                              @click="showSkill()"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </validation-provider>
                    </b-form-group>
                    <b-modal
                      ref="add_skill"
                      cancel-variant="outline-secondary"
                      ok-title="Add"
                      cancel-title="Close"
                      centered
                      title="Add Technology"
                      :no-close-on-backdrop="true"
                      @hide="closingSkillPopup"
                    >
                      <validation-observer
                        ref="newSkill"
                        tag="form"
                      >
                        <b-form>
                          <b-form-group>
                            <label>Technology</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Technology"
                              rules="required"
                            >
                              <b-form-input
                                id="new-technology"
                                v-model="addSkill.skill_name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form>
                      </validation-observer>
                      <template #modal-footer>
                        <div class="d-flex justify-content-end w-100">
                          <div />
                          <b-button
                            variant="primary"
                            class="ml-1 px-1"
                            @click="submitSkill"
                          >
                            Add
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Term</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Term"
                        rules="required"
                      >
                        <div class="d-flex">
                          <div class="left w-100">
                            <v-select
                              id="term_id"
                              v-model="addJobRequest.term_id"
                              :options="terms"
                              :reduce="item => item.id"
                              :clearable="false"
                              placeholder="Select"
                              @input="termChanged"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                          <div class="right ml-50">
                            <b-button
                              variant="outline-primary"
                              class="px-1"
                              @click="showTerm()"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </validation-provider>
                    </b-form-group>
                    <b-modal
                      ref="add_term"
                      cancel-variant="outline-secondary"
                      ok-title="Add"
                      cancel-title="Close"
                      centered
                      title="Add Term"
                      :no-close-on-backdrop="true"
                      @hide="closingTermPopup"
                    >
                      <validation-observer
                        ref="newTerm"
                        tag="form"
                      >
                        <b-form>
                          <b-form-group>
                            <label>Term</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Term"
                              rules="required"
                            >
                              <b-input-group>
                                <b-form-input
                                  id="new-term"
                                  v-model="addTerm.duration"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form>
                      </validation-observer>
                      <template #modal-footer>
                        <div class="d-flex justify-content-end w-100">
                          <div />
                          <b-button
                            variant="primary"
                            class="ml-1 px-1"
                            @click="submitTerm"
                          >
                            Add
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Rate</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Rate"
                        rules="required"
                      >
                        <b-input-group
                          prepend="$"
                        >
                          <money
                            id="rate"
                            v-model="addJobRequest.rate"
                            :min="0"
                            :step="0.5"
                            v-bind="money"
                            class="form-control"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Interviews</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Interviews"
                        rules="required"
                      >
                        <b-input-group append="Rounds">
                          <b-form-input
                            id="interview_id"
                            v-model="addJobRequest.rounds"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group
                      id="start_date"
                    >
                      <label>Start Date</label>
                      <b-input-group>
                        <flat-pickr
                          v-model="start_date"
                          class="form-control"
                          :config="start_date_config"
                          placeholder="MM-DD-YYYY"
                          @input="startDateChanged()"
                        />
                        <b-input-group-append>
                          <b-button
                            class="px-1 outline-grey"
                            variant="outline-secondary"
                            @click="removeStartDate"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group
                      id="end_date"
                    >
                      <label>End Date</label>
                      <b-input-group>
                        <flat-pickr
                          v-model="end_date"
                          class="form-control"
                          :config="end_date_config"
                          placeholder="MM-DD-YYYY"
                          @input="endDateChanged()"
                        />
                        <b-input-group-append>
                          <b-button
                            class="px-1 outline-grey"
                            variant="outline-secondary"
                            @click="removeEndDate"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Education</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Education"
                        rules="required"
                      >
                        <div class="d-flex">
                          <div class="left w-100">
                            <v-select
                              id="education"
                              v-model="addJobRequest.qualification_id"
                              :options="educations"
                              :reduce="item => item.id"
                              :clearable="false"
                              placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                          <div class="right ml-50">
                            <b-button
                              variant="outline-primary"
                              class="px-1"
                              @click="showEducation()"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </div>
                        </div>
                      </validation-provider>
                    </b-form-group>
                    <b-modal
                      ref="add_education"
                      cancel-variant="outline-secondary"
                      ok-title="Add"
                      cancel-title="Close"
                      centered
                      title="Add Education"
                      :no-close-on-backdrop="true"
                      @hide="closingEducationPopup"
                    >
                      <validation-observer
                        ref="newEducation"
                        tag="form"
                      >
                        <b-form>
                          <b-form-group>
                            <label>Education</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Education"
                              rules="required"
                            >
                              <b-form-input
                                id="new-recruiter"
                                v-model="addEducation.qualification"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form>
                      </validation-observer>
                      <template #modal-footer>
                        <div class="d-flex justify-content-end w-100">
                          <div />
                          <b-button
                            variant="primary"
                            class="ml-1 px-1"
                            @click="submitEducation"
                          >
                            Add
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Exp Required</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Exp Required"
                        rules="required|integer"
                      >
                        <b-input-group append="Years">
                          <b-form-input
                            id="exp_required_id"
                            v-model="addJobRequest.experience"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label># Positions</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Positions"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="position_id"
                          v-model="addJobRequest.no_positions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>City, Zip</label>
                      <div class="d-flex">
                        <div class="left w-100">
                          <v-select
                            id="location"
                            v-model="addJobRequest.city_id"
                            :options="locations"
                            :reduce="item => item.id"
                            :clearable="true"
                            placeholder="Select"
                          />
                        </div>
                        <!-- <div class="right ml-50">
                          <b-button
                            variant="outline-primary"
                            class="px-1"
                            @click="showLocation()"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                        </div> -->
                      </div>
                    </b-form-group>
                    <b-modal
                      ref="add_location"
                      cancel-variant="outline-secondary"
                      ok-title="Add"
                      cancel-title="Close"
                      centered
                      title="Add Location"
                      :no-close-on-backdrop="true"
                      @hide="closingLocationPopup"
                    >
                      <validation-observer
                        ref="newLocation"
                        tag="form"
                      >
                        <b-form>
                          <b-form-group>
                            <label>Location</label>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Location"
                              rules="required"
                            >
                              <b-form-input
                                id="new-location"
                                v-model="addLocation.city"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form>
                      </validation-observer>
                      <template #modal-footer>
                        <div class="d-flex justify-content-end w-100">
                          <div />
                          <b-button
                            variant="primary"
                            class="ml-1 px-1"
                            @click="submitLocation"
                          >
                            Add
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Status</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Status"
                        rules="required"
                      >
                        <v-select
                          id="status"
                          v-model="addJobRequest.status_id"
                          :options="status_list"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Working Status</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Working Status"
                      >
                        <v-select
                          id="status"
                          v-model="addJobRequest.working_status_id"
                          :options="workingStatus"
                          :reduce="item => item.id"
                          :clearable="true"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>End Client</label>
                      <v-select
                        id="end_client"
                        v-model="addJobRequest.client_id"
                        :options="endClients"
                        :reduce="item => item.id"
                        :clearable="true"
                        placeholder="Select"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Visa Types</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Visa Types"
                        rules="required"
                      >
                        <v-select
                          id="visa_types"
                          v-model="addJobRequest.visa_type_ids"
                          :options="visaTypes"
                          :reduce="item => item.id"
                          multiple
                          :clearable="false"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Shift Days</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shift Days"
                        rules="required|integer|between:0,7"
                      >
                        <b-input-group append="Days">
                          <b-form-input
                            id="shift_days"
                            v-model="addJobRequest.shift_days"
                            type="number"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Shift Hours</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Shift Hours"
                        rules="required|integer|between:0,24"
                      >
                        <b-input-group append="Hours">
                          <b-form-input
                            id="shift_hours"
                            v-model="addJobRequest.shift_hours"
                            type="number"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-for="(job_start_time, index) in addJobRequest.job_start_times"
                    :key="index"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-group>
                          <label>Shift Start Time</label>
                          <validation-provider
                            v-slot="{ errors }"
                            :rules="''"
                            :name="'Shift Start Time' + index"
                          >
                            <flat-pickr
                              v-model="job_start_time.time"
                              class="form-control"
                              :config="shiftTimeConfig"
                              :placeholder="'00:00'"
                            />
                            <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Shift Start Time' + index, 'Shift Start Time') : errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div class="right mt-2 ml-1">
                        <b-button
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="removeShiftTime(index)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-button
                      variant="outline-primary"
                      type="button"
                      class="mt-2 mb-1"
                      @click="addShiftTime"
                    >
                      Add Shift Time
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Job Duties / Responsibilities</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Job Duties / Responsibilities"
                        rules="required"
                      >
                        <quill-editor
                          v-model="addJobRequest.description"
                          :options="snowOption"
                          class="job-description-view"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-timeline-item>

              <app-timeline-item
                variant="primary"
                class="pb-1"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Comments</h6>
                </div>
                <b-row>
                  <b-col
                    v-for="(comment, index) in addJobRequest.comments"
                    :key="index"
                    cols="12"
                    md="6"
                    class="col-xxl-4"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-group>
                          <label>Comment</label>
                          <b-form-textarea
                            v-model="comment.comment"
                            rows="2"
                          />
                        </b-form-group>
                      </div>
                      <div class="right mt-2 ml-1">
                        <b-button
                          v-show="index === 0"
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          @click="addComment"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                        <b-button
                          v-show="index !== 0"
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="removeComment(index)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </app-timeline-item>
            </app-timeline>
            <hr class="my-0">
          </b-form>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Job Skills"
        :before-change="validationFormSkills"
      >
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-primary"
                class="mb-1"
                @click="showSecondarySkill()"
              >
                Add Secondary Skill
              </b-button>
            </div>

            <b-modal
              ref="add_secondary_skill"
              cancel-variant="outline-secondary"
              ok-title="Add"
              cancel-title="Close"
              centered
              title="Add Secondary Skill"
              :no-close-on-backdrop="true"
              @hide="closingSecondarySkillPopup"
            >
              <validation-observer
                ref="newSecondarySkill"
                tag="form"
              >
                <b-form>
                  <b-form-group>
                    <label>Skill Name</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Skill Name"
                      rules="required"
                    >
                      <b-form-input
                        id="new-secondary-Skill"
                        v-model="addSecondarySkill.skill_name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
              <template #modal-footer>
                <div class="d-flex justify-content-end w-100">
                  <div />
                  <b-button
                    variant="primary"
                    class="ml-1 px-1"
                    @click="submitSecondarySkill"
                  >
                    Add
                  </b-button>
                </div>
              </template>
            </b-modal>
          </b-col>
        </b-row>
        <validation-observer
          ref="skillRules"
          tag="form"
        >
          <b-table
            :items="addJobRequest.skills"
            :fields="fields"
            striped
            responsive
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(reqd)="row">
              <b-form-checkbox
                v-model="addJobRequest.skills[row.index].required"
                :disabled="addJobRequest.skills[row.index].preferred"
                plain
                class="vs-checkbox-con"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
              </b-form-checkbox>
            </template>
            <template #cell(preference)="row">
              <b-form-checkbox
                v-model="addJobRequest.skills[row.index].preferred"
                :disabled="addJobRequest.skills[row.index].required"
                plain
                class="vs-checkbox-con"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
              </b-form-checkbox>
            </template>
            <template #cell(exp_years)="row">
              <b-form-input
                v-model="addJobRequest.skills[row.index].experience"
                class="w-25"
              />
            </template>
          </b-table>
        </validation-observer>
        <hr class="my-0">
      </tab-content>
    </form-wizard>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard, BRow, BCol, BModal, VBModal, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BButton, BTable, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import JobRequests from '@/js/vendorjob'
import moment from 'moment'
import Constants from '@/js/constant'
import LIST from '@/js/vjmslists'
import VendorRecruiter from '@/js/vendorrecruiter'
import MainSkill from '@/js/mainskill'
import Secondaryskill from '@/js/secondaryskill'
import Technology from '@/js/skillstechnologies'

export default {
  name: 'JobRequestEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BTable,
    BFormCheckbox,
    BRow,
    BCol,
    BForm,
    BModal,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    quillEditor,
    // currency,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [{ key: 'index', label: 'Index' }, { key: 'label', label: 'Skill' }, { key: 'reqd', label: 'Reqd' }, { key: 'preference', label: 'Preference' }, { key: 'exp_years', label: 'Exp Years' }],
      items: [
        {
          skill: 'Java',
          reqd: '',
          preference: '',
          exp_years: '',
        },
        {
          skill: 'HTML',
          reqd: '',
          preference: '',
          exp_years: '',
        },
        {
          skill: 'SQL',
          reqd: '',
          preference: '',
          exp_years: '',
        },
      ],
      required,
      addRecruiter: {
        name: '',
        email: '',
        phone: '',
        vendor_id: null,
      },
      addTitleRequest: {
        title: '',
      },
      addSkill: {
        skill_name: '',
      },
      addTerm: {
        duration: '',
      },
      addSecondarySkill: {
        skill_name: '',
      },
      addEducation: {
        qualification: '',
      },
      addLocation: {
        city: '',
      },
      addJobRequest: {
        vendor_id: null,
        job_id: '',
        status_id: '',
        recruiter_id: null,
        title_id: null,
        main_skill_id: null,
        term_id: null,
        rate: '',
        rounds: 0,
        start_date: null,
        end_date: null,
        shift_days: 0,
        shift_hours: 0,
        qualification_id: null,
        experience: '',
        no_positions: 0,
        city_id: null,
        working_status_id: null,
        end_client_id: null,
        visa_type_ids: null,
        description: '',
        skills: [],
        comments: [],
        job_start_times: [],
      },
      start_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      end_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      shiftTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      start_date: null,
      end_date: null,
      shift_start_time: null,
      vendors: [],
      recruiters: [],
      jobTitles: [],
      technologies: [],
      skills: [],
      terms: [],
      educations: [],
      locations: [],
      status_list: [],
      workingStatus: [],
      endClients: [],
      visaTypes: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getVendorsAll(this)
        LIST.getJobTitles(this)
        LIST.getEducations(this)
        LIST.getMainSkillsAll(this)
        LIST.getVendorJobStatus(this)
        LIST.getEndClients(this)
        LIST.getWorkingStatus(this)
        LIST.getVisaTypes(this)
        LIST.getDurations(this)
      },
    },
  },
  created() {
    LIST.getVendorsAll(this)
    LIST.getJobTitles(this)
    LIST.getEducations(this)
    LIST.getMainSkillsAll(this)
    LIST.getVendorJobStatus(this)
    LIST.getEndClients(this)
    LIST.getWorkingStatus(this)
    LIST.getVisaTypes(this)
    LIST.getDurations(this)
    JobRequests.getVendorJobById(this, this.$route.params.id)
  },
  methods: {
    submitSpecificForm() {
      this.$refs.jobRules.validate().then(result => {
        if (result) {
          this.$refs.wizard.changeTab(0, 1)
        }
      })
    },
    formSubmitted() {
      this.$refs.jobRules.validate().then(result => {
        if (result) {
          this.$refs.skillRules.validate().then(resultSkill => {
            if (resultSkill) {
              if (this.start_date != null) {
                this.addJobRequest.start_date = Constants.getEprmMonthDateFromString(this.start_date)
              } else {
                this.addJobRequest.start_date = null
              }
              if (this.end_date != null) {
                this.addJobRequest.end_date = Constants.getEprmMonthDateFromString(this.end_date)
              } else {
                this.addJobRequest.end_date = null
              }
              this.showLoading = true
              JobRequests.updateVendorJob(this, this.$route.params.id, this.addJobRequest)
            }
          })
        }
      })
    },
    showAddRecruiter() {
      this.addRecruiter.vendor_id = this.addJobRequest.vendor_id
      this.$refs.add_recruiter.show()
    },
    showTitle() {
      this.$refs.add_title.show()
    },
    showSkill() {
      this.$refs.add_skill.show()
    },
    showTerm() {
      this.$refs.add_term.show()
    },
    showSecondarySkill() {
      this.$refs.add_secondary_skill.show()
    },
    showEducation() {
      this.$refs.add_education.show()
    },
    showLocation() {
      this.$refs.add_location.show()
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    startDateChanged() {
      this.end_date_config.minDate = new Date(moment(this.start_date, 'MM-DD-YYYY'))
    },
    endDateChanged() {
      this.start_date_config.maxDate = new Date(moment(this.end_date, 'MM-DD-YYYY'))
    },
    closingRecruiterPopup() {
      this.addRecruiter = {
        name: '',
        email: '',
        phone: '',
        vendor_id: null,
      }
    },
    closingTitlePopup() {
      this.addTitleRequest = {
        title: '',
      }
    },
    closingSkillPopup() {
      this.addSkill = {
        skill_name: '',
      }
    },
    closingTermPopup() {
      this.addTerm = {
        duration: '',
      }
    },
    closingSecondarySkillPopup() {
      this.addSecondarySkill = {
        skill_name: '',
      }
    },
    closingEducationPopup() {
      this.addEducation = {
        qualification: '',
      }
    },
    closingLocationPopup() {
      this.addLocation = {
        city: '',
      }
    },
    removeStartDate() {
      this.start_date = null
    },
    removeEndDate() {
      this.end_date = null
    },
    submitRecruiter() {
      this.$refs.newRecruiter.validate().then(result => {
        if (result) {
          this.showLoading = true
          VendorRecruiter.addVendorRecruiter(this, this.addRecruiter)
        }
      })
    },
    submitTitle() {
      this.$refs.newTitle.validate().then(result => {
        if (result) {
          this.showLoading = true
          LIST.addJobTitle(this, this.addTitleRequest)
        }
      })
    },
    submitSkill() {
      this.$refs.newSkill.validate().then(result => {
        if (result) {
          this.showLoading = true
          MainSkill.addMainSkill(this, this.addSkill)
        }
      })
    },
    submitTerm() {
      this.$refs.newTerm.validate().then(result => {
        if (result) {
          this.showLoading = true
          LIST.addDuration(this, this.addTerm)
        }
      })
    },
    submitSecondarySkill() {
      this.$refs.newSecondarySkill.validate().then(result => {
        if (result) {
          this.showLoading = true
          this.addSecondarySkill.main_skill_ids = []
          this.addSecondarySkill.main_skill_ids.push(this.addJobRequest.main_skill_id)
          Secondaryskill.addSecondarySkill(this, this.addSecondarySkill)
        }
      })
    },
    submitEducation() {
      this.$refs.newEducation.validate().then(result => {
        if (result) {
          this.showLoading = true
          LIST.addEducation(this, this.addEducation)
        }
      })
    },
    submitLocation() {
      this.$refs.newLocation.validate().then(result => {
        if (result) {
          this.showLoading = true
          LIST.addLocations(this, this.addLocation)
        }
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.addJobRequest.start_date = Constants.getEprmMonthDateFromString(
              this.start_date,
            )
          }
          if (this.end_date != null) {
            this.addJobRequest.end_date = Constants.getEprmMonthDateFromString(
              this.end_date,
            )
          }
          this.showLoading = true
          JobRequests.updateJobRequest(this, this.$route.params.id, this.addJobRequest)
        }
      })
    },
    vendorSelected() {
      this.addJobRequest.recruiter_id = null
      LIST.getRecruitersByVendorId(this, this.addJobRequest.vendor_id)
    },
    technologyChanged() {
      Technology.getTechnologiesBySkillIds(this, [this.addJobRequest.main_skill_id])
    },
    setVendorJob(vendorJob) {
      this.addJobRequest = vendorJob
      LIST.getRecruitersByVendorIdAll(this, this.addJobRequest.vendor_id)
      LIST.getLocations(this, this.addJobRequest.state_id)
      this.start_date = vendorJob.start_date
      this.end_date = vendorJob.end_date
      if (vendorJob.comments.length === 0) {
        this.addComment()
      }
      this.end_date_config.minDate = new Date(moment(this.start_date, 'MM-DD-YYYY'))
      this.start_date_config.maxDate = new Date(moment(this.end_date, 'MM-DD-YYYY'))
    },
    jobUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    jobNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    recruiterAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_recruiter.hide()
      this.addJobRequest.recruiter_id = data.id
      LIST.getRecruitersByVendorIdAll(this, this.addJobRequest.vendor_id)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    recruiterNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    titleAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_title.hide()
      this.addJobRequest.title_id = data.id
      LIST.getJobTitles(this)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    titleNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    mainSkillAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_skill.hide()
      this.addJobRequest.main_skill_id = data.id
      LIST.getMainSkills(this)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    mainSkillNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    educationAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_education.hide()
      this.addJobRequest.qualification_id = data.id
      LIST.getEducations(this)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    educationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    durationAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_term.hide()
      this.addJobRequest.term_id = data.id
      LIST.getDurations(this)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    durationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    locationAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_location.hide()
      this.addJobRequest.city_id = data.id
      LIST.getLocations(this, this.addJobRequest.state_id)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    locationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    secondarySkillAdded(data) {
      this.$refs.showLoading = false
      this.$refs.add_secondary_skill.hide()
      const tech = {
        skill: data.label,
        label: data.label,
        skill_id: data.id,
        required: false,
        preferred: false,
        experience: 0,
      }
      this.addJobRequest.skills.push(tech)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    secondarySkillNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setJobTitles(jobTitles) {
      this.jobTitles = jobTitles
    },
    setRecruiters(recruiters) {
      this.recruiters = recruiters
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setMainSkills(technologies) {
      this.technologies = technologies
    },
    setEducations(educations) {
      this.educations = educations
    },
    setLocations(locations) {
      this.locations = locations
    },
    setVendorJobStatus(status) {
      this.status_list = status
    },
    setDurations(duratons) {
      this.terms = duratons
    },
    setEndClients(Clients) {
      this.endClients = Clients
    },
    setWorkingStatus(workingStatus) {
      this.workingStatus = workingStatus
    },
    setSkillTechnologies(skills) {
      this.addJobRequest.skills = []
      skills.forEach(secondarySkill => {
        const skillItem = {
          skill: secondarySkill.label,
          skill_id: secondarySkill.id,
          label: secondarySkill.label,
          required: false,
          preferred: false,
          experience: 0,
        }
        this.addJobRequest.skills.push(skillItem)
      })
    },
    addShiftTime() {
      const jobStartTime = {
        time: null,
      }
      this.addJobRequest.job_start_times.push(jobStartTime)
    },
    removeShiftTime(index) {
      this.addJobRequest.job_start_times.splice(index, 1)
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addJobRequest.comments.push(comment)
    },
    removeComment(index) {
      this.addJobRequest.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/vendor-jobs-management/job-requests').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
