import Employees from '@/views/human-resources/employees/Employees.vue'
import EmployeesNew from '@/views/human-resources/employees/actions/New.vue'
import EmployeesEdit from '@/views/human-resources/employees/actions/Edit.vue'
import EmployeesView from '@/views/human-resources/employees/actions/View.vue'
import Consultants from '@/views/human-resources/consultants/Consultants.vue'
import ConsultantsNew from '@/views/human-resources/consultants/actions/New.vue'
import ConsultantsEdit from '@/views/human-resources/consultants/actions/Edit.vue'
import ConsultantsView from '@/views/human-resources/consultants/actions/View.vue'
import Prospects from '@/views/human-resources/prospects/Prospects.vue'
import ProspectsNew from '@/views/human-resources/prospects/actions/New.vue'
import ProspectsEdit from '@/views/human-resources/prospects/actions/Edit.vue'
import ProspectsView from '@/views/human-resources/prospects/actions/View.vue'
import JobApply from '@/views/human-resources/prospects/actions/JobApply.vue'
import BusinessPartners from '@/views/human-resources/businesspartners/BusinessPartners.vue'
import EmployerLetter from '@/views/human-resources/workforce/EmployerLetter.vue'
import ClientLetter from '@/views/human-resources/workforce/ClientLetter.vue'
import VerificationLetter from '@/views/human-resources/workforce/VerificationLetter.vue'
import Workforce from '@/views/human-resources/workforce/Workforce.vue'
import OfferLetter from '@/views/human-resources/workforce/OfferLetter.vue'
import QuickProspectsNew from '@/views/human-resources/quick-prospects/actions/New.vue'
import QuickProspectsEdit from '@/views/human-resources/quick-prospects/actions/Edit.vue'
import QuickProspectsView from '@/views/human-resources/quick-prospects/actions/View.vue'
import ProspectAdministrationProspectsView from '@/views/human-resources/prospect-administration/prospects/View.vue'
import Employers from '@/views/human-resources/employers/Employers.vue'
import EmployerssNew from '@/views/human-resources/employers/actions/New.vue'
import EmployersEdit from '@/views/human-resources/employers/actions/Edit.vue'
import EmployersView from '@/views/human-resources/employers/actions/View.vue'
import EmployersMSA from '@/views/human-resources/employers/actions/MSA.vue'
import EmployersPO from '@/views/human-resources/employers/actions/PO.vue'
import Vendors from '@/views/human-resources/vendors/Vendors.vue'
import VendorsNew from '@/views/human-resources/vendors/actions/New.vue'
import VendorsEdit from '@/views/human-resources/vendors/actions/Edit.vue'
import VendorsView from '@/views/human-resources/vendors/actions/View.vue'
import SubVendors from '@/views/human-resources/sub-vendors/SubVendors.vue'
import SubVendorsNew from '@/views/human-resources/sub-vendors/actions/New.vue'
import SubVendorsEdit from '@/views/human-resources/sub-vendors/actions/Edit.vue'
import SubVendorsView from '@/views/human-resources/sub-vendors/actions/View.vue'
import EndClients from '@/views/human-resources/end-clients/EndClients.vue'
import EndClientsNew from '@/views/human-resources/end-clients/actions/New.vue'
import EndClientsEdit from '@/views/human-resources/end-clients/actions/Edit.vue'
import EndClientsView from '@/views/human-resources/end-clients/actions/View.vue'
import Suppliers from '@/views/human-resources/suppliers/Suppliers.vue'
import SuppliersNew from '@/views/human-resources/suppliers/actions/New.vue'
import SuppliersEdit from '@/views/human-resources/suppliers/actions/Edit.vue'
import SuppliersView from '@/views/human-resources/suppliers/actions/View.vue'
import Referrers from '@/views/human-resources/referrers/Referrers.vue'
import ReferrersNew from '@/views/human-resources/referrers/actions/New.vue'
import ReferrersEdit from '@/views/human-resources/referrers/actions/Edit.vue'
import ReferrersView from '@/views/human-resources/referrers/actions/View.vue'
import Onboarding from '@/views/human-resources/onboarding/Onboarding.vue'
import OnboardingNew from '@/views/human-resources/onboarding/actions/New.vue'
import OnboardingEdit from '@/views/human-resources/onboarding/actions/Edit.vue'
import OnboardingView from '@/views/human-resources/onboarding/actions/View.vue'
import Interviews from '@/views/human-resources/interviews/Interviews.vue'
import CandidateInterviewConsent from '@/views/human-resources/interviews/actions/CandidateInterviewConsent.vue'
import InterviewsNew from '@/views/human-resources/interviews/actions/New.vue'
import InterviewsEdit from '@/views/human-resources/interviews/actions/Edit.vue'
import InterviewsView from '@/views/human-resources/interviews/actions/View.vue'
import Jobs from '@/views/human-resources/jobs/Jobs.vue'
import JobsNew from '@/views/human-resources/jobs/actions/New.vue'
import JobsEdit from '@/views/human-resources/jobs/actions/Edit.vue'
import JobsClone from '@/views/human-resources/jobs/actions/Clone.vue'
import JobsView from '@/views/human-resources/jobs/actions/View.vue'
import Candidates from '@/views/human-resources/candidates/Candidates.vue'
import CandidatesNew from '@/views/human-resources/candidates/actions/New.vue'
import CandidatesEdit from '@/views/human-resources/candidates/actions/Edit.vue'
import CandidatesView from '@/views/human-resources/candidates/actions/View.vue'
import InactiveCandidateView from '@/views/human-resources/candidates/actions/InactiveCandidateView.vue'
import InactiveCandidateApply from '@/views/human-resources/candidates/actions/InactiveCandidateApply.vue'
import ExistingCandidates from '@/views/human-resources/candidates/actions/ExistingCandidates.vue'
import JobPostingInterview from '@/views/human-resources/candidates/actions/JobPostingInterview.vue'
import Exceptions from '@/views/human-resources/exceptions/Exceptions.vue'
import ExceptionsNew from '@/views/human-resources/exceptions/actions/New.vue'
import ExceptionsEdit from '@/views/human-resources/exceptions/actions/Edit.vue'
import ExceptionsView from '@/views/human-resources/exceptions/actions/View.vue'
import AdvancedSearch from '@/views/human-resources/advanced-search/AdvancedSearch.vue'
import AdvancedSearchView from '@/views/human-resources/advanced-search/actions/View.vue'
import Dashboard from '@/views/human-resources/prospect-administration/Dashboard.vue'
import QuickProspects from '@/views/human-resources/prospect-administration/prospects/Prospects.vue'
import EmailGenerate from '@/views/human-resources/prospect-administration/email-generate/EmailGenerate.vue'

export default [
  // *===============================================---*
  // *--------- Human Resources -------------------------------*
  // *===============================================---*
  // *------------ Employees --------------------*
  {
    path: '/human-resources/workforce/employees',
    name: 'human-resources-employees',
    component: Employees,
  },
  {
    path: '/human-resources/workforce/employees/new',
    name: 'human-resources-employees-new',
    component: EmployeesNew,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/employees/edit/:id',
    name: 'human-resources-employees-edit',
    component: EmployeesEdit,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/employees/view/:id',
    name: 'human-resources-employees-view',
    component: EmployeesView,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/employees/view/:id/offer-letter',
    name: 'human-resources-employees-offer-letter',
    component: OfferLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/employees/view/:id/employer-letter',
    name: 'human-resources-employees-employer-letter',
    component: EmployerLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/employees/view/:id/client-letter',
    name: 'human-resources-employees-client-letter',
    component: ClientLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/employees/view/:id/verification-letter',
    name: 'human-resources-employees-verification-letter',
    component: VerificationLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  // *------------ Consultants --------------------*
  {
    path: '/human-resources/workforce/consultants',
    name: 'human-resources-consultants',
    component: Consultants,
  },
  {
    path: '/human-resources/workforce/consultants/new',
    name: 'human-resources-consultants-new',
    component: ConsultantsNew,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/consultants/edit/:id',
    name: 'human-resources-consultants-edit',
    component: ConsultantsEdit,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/consultants/view/:id',
    name: 'human-resources-consultants-view',
    component: ConsultantsView,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/consultants/view/:id/offer-letter',
    name: 'human-resources-consultants-offer-letter',
    component: OfferLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/consultants/view/:id/employer-letter',
    name: 'human-resources-consultants-employer-letter',
    component: EmployerLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/consultants/view/:id/client-letter',
    name: 'human-resources-consultants-client-letter',
    component: ClientLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/consultants/view/:id/verification-letter',
    name: 'human-resources-consultants-verification-letter',
    component: VerificationLetter,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  // *------------ Prospects --------------------*
  {
    path: '/human-resources/workforce/prospects',
    name: 'human-resources-prospects',
    component: Prospects,
  },
  {
    path: '/human-resources/workforce/prospects/new',
    name: 'human-resources-prospects-new',
    component: ProspectsNew,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/prospects/edit/:id',
    name: 'human-resources-prospects-edit',
    component: ProspectsEdit,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/prospects/view/:id',
    name: 'human-resources-prospects-view',
    component: ProspectsView,
    meta: {
      navActiveLink: 'human-resources-workforce',
    },
  },
  {
    path: '/human-resources/workforce/prospects/:id/apply',
    name: 'human-resources-prospects-apply',
    component: JobApply,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/workforce/prospects/view/:id/apply',
    name: 'human-resources-prospects-view-apply',
    component: JobApply,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  // *------------ Business-Partner --------------------*
  {
    path: '/human-resources/business-partner',
    name: 'human-resources-business-partner',
    component: BusinessPartners,
  },
  // *------------ Workforce --------------------*
  {
    path: '/human-resources/workforce',
    name: 'human-resources-workforce',
    component: Workforce,
  },
  // *------------ Quick Prospects --------------------*
  {
    path: '/human-resources/quick-prospects/new',
    name: 'human-resources-quick-prospects-new',
    component: QuickProspectsNew,
    meta: {
      navActiveLink: 'human-resources-quick-prospects',
    },
  },
  {
    path: '/human-resources/quick-prospects/edit/:id',
    name: 'human-resources-quick-prospects-edit',
    component: QuickProspectsEdit,
    meta: {
      navActiveLink: 'human-resources-quick-prospects',
    },
  },
  {
    path: '/human-resources/quick-prospects/view/:id',
    name: 'human-resources-quick-prospects-view',
    component: QuickProspectsView,
    meta: {
      navActiveLink: 'human-resources-quick-prospects',
    },
  },
  // *------------ Employers --------------------*
  {
    path: '/human-resources/business-partner/employers',
    name: 'human-resources-employers',
    component: Employers,
  },
  {
    path: '/human-resources/business-partner/employers/new',
    name: 'human-resources-employers-new',
    component: EmployerssNew,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/employers/edit/:id',
    name: 'human-resources-employers-edit',
    component: EmployersEdit,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/employers/view/:id',
    name: 'human-resources-employers-view',
    component: EmployersView,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/employers/view/:id/msa',
    name: 'human-resources-employers-msa',
    component: EmployersMSA,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/employers/view/:id/po',
    name: 'human-resources-employers-po',
    component: EmployersPO,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  // *------------ Vendors --------------------*
  {
    path: '/human-resources/business-partner/vendors',
    name: 'human-resources-vendors',
    component: Vendors,
  },
  {
    path: '/human-resources/business-partner/vendors/new',
    name: 'human-resources-vendors-new',
    component: VendorsNew,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/vendors/edit/:id',
    name: 'human-resources-vendors-edit',
    component: VendorsEdit,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/vendors/view/:id',
    name: 'human-resources-vendors-view',
    component: VendorsView,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  // *------------ Sub Vendors --------------------*
  {
    path: '/human-resources/business-partner/sub-vendors',
    name: 'human-resources-sub-vendors',
    component: SubVendors,
  },
  {
    path: '/human-resources/business-partner/sub-vendors/new',
    name: 'human-resources-sub-vendors-new',
    component: SubVendorsNew,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/sub-vendors/edit/:id',
    name: 'human-resources-sub-vendors-edit',
    component: SubVendorsEdit,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/sub-vendors/view/:id',
    name: 'human-resources-sub-vendors-view',
    component: SubVendorsView,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  // *------------ End Clients --------------------*
  {
    path: '/human-resources/business-partner/end-clients',
    name: 'human-resources-end-clients',
    component: EndClients,
  },
  {
    path: '/human-resources/business-partner/end-clients/new',
    name: 'human-resources-end-clients-new',
    component: EndClientsNew,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/end-clients/edit/:id',
    name: 'human-resources-end-clients-edit',
    component: EndClientsEdit,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/end-clients/view/:id',
    name: 'human-resources-end-clients-view',
    component: EndClientsView,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  // *------------ Suppliers --------------------*
  {
    path: '/human-resources/business-partner/suppliers',
    name: 'human-resources-suppliers',
    component: Suppliers,
  },
  {
    path: '/human-resources/business-partner/suppliers/new',
    name: 'human-resources-suppliers-new',
    component: SuppliersNew,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/suppliers/edit/:id',
    name: 'human-resources-suppliers-edit',
    component: SuppliersEdit,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/suppliers/view/:id',
    name: 'human-resources-suppliers-view',
    component: SuppliersView,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  // *------------ Referrers --------------------*
  {
    path: '/human-resources/business-partner/referrers',
    name: 'human-resources-referrers',
    component: Referrers,
  },
  {
    path: '/human-resources/business-partner/referrers/new',
    name: 'human-resources-referrers-new',
    component: ReferrersNew,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/referrers/edit/:id',
    name: 'human-resources-referrers-edit',
    component: ReferrersEdit,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  {
    path: '/human-resources/business-partner/referrers/view/:id',
    name: 'human-resources-referrers-view',
    component: ReferrersView,
    meta: {
      navActiveLink: 'human-resources-business-partner',
    },
  },
  // *------------ Onboarding --------------------*
  {
    path: '/human-resources/onboarding',
    name: 'human-resources-onboarding',
    component: Onboarding,
  },
  {
    path: '/human-resources/onboarding/new',
    name: 'human-resources-onboarding-new',
    component: OnboardingNew,
    meta: {
      navActiveLink: 'human-resources-onboarding',
    },
  },
  {
    path: '/human-resources/onboarding/edit/:id',
    name: 'human-resources-onboarding-edit',
    component: OnboardingEdit,
    meta: {
      navActiveLink: 'human-resources-onboarding',
    },
  },
  {
    path: '/human-resources/onboarding/view/:id',
    name: 'human-resources-onboarding-view',
    component: OnboardingView,
    meta: {
      navActiveLink: 'human-resources-onboarding',
    },
  },
  // *------------ Interviews --------------------*
  {
    path: '/human-resources/interviews',
    name: 'human-resources-interviews',
    component: Interviews,
  },
  {
    path: '/interview-consent/:token',
    name: 'interview-consent',
    component: CandidateInterviewConsent,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/human-resources/interviews/new',
    name: 'human-resources-interviews-new',
    component: InterviewsNew,
    meta: {
      navActiveLink: 'human-resources-interviews',
    },
  },
  {
    path: '/human-resources/interviews/edit/:id',
    name: 'human-resources-interviews-edit',
    component: InterviewsEdit,
    meta: {
      navActiveLink: 'human-resources-interviews',
    },
  },
  {
    path: '/human-resources/interviews/view/:id',
    name: 'human-resources-interviews-view',
    component: InterviewsView,
    meta: {
      navActiveLink: 'human-resources-interviews',
    },
  },
  // *------------ Jobs --------------------*
  {
    path: '/human-resources/jobs',
    name: 'human-resources-jobs',
    component: Jobs,
  },
  {
    path: '/human-resources/jobs/new',
    name: 'human-resources-jobs-new',
    component: JobsNew,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/edit/:id',
    name: 'human-resources-jobs-edit',
    component: JobsEdit,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/clone/:id',
    name: 'human-resources-jobs-clone',
    component: JobsClone,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/view/:id',
    name: 'human-resources-jobs-view',
    component: JobsView,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  // *------------ Candidates --------------------*
  {
    path: '/human-resources/jobs/:job_id/candidates',
    name: 'human-resources-candidates',
    component: Candidates,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/candidates/new',
    name: 'human-resources-candidates-new',
    component: CandidatesNew,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/candidates/:id/edit',
    name: 'human-resources-candidates-edit',
    component: CandidatesEdit,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/candidates/:id/view',
    name: 'human-resources-candidates-view',
    component: CandidatesView,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/candidates/:id/inactive-candidate-view',
    name: 'human-resources-candidates-inactive-candidate-view',
    component: InactiveCandidateView,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/candidates/:id/apply',
    name: 'human-resources-candidates-inactive-candidate-apply',
    component: InactiveCandidateApply,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/candidates',
    name: 'job-human-resources-candidates',
    component: Candidates,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/jobs/:job_id/existing-candidates',
    name: 'job-human-resources-existing-candidates',
    component: ExistingCandidates,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/:job_candidate/interview',
    name: 'job-human-resources-job-interview',
    component: JobPostingInterview,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  // *------------ Exceptions --------------------*
  {
    path: '/human-resources/exceptions',
    name: 'human-resources-exceptions',
    component: Exceptions,
  },
  {
    path: '/human-resources/exceptions/new',
    name: 'human-resources-exceptions-new',
    component: ExceptionsNew,
    meta: {
      navActiveLink: 'human-resources-exceptions',
    },
  },
  {
    path: '/human-resources/exceptions/edit/:id',
    name: 'human-resources-exceptions-edit',
    component: ExceptionsEdit,
    meta: {
      navActiveLink: 'human-resources-exceptions',
    },
  },
  {
    path: '/human-resources/exceptions/view/:id',
    name: 'human-resources-exceptions-view',
    component: ExceptionsView,
    meta: {
      navActiveLink: 'human-resources-exceptions',
    },
  },
  // *------------ Advanced Search --------------------*
  {
    path: '/human-resources/workforce/advanced-search',
    name: 'human-resources-advanced-search',
    component: AdvancedSearch,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/workforce/advanced-search/view/:id',
    name: 'human-resources-advanced-search-view',
    component: AdvancedSearchView,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  // *===============================================---*
  // *--------- Prospect Administration -------------------------------*
  // *===============================================---*
  // *------------ Dashobard --------------------*
  {
    path: '/human-resources/prospect-administration/dashboard',
    name: 'human-resources-prospect-administration-dashboard',
    component: Dashboard,
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  // *------------ Quick Prospects --------------------*
  {
    path: '/human-resources/prospect-administration/dashboard/prospects/:skillid',
    name: 'human-resources-prospect-administration-prospects',
    component: QuickProspects,
    meta: {
      navActiveLink: 'human-resources-prospect-administration-dashboard',
    },
  },
  {
    path: '/human-resources/prospect-administration/dashboard/prospects/:skillid/edit/:id',
    name: 'human-resources-prospect-administration-prospects-edit',
    component: QuickProspectsEdit,
    meta: {
      navActiveLink: 'human-resources-prospect-administration-dashboard',
    },
  },
  {
    path: '/human-resources/prospect-administration/dashboard/prospects/:skillid/view/:id',
    name: 'human-resources-prospect-administration-prospects-view',
    component: ProspectAdministrationProspectsView,
    meta: {
      navActiveLink: 'human-resources-prospect-administration-dashboard',
    },
  },
  // *------------ Email Generate --------------------*
  {
    path: '/human-resources/prospect-administration/dashboard/email-generator',
    name: 'human-resources-prospect-administration-email-generator',
    component: EmailGenerate,
    meta: {
      navActiveLink: 'human-resources-prospect-administration-dashboard',
    },
  },
  {
    path: '/human-resources/prospect-administration/dashboard/email-generator/view/:id',
    name: 'human-resources-prospect-administration-email-generator-quick-prospects-view',
    component: ProspectAdministrationProspectsView,
    meta: {
      navActiveLink: 'human-resources-prospect-administration-dashboard',
    },
  },
]
