<template>
  <b-card
    class="mb-0"
  >
    <div
      v-if="!isNextClicked || parent_type !== 'vendors'"
      class="mb-0"
    >
      <b-row class="mb-md-1">
        <b-col
          cols="12"
          md="4"
          class="col-xxl-3"
        >
          <h4
            v-if="parent_type !== 'employer' && parent_type !== 'suppliers' && parent_type !== 'referrer' && parent_type !== 'subvendor' && parent_type !== 'vendors'"
            class="card-title mb-0 pt-50"
          >
            Business Partner Invoices
          </h4>
          <h4
            v-if="parent_type === 'employer'"
            class="card-title mb-0 pt-50"
          >
            Invoices From Employers
          </h4>
          <h4
            v-if="parent_type === 'suppliers'"
            class="card-title mb-0 pt-50"
          >
            Invoices From Suppliers
          </h4>
          <h4
            v-if="parent_type === 'referrer'"
            class="card-title mb-0 pt-50"
          >
            Invoices From Referrers
          </h4>
          <h4
            v-if="parent_type === 'subvendor'"
            class="card-title mb-0 pt-50"
          >
            Invoices From Sub-Vendors
          </h4>
          <h4
            v-if="parent_type === 'vendors'"
            class="card-title mb-1 pt-50"
          >
            Invoices To Vendors
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="4 ml-auto"
          class="col-xxl-2 order-xl-1 order-xxl-0"
        >
          <b-form-group>
            <v-select
              id="parent_type"
              v-model="parent_type"
              :options="parentTypes"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Business Partner"
              @input="parentChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-show="parent_type === 'vendors'"
          cols="12"
          md="4"
          class="col-xxl-2 order-xl-2 order-xxl-0"
        >
          <b-form-group>
            <v-select
              v-model="vendor"
              :options="vendors"
              label="name"
              :reduce="item => item"
              :clearable="false"
              placeholder="Select Vendor"
              @input="onVendorChanged()"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="col-xxl-2 order-xl-3 order-xxl-0"
        >
          <b-form-group class="mb-md-0">
            <b-input-group>
              <flat-pickr
                v-model="month"
                class="form-control"
                :config="$store.state.monthConfig"
                placeholder="Select Month"
                :clear-button="true"
                @on-close="monthDateChanged"
              />
              <b-input-group-append>
                <b-button
                  class="px-1 outline-grey"
                  variant="outline-secondary"
                  @click="removeMonthDate"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
          class="col-xxl-5 mb-1"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group v-if="parent_type !== 'vendors'">
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              v-if="parent_type !== 'vendors' && parent_type !== null"
              variant="primary"
              class="ml-2 min-w-130"
              :to="{ name: parent_type === 'employer' ? 'finance-and-accounting-invoices-from-employers-new' : parent_type === 'suppliers' ? 'finance-and-accounting-invoices-from-suppliers-new' : parent_type === 'referrer' ? 'finance-and-accounting-invoices-from-referrers-new' : parent_type === 'subvendor' ? 'finance-and-accounting-invoices-from-subvendors-new' : '' }"
            >
              Add Invoice
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="7 ml-auto"
          class="col-xxl-6"
        >
          <div class="business-card mb-1">
            <div class="business-items">
              <div class="business-item">
                <div class="budget-wrapper">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="mr-50">Bank Balance:</span>
                    <h2 class="mb-25 mr-2 text-primary">
                      {{ invoicesResponse.account_balance | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                    </h2>
                    <span class="mr-1">as of {{ invoicesResponse.balance_date }}</span>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      @click="fetchBalance()"
                    >
                      <feather-icon
                        size="21"
                        icon="RefreshCwIcon"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="parent_type === 'vendors' ? vendorColumns : columns"
            :rows="invoicesResponse.invoices"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: vendorId !== null,
              selectOnCheckboxOnly: vendorId !== null, // only select when checkbox is clicked instead of the row
              disableSelectInfo: vendorId !== null, // disable the select info panel on top
              selectAllByGroup: vendorId !== null, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
            }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            @on-selected-rows-change="selectionChanged"
          >
            <template slot="table-actions-bottom">
              <table
                v-show="invoicesResponse.show_total_amount && !(invoicesResponse.invoices.length !== 0 && currentPage === 0)"
                class="vgt-table bordered"
              >
                <tfoot>
                  <tr>
                    <td class="text-right">
                      Total
                    </td>
                    <td
                      style="width:160px"
                    >
                      {{ invoicesResponse.total_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Currency -->
              <span v-if="props.column.field === 'cellRendererCurrency'">
                <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status)"
                  class="custom-color"
                >
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'cellRendererConsultant'">
                <span>{{ props.row.consultants }}</span>
                <b-badge
                  v-if="props.row.reject_exist"
                  variant="light-warning"
                  class="ml-1"
                >
                  This invoice is rejected
                </b-badge>
              </span>

              <!-- Column: Mail Sent -->
              <span v-else-if="props.column.field === 'cellRendererMailSent'">
                <span v-if="props.row.mail_sent">
                  <feather-icon
                    v-b-tooltip.hover.v-success
                    icon="CheckSquareIcon"
                    size="16"
                    :title="props.row.message"
                    class="align-middle text-success"
                  />  {{ props.row.sent_date }}
                </span>
                <span v-if="!props.row.mail_sent">
                  <feather-icon
                    v-b-tooltip.hover.v-danger
                    icon="AlertTriangleIcon"
                    size="16"
                    :title="props.row.message"
                    class="align-middle text-danger"
                  /> {{ props.row.sent_date }}
                </span>
              </span>

              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-action"
              >
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none pl-0"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item :to="{ name: parent_type === 'employer' ? 'finance-and-accounting-invoices-from-employers-edit' : parent_type === 'vendors' ? 'finance-and-accounting-invoices-to-vendors-edit' : parent_type === 'suppliers' ? 'finance-and-accounting-invoices-from-suppliers-edit' : parent_type === 'referrer' ? 'finance-and-accounting-invoices-from-referrers-edit' : parent_type === 'subvendor' ? 'finance-and-accounting-invoices-from-subvendors-edit' : '', params: { id: props.row.id } }">
                      <feather-icon icon="Edit2Icon" />
                      <span class="ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-link
                    id="view"
                    :to="{ name: parent_type === 'employer' ? 'finance-and-accounting-invoices-from-employers-view' : parent_type === 'vendors' ? 'finance-and-accounting-invoices-to-vendors-view' : parent_type === 'suppliers' ? 'finance-and-accounting-invoices-from-suppliers-view' : parent_type === 'referrer' ? 'finance-and-accounting-invoices-from-referrers-view' : parent_type === 'subvendor' ? 'finance-and-accounting-invoices-from-subvendors-view' : '', params: { id: props.row.id } }"
                  >
                    <b-badge
                      pill
                      variant="light-primary"
                    ><feather-icon icon="EyeIcon" />
                    </b-badge>
                  </b-link>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <b-row
                v-show="consultants.length > 0"
                class="mt-1"
              >
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="sendEmail"
                  >
                    Send Email
                  </b-button>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="invoicesResponse.count"
                    first-number
                    last-number
                    align="right"
                    :per-page="1"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
    <!-- Send Email -->
    <div
      v-if="isNextClicked && isEmail"
      class="mb-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Invoices To Vendors</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-form-group
                    label="To"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <v-select
                        v-model="mass_communication.to_emails"
                        :options="vendor.emails"
                        multiple
                        :clearable="false"
                        placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Subject"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Subject"
                      rules="required"
                    >
                      <b-form-input
                        v-model="mass_communication.subject"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Body"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Body"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="mass_communication.message"
                        rows="12"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Signature"
                    class="text-left"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Signature"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="mass_communication.signature"
                        rows="2"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="ml-2">
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="submitForm"
                  >
                    Send
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BForm, BPagination, BFormTextarea, BFormInput, BInputGroup, BInputGroupAppend, BFormGroup, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import moment from 'moment'
import EmployerInvoices from '@/js/invoicesemployer'
import SupplierInvoices from '@/js/invoicesupplier'
import SubVendorInvoices from '@/js/invoicesubvendor'
import ReferrerInvoices from '@/js/invoicesreferrer'
import VendorInvoices from '@/js/invoicesvendor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

import Ripple from 'vue-ripple-directive'
import LIST from '@/js/lists'

export default {
  name: 'BusinessPartnerInvoices',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      searchQuery: '',
      pageLength: 10,
      dir: false,
      month: null,
      parent_type: null,
      vendorId: null,
      vendors: [],
      selectedInvoices: [],
      isNextClicked: false,
      isMessage: false,
      isEmail: false,
      submitted: false,
      consultants: [],
      parentTypes: [],
      mass_communication: {
        vendor_id: null,
        subject: '',
        message: '',
        to_emails: [],
        signature: `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`,
        invoice_ids: [],
      },
      invoicesResponse: {
        invoices: [],
        count: 1,
        account_balance: 0.00,
        balance_date: '',
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Invoice#',
          field: 'invoice',
          sortable: false,
        },
        {
          label: 'Employer',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Name Of Consultant',
          field: 'consultants',
          sortable: false,
        },
        {
          label: 'Period',
          field: 'period',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '150px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      vendorColumns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Invoice#',
          field: 'invoice',
          sortable: false,
        },
        {
          label: 'Name Of Consultant',
          field: 'cellRendererConsultant',
          sortable: false,
        },
        {
          label: 'Period',
          field: 'period',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Sent Date',
          field: 'cellRendererMailSent',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '145px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Paid Date',
          field: 'paid_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      period: null,
      rows: [],
      searchTerm: '',
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        this.getParentList()
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Submitted  : 'light-primary',
        Approved   : 'light-success',
        Rejected   : 'light-danger',
        Corrected  : 'light-primary',
        Paid       : 'light-success',
        Partially  : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getFABusinessPartners(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getMassCommunicationVendors(this)
      },
    },
  },
  created() {
    if (this.$store.state.query.month !== null) {
      this.month = this.$store.state.query.month
    }
    this.invoicesResponse.count = this.$store.state.query.page
    this.vendor = this.$store.state.query.vendor
    if (this.vendor !== null && this.vendor !== undefined) {
      this.vendorId = this.vendor.id
    }
    this.parent_type = this.$store.state.query.parent_type
    this.searchQuery = this.$store.state.query.query
    LIST.getFABusinessPartners(this)
    LIST.getMassCommunicationVendors(this)
    this.getParentList()
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          VendorInvoices.sendInvoices(this, this.mass_communication)
        }
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.invoice}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteParent(obj.id)
        }
      })
    },
    fetchBalance() {
      LIST.getBalance(this)
    },
    setBalance(balanceResponse) {
      this.invoicesResponse.account_balance = balanceResponse.balance
      this.invoicesResponse.balance_date = balanceResponse.date
    },
    setEmployees(vendors) {
      this.vendors = vendors
    },
    onVendorChanged() {
      this.$store.state.query.vendor = this.vendor
      if (this.vendor !== null) {
        this.vendorId = this.vendor.id
      }
      this.currentPage = 1
    },
    invoiceDeleted(data) {
      this.getParentList()
      this.$swal({
        icon: 'success',
        title: 'Invoice Deleted!',
        text: data.return_message,
      })
    },
    invoiceNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Invoice Not Deleted!',
        text: message,
      })
    },
    selectionChanged(params) {
      const rows = params.selectedRows
      this.consultants = []
      this.selectedInvoices = []
      for (let i = 0; i < rows.length; i += 1) {
        if (!this.selectedInvoices.includes(rows[i].id)) {
          this.selectedInvoices.push(rows[i].id)
        }
        if (!this.consultants.includes(rows[i].consultants)) {
          this.consultants.push(rows[i].consultants)
        }
        this.period = rows[i].period
      }
    },
    sendEmail() {
      if (!this.submitted) {
        let consultantNames = this.consultants.join(',')
        const MCM = 'Please find the attached invoices for: \nPeriod:'
        const IF = 'Invoice for'
        consultantNames = consultantNames.split(',').join('\n')
        this.mass_communication.to_emails = []
        this.mass_communication.vendor_id = this.vendorId
        this.mass_communication.invoice_ids = this.selectedInvoices
        this.mass_communication.message = `${MCM}${this.period}\nConsultants\n${consultantNames}`
        this.mass_communication.subject = `${IF} ${this.period}`
        this.isNextClicked = true
        this.isEmail = true
        this.submitted = true
      }
    },
    creatError(message) {
      this.submitted = false
      this.$swal({
        icon: 'warning',
        title: 'Email Sent',
        text: message,
      })
      this.$dialog.alert(message)
    },
    created(message) {
      this.$swal({
        icon: 'success',
        title: 'Email Sent',
        text: message,
      })
      this.selectedInvoices = []
      this.consultants = []
      this.back()
      this.currentPage = 1
    },
    back() {
      this.isNextClicked = false
      this.submitted = false
      this.mass_communication.subject = ''
      this.mass_communication.message = ''
      this.mass_communication.signature = `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`
      this.consultants = []
    },
    monthDateChanged(config, date) {
      this.month = date
      this.$store.state.query.month = this.month
      this.currentPage = 1
    },
    removeMonthDate() {
      this.month = null
      this.$store.state.query.month = null
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    parentChanged() {
      this.$store.state.query.parent_type = this.parent_type
      this.invoicesResponse.invoices = []
      this.invoicesResponse.count = 1
      this.vendorId = null
      this.$store.state.query.vendor_id = null
      this.invoicesResponse.total_amount = 0.00
      this.$store.state.query.query = ''
      this.searchQuery = ''
      this.currentPage = 1
    },
    getParentList() {
      switch (this.parent_type) {
        case 'employer':
          this.columns[2].label = 'Employer'
          EmployerInvoices.getInvoices(this)
          break
        case 'vendors':
          this.columns[2].label = 'Vendor'
          this.$store.state.query.query = ''
          this.searchQuery = ''
          VendorInvoices.getInvoices(this, this.vendorId)
          break
        case 'suppliers':
          this.columns[2].label = 'Supplier'
          SupplierInvoices.getInvoices(this)
          break
        case 'referrer':
          this.columns[2].label = 'Referrer'
          ReferrerInvoices.getInvoices(this)
          break
        case 'subvendor':
          this.columns[2].label = 'Sub-Vendor'
          SubVendorInvoices.getInvoices(this)
          break
        default:
          break
      }
    },
    deleteParent(id) {
      switch (this.parent_type) {
        case 'employer':
          EmployerInvoices.delete(this, id)
          break
        case 'vendors':
          VendorInvoices.delete(this, id)
          break
        case 'suppliers':
          SupplierInvoices.delete(this, id)
          break
        case 'referrer':
          ReferrerInvoices.delete(this, id)
          break
        case 'subvendor':
          SubVendorInvoices.delete(this, id)
          break
        default:
          break
      }
    },
    setPartners(parentTypes) {
      this.parentTypes = parentTypes
    },
    setInvoices(invoicesResponse) {
      this.invoicesResponse = invoicesResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-color.badge-secondary {
    background-color: rgba(40, 199, 111, 0.12);
    color: #28c76f !important;
}
</style>
