<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="float-left pr-1 mb-0">
              Financial
            </h2>
          </div>
        </div>
      </div>
    </div>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-invoice-alerts', query: { type: 'receivable'} }"
          >
            <b-card-header>
              <b-card-title class="w-80">
                Invoice To Vendor Timesheet
              </b-card-title>
              <b-link
                :to="{ name: 'financial-statements-invoice-alerts', query: { type: 'receivable'} }"
                class="alert-icon d-flex align-items-center"
              >
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.invoice_receivable }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.invoice_receivable }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-invoice-due-alerts', query: { type: 'not_received'} }"
          >
            <b-card-header>
              <b-card-title class="w-80">
                Invoice From Vendor Due
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.invoice_receivable_due }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.invoice_receivable_due }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-invoice-alerts' , query: { type: 'payable'}}"
          >
            <b-card-header>
              <b-card-title class="w-80">
                No Invoice From Employer Timesheet
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.invoice_payable }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.invoice_payable }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-invoice-due-alerts' , query: { type: 'not_paid'} }"
          >
            <b-card-header>
              <b-card-title class="w-80">
                Invoice From Employer Due
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.invoice_payable_due }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.invoice_payable_due }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-timesheet-alerts', query: { type: 'not_submitted'} }"
          >
            <b-card-header>
              <b-card-title class="w-80">
                Not Submitted
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.timesheet_not_submitted }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.timesheet_not_submitted }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-timesheet-alerts', query: { type: 'pending'} }"
          >
            <b-card-header>
              <b-card-title class="w-80">
                Pending Approval Timesheets
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.timesheet_pending_approval }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.timesheet_pending_approval }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
    </b-row>
    <div class="row content-header">
      <div class="content-header-left mt-2 mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="float-left pr-1 mb-0">
              Non - Financial
            </h2>
          </div>
        </div>
      </div>
    </div>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-job-alerts', query: { type: 'long_open'}}"
          >
            <b-card-header>
              <b-card-title class="w-80">
                Jobs Open for More Than 10 Days
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.jobs_long_open }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.jobs_long_open }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-job-alerts' , query: { type: 'no_resumes'}}"
          >
            <b-card-header>
              <b-card-title>
                No Resumes for Jobs
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.job_no_resumes }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.job_no_resumes }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-document-expired-alerts'}"
          >
            <b-card-header>
              <b-card-title>
                Documents Expired
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.expired_documents }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.expired_documents }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-document-expire-soon-alerts'}"
          >
            <b-card-header>
              <b-card-title>
                Documents Expire Soon
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.expire_soon_documents }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.expire_soon_documents }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link
            :to="{ name: 'financial-statements-document-not-exist-alerts'}"
          >
            <b-card-header>
              <b-card-title>
                No Documents Exist
              </b-card-title>
              <b-link class="alert-icon d-flex align-items-center">
                <span class="feather-icon position-relative">
                  <feather-icon
                    size="21"
                    icon="BellIcon"
                  />
                  <span class="badge badge-up badge-pill bg-danger">{{ alertCount.no_documents }}</span>
                </span>
              </b-link>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="FileTextIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ alertCount.no_documents }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BLink,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Alert from '@/js/alerts'

export default {
  name: 'AllAlerts',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BLink,
  },
  data() {
    return {
      avatarText,
      alertCount: { },
    }
  },
  created() {
    Alert.getAlertsCount(this)
  },
  methods: {
    setAlertsCount(alertCount) {
      this.alertCount = alertCount
      this.$store.commit('ALERTS_COUNT', alertCount.total_counts)
      this.$store.state.counts.alerts_count = alertCount.total_counts
      this.$store.state.alerts = alertCount.total_counts
    },
  },
}
</script>
<style>
  .card-statistics .card-header {
    padding-left: 1.5rem;
  }
</style>
<style lang="scss">
</style>
