<template>
  <div>
    <b-card
      no-body
      class="business-card"
    >
      <b-row class="collapse-search">
        <b-col
          cols="12"
          md="8 ml-auto"
          class="col-xxl-7"
        >
          <div class="d-md-flex justify-content-end align-items-center">
            <b-input-group class="mr-xxl-2 mr-1">
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click.stop="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="min-w-170"
              @click.stop="generateEmail"
            >
              Advanced Search
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-card-header
        v-b-toggle.prospects-by-skill
        class="btn-collapse pb-1 not-collapsed"
      >
        <b-card-title>Prospects by Skill</b-card-title>
        <h4 class="mb-0 text-right">
          Total: {{ prospectBySkillResponse.total }}
        </h4>
      </b-card-header>
      <hr class="my-0">
      <b-collapse
        id="prospects-by-skill"
        class="show"
        style="display: block;"
      >
        <b-card-body class="px-2">
          <b-row class="business-items">
            <b-col
              v-for="count, index in prospectBySkillResponse.counts"
              :key="index"
              cols="12"
              md="3"
              class="mb-1"
            >
              <b-link
                :event="count.count !== 0 ? 'click' : ''"
                :to="{ name: 'human-resources-prospect-administration-prospects', params: { skillid: count.skill_id } }"
                @click="skillClicked"
              >
                <div class="business-item">
                  <div class="d-flex align-items-center justify-content-between">
                    <b-card-text class="mb-0">
                      {{ count.label }}
                    </b-card-text>
                    <b-badge variant="primary">
                      {{ count.count }}
                    </b-badge>
                  </div>
                </div>
              </b-link>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BInputGroup, BFormInput, BInputGroupAppend, BBadge, BCollapse, VBToggle, BLink, BButton,
} from 'bootstrap-vue'
import ProspectAdministration from '@/js/prospectAdministration'

export default {
  name: 'ProspectAdministrationDashboard',
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCollapse,
    BLink,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      searchQuery: '',
      showSkillProspects: true,
      allCounts: [],
      prospectBySkillResponse: {
        counts: [],
        total: 0,
      },
      prospectByUserResponse: {
        counts: {},
        total: 0,
      },
    }
  },
  computed: {
  },
  created() {
    this.searchQuery = this.$store.state.query.innerQuery
    ProspectAdministration.getProspectBySkill(this)
  },
  methods: {
    updateSearchQuery() {
      const counts = this.allCounts.filter(count => count.label.toLowerCase().includes(this.searchQuery.toLowerCase()))
      this.prospectBySkillResponse.counts = { ...counts }
      this.$store.state.query.innerQuery = this.searchQuery
    },
    generateEmail() {
      this.$router.push('/human-resources/prospect-administration/dashboard/email-generator')
    },
    skillClicked() {
      this.$store.state.query.query = ''
      this.$store.state.query.page = 1
    },
    setProspectBySkillCount(prospectBySkillResponse) {
      this.prospectBySkillResponse = prospectBySkillResponse
      this.allCounts = prospectBySkillResponse.counts
      this.updateSearchQuery()
    },
    setProspectBySkillCountNotRender() {
    },
  },
}
</script>
