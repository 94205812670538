<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Invoice From Employer</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Employer
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="invoice.user_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant Name
              </dt>
              <dd class="col-sm-12">
                <span class="form-control h-auto">
                  <b-badge
                    v-for="(option, index) in invoice.employee_names"
                    :key="index"
                    pill
                    variant="light-primary"
                    class="mr-25"
                  >
                    <span
                      :key="index"
                      v-text="option"
                    />
                  </b-badge>
                </span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invoice.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invoice.received_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invoice.period"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Amount
              </dt>
              <dd class="col-sm-12">
                <span class="form-control">{{ invoice.total_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Time Sheets
              </dt>
              <dd class="col-sm-12">
                <span class="form-control h-auto">
                  <b-badge
                    v-for="option in invoice.timesheet_periods"
                    :key="option"
                    pill
                    variant="light-primary"
                    class="mr-25"
                  >
                    <span
                      :key="option"
                      v-text="option"
                    />
                  </b-badge>
                </span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Expenses
              </dt>
              <dd class="col-sm-12">
                <span class="form-control">{{ invoice.expenses | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invoice.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="invoice.reject_reason !== null"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Reject Reason</label>
              <b-form-textarea
                v-model="invoice.reject_reason"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="invoice.cheque_responses.length > 0"
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Check Details</h6>
        </div>
        <div
          v-for="(cheque, index) in invoice.cheque_responses"
          :key="index"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Number
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="cheque.number"
                  />
                </dd>
              </dl>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Amount
                </dt>
                <dd class="col-sm-12">
                  <span class="form-control">{{ cheque.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                </dd>
              </dl>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Deposited
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="cheque.deposited ? 'Yes' : 'No'"
                  />
                </dd>
              </dl>
            </b-col>
            <b-col
              v-if="cheque.deposited"
              cols="12"
              md="6"
              lg="4"
            >
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Deposit Date
                </dt>
                <dd class="col-sm-12">
                  <b-input-group append="Years">
                    <span
                      class="form-control"
                      v-text="cheque.deposit_date_text"
                    />
                  </b-input-group>
                </dd>
              </dl>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <dl class="row">
                <dt class="col-sm-12 dt-text">
                  Paid Date
                </dt>
                <dd class="col-sm-12">
                  <span
                    class="form-control"
                    v-text="cheque.date_text"
                  />
                </dd>
              </dl>
            </b-col>
          </b-row>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-if="invoice.corrected_invoice !== null"
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Corrected Invoice</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Number
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invoice.corrected_invoice.number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Amount
              </dt>
              <dd class="col-sm-12">
                <span class="form-control">{{ invoice.corrected_invoice.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice History</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive-sm">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Previous Amount</th>
                    <th>Current Amount</th>
                    <th>Previous Status</th>
                    <th>Current Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(invoicehistory, index) in invoice.history"
                    :key="index"
                  >
                    <td>{{ invoicehistory.index }}</td>
                    <td>{{ invoicehistory.date }}</td>
                    <td>{{ invoicehistory.time }}</td>
                    <td>{{ invoicehistory.previous_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</td>
                    <td>{{ invoicehistory.current_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</td>
                    <td>{{ invoicehistory.previous_status }}</td>
                    <td>{{ invoicehistory.current_status }}</td>
                  </tr>
                  <tr v-if="invoice.history.length == 0">
                    <td
                      colspan="7"
                      class="text-center"
                    >
                      No Invoice History Available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Linked Invoices</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive-sm">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Invoice #</th>
                    <th>Vendor Name</th>
                    <th>Employee/Consultant Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(invoicehistory, index) in invoice.connected_invoices"
                    :key="index"
                  >
                    <td>{{ invoicehistory.index }}</td>
                    <td>{{ invoicehistory.number }}</td>
                    <td>{{ invoicehistory.employer_name }}</td>
                    <td>{{ invoicehistory.employee_names }}</td>
                    <td>{{ invoicehistory.status }}</td>
                  </tr>
                  <tr v-if="invoice.connected_invoices.length == 0">
                    <td
                      colspan="7"
                      class="text-center"
                    >
                      No Invoice History Available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="primary"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Tasks</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            class="mt-1"
          >
            <div class="table-responsive-sm">
              <table class="table b-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created Date</th>
                    <th>Due Date</th>
                    <th>Status</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="task in invoice.tasks"
                    :key="task"
                    :value="task"
                  >
                    <td>{{ task.name }}</td>
                    <td>{{ task.created_date }}</td>
                    <td>{{ task.due_date }}</td>
                    <td>{{ task.status }}</td>
                    <td>{{ task.description }}</td>
                  </tr>
                  <tr v-if="invoice.tasks.length == 0">
                    <td
                      colspan="7"
                      class="text-center"
                    >
                      No Tasks Available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row
          v-if="invoice.documents!==null"
          class="mt-1"
        >
          <b-col cols="12">
            <b-link
              v-for="option in invoice.documents"
              :key="option.id"
              :value="option.id"
              :href="option.attachment_link"
              :title="option.name"
              target="_blank"
              :class="{'mt-75': option}"
            >
              <b-badge
                variant="primary"
                class="mr-1 mb-50"
              >
                <span>{{ option.name }}</span>
              </b-badge>
            </b-link>
          </b-col>
        </b-row>
        <div
          v-if="invoice.documents.length == 0"
          class="text-center"
        >
          <p> No Documents available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="comment in invoice.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="invoice.comments.length == 0"
          class="text-center"
        >
          <p> No Comments available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="invoice.user_defined_fields.length !== 0"
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="field in invoice.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Invoice from '@/js/invoicesemployer'

import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormTextarea, BBadge, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'InvoicesEmployerView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BBadge,
    BLink,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      invoice: {},
    }
  },
  mounted() {
  },
  created() {
    Invoice.getInvoiceById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setInvoice(invoice) {
      this.invoice = invoice
      this.invoice.period = invoice.period
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
