import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const invitation = `${HOST}profile-invitation`

export default {
  getInvitations(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(invitation, params).then(response => {
      context.setInvitations(response.data)
    }, () => {
    })
  },
  addInvitation(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(invitation, body).then(response => {
      context.$vs.loading.close()
      context.invitationAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invitationNotAdded(error.response.data.return_message)
    })
  },
  updateInvitation(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${invitation}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.invitationUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invitationNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${invitation}/${id}`).then(response => {
      context.$vs.loading.close()
      context.invitationDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invitationNotDeleted(error.response.data.return_message)
    })
  },
  getInvitationById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${invitation}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setInvitation(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invitationNotFound(error.response.data.return_message)
    })
  },
  resendEmail(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.post(`${invitation}/email/resend/${id}`).then(response => {
      context.$vs.loading.close()
      context.resendEmailResponse(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.resendEmailResponseFail(error.response.data.return_message)
    })
  },

}
