<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add End Client</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="addEndClient.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Payment Terms</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Terms"
                    rules="required"
                  >
                    <v-select
                      id="payment_terms"
                      v-model="addEndClient.payment_terms_id"
                      :options="paymentTerms"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="addEndClient.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Contact Information</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Name"
                    rules="required"
                  >
                    <b-form-input
                      id="contact_name"
                      v-model="addEndClient.contact_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="addEndClient.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="phone"
                      v-model="addEndClient.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 1</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" Address 1"
                    rules="required"
                  >
                    <b-form-input
                      id="address_address1"
                      v-model="addEndClient.address.address1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 2</label>
                  <b-form-input
                    id="address_address"
                    v-model="addEndClient.address.address2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" City"
                    rules="required"
                  >
                    <b-form-input
                      id="address_city"
                      v-model="addEndClient.address.city"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>State</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" State"
                    rules="required"
                  >
                    <v-select
                      id="address_state_id"
                      v-model="addEndClient.address.state_id"
                      :options="states"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Zip Code</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" Zip Code"
                    rules="required|integer|digits:5"
                  >
                    <b-form-input
                      id="address_zip_id"
                      v-model="addEndClient.address.zip_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Additional Contact Information</h6>
            </div>
            <div
              ref="form"
              class="repeater-form"
            >
              <b-row
                v-for="(contactInfo, index) in addEndClient.contact_information"
                :id="contactInfo.id"
                :key="contactInfo.id"
                ref="row"
              >
                <b-col cols="12">
                  <div class="d-flex">
                    <div class="left">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Contact Type</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Contact Type' + index"
                              rules="required"
                            >
                              <v-select
                                id="contact_info_type"
                                v-model="addEndClient.contact_information[index].type_id"
                                :options="contactTypes"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                @input="contactTypeMatch(addEndClient.contact_information[index].type_id, index)"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Contact Type' + index, 'Contact Type') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Contact Name</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Contact Name' + index"
                              rules="required"
                            >
                              <b-form-input
                                id="contact_info_contact_name"
                                v-model="contactInfo.contact_name"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Contact Name' + index, 'Contact Name') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Email</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Email' + index"
                              rules="required|email"
                            >
                              <b-form-input
                                id="contact_info_email_address"
                                v-model="contactInfo.email_address"
                                :state="errors.length > 0 ? false:null"
                                type="email"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Email' + index, 'Email') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Phone</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Phone' + index"
                              rules="required|integer|digits:10"
                            >
                              <b-form-input
                                id="contact_info_phone_number"
                                v-model="contactInfo.phone_number"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Phone' + index, 'Phone') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Address 1</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Address 1' + index"
                              rules="required"
                            >
                              <b-form-input
                                id="contact_info_address"
                                v-model="contactInfo.address.address1"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Address 1' + index, 'Address 1') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Address 2</label>
                            <b-form-input
                              id="contact_info_address_address2"
                              v-model="contactInfo.address.address2"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>City</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'City' + index"
                              rules="required"
                            >
                              <b-form-input
                                id="contact_info_address_city"
                                v-model="contactInfo.address.city"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('City' + index, 'City') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>State</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'State' + index"
                              rules="required"
                            >
                              <v-select
                                id="contact_info_address_state"
                                v-model="contactInfo.address.state_id"
                                :options="states"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('State' + index, 'State') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <b-form-group>
                            <label>Zip Code</label>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="'Zip Code' + index"
                              rules="required|integer|digits:5"
                            >
                              <b-form-input
                                id="contact_info_address_zip"
                                v-model="contactInfo.address.zip_id"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0].replace('Zip Code' + index, 'Zip Code') : ''"
                              />
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="right mt-1 ml-1">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="btn-icon rounded-circle"
                        @click="removeContactInfo(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <hr class="mt-0">
                </b-col>
              </b-row>
            </div>
            <div v-if="contactTypes.length !== addEndClient.contact_information.length">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="addContactInformation"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Additional Contact</span>
              </b-button>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Bank Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Bank Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Name"
                    :rules="(addEndClient.bank.acct_no !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.state_id !== null || addEndClient.bank.address.zip_id !== '') ? 'required' : ''"
                  >
                    <b-form-input
                      id="bank_name"
                      v-model="addEndClient.bank.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Bank Acc#</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Acc"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.state_id !== null || addEndClient.bank.address.zip_id !== '') ? 'required|min:5' : ''"
                  >
                    <b-form-input
                      id="bank_acct_no"
                      v-model="addEndClient.bank.acct_no"
                      max-length="20"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>SWIFT Code</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="SWIFT Code"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.acct_no !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.state_id !== null || addEndClient.bank.address.zip_id !== '') ? 'required|min:8' : ''"
                  >
                    <b-form-input
                      id="bank_swift_code"
                      v-model="addEndClient.bank.swift_code"
                      max-length="15"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Routing</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Routing"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.acct_no !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.state_id !== null || addEndClient.bank.address.zip_id !== '') ? 'required|digits:10' : ''"
                  >
                    <b-form-input
                      id="bank_routing_no"
                      v-model="addEndClient.bank.routing_no"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 1</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address 1"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.acct_no !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.state_id !== null || addEndClient.bank.address.zip_id !== '') ? 'required' : ''"
                  >
                    <b-form-input
                      id="bank_address_address1"
                      v-model="addEndClient.bank.address.address1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 2</label>
                  <b-form-input
                    id="bank_address_address2"
                    v-model="addEndClient.bank.address.address2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.acct_no !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.state_id !== null || addEndClient.bank.address.zip_id !== '') ? 'required' : ''"
                  >
                    <b-form-input
                      id="bank_address_city"
                      v-model="addEndClient.bank.address.city"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>State</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.acct_no !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.zip_id !== '') ? 'required' : ''"
                  >
                    <v-select
                      id="bank_address_state"
                      v-model="addEndClient.bank.address.state_id"
                      :options="states"
                      :reduce="item => item.id"
                      :clearable="true"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Zip Code</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    :rules="(addEndClient.bank.name !== '' || addEndClient.bank.acct_no !== '' || addEndClient.bank.swift_code !== '' || addEndClient.bank.routing_no !== '' || addEndClient.bank.address.address1 !== '' || addEndClient.bank.address.address2 !== '' || addEndClient.bank.address.city !== '' || addEndClient.bank.address.state_id !== null) ? 'required|integer|digits:5' : ''"
                  >
                    <b-form-input
                      id="bank_address_zip"
                      v-model="addEndClient.bank.address.zip_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addEndClient.comments"
                :key="index"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addEndClient.user_defined_fields.length !== 0"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addEndClient.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add End Client
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import EndClient from '@/js/endclient'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  name: 'EndClientNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BButton,
    // currency,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      visa_expiry_date: null,
      addEndClient: {
        name: '',
        status_id: null,
        payment_terms_id: null,
        contact_name: '',
        email_address: '',
        phone_number: '',
        address: {
          address1: null,
          address2: null,
          city: null,
          zip_id: null,
          state_id: null,
        },
        bank: {
          name: '',
          acct_no: '',
          swift_code: '',
          routing_no: '',
          address: {
            address1: '',
            address2: '',
            city: '',
            zip_id: '',
            state_id: null,
          },
        },
        contact_information: [],
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      url: null,
      documentName: null,
      documentNameId: null,
      document_name: '',
      selectedFile: null,
      companies: [],
      states: [],
      contactTypes: [],
      status: [],
      paymentTerms: [],
      frequencies: [],
      documentNames: [],
      formstate: {},
      submitted: false,
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getStatesFullList(this)
        LIST.getContactTypesFullList(this)
        LIST.getPaymentTerms(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getEndClientStatusFullList(this)
        LIST.getEndClientsDocumentNameFullList(this)
        LIST.getUserDefinedFieldsEndClient(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getStatesFullList(this)
    LIST.getEndClientStatusFullList(this)
    LIST.getEndClientsDocumentNameFullList(this)
    LIST.getPaymentTerms(this)
    LIST.getContactTypesFullList(this)
    LIST.getUserDefinedFieldsEndClient(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          if (this.addEndClient.bank !== null) {
            if (!(this.addEndClient.bank.acct_no !== '' && this.addEndClient.bank.name !== '' && this.addEndClient.bank.swift_code !== '' && this.addEndClient.bank.routing_no !== '' && this.addEndClient.bank.address.address1 !== '' && this.addEndClient.bank.address.address1 !== '' && this.addEndClient.bank.address.state_id !== null && this.addEndClient.bank.zip_id !== '')) {
              this.addEndClient.bank = null
            }
          }
          EndClient.addEndClient(this, this.addEndClient)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addEndClient.user_defined_fields = userDefinedFields
    },
    contactTypeMatch(typeId, index) {
      if (this.addEndClient.contact_information.filter(contactInformation => contactInformation.type_id === typeId).length > 1) {
        this.addEndClient.contact_information[index].type_id = null
      }
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(obj, position) {
      const dis = this
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dis.addEndClient.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addEndClient.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    addContactInformation() {
      const contactInfo = {
        type_id: null,
        contact_name: '',
        email_address: '',
        phone_number: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: null,
        },
      }
      this.addEndClient.contact_information.push(contactInfo)
      // this.contactInformation.push({
      //   id: this.nextTodoId += this.nextTodoId,
      // })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeContactInfo(index) {
      this.addEndClient.contact_information.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    // initTrHeight() {
    //   this.trSetHeight(null)
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight)
    //   })
    // },
    setCompanies(companies) {
      this.companies = companies
    },
    setContactTypes(contactTypes) {
      this.contactTypes = contactTypes
    },
    setStatus(status) {
      this.status = status
    },
    setSalaryTypes(salaryTypes) {
      this.salaryTypes = salaryTypes
    },
    setPaymentTerms(paymentTerms) {
      this.paymentTerms = paymentTerms
    },
    setStates(states) {
      this.states = states
    },
    setFrequencies(frequencies) {
      this.frequencies = frequencies
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    endClientAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        html: Constants.getParentSuccessMessage(data.return_message),
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: Constants.getUploadButtonText(),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push(`/documents/document-tracker/new?parent_id=${data.id}&parent_type_id=${data.parent_type_id}`).catch(() => {})
        }
      })
    },
    endClientNotAdded(message) {
      if (this.addEndClient.bank == null) {
        this.addEndClient.bank = {
          name: '',
          acct_no: '',
          swift_code: '',
          routing_no: '',
          address: {
            address1: '',
            address2: '',
            city: '',
            zip_id: '',
            state_id: null,
          },
        }
      }
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addEndClient.comments.push(comment)
    },
    removeComment(index) {
      this.addEndClient.comments.splice(index, 1)
    },
    setEndClient(endclient) {
      this.addEndClient = endclient
      if (this.addEndClient.contact_information == null) {
        this.addEndClient.contact_information = []
      }
      if (this.addEndClient.comments.length === 0) {
        this.addComment()
      }
      if (endclient.bank == null) {
        this.addEndClient.bank = {
          name: '',
          acct_no: '',
          swift_code: '',
          routing_no: '',
          address: {
            address1: '',
            address2: '',
            city: '',
            zip_id: '',
            state_id: null,
          },
        }
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
