<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-tabs class="custom-tabs mt-50">
        <b-tab
          v-for="vendor_job in byVendorResponse.vendor_jobs"
          :key="vendor_job"
          :value="vendor_job"
        >
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-1 py-75 me-6">
              <div
                class="tab-avatar text-secondary rounded-sm mb-50 cursor-pointer"
                @click.stop="vendorSelected(vendor_job.id)"
              >
                <span class="badge badge-light-secondary"> {{ vendor_job.count }} </span>
              </div>
              <span class="tab-text-warp">{{ vendor_job.vendor_name }}</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="vendor_skill in vendor_job.skill"
                :key="vendor_skill"
                :value="vendor_skill"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click.stop="vendorSkillSelected(vendor_job.id, vendor_skill.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ vendor_skill.label }} </p>
                    <span class="badge badge-primary"> {{ vendor_skill.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-tabs class="custom-tabs mt-50">
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By Main Skill</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="vendor_job in bySkillResponse.vendor_jobs"
                :key="vendor_job"
                :value="vendor_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="skillSelected(vendor_job.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ vendor_job.label }} </p>
                    <span class="badge badge-primary"> {{ vendor_job.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By Location</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="vendor_job in byLocationResponse.vendor_jobs"
                :key="vendor_job"
                :value="vendor_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="locationSelected(vendor_job.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ vendor_job.label }} </p>
                    <span class="badge badge-primary"> {{ vendor_job.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import Dashboard from '@/js/vjmsdashboard'

export default {
  name: 'VjmsDashboard',
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  directives: {
  },
  data() {
    return {
      byVendorResponse: {},
      bySkillResponse: {},
      byLocationResponse: {},
    }
  },
  created() {
    Dashboard.getCountByVendor(this)
    Dashboard.getCountBySkill(this)
    Dashboard.getCountByLocation(this)
  },
  methods: {
    vendorSelected(vendorId) {
      this.$router.push(`/vendor-jobs-management/job-requests?vendor_id=${vendorId}`).catch(() => {})
    },
    vendorSkillSelected(vendorId, skillId) {
      this.$router.push(`/vendor-jobs-management/job-requests?vendor_id=${vendorId}&skill_id=${skillId}`).catch(() => {})
    },
    skillSelected(skillId) {
      this.$router.push(`/vendor-jobs-management/job-requests?skill_id=${skillId}`).catch(() => {})
    },
    locationSelected(locationId) {
      this.$router.push(`/vendor-jobs-management/job-requests?location_id=${locationId}`).catch(() => {})
    },
    setCountByVendor(byVendorResponse) {
      this.byVendorResponse = byVendorResponse
    },
    setCountBySkill(bySkillResponse) {
      this.bySkillResponse = bySkillResponse
    },
    setCountByLocation(byLocationResponse) {
      this.byLocationResponse = byLocationResponse
    },
  },
}
</script>
