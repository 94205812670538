import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const exception = `${HOST}exception`

export default {
  getExceptions(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(exception, params).then(response => {
      context.setExceptions(response.data)
    }, () => {
    })
  },
  addException(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(exception, body).then(response => {
      context.$vs.loading.close()
      context.exceptionAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.exceptionNotAdded(error.response.data.return_message)
    })
  },
  updateException(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${exception}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.exceptionUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.exceptionNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${exception}/${id}`).then(response => {
      context.$vs.loading.close()
      context.exceptionDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.exceptionNotDeleted(error.response.data.return_message)
    })
  },
  getExceptionById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${exception}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setException(response.data)
    }, error => {
      context.$vs.loading.close()
      context.exceptionNotFound(error.response.data.return_message)
    })
  },

}
