<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1 mb-2">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-checkbox
                  id="self_task"
                  v-model="addTask.self_task"
                  @change="check(addTask.self_task)"
                >
                  Self Task
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="!addTask.self_task">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    :rules="addTask.self_task ? '' : 'required'"
                  >
                    <v-select
                      id="parent_type"
                      v-model="addTask.parent_type"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    :rules="addTask.self_task ? '' : 'required'"
                  >
                    <v-select
                      id="parent_id"
                      v-model="addTask.parent_id"
                      :options="employers"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      :disabled="addTask.parent_type == null"
                      @input="onProviderChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="addTask.parent_id !== null"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employee Name</label>
                  <v-select
                    id="employee_id"
                    v-model="addTask.employee_id"
                    :options="employees"
                    :reduce="item => item.id"
                    :clearable="false"
                    placeholder="Select"
                    :disabled="addTask.parent_type == null"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Task Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="addTask.name"
                      :disabled="addTask.parent_type == null && !addTask.self_task"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group id="due_date">
                  <label>Due Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Due Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="due_date"
                      class="form-control"
                      :config="$store.state.config"
                      :disabled="addTask.parent_type == null && !addTask.self_task"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status_id"
                      v-model="addTask.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group>
                  <label>Task Description</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="addTask.description"
                      rows="2"
                      :disabled="addTask.parent_type == null && !addTask.self_task"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addTask.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        id="comment"
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addTask.user_defined_fields.length !== 0"
            variant="success"
            class="pb-1"
          >

            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addTask.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Task
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Task from '@/js/tasks'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'TaskNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addTask: {
        self_task: false,
        employee_id: null,
        parent_type: null,
        parent_id: null,
        due_date: null,
        description: '',
        task_name: '',
        status_id: null,
        comments: [],
        user_defined_fields: [],
      },
      url: null,
      due_date: null,
      types: [],
      employers: [],
      employees: [],
      status: [],
    }
  },
  watch: {
    '$store.state.lists.moduleLists': {
      immediate: true,
      handler() {
        LIST.getTaskStatusFullList(this)
        LIST.getUserDefinedFieldsTasks(this)
      },
    },
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRBusinessPartnerParentTypes(this)
        this.onTypeChanged()
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    this.due_date = moment().format('MM-DD-YYYY')
    this.addTask.due_date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
    LIST.getHRBusinessPartnerParentTypes(this)

    LIST.getTaskStatusFullList(this)
    LIST.getUserDefinedFieldsTasks(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.addTask.employee_id != null) {
            this.addTask.parent_id = this.addTask.employee_id
            this.addTask.parent_type = 3
          }
          if (this.due_date != null) {
            this.addTask.due_date = Constants.getEprmMonthDateFromString(this.due_date)
          }
          this.addTask.created_date = Constants.getEprmMonthDateFromString(moment())
          this.showLoading = true
          Task.addParentTask(this)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addTask.user_defined_fields = userDefinedFields
    },
    check(event) {
      if (event) {
        this.addTask.parent_type = null
        this.addTask.parent_id = null
        this.addTask.employee_id = null
      }
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    setEmployees(employees) {
      this.employees = employees
    },
    setEmployers(vendors) {
      this.employers = vendors
    },
    setVendors(vendors) {
      this.employers = vendors
    },
    setSubVendors(vendors) {
      this.employers = vendors
    },
    setSuppliers(vendors) {
      this.employers = vendors
    },
    setReferrers(referrers) {
      this.employers = referrers
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    onTypeChanged() {
      this.addTask.parent_id = null
      this.addTask.employers = []
      if (this.addTask.parent_type !== null) {
        const employerType = this.types.find(type => type.parent_type_id === this.addTask.parent_type)
        switch (employerType.id) {
          case 'employer':
            LIST.getEmployersFullList(this)
            break
          case 'vendors':
            LIST.getVendorsFullList(this)
            break
          case 'suppliers':
            LIST.getSuppliersFullList(this)
            break
          case 'referrer':
            LIST.getReferrersFullList(this)
            break
          case 'subvendor':
            LIST.getSubVendorsFullList(this)
            break
          default:
            break
        }
      }
    },
    onProviderChanged() {
      this.addTask.employee_id = null
      this.employees = []
      if (this.addTask.parent_type !== null) {
        if (this.addTask.parent_type === 2) {
          LIST.getEmployeeInvoicesFullList(this, this.addTask.parent_id)
        } else if (this.addTask.parent_type === 4) {
          LIST.getVendorInvoicesFullList(this, this.addTask.parent_id)
        } else if (this.addTask.parent_type === 5) {
          LIST.getSubVendorInvoicesFullList(this, this.addTask.parent_id)
        } else if (this.addTask.parent_type === 6) {
          LIST.getSupplierInvoicesFullList(this, this.addTask.parent_id)
        } else if (this.addTask.parent_type === 9) {
          LIST.getReferrerInvoicesFullList(this, this.addTask.parent_id)
        }
      }
    },
    taskAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    taskNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addTask.comments.push(comment)
    },
    removeComment(index) {
      this.addTask.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/tasks').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
