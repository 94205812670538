import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const referrer = `${HOST}referrer`

export default {
  getReferrers(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(referrer, params).then(response => {
      context.setReferrers(response.data)
    }, () => {
    })
  },
  addReferrer(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(referrer, body).then(response => {
      context.$vs.loading.close()
      context.referrerAdded(response.data)
      context.$store.state.lists.commonLists.lists.referrers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.referrerNotAdded(error.response.data.return_message)
    })
  },
  updateReferrer(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${referrer}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.referrerUpdated(response.data)
      context.$store.state.lists.commonLists.lists.referrers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.referrerNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${referrer}/${id}`).then(response => {
      context.$vs.loading.close()
      context.parentDeleted(response.data)
      context.$store.state.lists.commonLists.lists.referrers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.parentNotDeleted(error.response.data.return_message)
    })
  },
  getReferrerById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${referrer}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setReferrer(response.data)
    }, error => {
      context.$vs.loading.close()
      context.referrerNotFound(error.response.data.return_message)
    })
  },

}
