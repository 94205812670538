import Constants from '@/js/constant'
import axios from 'axios'

const HOST = Constants.hosts.vjmsUrl
const LIST = `${HOST}vendor-job-dashboard`

export default {
  getCountByVendor(context) {
    axios.get(`${LIST}/by-vendor`).then(response => {
      context.setCountByVendor(response.data)
    })
  },
  getCountBySkill(context) {
    axios.get(`${LIST}/by-skill`).then(response => {
      context.setCountBySkill(response.data)
    })
  },
  getCountByLocation(context) {
    axios.get(`${LIST}/by-location`).then(response => {
      context.setCountByLocation(response.data)
    })
  },
}
