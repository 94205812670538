<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Invite Job User</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Provider Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Provider Type"
                    rules="required"
                  >
                    <v-select
                      id="employer_type_id"
                      v-model="addInvitation.employer_type_id"
                      :options="types"
                      :reduce="item => item.parent_type_id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onTypeChanged($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employer Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Employer Name"
                    rules="required"
                  >
                    <v-select
                      id="employer"
                      v-model="employer"
                      :options="employers"
                      :reduce="item => item"
                      :clearable="false"
                      :disabled="addInvitation.employer_type_id == null"
                      placeholder="Select"
                      @input="onEmployerChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Type"
                    rules="required"
                  >
                    <v-select
                      id="contact_type_id"
                      v-model="addInvitation.contact_type_id"
                      :options="contactTypes"
                      label="contact_type"
                      :reduce="item => item.contact_type_id"
                      :clearable="false"
                      :disabled="addInvitation.employer_id === null"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
                class="col-xxl-4"
              >
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Send Notification Before</label>
                      <v-select
                        id="invitaion_before"
                        v-model="addInvitation.invitaion_before"
                        :options="days"
                        :reduce="item => item.id"
                        :clearable="true"
                        placeholder="Select"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Send Notification After</label>
                      <v-select
                        id="invitaion_after"
                        v-model="addInvitation.invitaion_after"
                        :options="days"
                        :reduce="item => item.id"
                        :clearable="true"
                        placeholder="Select"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status_id"
                      v-model="addInvitation.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      id="send_notification"
                      v-model="addInvitation.send_notification"
                      class="mt-md-2"
                    >
                      Send Invitation
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addInvitation.user_defined_fields.length !== 0"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addInvitation.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Job Invitation
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormCheckbox, BButton, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import moment from 'moment'
import Invitation from '@/js/jobinvitations'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'JobInvitationNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addInvitation: {
        employer_type_id: null,
        employer_id: null,
        contact_type_id: null,
        invitaion_before: null,
        invitaion_after: null,
        status_id: null,
        invitation_date: null,
        send_notification: false,
        user_defined_fields: [],
      },
      invitaionDate: null,
      employer: null,
      types: [],
      companies: [],
      employers: [],
      contactTypes: [],
      days: [],
      status: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getJobInvitationEmployerTypesFullList(this)
      },
    },
    '$store.state.lists.adLists': {
      immediate: true,
      handler() {
        LIST.getJobInvitationStatusFullList(this)
        LIST.getUserDefinedFieldsJobInvitations(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getJobInvitationEmployerTypesFullList(this)
    LIST.getJobInvitationStatusFullList(this)
    Constants.getAfterBeforeDays(this)
    this.invitaionDate = this.minDate()
    LIST.getUserDefinedFieldsJobInvitations(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.invitaionDate !== null) {
            this.addInvitation.invitation_date = Constants.getEprmMonthDateFromString(moment())
          }
          this.showLoading = true
          Invitation.addInvitation(this, this.addInvitation)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addInvitation.user_defined_fields = userDefinedFields
    },
    minDate() {
      return moment().format('MM-DD-YYYY')
    },
    onEmployerChanged() {
      this.contactTypes = this.employer.contact_information
      this.addInvitation.employer_id = this.employer.id
      this.addInvitation.contact_type_id = this.employer.contact_information[0].contact_type_id
    },
    onTypeChanged() {
      if (this.addInvitation.employer_type_id == null) {
        this.employers = []
      } else {
        this.addInvitation.employer_id = null
        const employerType = this.types.find(type => type.parent_type_id === this.addInvitation.employer_type_id)
        if (employerType === null) return
        switch (employerType.id) {
          case 'employer':
            LIST.getInvitationEmployersFullList(this)
            break
          case 'vendors':
            LIST.getInvitationVendorsFullList(this)
            break
          case 'suppliers':
            LIST.getInvitationSuppliersFullList(this)
            break
          case 'referrer':
            LIST.getInvitationReferrersFullList(this)
            break
          case 'subvendor':
            LIST.getInvitationSubVendorsFullList(this)
            break
          default:
            break
        }
      }
    },
    setTypes(types) {
      this.types = types
    },
    setEmployers(employers) {
      this.employers = employers
    },
    setVendors(vendors) {
      this.employers = vendors
    },
    setSubVendors(subvendors) {
      this.employers = subvendors
    },
    setSuppliers(suppliers) {
      this.employers = suppliers
    },
    setReferrers(employers) {
      this.employers = employers
    },
    setInvitationBefore(invitationbefore) {
      this.invitationbefore = invitationbefore
    },
    setInvitationAfter(invitationafter) {
      this.invitationafter = invitationafter
    },
    setStatus(status) {
      this.status = status
    },
    invitationAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    invitationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/job-invitations').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
