<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
        >
          <h4 class="card-title mb-0 pt-50">
            Invoice Payment Due Date Report</h4>
        </b-col>
        <b-col
          cols="12"
          md="7 ml-auto"
          class="col-xxl-6"
        >
          <div class="d-sm-flex align-items-center justify-content-end">
            <b-form-group class="w-50 mb-sm-0">
              <flat-pickr
                id="month"
                v-model="month"
                class="form-control"
                :config="$store.state.monthConfig"
                placeholder="Select Month"
                :clear-button="true"
                @on-change="monthDateChanged"
              />
            </b-form-group>
            <b-form-group class="w-50 mb-sm-0 ml-2">
              <v-select
                id="type"
                v-model="type"
                :options="types"
                :reduce="item => item.parent_type_id"
                :clearable="false"
                placeholder="Select Parent Type"
                @input="onTypeChanged($event)"
              />
            </b-form-group>
            <b-button
              variant="outline-secondary"
              class="ml-2 min-w-100"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="reports.invoices"
      :rtl="direction"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Currency -->
        <span v-if="props.column.field === 'cellRendererCurrency'">
          <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'cellRendererDueDaysSelect'">
          <b-badge :variant="statusVariant(props.row.type)">
            {{ props.row.due_days }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <hr>
    <b-row class="mt-2">
      <b-col cols="5">
        <dl class="row">
          <dt class="col-sm-6 col-xxl-8 text-right font-weight-bolder">
            Paid:
          </dt>
          <dd class="col-sm-6 col-xxl-4">
            <span class="font-weight-bolder ml-2">{{ reports.paid_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </dd>
        </dl>
      </b-col>
      <b-col
        cols="5"
        offset="1"
      >
        <dl class="row">
          <dt class="col-sm-6 col-xxl-8 text-right font-weight-bolder">
            Unpaid:
          </dt>
          <dd class="col-sm-6 col-xxl-4">
            <span class="font-weight-bolder ml-2">{{ reports.un_paid_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </dd>
        </dl>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import moment from 'moment'
import Reports from '@/js/reports'
import LIST from '@/js/lists'

export default {
  name: 'InvoicePaymentDueDateReport',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BFormGroup,
    BButton,
    flatPickr,
    BBadge,

    vSelect,
  },
  data() {
    return {
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      month: moment().subtract(1, 'months').format('MMM-YYYY'),
      reports: {
        invoices: [],
      },
      type: null,
      types: [],
      columns: [
        {
          label: '#',
          field: 'index',
          sortable: false,
        },
        {
          label: 'Employer',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Invoice #',
          field: 'number',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          sortable: false,
          width: '140px',
        },
        {
          label: 'Received Date',
          field: 'submitted_date',
          sortable: false,
          width: '140px',
        },
        {
          label: 'Due Date',
          field: 'due_date',
          sortable: false,
          width: '130px',
        },
        {
          label: 'Due Days',
          field: 'cellRendererDueDaysSelect',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        completed    : 'light-success',
        remaining    : 'light-warning',
        past         : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getFABusinessPartners(this)
      },
    },
  },
  created() {
    LIST.getFABusinessPartners(this)
    Reports.getInvoicePaymentDueDateReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.type, moment().format('DD'), moment().format('MM'), moment().format('YYYY'))
  },
  methods: {
    monthDateChanged(config, month) {
      this.month = month
      this.data = []
      Reports.getInvoicePaymentDueDateReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.type, moment().format('DD'), moment().format('MM'), moment().format('YYYY'))
    },
    onTypeChanged() {
      this.data = []
      Reports.getInvoicePaymentDueDateReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'), this.type, moment().format('DD'), moment().format('MM'), moment().format('YYYY'))
    },
    setPartners(parentTypes) {
      this.types = parentTypes
    },
    setReport(reports) {
      this.reports = reports
    },
    back() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
