<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="mb-2">
          <b-col
            cols="12"
            md="3"
          >
            <h4 class="card-title mb-0 pt-50">
              Interviewers Dashboard</h4>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="From Date"
              label-cols-md="3"
              class="text-right mb-0"
            >
              <flat-pickr
                v-model="from_date"
                class="form-control"
                :config="$store.state.config"
                :clear-button="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="To Date"
              label-cols-md="3"
              class="text-right mb-0"
            >
              <flat-pickr
                v-model="to_date"
                class="form-control"
                :config="$store.state.config"
                :clear-button="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
          >
            <b-button
              variant="primary"
              @click="getData"
            >
              Get
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="text-right"
          >
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      v-for="(date, index) in interviewResponse.dates"
      :key="index"
      :value="index"
      class="card-app-design"
    >
      <b-badge variant="light-primary">
        {{ date }}
      </b-badge>
      <b-row class="card-blocks">
        <b-col
          v-for="(interviewer, position) in Object.keys(interviewResponse.interviews[date].interviewers)"
          :key="position"
          :value="position"
          cols="4"
        >
          <div class="card-block">
            <b-card-title class="mt-1 mb-75">
              {{ interviewer }}
            </b-card-title>
            <div class="design-group mt-2">
              <h6 class="section-label">
                Candidates
              </h6>
              <b-card
                no-body
                class="card-employee-task"
              >
                <div
                  v-for="candidate in interviewResponse.interviews[date].interviewers[interviewer].candidates"
                  :key="candidate"
                  :value="candidate"
                  class="employee-task d-flex justify-content-between align-items-center"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar
                        variant="light-secondary"
                        rounded-circle
                        size="42"
                        :text="avatarText(candidate.name)"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        {{ candidate.name }}
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="d-flex align-items-center">
                    <small class="text-muted mr-75">{{ candidate.from_time }} to {{ candidate.to_time }}</small>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BCardBody, BCardTitle, BMedia, BMediaAside, BMediaBody, BAvatar, BButton, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import Interviewers from '@/js/interviewers'

export default {
  name: 'InterviewersDashboard',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    BBadge,
    flatPickr,
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      dates: [],
      interviewResponse: {
        dates: [],
        interviews: {},
      },
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    back() {
      this.$router.push('/administration/interviewers').catch(() => {})
    },
    setInterviewers(interviewResponse) {
      this.dates = Object.keys(interviewResponse.interviews)
      this.interviewResponse = interviewResponse
    },
    getData() {
      if (this.from_date !== null && this.to_date !== null) {
        Interviewers.getCalendarInterviewers(this, this.from_date, this.to_date)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.card-app-design .card-body .card-employee-task:last-child {
  margin-bottom: 0px;
}
</style>
