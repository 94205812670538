<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Vendor Recruiter</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Recruiter Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="recruiter.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Vendor
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="recruiter.vendor"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="recruiter.email"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="recruiter.phone"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="recruiter.status"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Additional Contact Information</h6>
        </div>
        <b-row
          v-for="(contactInfo, index) in recruiter.contact_information"
          :key="index"
          :value="index"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contactInfo.contact_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="contactInfo.email"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="contactInfo.phone"
                />
              </dd>
            </dl>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-0">
          </b-col>
        </b-row>
        <div
          v-if="recruiter.contact_information.length == 0"
          class="text-center"
        >
          <p>No additional contact information available</p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in recruiter.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="recruiter.comments.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Comments available
          </p>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VendorRecruiter from '@/js/vendorrecruiter'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'VendorRecruiterView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      recruiter: {
        contact_information: [],
        comments: [],
      },
    }
  },
  mounted() {
  },
  created() {
    VendorRecruiter.getRecruiterById(this, this.$route.params.id)
  },
  methods: {
    setRecruiter(vendorRecruiter) {
      this.recruiter = vendorRecruiter
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
