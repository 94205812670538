<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Onboarding Request</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Consultant</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Consultant"
                    rules="required"
                  >
                    <v-select
                      id="consultant"
                      v-model="addOnboarding.employee_id"
                      :options="employees"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="due_date"
                >
                  <label>Due Date</label>
                  <validation-provider
                    name="Due Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="due_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Onboarding Items</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Onboarding Items"
                    rules="required"
                  >
                    <v-select
                      id="onboarding_item"
                      v-model="selectedItems"
                      :options="items"
                      :reduce="item => item.id"
                      multiple
                      :clearable="false"
                      placeholder="Select"
                      @input="itemSelected(selectedItems)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="addOnboarding.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    id="document"
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addOnboarding.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="option.attachment_link"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addOnboarding.comments"
                :key="index"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addOnboarding.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addOnboarding.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Onboarding
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormTextarea, BButtonGroup, BButton, BBadge, BFormInput,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Constants from '@/js/constant'
import Onboarding from '@/js/onboarding'
import LIST from '@/js/lists'

export default {
  name: 'OnBoardingNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addOnboarding: {
        employee_id: null,
        due_date: null,
        item_ids: null,
        status_id: null,
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      url: null,
      due_date: moment().format('MM-DD-YYYY'),
      documentName: null,
      documentNameId: null,
      document_name: '',
      selectedFile: null,
      selectedItems: null,
      companies: [],
      status: [],
      employees: [],
      items: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getEmployeeConsultantFullList(this)
        LIST.getOnboardingItemsFullList(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getOnboardingStatusFullList(this)
        LIST.getOnboardingDocumentNameFullList(this)
        LIST.getUserDefinedFieldsOnboarding(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getEmployeeConsultantFullList(this)
    LIST.getOnboardingItemsFullList(this)
    LIST.getOnboardingStatusFullList(this)
    LIST.getOnboardingDocumentNameFullList(this)
    LIST.getUserDefinedFieldsOnboarding(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addOnboarding.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.showLoading = true
          if (this.due_date != null) {
            this.addOnboarding.due_date = Constants.getEprmMonthDateFromString(this.due_date)
          }
          this.submitted = true
          Onboarding.addOnboarding(this, this.addOnboarding)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addOnboarding.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addOnboarding.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addOnboarding.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setStatus(status) {
      this.status = status
    },
    setEmployeeConsultants(employees) {
      this.employees = employees
    },
    setOnboardingItems(items) {
      this.items = items
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    itemSelected(items) {
      this.addOnboarding.item_ids = []
      for (let i = 0; i < items.length; i += 1) {
        this.addOnboarding.item_ids.push(items[i])
      }
      this.itemsChanges()
    },
    itemsChanges() {
      if (this.addOnboarding.item_ids.length === 0) {
        this.addOnboarding.item_ids = null
        this.selectedItems = null
      }
    },
    onEmployeeChanged() {
      if (this.addOnboarding.employee_id == null) {
        this.addOnboarding.due_date = null
        this.due_date = null
      } else {
        LIST.getOnboardingFullList(this, this.addOnboarding.employee_id)
      }
    },
    dueDateChanged() {
      const start = moment(this.addOnboarding.due_date)
      const end = moment(this.addOnboarding.due_date)
      if (start.isAfter(end, 'day')) {
        this.addOnboarding.due_date = start
      }
    },
    onboardingAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onboardingNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addOnboarding.comments.push(comment)
    },
    removeComment(index) {
      this.addOnboarding.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/human-resources/onboarding').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
