<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Interview</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Interviewer</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="interview.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Custom Time From
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.from_time"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Custom Time To
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.to_time"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Communication Mode
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.communication_mode"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Send Invite
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.send_invite ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Reminder
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.reminder"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Reminder Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.reminder_type"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Candidate</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Employer Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="interview.employer_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Candidate Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="interview.employee_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consent
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.is_consent ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="interview.consentA_accepted_date !== null"
            cols="6"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consent accepted date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="interview.consentA_accepted_date"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Technology Rating</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="col-xxl-6"
          >
            <div class="table-responsive-sm">
              <table class="table b-table">
                <thead>
                  <tr>
                    <th>Technology Name</th>
                    <th>Rating</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="rating in interview.ratings"
                    :key="rating"
                    :value="rating"
                  >
                    <td>{{ rating.name }}</td>
                    <td>{{ rating.rating }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Average of Rating</th>
                    <th>7</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row v-if="interview.documents !== null">
          <b-col cols="12">
            <b-link
              v-for="option in interview.documents"
              :key="option.id"
              :value="option.id"
              :href="option.attachment_link"
              :title="option.name"
              target="_blank"
            >
              <b-badge
                variant="primary"
                class="mr-1 mb-50"
              >
                <span>{{ option.name }}</span>
              </b-badge>
            </b-link>
          </b-col>
        </b-row>
        <div
          v-if="interview.documents.length === 0"
          class="text-center"
        >
          <p class="mb-0">
            No Documents available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in interview.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="interview.comments.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Comments available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="interview.user_defined_fields.length !== 0"
        variant="secondary"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in interview.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Interview from '@/js/interviews'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BBadge, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'InterviewView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BLink,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      interview: {},
    }
  },
  mounted() {
  },
  created() {
    Interview.getInterviewById(this, this.$route.params.id)
  },
  methods: {
    setInterview(interview) {
      this.interview = interview
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
