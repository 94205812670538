<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Invoice User</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="invitation.employee_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Contact Email</label>
              <span
                class="form-control h-auto"
                v-text="invitation.email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invitation.contact_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Frequency
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invitation.frequency"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="col-xxl-4"
          >
            <dl class="row mb-0">
              <dd class="col-sm-12">
                <b-row>
                  <b-col cols="6">
                    <dl class="row mb-0">
                      <dt class="col-sm-12 dt-text">
                        Send Notification Before
                      </dt>
                      <dd class="col-sm-12">
                        <span
                          class="form-control"
                          v-text="invitation.before_notification"
                        />
                      </dd>
                    </dl>
                  </b-col>
                  <b-col cols="6">
                    <dl class="row mb-0">
                      <dt class="col-sm-12 dt-text">
                        Send Notification After
                      </dt>
                      <dd class="col-sm-12">
                        <span
                          class="form-control"
                          v-text="invitation.after_notification"
                        />
                      </dd>
                    </dl>
                  </b-col>
                </b-row>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invitation.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Send Invitation
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="invitation.sent_invitation ? 'Yes' : 'No'"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-show="invitation.user_defined_fields.length !== 0"
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="field in invitation.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Invitation from '@/js/invoiceinvitations'

import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'InvoiceInvitationView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
  },
  directives: {
  },
  data() {
    return {
      invitation: {},
    }
  },
  mounted() {
  },
  created() {
    Invitation.getInvitationById(this, this.$route.params.id)
  },
  methods: {
    setInvitation(invitation) {
      this.invitation = invitation
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
