import axios from 'axios'

const quickProspect = 'quick-prospect-administration'

export default {
  getProspectBySkill(context1) {
    const context = context1
    axios.get(`${quickProspect}/by-skill`).then(response => {
      context.setProspectBySkillCount(response.data)
    }, error => {
      context.setProspectBySkillCountNotRender(error.response.data.id)
    })
  },
  getProspectByUser(context1) {
    const context = context1
    axios.get(`${quickProspect}/by-user`).then(response => {
      context.setProspectByUserCount(response.data)
    }, error => {
      context.setProspectByUserCountNotRender(error.response.data.id)
    })
  },
}
