<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="5"
        class="col-xxl-4"
      >
        <h4 class="card-title mb-0 pt-50">
          Monthly Income Projection Report</h4>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Month"
          label-cols-md="3"
          class="text-right"
        >
          <flat-pickr
            id="month"
            v-model="month"
            class="form-control"
            :config="$store.state.monthConfig"
            placeholder="MM-YYYY"
            :clear-button="true"
            @on-change="monthDateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="invoice-preview">
      <b-col
        cols="12"
        class="col-xxl-8 offset-xxl-2"
      >
        <b-card-body>
          <div class="d-flex align-items-center justify-content-end">
            <h5 class="mb-0 ml-50">
              Work for the month of {{ report.month }}
            </h5>
          </div>
          <div class="d-flex mb-1">
            <h4 class="mb-0 ml-50">
              Income
            </h4>
          </div>
          <table class="table b-table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th class="w-25 text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(vendor, index) in report.reports"
                :key="index"
              >
                <td
                  colspan="2"
                  class="px-0"
                >
                  <table class="table b-table table-bordered">
                    <tbody>
                      <tr
                        class="table-secondary"
                        @click="vendorSelectedIndex = index"
                      >
                        <td>{{ vendor.name }}</td>
                        <td class="text-right w-25">
                          {{ vendor.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                        </td>
                      </tr>
                      <tr v-if="vendorSelectedIndex == index">
                        <td colspan="2">
                          <table class="table b-table table-bordered">
                            <tbody>
                              <tr v-if="vendor.employee_reports.length == 0">
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  No Employees Available
                                </td>
                              </tr>
                              <tr
                                v-for="(employees, index1) in vendor.employee_reports"
                                :key="index1"
                              >
                                <td class="text-dark">
                                  {{ employees.name }}
                                </td>
                                <td class="text-right w-25">
                                  {{ employees.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-right">
                  Total Income
                </th>
                <th class="text-right">
                  {{ report.total_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import moment from 'moment'
import Reports from '@/js/reports'

export default {
  name: 'MonthlyIncomeProjectionReport',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BCardBody,
    flatPickr,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      month: null,
      report: {},
      vendorSelectedIndex: 0,
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  mounted() {
  },
  created() {
    this.month = moment(new Date()).format('MMM-YYYY')
    Reports.getProjectionMonthlyIncomeReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
  },
  destroyed() {
  },
  methods: {
    setReport(report) {
      this.report = report
    },
    monthDateChanged(config, month) {
      this.month = month
      Reports.getProjectionMonthlyIncomeReport(this, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
    },
    back() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
