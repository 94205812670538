<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Secondary Skill</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Secondary Skill</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Skill Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="skill.skill_name"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Main Skills</h6>
        </div>
        <div class="demo-inline-spacing">
          <b-badge
            v-for="option in skill.main_skills"
            :key="option"
            :value="option"
            variant="light-primary"
            class="line-height-inherit"
          >
            <span>{{ option }}</span>
          </b-badge>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Secondaryskill from '@/js/secondaryskill'

import {
  BCard, BRow, BCol, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'SecondarySkillView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BBadge,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      skill: {},
    }
  },
  mounted() {
  },
  created() {
    Secondaryskill.getSecondarySkillById(this, this.$route.params.id)
  },
  methods: {
    setSecondarySkill(secondarySkill) {
      this.skill = secondarySkill
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
