<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Quick Prospect</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Name</label>
              <span
                class="form-control h-auto"
                v-text="prospect.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Email</label>
              <span
                class="form-control h-auto"
                v-text="prospect.email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Phone</label>
              <span
                class="form-control"
                v-text="prospect.phone"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Experience</label>
              <b-input-group append="Years">
                <span
                  class="form-control"
                  v-text="prospect.experience"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
            v-for="(skill, index) in prospect.skills"
            :id="index"
            :key="index"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>Main Skill</label>
                  <span class="form-control h-auto">
                    <b-badge
                      variant="light-primary"
                      class="mr-50 my-25"
                    >
                      {{ skill.skill }}
                    </b-badge>
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>Years of Exp</label>
                  <b-input-group append="Years">
                    <span
                      class="form-control"
                      v-text="skill.experience"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Resume</label>
              <span class="form-control h-auto overflow-x-auto">
                <b-link
                  :href="prospect.document.document_location"
                  :title="prospect.document.file_name"
                  target="_blank"
                  class="mt-75"
                >
                  <b-badge
                    variant="primary"
                    class="mr-1"
                  >
                    <span>{{ prospect.document.file_name }}</span>
                  </b-badge>
                </b-link>
              </span>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Relocate</label>
              <span
                class="form-control"
                v-text="prospect.is_relocate ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Visa Type</label>
              <span
                class="form-control"
                v-text="prospect.visa_type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>W2/C2C</label>
              <span
                class="form-control"
                v-text="prospect.w2_c2c"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Source</label>
              <span
                class="form-control"
                v-text="prospect.source"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Linkedin</label>
              <span
                class="form-control h-auto"
                v-text="prospect.linkedin"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Status</label>
              <span
                class="form-control h-auto"
                v-text="prospect.status"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Verified</label>
              <span
                class="form-control h-auto"
                v-text="prospect.is_verified ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
            v-show="prospect.verified_date !== null && prospect.verified_date !== ''"
          >
            <b-form-group>
              <label>Verified Date</label>
              <span
                class="form-control h-auto"
                v-text="prospect.verified_date"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-for="comment in prospect.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Notes</label>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormTextarea, BBadge, BLink, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import QuickProspect from '@/js/quickProspect'

export default {
  name: 'QuickProspectView',
  components: {
    BCard,
    BLink,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BBadge,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      prospect: {},
    }
  },
  mounted() {
  },
  created() {
    QuickProspect.getProspectById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setProspect(prospectResponse) {
      if (prospectResponse.comments.length === 0) {
        prospectResponse.comments.push({
          comment: '',
        })
      }
      this.prospect = prospectResponse
    },
    setProspectNotFound(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      this.back()
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
</style>
