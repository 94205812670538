<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Role User</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="addRoleuser.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="addRoleuser.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>User Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="addRoleuser.type"
                      :options="types"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @change="onTypeChanged($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Password</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required|min:6"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="addRoleuser.type === 'admin'">
              <b-col cols="12">
                <b-alert
                  variant="primary"
                  show
                >
                  <div class="alert-body">
                    <span><strong>All Roles Selected</strong></span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="addRoleuser.type === 'internal'"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Select Roles</h6>
            </div>
            <b-row
              v-for="(departmentDevision, index) in Object.keys(addRoleuser.devisions)"
              :key="index"
              :value="index"
              class="mt-1"
            >
              <b-col cols="12">
                <b-form-checkbox
                  v-model="addRoleuser.devisions[departmentDevision].selected"
                  class="custom-control-primary mb-1"
                  @input="departmentSelected(departmentDevision)"
                >
                  {{ departmentDevision }}
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                class="pl-5"
              >
                <b-row v-show="addRoleuser.devisions[departmentDevision].selected">
                  <b-col
                    v-for="(devision, devisionIndex) in addRoleuser.devisions[departmentDevision]"
                    :key="devisionIndex"
                    :value="devisionIndex"
                    cols="3"
                  >
                    <b-form-checkbox
                      v-model="devision.selected"
                      class="custom-control-primary mb-1"
                      @input="devisionSelected(departmentDevision, devisionIndex, devision.selected)"
                    >
                      {{ devision.name }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addRoleuser.user_defined_fields.length !== 0"
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addRoleuser.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Role User
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox, BAlert, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import CryptoJS from 'crypto-js'
import RoleUser from '@/js/roleusers'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'RoleUserNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BAlert,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addRoleuser: {
        name: '',
        email: '',
        type: 'internal',
        devisions: {},
        user_defined_fields: [],
      },
      types: [],
      password: '',
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getAllRolesNyUserTypeId(this)
      },
    },
  },
  mounted() {
  },
  created() {
    Constants.getUserRoleTypes(this)
    LIST.getUserDefinedFieldsRoleUsers(this)
    LIST.getAllRolesNyUserTypeId(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.addRoleuser.password = encodeURIComponent(CryptoJS.MD5(this.password).toString())
          RoleUser.addRoleuser(this, this.addRoleuser)
        }
      })
    },
    setAllRoles(departmentDevisions) {
      this.addRoleuser.devisions = departmentDevisions.devisions
      if (departmentDevisions.devisions === null) {
        this.addRoleuser.devisions = {}
      }
      Object.keys(this.addRoleuser.devisions).forEach(key => {
        Object.keys(this.addRoleuser.devisions[key]).forEach(devision => {
          this.addRoleuser.devisions[key][devision].selected = false
        })
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addRoleuser.user_defined_fields = userDefinedFields
    },
    devisionSelected(departmentDevision, devision, selected) {
      if (selected) {
        this.addRoleuser.devisions[departmentDevision][devision].selected = true
      } else {
        this.addRoleuser.devisions[departmentDevision][devision].selected = false
        this.addRoleuser.devisions[departmentDevision].selected = false
        Object.keys(this.addRoleuser.devisions[departmentDevision]).forEach(key => {
          if (key !== 'selected') {
            if (this.addRoleuser.devisions[departmentDevision][key].selected) {
              this.addRoleuser.devisions[departmentDevision].selected = true
            }
          }
        })
      }
    },
    departmentSelected(departmentDevision) {
      Object.keys(this.addRoleuser.devisions[departmentDevision]).forEach(devision => {
        if (devision !== 'selected') {
          if (this.addRoleuser.devisions[departmentDevision].selected) {
            this.addRoleuser.devisions[departmentDevision][devision].selected = true
          } else {
            this.addRoleuser.devisions[departmentDevision][devision].selected = false
          }
        }
      })
    },
    // onTypeChanged() {
    //   if (this.addRoleuser.type !== null) {
    //   }
    // },
    roleUserAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    roleUserNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
