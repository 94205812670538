<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
        >
          <h4 class="card-title mb-0 pt-50">
            Apply for {{ title }}</h4>
        </b-col>
        <b-col
          cols="12"
          md="7 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-130"
              :to="{ name: 'human-resources-candidates-new', params: { job_id: jobId}, query: {name: title}}"
            >
              Apply New
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-2 w-20"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="col-xxl-4"
        >
          <b-form-group
            label="Provider Type"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              v-model="employer_type_id"
              :options="types"
              :reduce="item => item.parent_type_id"
              :clearable="true"
              placeholder="Select"
              @input="onTypeChanged()"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="col-xxl-4"
        >
          <b-form-group
            label="Employer Name"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              v-model="employer_id"
              :options="employers"
              :reduce="item => item.id"
              :clearable="false"
              placeholder="Select"
              :disabled="employer_type_id == null"
              @input="onEmployerChanged()"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="candidatesResponse.prospects"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Select -->
        <span v-if="props.column.field === 'cellRendererCheckbox'">
          <b-form-checkbox
            :id="props.row.id"
            v-model="props.row.selected"
            :disabled="!props.row.editable || (selected_prospects.length >= 1 && selected_prospects[0] != props.row.id)"
            @input="prospectSelected(props.row.id)"
          />
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="candidatesResponse.count"
            first-number
            last-number
            align="right"
            :per-page="1"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="pageChanged()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </vue-good-table>
    <hr>

    <div
      v-if="selected_prospects.length >= 1"
      class="d-flex mt-2"
    >
      <h4 class="mb-0 ml-50">
        Experience
      </h4>
    </div>
    <validation-observer
      v-if="selected_prospects.length >= 1"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col
            v-for="option in technologies"
            :key="option.id"
            :value="option.id"
            cols="4"
          >
            <b-form-group
              :label="option.name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="option.name"
                rules="required"
              >
                <b-form-spinbutton
                  v-model="option.years"
                  :min="0"
                  :max="10"
                  :step="0.5"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row
          v-if="selected_prospects.length >= 1"
          class="ml-2"
        >
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Candidates
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormCheckbox, BForm, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormSpinbutton,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import Constants from '@/js/constant'
import moment from 'moment'
import Candidates from '@/js/candidates'
import Prospect from '@/js/prospects'
import LIST from '@/js/lists'

export default {
  name: 'ExistingCandidates',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCard,
    BRow,
    BFormCheckbox,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSpinbutton,
    vSelect,
  },
  data() {
    return {
      required,
      candidatesResponse: { prospects: [], count: 1 },
      searchQuery: '',
      selected_prospects: [],
      jobId: null,
      title: '',
      types: [],
      employers: [],
      employer_type_id: null,
      employer_id: null,
      technologies: [],
      copytechnologies: [],
      pageLength: 10,
      dir: false,
      count: 1,
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email_addreess',
          sortable: false,
        },
        {
          label: 'Submitted Date',
          field: 'join_date',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Select',
          field: 'cellRendererCheckbox',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active',
        2: 'Inactive',
        3: 'Terminated',
      },
      {
        1: 'light-success',
        2: 'light-danger',
        3: 'light-info',
      }],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.innerpage
      },
      set(val) {
        this.$store.state.query.page = val
        Candidates.getCandidates(this, this.$route.params.job_id)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getJobInvitationEmployerTypesFullList(this)
      },
    },
  },
  created() {
    this.jobId = this.$route.params.job_id
    this.title = this.$route.query.name
    this.searchQuery = this.$route.query.innerQuery
    LIST.getTechnologiesListByJobId(this, this.$route.params.job_id)
    LIST.getJobInvitationEmployerTypesFullList(this)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          Candidates.addExistingusers(this, this.jobId, this.employer_type_id, this.employer_id, this.selected_prospects, this.technologies, Constants.getEprmMonthDateFromString(moment()))
        }
      })
    },
    pageChanged() {
      Prospect.getJobProspects(this, this.$route.params.job_id)
    },
    prospectSelected(id) {
      if (!this.selected_prospects.includes(id)) {
        this.selected_prospects.push(id)
      } else {
        this.selected_prospects.splice(this.selected_prospects.indexOf(id), 1)
        this.technologies = this.copytechnologies
      }
      for (let i = 0; i < this.technologies.length; i += 1) {
        this.technologies[i].years = 0.00
      }
    },
    candidateAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    candidateNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Candidates.delete(this, obj.id)
        }
      })
    },
    candidateDeleted() {
      Prospect.getJobProspects(this, this.$route.params.job_id)
      this.$swal({
        icon: 'success',
        title: 'Candidate Deleted!',
        text: 'Your Candidate has been deleted',
      })
    },
    candidateNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Candidate Not Deleted!',
        text: 'Your Candidate has been not deleted',
      })
    },
    setTechnologies(technologies) {
      this.technologies = technologies
      this.copytechnologies = technologies
    },
    setTypes(types) {
      this.types = types
    },
    setEmployers(employers) {
      this.employers = employers
    },
    setSuppliers(suppliers) {
      this.employers = suppliers
    },
    setVendors(vendors) {
      this.employers = vendors
    },
    setSubVendors(subVendors) {
      this.employers = subVendors
    },
    setReferrers(referrers) {
      this.employers = referrers
    },
    updateSearchQuery() {
      this.$route.query.innerQuery = this.searchQuery
      Prospect.getJobProspects(this, this.$route.params.job_id)
    },
    setProspects(candidatesResponse) {
      this.candidatesResponse = candidatesResponse
    },
    onTypeChanged() {
      this.selected_prospects = []
      this.candidatesResponse.prospects = []
      if (this.employer_type_id == null) {
        this.employer_id = null
        this.employers = []
      } else {
        this.employer_id = null
        const employerType = this.types.find(type => type.parent_type_id === this.employer_type_id)
        switch (employerType.id) {
          case 'employer':
            LIST.getEmployersFullList(this)
            break
          case 'vendors':
            LIST.getVendorsFullList(this)
            break
          case 'suppliers':
            LIST.getSuppliersFullList(this)
            break
          case 'referrer':
            LIST.getReferrersFullList(this)
            break
          case 'subvendor':
            LIST.getSubVendorsFullList(this)
            break
          default:
            break
        }
      }
      if (this.employer_id !== null) {
        Prospect.getJobProspects(this, this.$route.params.job_id)
      }
    },
    back() {
      this.$router.go(-1)
    },
    onEmployerChanged() {
      this.selected_prospects = []
      this.candidatesResponse.prospects = []
      if (this.employer_id !== null) {
        Prospect.getJobProspects(this, this.$route.params.job_id)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
