import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const employer = `${HOST}employer`

export default {
  getEmployers(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(employer, params).then(response => {
      context.setEmployers(response.data)
    }, () => {
    })
  },
  addEmployer(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(employer, body).then(response => {
      context.$vs.loading.close()
      context.employerAdded(response.data)
      context.$store.state.lists.commonLists.lists.employers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.employerNotAdded(error.response.data.return_message)
    })
  },
  updateEmployer(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${employer}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.employerUpdated(response.data)
      context.$store.state.lists.commonLists.lists.employers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.employerNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${employer}/${id}`).then(response => {
      context.$vs.loading.close()
      context.parentDeleted(response.data)
      context.$store.state.lists.commonLists.lists.employers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.parentNotDeleted(error.response.data.return_message)
    })
  },
  getEmployerById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${employer}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployer(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employerNotFound(error.response.data.return_message)
    })
  },
}
