<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit H-1B Administration</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="h1bRequest.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Middle Name</label>
                  <b-form-input
                    v-model="h1bRequest.middle_name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="h1bRequest.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email Address</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email Address"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="h1bRequest.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone Number</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      v-model="h1bRequest.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>H-1B/I-94 Expiration Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="expiration_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      :clear-button="true"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeVisaExpairyDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Education (Field of Study)</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Education (Field of Study)"
                    rules="required"
                  >
                    <b-form-input
                      v-model="h1bRequest.education"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Bachelor's</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bachelor's"
                    rules="required"
                  >
                    <b-form-input
                      v-model="h1bRequest.bachelors"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Master's (if applicable)</label>
                  <b-form-input
                    v-model="h1bRequest.masters"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Is the foreign national currently employed with your company?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="h1bRequest.is_currently_employeed"
                      name="currently_employeed_yes"
                      value="true"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="h1bRequest.is_currently_employeed"
                      name="currently_employeed_no"
                      value="false"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>If yes, is the work location the same as the previous petition?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="h1bRequest.is_work_location_previous_location"
                      name="work_location_previous_location_yes"
                      value="true"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="h1bRequest.is_work_location_previous_location"
                      name="work_location_previous_location_no"
                      value="false"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Proposed/Current Salary</label>
                  <b-input-group
                    prepend="$"
                  >
                    <money
                      id="referrer_pay_rate"
                      v-model="h1bRequest.salary"
                      :min="0"
                      :step="0.5"
                      v-bind="money"
                      class="form-control"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employee’s Job Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Employee’s Job Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="h1bRequest.job_title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>In-house at employer’s own location</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="h1bRequest.is_employer_location"
                      name="employer_location_yes"
                      value="true"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="h1bRequest.is_employer_location"
                      name="employer_location_no"
                      value="false"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client location – please provide the following</label>
                  <b-form-input
                    v-model="h1bRequest.client_location"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>End-user’s name, full address with street, city, state, and zip code</label>
                  <b-form-input
                    v-model="h1bRequest.end_user_details"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Middle vendor (if applicable)</label>
                  <b-form-input
                    v-model="h1bRequest.middle_vendor"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group>
                  <label>Detailed description of job duties/responsibilities (can be attached as a separate document) <span class="text-danger"> *</span></label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="h1bRequest.description"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Do you have a spouse and/or child currently in the US who need H4?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="h1bRequest.need_h4"
                      name="need_h4_yes"
                      value="true"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="h1bRequest.need_h4"
                      name="need_h4_no"
                      value="false"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Do you have a green card petition in process?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="h1bRequest.green_card_petitions"
                      name="green_card_petitions_yes"
                      value="true"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="h1bRequest.green_card_petitions"
                      name="green_card_petitions_no"
                      value="false"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="h1bRequest.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Linkedin Url</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Linkedin Url"
                    rules="required"
                  >
                    <b-form-input
                      v-model="h1bRequest.linkedin_url"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Reference</h6>
            </div>
            <div
              v-for="(reference, index) in h1bRequest.referrers"
              :key="index"
              class="d-flex border-b-repeat"
            >
              <div class="left w-100">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>First Name</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'First Name' + index"
                        :rules="isRequired(index) ? 'required' : ''"
                      >
                        <b-form-input
                          v-model="reference.first_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace('First Name' + index, 'First Name') : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Last Name</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Last Name' + index"
                        :rules="isRequired(index) ? 'required' : ''"
                      >
                        <b-form-input
                          v-model="reference.last_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace('Last Name' + index, 'Last Name') : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Company</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Company' + index"
                        :rules="isRequired(index) ? 'required' : ''"
                      >
                        <b-form-input
                          v-model="reference.company"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace('Company' + index, 'Company') : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Type</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Type' + index"
                        :rules="isRequired(index) ? 'required' : ''"
                      >
                        <v-select
                          v-model="reference.type_id"
                          :clearable="true"
                          placeholder="Select"
                          :options="types"
                          :reduce="item => item.id"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace('Type' + index, 'Type') : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Official Email</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Email' + index"
                        :rules="isRequired(index) ? 'required|email' : ''"
                      >
                        <b-form-input
                          v-model="reference.email"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace('Email' + index, 'Email') : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Phone Number</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Phone Number' + index"
                        :rules="isRequired(index) ? 'required|integer|digits:10' : ''"
                      >
                        <b-form-input
                          v-model="reference.phone"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace('Phone Number' + index, 'Phone Number') : '' }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="mt-0">
              </div>
              <div class="right mt-1 ml-1">
                <b-button
                  v-show="index === 0"
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  @click="addReference"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  v-show="index !== 0"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeReference(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Workflow Tasks</h6>
            </div>
            <b-tabs class="wizard-tabs">
              <b-tab
                v-for="(task, index) in h1bRequest.tasks"
                :key="index"
                :value="index"
                :active="task.active"
                :disabled="task.disabled"
                @click="tabSelected(index)"
              >
                <template #title>
                  <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'wizard-icon-circle md checked square_shape' : 'wizard-icon-circle md square_shape'">
                    <span class="wizard-icon-container square_shape">
                      <span class="wizard-icon">{{ index + 1 }}</span>
                    </span>
                  </span>
                  <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'stepTitle checked' : 'stepTitle'">{{ task.name }}</span>
                </template>
                <validation-observer ref="tasks">
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        {{ task.name }}
                      </h5>
                    </b-col>
                    <b-col
                      v-for="(field, fieldIndex) in task.fields"
                      :key="fieldIndex"
                      :value="fieldIndex"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group
                        v-if="field.type === 'Text'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <b-form-input
                            :v-model="task.fields[fieldIndex].value"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'DropDown'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <v-select
                            v-model="task.fields[fieldIndex].value"
                            :options="field.options"
                            label="option"
                            :clearable="!(field.required && task.active)"
                            :reduce="item => item.option"
                            placeholder="Select"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'TextArea'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <b-form-textarea
                            v-model="task.fields[fieldIndex].value"
                            rows="2"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'Date'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <flat-pickr
                            v-model="task.fields[fieldIndex].value"
                            class="form-control"
                            :config="startDateConfig"
                            :placeholder="'MM-DD-YYYY'"
                            :clear-button="true"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        v-if="field.type === 'Time'"
                      >
                        <label>{{ field.name }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="task.fields[fieldIndex].required && task.active ? 'required' : ''"
                          :name="task.fields[fieldIndex].name + index + fieldIndex"
                        >
                          <flat-pickr
                            v-model="task.fields[fieldIndex].value"
                            class="form-control"
                            :config="timeConfig"
                            :placeholder="'HH:mm a'"
                            :clear-button="true"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace(task.fields[fieldIndex].name + index + fieldIndex, field.name) : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="task.name.toLowerCase().includes('interview')"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-link
                        class="btn mt-2 btn-outline-primary"
                        @click="scheduleInterview()"
                      >
                        <span>Schedule Interview</span>
                      </b-link>
                    </b-col>
                  </b-row>
                  <hr class="my-50">
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        v-if="index !== 0"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="previous(index)"
                      >
                        Previous
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-center
                        variant="outline-danger"
                        class="ml-1"
                        @click="endWorkFlow(index)"
                      >
                        End Workflow
                      </b-button>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-flex align-items-center justify-content-end text-left">
                        <b-button
                          v-if="index + 1 < h1bRequest.tasks.length"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="next(index)"
                        >
                          Next
                        </b-button>
                        <b-button
                          v-if="index + 1 === h1bRequest.tasks.length"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="next(index)"
                        >
                          Complete Workflow
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
            <b-modal
              id="modal-center"
              ref="endprocess"
              centered
              title="Reject Reason"
              ok-only
              ok-title="End Process"
              :no-close-on-backdrop="true"
              @ok="endProcess()"
            >
              <b-form-group v-if="showList">
                <label>Reason</label>
                <div class="d-flex">
                  <div class="left w-100">
                    <v-select
                      id="reason_id"
                      v-model="reason_id"
                      :options="reasons"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                  </div>
                  <div class="right ml-50">
                    <b-button
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addNewReason"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="showName">
                <label>Reason</label>
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-input
                      id="reason_name"
                      v-model="reason"
                    />
                  </div>
                  <div class="right ml-50">
                    <b-button
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="showListOption"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-modal>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    id="document_name"
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in h1bRequest.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="option.attachment_link"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in h1bRequest.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="sendForm"
            >
              Update Request
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormRadio, BButton, BBadge, BButtonGroup, BFormTextarea, BTabs, BTab, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import H1b from '@/js/h1b'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  name: 'H1bAdministrationEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    BButton,
    BBadge,
    BButtonGroup,
    BTabs,
    BTab,
    BModal,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      h1bRequest: {
        first_name: '',
        middel_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        visa_expiry_date: null,
        education: '',
        bachelors: '',
        masters: '',
        is_employeed_yes: false,
        is_location_yes: false,
        current_salary: '',
        employers_job_title: '',
        in_house_location_yes: false,
        client_location: '',
        address: '',
        middle_vender: '',
        description: '',
        who_need_h4: false,
        green_card_petitions: false,
        status_id: null,
        comments: [],
        documents: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      tabIndex: null,
      result_id: null,
      reason_id: null,
      reason: '',
      showList: true,
      showName: false,
      expiration_date: null,
      selectedFile: null,
      types: [],
      documentNameId: null,
      documentNames: [],
      results: [],
      reasons: [],
      status: [],
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'h:i K',
        altFormat: 'h:i K',
        defaultHour: new Date().getHours(),
        defaultMinute: new Date().getMinutes(),
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: moment(new Date()).format('MM-DD-YYYY'),
      },
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        end: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getH1UserStatus(this)
        LIST.getReferrerTypes(this)
        LIST.getProcessReasons(this)
      },
    },
    '$store.state.lists.moduleLists': {
      immediate: true,
      handler() {
        LIST.getH1bDocumentNameFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getH1UserStatus(this)
    LIST.getH1bDocumentNameFullList(this)
    LIST.getReferrerTypes(this)
    LIST.getProcessReasons(this)
    H1b.getH1bUserById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.tasks.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    previous(index) {
      this.h1bRequest.tasks[index].active = false
      this.h1bRequest.tasks[index - 1].active = true
    },
    next(index) {
      return new Promise((resolve, reject) => {
        this.$refs.tasks[index].validate().then(result => {
          if (result) {
            resolve(true)
            const request = {
              fields: this.h1bRequest.tasks[index].fields,
              status_id: this.h1bRequest.tasks[index].status_id,
              result_id: this.h1bRequest.tasks[index].result_id,
            }
            H1b.updateH1bTask(this, this.h1bRequest.tasks[index].task_id, request, index)
          } else {
            reject()
          }
        })
      })
    },
    endWorkFlow(index) {
      this.tabIndex = index
      this.$refs.endprocess.show()
    },
    endProcess() {
      if ((this.reason === '' || this.reason === null || this.reason === undefined) && this.reason_id === null) {
        this.$swal({
          title: 'Error!',
          text: 'Please Select Reason',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        const request = {
          reason: this.reason,
          reason_id: this.reason_id,
          fields: this.h1bRequest.tasks[this.tabIndex].fields,
          status_id: this.h1bRequest.tasks[this.tabIndex].status_id,
          result_id: this.h1bRequest.tasks[this.tabIndex].result_id,
        }
        H1b.endProcessByH1bId(this, this.h1bRequest.tasks[this.tabIndex].task_id, request)
      }
    },
    addNewReason() {
      this.reason_id = null
      this.showList = false
      this.showName = true
    },
    showListOption() {
      this.showList = true
      this.showName = false
      this.reason = ''
    },
    sendForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.h1bRequest.referrers = this.h1bRequest.referrers.filter(referrer => this.isRequiredByReferrer(referrer))
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.h1bRequest.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          if (this.expiration_date !== null) {
            this.h1bRequest.expiration_date = Constants.getEprmMonthDateFromString(this.expiration_date)
          } else {
            this.h1bRequest.expiration_date = null
          }
          H1b.updateH1bUsers(this, this.$route.params.id, this.h1bRequest)
        }
      })
    },
    tabSelected(index) {
      for (let i = 0; i < this.h1bRequest.tasks.length; i += 1) {
        if (!this.h1bRequest.tasks[i].disabled) {
          this.h1bRequest.tasks[i].active = false
        }
      }
      this.h1bRequest.tasks[index].active = true
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    isRequired(position) {
      return this.isRequiredByReferrer(this.h1bRequest.referrers[position])
    },
    isRequiredByReferrer(referrer) {
      return referrer.first_name !== '' || referrer.last_name !== '' || referrer.company !== '' || referrer.type_id !== null || referrer.email !== '' || referrer.phone !== ''
    },
    setProcessReasons(reasons) {
      this.reasons = reasons
    },
    setReferrerTypes(types) {
      this.types = types
    },
    removeFile(file, position) {
      if (file.id == null) {
        this.h1bRequest.documents.splice(position, 1)
      } else {
        this.deleteConfirm(file, position)
      }
    },
    deleteConfirm(obj, position) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.h1bRequest.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.h1bRequest.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    removeVisaExpairyDate() {
      this.expiration_date = null
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setH1buser(h1bResponse) {
      this.h1bRequest = h1bResponse
      this.expiration_date = h1bResponse.expiration_date
      if (this.h1bRequest.referrers.length === 0) {
        this.addReference()
      }
      if (this.h1bRequest.comments.length === 0) {
        this.addComment()
      }
      if (this.h1bRequest.green_card_petitions === null) {
        this.h1bRequest.green_card_petitions = false
      }
      if (this.h1bRequest.is_currently_employeed === null) {
        this.h1bRequest.is_currently_employeed = false
      }
      if (this.h1bRequest.is_work_location_previous_location === null) {
        this.h1bRequest.is_work_location_previous_location = false
      }
      if (this.h1bRequest.is_employer_location === null) {
        this.h1bRequest.is_employer_location = false
      }
      if (this.h1bRequest.need_h4 === null) {
        this.h1bRequest.need_h4 = false
      }
    },
    setH1buserNotFound(message) {
      this.back()
      this.$swal({
        icon: 'warning',
        title: 'H1b user Not Found!',
        text: message,
      })
    },
    h1bTaskUpdated(data, index) {
      if (index + 1 < this.h1bRequest.tasks.length) {
        this.h1bRequest.tasks[index].active = false
        this.h1bRequest.tasks[index + 1].disabled = false
        this.h1bRequest.tasks[index + 1].active = true
      } else {
        this.back()
      }
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    h1bTaskNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    endProcessUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    endProcessNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    h1bUserUpdated(message) {
      this.back()
      this.$swal({
        icon: 'success',
        title: 'H1b user Added!',
        text: message,
      })
    },
    h1bUserNotUpdated(message) {
      this.$swal({
        icon: 'warning',
        title: 'H1b user Not Added!',
        text: message,
      })
    },
    addReference() {
      const reference = {
        first_name: '',
        last_name: '',
        company: '',
        type_id: null,
        email: '',
        phone: '',
      }
      this.h1bRequest.referrers.push(reference)
    },
    removeReference(index) {
      this.h1bRequest.referrers.splice(index, 1)
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.h1bRequest.comments.push(comment)
    },
    removeComment(index) {
      this.h1bRequest.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
