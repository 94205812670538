<template>
  <b-row>
    <b-col
      cols="6"
    >
      <router-link :to="{ name: 'financial-statements-reports-account-receivable-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Account Receivable Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col cols="6">
      <router-link :to="{ name: 'financial-statements-reports-account-payable-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Account Payable Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col cols="6">
      <router-link :to="{ name: 'financial-statements-reports-account-payroll-report' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Payroll Report
          </h4>
        </b-card>
      </router-link>
    </b-col>
    <b-col cols="6">
      <router-link :to="{ name: 'financial-statements-reports-account-operating-expenses' }">
        <b-card>
          <h4 class="mb-0 text-primary">
            Operating Expenses
          </h4>
        </b-card>
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'FinancialReports',
  components: {

    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
