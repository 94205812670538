import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.vjmsUrl
const vendorJob = `${HOST}vendor-job`

export default {
  getVendorJobs(context1) {
    const context = context1
    const params = {
      params: {
        working_status_id: context.$store.state.query.working_status_id, vendor_id: context.$store.state.query.vendor_id, skill_id: context.$store.state.query.skill_id, location_id: context.$store.state.query.location_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1,
      },
    }
    axios.get(vendorJob, params).then(response => {
      context.setVendorJobs(response.data)
    }, () => {
    })
  },
  addVendorJob(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(vendorJob, body).then(response => {
      context.$vs.loading.close()
      context.jobAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotAdded(error.response.data.return_message)
    })
  },
  updateVendorJob(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${vendorJob}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.jobUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${vendorJob}/${id}`).then(response => {
      context.$vs.loading.close()
      context.jobDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotDeleted(error.response.data.return_message)
    })
  },
  getVendorJobById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${vendorJob}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setVendorJob(response.data)
    }, error => {
      context.$vs.loading.close()
      context.vendorJobNotFound(error.response.data.return_message)
    })
  },
  getVendorJobProspectsById(context1, id) {
    const context = context1
    const params = { params: { query: context.$store.state.query.innerQuery, page: context.$store.state.query.innerpage - 1 } }
    context.$vs.loading()
    axios.get(`${vendorJob}/${id}/prospects`, params).then(response => {
      context.$vs.loading.close()
      context.setVendorJobProspects(response.data)
    }, error => {
      context.$vs.loading.close()
      context.vendorJobNotFound(error.response.data.return_message)
    })
  },
  postVendorJobById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.post(`${vendorJob}/${id}/post-job`).then(response => {
      context.$vs.loading.close()
      context.setJobPosted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setJobNotPosted(error.response.data.return_message)
    })
  },
  rejectVendorJobById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${vendorJob}/${id}/reject`).then(response => {
      context.$vs.loading.close()
      context.setJobRejected(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setJobNotRejected(error.response.data.return_message)
    })
  },
  syncVendorJobById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.post(`${vendorJob}/${id}/link-prospect`).then(response => {
      context.$vs.loading.close()
      context.setJobSynced(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setJobNotSynced(error.response.data.return_message)
    })
  },
}
