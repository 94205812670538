import Dashboard from '@/views/prospect-administration/Dashboard.vue'
import BulkUpload from '@/views/prospect-administration/BulkUpload.vue'
import QuickProspects from '@/views/prospect-administration/quick-prospects/QuickProspects.vue'
import QuickProspectsEdit from '@/views/human-resources/quick-prospects/actions/Edit.vue'
import QuickProspectsView from '@/views/human-resources/quick-prospects/actions/View.vue'
import EmailGenerate from '@/views/prospect-administration/email-generate/EmailGenerate.vue'

export default [
  // *===============================================---*
  // *--------- Prospect Administration -------------------------------*
  // *===============================================---*
  // *------------ Dashobard --------------------*
  {
    path: '/prospect-administration/dashboard',
    name: 'prospect-administration-dashboard',
    component: Dashboard,
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  // *------------ Bulk Upload --------------------*
  {
    path: '/prospect-administration/bulk-upload',
    name: 'prospect-administration-bulk-upload',
    component: BulkUpload,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
  // *------------ Quick Prospects --------------------*
  {
    path: '/prospect-administration/dashboard/quick-prospects/:skillid',
    name: 'prospect-administration-quick-prospects',
    component: QuickProspects,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
  {
    path: '/prospect-administration/dashboard/quick-prospects/:skillid/edit/:id',
    name: 'prospect-administration-quick-prospects-edit',
    component: QuickProspectsEdit,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
  {
    path: '/prospect-administration/dashboard/quick-prospects/:skillid/view/:id',
    name: 'prospect-administration-quick-prospects-view',
    component: QuickProspectsView,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
  // *------------ Email Generate --------------------*
  {
    path: '/prospect-administration/dashboard/email-generator',
    name: 'prospect-administration-email-generator',
    component: EmailGenerate,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
  {
    path: '/prospect-administration/dashboard/email-generator/edit/:id',
    name: 'prospect-administration-email-generator-quick-prospects-edit',
    component: QuickProspectsEdit,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
  {
    path: '/prospect-administration/dashboard/email-generator/view/:id',
    name: 'prospect-administration-email-generator-quick-prospects-view',
    component: QuickProspectsView,
    meta: {
      navActiveLink: 'prospect-administration-dashboard',
    },
  },
]
