var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Add Required Document")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"documentRules",attrs:{"tag":"form"}},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Parent Details")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Parent Type")]),_c('validation-provider',{attrs:{"name":"Parent Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"parent_type_id","options":_vm.types,"reduce":function (item) { return item.parent_type_id; },"clearable":false,"placeholder":"Select"},on:{"input":_vm.onTypeChanged},model:{value:(_vm.addRequiredDocument.parent_type_id),callback:function ($$v) {_vm.$set(_vm.addRequiredDocument, "parent_type_id", $$v)},expression:"addRequiredDocument.parent_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmployee),expression:"isEmployee"}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Workforce Type")]),_c('validation-provider',{attrs:{"name":"Workforce Type","rules":_vm.isEmployee ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"workforce_id","options":_vm.workforce,"reduce":function (item) { return item.parent_type_id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addRequiredDocument.workforce_id),callback:function ($$v) {_vm.$set(_vm.addRequiredDocument, "workforce_id", $$v)},expression:"addRequiredDocument.workforce_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Required Document Details")])]),_c('div',{staticClass:"repeat-border"},_vm._l((_vm.document_types),function(documentType,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Document Type")]),_c('validation-provider',{attrs:{"name":'Document Type' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"options":_vm.documentTypes,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.documentTypeChanged(documentType.document_type_id, index)}},model:{value:(documentType.document_type_id),callback:function ($$v) {_vm.$set(documentType, "document_type_id", $$v)},expression:"documentType.document_type_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Document Type' + index, 'Document Type') : '')}})],1)])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Required Document Names")]),_c('validation-provider',{attrs:{"name":'Required Document Names' + index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"options":documentType.documentNames,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select","multiple":""},on:{"input":function($event){return _vm.requiredDocumentNameChanged(index)}},model:{value:(documentType.required_document_name_ids),callback:function ($$v) {_vm.$set(documentType, "required_document_name_ids", $$v)},expression:"documentType.required_document_name_ids"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Required Document Names' + index, 'Required Document Names') : '')}})],1)])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Optional Document Names")]),_c('validation-provider',{attrs:{"name":'Optional Document Names' + index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"options":documentType.documentNames,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select","multiple":""},on:{"input":function($event){return _vm.optionalDocumentNameChanged(index)}},model:{value:(documentType.optional_document_name_ids),callback:function ($$v) {_vm.$set(documentType, "optional_document_name_ids", $$v)},expression:"documentType.optional_document_name_ids"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Optional Document Names' + index, 'Optional Document Names') : '')}})],1)])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Optional Min No.of")]),_c('validation-provider',{attrs:{"name":'Optional Min' + index,"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(documentType.optional_document_count),callback:function ($$v) {_vm.$set(documentType, "optional_document_count", $$v)},expression:"documentType.optional_document_count"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Optional Min' + index, 'Optional Min') : '')}})]}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"right mt-2 ml-1"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(index === 0 && _vm.documentTypes.length > _vm.document_types.length),expression:"index === 0 && documentTypes.length > document_types.length"}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-primary"},on:{"click":_vm.addNewRequiredDocument}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeRequiredDocument(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])}),0)])],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Save Required Document ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }