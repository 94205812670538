import axios from 'axios'
import moment from 'moment'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const expense = `${HOST}expenses`

export default {
  getExpenses(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    if (context.$store.state.query.month !== null && context.$store.state.query.month !== '') {
      params.params.month = moment(context.$store.state.query.month, 'MMM-YYYY').format('MM')
      params.params.year = moment(context.$store.state.query.month, 'MMM-YYYY').format('YYYY')
    }
    axios.get(expense, params).then(response => {
      context.setExpenses(response.data)
    }, () => {
    })
  },
  addExpense(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(expense, body).then(response => {
      context.$vs.loading.close()
      context.expenseAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.expenseNotAdded(error.response.data.return_message)
    })
  },
  updateExpense(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${expense}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.expenseUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.expenseNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${expense}/${id}`).then(response => {
      context.$vs.loading.close()
      context.expenseDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.expenseNotDeleted(error.response.data.return_message)
    })
  },
  getExpenseById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${expense}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setExpense(response.data)
    }, error => {
      context.$vs.loading.close()
      context.expenseNotFound(error.response.data.return_message)
    })
  },

}
