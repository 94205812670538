<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        variant="primary"
        class="mr-1"
        @click="showAddProspectPopup"
      >
        <span class="align-middle">Quick Prospect</span>
      </b-button>
      <b-button
        variant="primary"
        type="button"
        class="mr-1"
        @click="launchCRM"
      >
        <feather-icon
          icon="UserIcon"
          class="mr-50"
        />
        <span class="align-middle">CRM</span>
      </b-button>
      <b-button
        variant="primary"
        type="button"
        @click="support"
      >
        Support
      </b-button>
      <li
        v-if="$store.state.roles.alerts_exist"
        class="nav-item b-nav-dropdown dropdown dropdown-notification ml-1 mr-25 show"
      >
        <b-link
          :to="{ name: 'financial-statements-alerts' }"
          class="nav-link dropdown-toggle"
        >
          <span class="feather-icon position-relative">
            <feather-icon
              size="21"
              icon="BellIcon"
              class="text-body"
            />
            <span class="badge badge-up badge-pill bg-danger">{{ $store.state.alerts }}</span>
          </span>
        </b-link>
      </li>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-2"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.AppActiveUser.displayName }}
            </p>
            <span class="user-status">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="$store.state.AppActiveUser.photoUrl === null ? require('@/assets/images/avatars/13-small.png') : $store.state.AppActiveUser.photoUrl"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      ref="add_prospect"
      cancel-variant="outline-secondary"
      ok-title="Add"
      centered
      title="Add Quick Prospect"
      ok-only
      :no-close-on-backdrop="true"
      @hide="closingProspectPopup"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group>
            <label for="email">Email:</label>
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="addProspect.email_address"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-alert
            variant="primary"
            :show="prospectExist"
          >
            <h4 class="alert-heading">
              Prospect! Already added.
            </h4>
            <div class="alert-body">
              If modify the details click Edit button!
            </div>
          </b-alert>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button
            v-show="prospectExist"
            variant="primary"
            @click="optOutProspect()"
          >
            Opt Out
          </b-button>
          <b-button
            v-show="prospectExist"
            variant="success"
            class="ml-1"
            @click="viewProspect()"
          >
            Veiw
          </b-button>
          <b-button
            v-show="prospectExist"
            variant="warning"
            class="ml-1"
            @click="editProspect()"
          >
            Edit
          </b-button>
          <b-button
            v-show="!prospectExist"
            variant="primary"
            class="ml-1 px-1"
            @click="submitForm()"
          >
            Add
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BButton, BModal, VBModal, BForm, BFormGroup, BFormInput, BAlert,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import QuickProspect from '@/js/quickProspect'

export default {
  name: 'AppNavBar',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BButton,
    BAlert,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      prospectExist: false,
      prospectId: null,
      addProspect: {
        email_address: '',
      },
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          QuickProspect.getProspectByEmail(this, this.addProspect.email_address)
        }
      })
    },
    optOutProspect() {
      QuickProspect.optOutProspect(this, this.prospectId)
    },
    editProspect() {
      this.$router.push(`/human-resources/quick-prospects/edit/${this.prospectId}`).catch(() => {})
      this.$refs.add_prospect.hide()
    },
    viewProspect() {
      this.$router.push(`/human-resources/quick-prospects/view/${this.prospectId}`).catch(() => {})
      this.$refs.add_prospect.hide()
    },
    setProspectNotExist() {
      this.$store.state.quickProspectEmail = this.addProspect.email_address
      this.$router.push('/human-resources/quick-prospects/new').catch(() => {})
      this.$refs.add_prospect.hide()
    },
    setProspectExist(id) {
      this.prospectId = id
      this.prospectExist = true
    },
    showAddProspectPopup() {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
      this.$refs.add_prospect.show()
    },
    closingProspectPopup() {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
    },
    prospectUpdated(data) {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
      this.$refs.add_prospect.hide()
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    prospectNotUpdated(message) {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
      this.$refs.add_prospect.hide()
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    logout() {
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('userInfo')
      this.$store.state.AppActiveUser = {}
      this.$store.state.AppActiveUser = {}
      this.$store.commit('UPDATE_USER_INFO', {})
      this.$store.commit('HR_LISTS', {})
      this.$store.commit('COMMON_LISTS', {})
      this.$store.commit('AD_LISTS', {})
      this.$store.commit('FA_LISTS', {})
      this.$store.commit('MODULE_LISTS', {})
      this.$store.commit('USER_ROLES', {})
      this.$store.commit('ALERTS_COUNT', {})
      this.$store.commit('UPDATE_COMPANY_NAME', {})
      this.$store.commit('UPDATE_COMPANY_LOGO', {})
      this.$router.push('/login').catch(() => {})
    },
    support() {
      window.open(`http://3.14.82.134/#/pages/generate-token/${this.$store.state.AppActiveUser.support_token}?email=${encodeURIComponent(this.$store.state.AppActiveUser.email)}`, '_blank')
    },
    launchCRM() {
      window.open(`https://crm.snaphr.co/#/launch-crm?token=${this.$store.state.AppActiveUser.access_token}`, '_blank')
    },
  },
}
</script>
