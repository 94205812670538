import moment from 'moment'
import axios from 'axios'

export default {
  hosts: {
    url: 'https://1xg1eluc8f.execute-api.us-east-2.amazonaws.com/prod-all/',
    hrurl: 'https://v0uucq4f96.execute-api.us-east-2.amazonaws.com/prod-hr/hr/',
    faurl: 'https://g8tt5l9crj.execute-api.us-east-2.amazonaws.com/prod-fa/fa/',
    vjmsUrl: 'https://rc3y3vg52i.execute-api.us-east-2.amazonaws.com/eprmvjms/',
    adurl: 'https://ukjvuec0na.execute-api.us-east-2.amazonaws.com/prod-ad/ad/',
    // url: 'https://z7knw4qh29.execute-api.us-east-2.amazonaws.com/all-dev/',
    // hrurl: 'https://6ppfl78pdd.execute-api.us-east-2.amazonaws.com/hr-dev/hr/',
    // faurl: 'https://ipqffe9u83.execute-api.us-east-2.amazonaws.com/fa-dev/fa/',
    // vjmsUrl: 'https://ryc9j665xa.execute-api.us-east-2.amazonaws.com/eprmvjms/',
    // adurl: 'https://sxtd5ikh0f.execute-api.us-east-2.amazonaws.com/ad-dev/ad/',
  },
  EmployerTypeId: 2,
  SubVendorTypeId: 5,
  SupplierTypeId: 6,
  ReferrerTypeId: 9,
  convertObjectToString(obj) {
    return JSON.stringify(obj)
  },
  getIpAddress(context) {
    const axiosInstanceRemote = axios.create({
      baseUrl: '',
    })
    axiosInstanceRemote.get('https://api.ipify.org?format=json', { headers: {} })
      .then(response => {
        context.setIpAddress(response.data.ip)
      })
  },
  getEprmMonthDateFromString(dateStr) {
    const momenta = moment.isMoment(dateStr)
    if (momenta) {
      const obj = { date: dateStr.date(), month: dateStr.month() + 1, year: dateStr.year() }
      return obj
    }
    if (dateStr === '') {
      return null
    }
    const parts = dateStr.split('-')
    const obj = { date: parts[1], month: parts[0], year: parts[2] }
    return obj
  },
  getParentSuccessMessage(message) {
    return `<strong>${message}</strong><br><br>Do you want to add documents.`
  },
  getUploadButtonText() {
    return 'Yes, want to upload!'
  },
  getDateStringFromEprmDate(date) {
    const dateString = `${date.month}-${date.date}-${date.year}`
    return dateString
  },
  getPaymentStatus(minutes) {
    if (typeof minutes === 'undefined' || minutes === null) return 'btn-default'
    return 'btn-primary'
  },
  getW2C2c(context1) {
    const context = context1
    const status = [
      {
        id: 'W2',
        label: 'W2',
      },
      {
        id: 'C2C',
        label: 'C2C',
      },
    ]
    context.w2C2C = status
  },
  getDocumentStatus(context1) {
    const context = context1
    const status = [
      {
        id: 'expired',
        label: 'Expired',
      },
      {
        id: 'ready_expired',
        label: 'Ready To Expire',
      },
    ]
    context.types = status
  },
  getWorkingStatus(context1) {
    const context = context1
    const status = [
      {
        id: 'not-rejected',
        label: 'Not Rejected',
      },
      {
        id: 'rejected',
        label: 'Rejected',
      },
    ]
    context.workingStatus = status
  },

  getMissingTimesheetStatus(context1) {
    const context = context1
    const status = [
      {
        id: '',
        label: 'Not Submitted',
      },
      {
        id: 'rejected',
        label: 'Rejected',
      },
    ]
    context.status = status
  },
  getCaliculatorSalaryTypes(context1) {
    const context = context1
    const salaryTypes = [
      {
        id: 'Yearly',
        label: 'Yearly',
      },
      {
        id: 'Hourly',
        label: 'Hourly',
      },
    ]
    context.salaryTypes = salaryTypes
  },
  getMissingInvoicesStatus(context1) {
    const context = context1
    const status = [
      {
        id: 'notpaid',
        label: 'Not Paid',
      },
      {
        id: 'notdeposited',
        label: 'Not Deposited',
      },
      {
        id: '',
        label: 'Not Submitted',
      },
    ]
    context.status = status
  },
  getAllTypes(context1) {
    const context = context1
    const employerTypes = [
      {
        id: 2,
        label: 'Employer',
      },
      {
        id: 3,
        label: 'Employee',
      },
      {
        id: 4,
        label: 'Vendor',
      },
      {
        id: 5,
        label: 'Sub Vendor',
      },
      {
        id: 6,
        label: 'Supplier',
      },
      {
        id: 9,
        label: 'Referrer',
      },
    ]
    context.types = employerTypes
  },
  getAfterBeforeDays(context1) {
    const context = context1
    const afterBeforeDays = [
      {
        id: '1',
        label: '1',
      },
      {
        id: '2',
        label: '2',
      },
      {
        id: '3',
        label: '3',
      },
      {
        id: '10',
        label: '10',
      },
      {
        id: '11',
        label: '11',
      },
      {
        id: '12',
        label: '12',
      },
      {
        id: '13',
        label: '13',
      },
      {
        id: '14',
        label: '14',
      },
      {
        id: '15',
        label: '15',
      },
      {
        id: '16',
        label: '16',
      },
      {
        id: '17',
        label: '17',
      },
      {
        id: '18',
        label: '18',
      },
      {
        id: '19',
        label: '19',
      },
      {
        id: '20',
        label: '20',
      },
    ]
    context.days = afterBeforeDays
  },
  getUserRoleTypes(context1) {
    const context = context1
    const types = [
      {
        id: 'admin',
        label: 'Admin',
      },
      {
        id: 'internal',
        label: 'Internal',
      },
    ]
    context.types = types
  },
  getBankTransactionTypes(context1) {
    const context = context1
    const types = [
      {
        id: 'all',
        label: 'All',
      },
      {
        id: 'non-reconciled',
        label: 'Non Reconciled',
      },
      {
        id: 'reconciled',
        label: 'Reconciled',
      },
    ]
    context.status = types
  },
  getCreditDebitTypes(context1) {
    const context = context1
    const types = [
      {
        id: 'credit',
        label: 'Credit',
      },
      {
        id: 'debit',
        label: 'Debit',
      },
    ]
    context.creditDebit = types
  },
  getStatusListReturn(context) {
    const context1 = context
    const predefinedList = [
      {
        id: '1',
        label: 'Active',
      },
      {
        id: '2',
        label: 'Revoke',
      },
    ]
    context1.status_list = predefinedList
  },
}
