var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("INCOME FROM SALES OR SERVICES")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Gross Income From Sales or Service")]),_c('validation-provider',{attrs:{"name":"Gross Income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"gross_sales","min":0,"step":0.5},on:{"input":_vm.netSalesChanged},model:{value:(_vm.addStatement.gross_sales),callback:function ($$v) {_vm.$set(_vm.addStatement, "gross_sales", $$v)},expression:"addStatement.gross_sales"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Less Returns and Allowances")]),_c('validation-provider',{attrs:{"name":"Less Returns","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"sales_returns","min":0,"step":0.5},on:{"input":_vm.netSalesChanged},model:{value:(_vm.addStatement.sales_returns),callback:function ($$v) {_vm.$set(_vm.addStatement, "sales_returns", $$v)},expression:"addStatement.sales_returns"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Net Sales")]),_c('validation-provider',{attrs:{"name":"Net Sales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"net_sales","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.net_sales),callback:function ($$v) {_vm.$set(_vm.addStatement, "net_sales", $$v)},expression:"addStatement.net_sales"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("COST OF SALES")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Inventory – Beginning of Period")]),_c('validation-provider',{attrs:{"name":"Beginning Inventory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"beginning_inventory","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.beginning_inventory),callback:function ($$v) {_vm.$set(_vm.addStatement, "beginning_inventory", $$v)},expression:"addStatement.beginning_inventory"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Purchases")]),_c('validation-provider',{attrs:{"name":"Purchases","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"purchases","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.purchases),callback:function ($$v) {_vm.$set(_vm.addStatement, "purchases", $$v)},expression:"addStatement.purchases"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Labor")]),_c('validation-provider',{attrs:{"name":"Labor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"labor","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.labor),callback:function ($$v) {_vm.$set(_vm.addStatement, "labor", $$v)},expression:"addStatement.labor"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Materials and Supplies")]),_c('validation-provider',{attrs:{"name":"Materials and Supplies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"materials_and_supplies","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.materials_and_supplies),callback:function ($$v) {_vm.$set(_vm.addStatement, "materials_and_supplies", $$v)},expression:"addStatement.materials_and_supplies"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Freight-in")]),_c('validation-provider',{attrs:{"name":"Freight-in","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"freight_in","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.freight_in),callback:function ($$v) {_vm.$set(_vm.addStatement, "freight_in", $$v)},expression:"addStatement.freight_in"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("OTHER COSTS OF SALES")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Vendor Expenses (Sub-Contractor)")]),_c('validation-provider',{attrs:{"name":"Vendor Expenses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"vendor_expenses","min":0,"step":0.5},on:{"input":_vm.vendorExpensesChanged},model:{value:(_vm.addStatement.vendor_expenses),callback:function ($$v) {_vm.$set(_vm.addStatement, "vendor_expenses", $$v)},expression:"addStatement.vendor_expenses"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Sub Total")]),_c('validation-provider',{attrs:{"name":"Sub Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"sub_total","min":0,"step":0.5},on:{"input":_vm.grossProfitChanged},model:{value:(_vm.addStatement.sub_total),callback:function ($$v) {_vm.$set(_vm.addStatement, "sub_total", $$v)},expression:"addStatement.sub_total"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Deduct Inventory – End of Period")]),_c('validation-provider',{attrs:{"name":"Deduct Inventory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"deduct_inventory","min":0,"step":0.5},on:{"input":_vm.soldChanged},model:{value:(_vm.addStatement.deduct_inventory),callback:function ($$v) {_vm.$set(_vm.addStatement, "deduct_inventory", $$v)},expression:"addStatement.deduct_inventory"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Cost of Sales")]),_c('validation-provider',{attrs:{"name":"Total Cost of Sales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"cost_of_sales","min":0,"step":0.5},on:{"input":_vm.grossProfitChanged},model:{value:(_vm.addStatement.cost_of_sales),callback:function ($$v) {_vm.$set(_vm.addStatement, "cost_of_sales", $$v)},expression:"addStatement.cost_of_sales"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Gross Profit")]),_c('validation-provider',{attrs:{"name":"Gross Profit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"gross_profit","min":0,"step":0.5},on:{"input":_vm.operatingProfitChanged},model:{value:(_vm.addStatement.gross_profit),callback:function ($$v) {_vm.$set(_vm.addStatement, "gross_profit", $$v)},expression:"addStatement.gross_profit"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Operating Expenses")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Advertising & Marketing")]),_c('validation-provider',{attrs:{"name":"Advertising","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"advertising","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.advertising),callback:function ($$v) {_vm.$set(_vm.addStatement, "advertising", $$v)},expression:"addStatement.advertising"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Amortization")]),_c('validation-provider',{attrs:{"name":"Amortization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"amortization","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.amortization),callback:function ($$v) {_vm.$set(_vm.addStatement, "amortization", $$v)},expression:"addStatement.amortization"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Bad Debts")]),_c('validation-provider',{attrs:{"name":"Bad Debts","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"bad_debts","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.bad_debts),callback:function ($$v) {_vm.$set(_vm.addStatement, "bad_debts", $$v)},expression:"addStatement.bad_debts"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Bank Charges & Fees")]),_c('validation-provider',{attrs:{"name":"Bank Charges","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"bank_charges","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.bank_charges),callback:function ($$v) {_vm.$set(_vm.addStatement, "bank_charges", $$v)},expression:"addStatement.bank_charges"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Charitable Contributions")]),_c('validation-provider',{attrs:{"name":"Charitable Contributions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"charitable_contributions","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.charitable_contributions),callback:function ($$v) {_vm.$set(_vm.addStatement, "charitable_contributions", $$v)},expression:"addStatement.charitable_contributions"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Commissions")]),_c('validation-provider',{attrs:{"name":"Commissions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"commissions","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.commissions),callback:function ($$v) {_vm.$set(_vm.addStatement, "commissions", $$v)},expression:"addStatement.commissions"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Contract Labor")]),_c('validation-provider',{attrs:{"name":"Contract Labor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"contract_labor","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.contract_labor),callback:function ($$v) {_vm.$set(_vm.addStatement, "contract_labor", $$v)},expression:"addStatement.contract_labor"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Expenses")]),_c('validation-provider',{attrs:{"name":"Expenses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"expenses","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.expenses),callback:function ($$v) {_vm.$set(_vm.addStatement, "expenses", $$v)},expression:"addStatement.expenses"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Depreciation")]),_c('validation-provider',{attrs:{"name":"Depreciation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"depreciation","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.depreciation),callback:function ($$v) {_vm.$set(_vm.addStatement, "depreciation", $$v)},expression:"addStatement.depreciation"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Dues and Subscriptions")]),_c('validation-provider',{attrs:{"name":"Dues and Subscriptions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"dues_and_subscriptions","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.dues_and_subscriptions),callback:function ($$v) {_vm.$set(_vm.addStatement, "dues_and_subscriptions", $$v)},expression:"addStatement.dues_and_subscriptions"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Employee Benefit Programs")]),_c('validation-provider',{attrs:{"name":"Employee Benefit Programs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"employee_benefit_programs","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.employee_benefit_programs),callback:function ($$v) {_vm.$set(_vm.addStatement, "employee_benefit_programs", $$v)},expression:"addStatement.employee_benefit_programs"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Insurance")]),_c('validation-provider',{attrs:{"name":"Insurance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"insurance","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.insurance),callback:function ($$v) {_vm.$set(_vm.addStatement, "insurance", $$v)},expression:"addStatement.insurance"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Interest")]),_c('validation-provider',{attrs:{"name":"Interest","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"interest","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.interest),callback:function ($$v) {_vm.$set(_vm.addStatement, "interest", $$v)},expression:"addStatement.interest"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Legal and Professional Fees")]),_c('validation-provider',{attrs:{"name":"Legal and Professional Fees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"legal_and_professional_fees","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.legal_and_professional_fees),callback:function ($$v) {_vm.$set(_vm.addStatement, "legal_and_professional_fees", $$v)},expression:"addStatement.legal_and_professional_fees"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Licenses and Fees")]),_c('validation-provider',{attrs:{"name":"Licenses and Fees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"licenses_and_fees","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.licenses_and_fees),callback:function ($$v) {_vm.$set(_vm.addStatement, "licenses_and_fees", $$v)},expression:"addStatement.licenses_and_fees"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Miscellaneous")]),_c('validation-provider',{attrs:{"name":"Miscellaneous","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"miscellaneous","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.miscellaneous),callback:function ($$v) {_vm.$set(_vm.addStatement, "miscellaneous", $$v)},expression:"addStatement.miscellaneous"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Meals & Entertainment")]),_c('validation-provider',{attrs:{"name":"Meals & Entertainment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"office_expense","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.office_expense),callback:function ($$v) {_vm.$set(_vm.addStatement, "office_expense", $$v)},expression:"addStatement.office_expense"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Payroll Taxes")]),_c('validation-provider',{attrs:{"name":"Payroll Taxes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"payroll_taxes","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.payroll_taxes),callback:function ($$v) {_vm.$set(_vm.addStatement, "payroll_taxes", $$v)},expression:"addStatement.payroll_taxes"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Mailing & Postage")]),_c('validation-provider',{attrs:{"name":"Postage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"postage","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.postage),callback:function ($$v) {_vm.$set(_vm.addStatement, "postage", $$v)},expression:"addStatement.postage"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Rent & Lease")]),_c('validation-provider',{attrs:{"name":"Rent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"rent","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.rent),callback:function ($$v) {_vm.$set(_vm.addStatement, "rent", $$v)},expression:"addStatement.rent"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Repairs and Maintenance")]),_c('validation-provider',{attrs:{"name":"Repairs and Maintenance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"repairs_and_maintenance","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.repairs_and_maintenance),callback:function ($$v) {_vm.$set(_vm.addStatement, "repairs_and_maintenance", $$v)},expression:"addStatement.repairs_and_maintenance"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Office Supplies & Software")]),_c('validation-provider',{attrs:{"name":"Supplies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"supplies","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.supplies),callback:function ($$v) {_vm.$set(_vm.addStatement, "supplies", $$v)},expression:"addStatement.supplies"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Telephone")]),_c('validation-provider',{attrs:{"name":"Telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"telephone","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.telephone),callback:function ($$v) {_vm.$set(_vm.addStatement, "telephone", $$v)},expression:"addStatement.telephone"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Travel, Parking & Tolls")]),_c('validation-provider',{attrs:{"name":"Travel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"travel","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.travel),callback:function ($$v) {_vm.$set(_vm.addStatement, "travel", $$v)},expression:"addStatement.travel"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Utilities")]),_c('validation-provider',{attrs:{"name":"Utilities","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"utilities","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.utilities),callback:function ($$v) {_vm.$set(_vm.addStatement, "utilities", $$v)},expression:"addStatement.utilities"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Vehicle Expenses")]),_c('validation-provider',{attrs:{"name":"Vehicle Expenses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"vehicle_expenses","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.vehicle_expenses),callback:function ($$v) {_vm.$set(_vm.addStatement, "vehicle_expenses", $$v)},expression:"addStatement.vehicle_expenses"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Wages")]),_c('validation-provider',{attrs:{"name":"Wages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"wages","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.wages),callback:function ($$v) {_vm.$set(_vm.addStatement, "wages", $$v)},expression:"addStatement.wages"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Software Development")]),_c('validation-provider',{attrs:{"name":"Software Development","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"software_development","min":0,"step":0.5},on:{"input":_vm.expensesChanged},model:{value:(_vm.addStatement.software_development),callback:function ($$v) {_vm.$set(_vm.addStatement, "software_development", $$v)},expression:"addStatement.software_development"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Operating Expenses")]),_c('validation-provider',{attrs:{"name":"Total Operating Expenses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_operating_expenses","min":0,"step":0.5},on:{"input":_vm.operatingProfitChanged},model:{value:(_vm.addStatement.total_operating_expenses),callback:function ($$v) {_vm.$set(_vm.addStatement, "total_operating_expenses", $$v)},expression:"addStatement.total_operating_expenses"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Operating Profit or Loss")]),_c('validation-provider',{attrs:{"name":"Operating Profit or Loss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"operating_profit","min":0,"step":0.5},on:{"input":_vm.netIncomeChanged},model:{value:(_vm.addStatement.operating_profit),callback:function ($$v) {_vm.$set(_vm.addStatement, "operating_profit", $$v)},expression:"addStatement.operating_profit"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Provision for Income Tax")]),_c('validation-provider',{attrs:{"name":"Provision for Income Tax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"income_tax","min":0,"step":0.5},on:{"input":_vm.netIncomeChanged},model:{value:(_vm.addStatement.income_tax),callback:function ($$v) {_vm.$set(_vm.addStatement, "income_tax", $$v)},expression:"addStatement.income_tax"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Net Income")]),_c('validation-provider',{attrs:{"name":"Net Income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"net_income","min":0,"step":0.5},model:{value:(_vm.addStatement.net_income),callback:function ($$v) {_vm.$set(_vm.addStatement, "net_income", $$v)},expression:"addStatement.net_income"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.create}},[_vm._v(" Create ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","type":"button"},on:{"click":_vm.saveCreate}},[_vm._v(" Save & Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }