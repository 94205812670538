import axios from 'axios'
import Constants from '@/js/constant'
import Lists from '@/js/lists'

const HOST = Constants.hosts.vjmsUrl
const secondarySkill = `${HOST}vendor-job/secondary-skill`

export default {
  getecondarySkills(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(secondarySkill, params).then(response => {
      context.setSecondarySkills(response.data)
    }, () => {
    })
  },
  addSecondarySkill(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(secondarySkill, body).then(response => {
      context.$vs.loading.close()
      const { technologies } = context.$store.state.lists.commonLists.lists
      technologies.push({ id: response.data.id, label: body.skill_name, name: body.skill_name })
      const { commonLists } = context.$store.state.lists
      commonLists.lists.technologies = technologies
      context.$store.commit('COMMON_LISTS', commonLists)
      context.secondarySkillAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.secondarySkillNotAdded(error.response.data.return_message)
    })
  },
  updateSecondarySkill(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${secondarySkill}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.secondarySkillUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.secondarySkillNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${secondarySkill}/${id}`).then(response => {
      context.$vs.loading.close()
      context.secondarySkillDeleted(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.secondarySkillNotDeleted(error.response.data.return_message)
    })
  },
  getSecondarySkillById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${secondarySkill}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setSecondarySkill(response.data)
    }, error => {
      context.$vs.loading.close()
      context.secondarySkillNotFound(error.response.data.return_message)
    })
  },

}
