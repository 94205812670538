<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-1">
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="4"
        >
          <h4 class="card-title mb-0 pt-50">
            E-Signature</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Parent Type"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="parent_type_id"
              v-model="parent_type_id"
              :options="parentTypes"
              :reduce="item => item.parent_type_id"
              :clearable="true"
              placeholder="Select"
              @input="onTypeChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Parent"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="parent_id"
              v-model="parent_id"
              :options="parents"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select"
              @input="parentChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Status"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="status_id"
              v-model="status_id"
              :options="signature_status"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select"
              @input="statusChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="signatrueResponse.esignatures"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- <span
            v-if="props.column.field === 'cellRendererSelectStatus'"
            >
              <b-form-select
                v-model="props.row.status_id"
                :options="signature_status"
                value-field="id"
                text-field="label"
                placeholder="Select"
                @change="onStatusChanged(props.row)"
              />
            </span> -->

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none pl-0"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item v-show="props.row.user_sign_ready" :to="{ name: props.row.template_code === 'msa' ? 'e-signature-msa-sign' : props.row.template_code === 'po' ? 'e-signature-po-sign' : props.row.template_code === 'ol' ? 'e-signature-ol-sign' : props.row.template_code === 'el' ? 'e-signature-employer-letter-sign' : 'e-signature-verification-letter-sign', params: { id: props.row.id } }">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Sign</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-show="props.row.download_available" @click="confirmDownloadRecord(props.row)">
                    <feather-icon icon="ArrowDownCircleIcon" />
                    <span class="ml-50">Download</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-show="props.row.expired" @click="confirmResendRecord(props.row)">
                    <feather-icon icon="SendIcon" />
                    <span class="ml-50">Resend</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-link
                  id="view"
                  :to="{ name: props.row.template_code === 'msa' ? 'e-signature-msa-view' : props.row.template_code === 'po' ? 'e-signature-po-view' : props.row.template_code === 'ol' ? 'e-signature-offer-letter-view' : props.row.template_code === 'el' ? 'e-signature-employer-letter-view' : props.row.template_code === 'evl' ? 'e-signature-verification-letter-view' : 'e-signature-view', params: { id: props.row.id } }"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="signatrueResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Esignatures from '@/js/esignatures'
import LIST from '@/js/lists'

export default {
  name: 'E-Signature',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      parent_type_id: null,
      parent_id: null,
      status_id: null,
      parentTypes: [],
      parents: [],
      status: [],
      signature_status: [],
      dir: false,
      signatrueResponse: {
        e_signatures: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Document Name',
          field: 'document_name',
          sortable: false,
        },
        {
          label: 'Document Type',
          field: 'template_type',
          sortable: false,
        },
        {
          label: 'Parent Type',
          field: 'parent_type',
          sortable: false,
        },
        {
          label: 'Sent Date',
          field: 'sent_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Receiever',
          field: 'receiver',
          sortable: false,
        },
        {
          label: 'Receiver Signed Date',
          field: 'signed_date',
          width: '125px',
          sortable: false,
        },
        {
          label: 'Valid For',
          field: 'valid_for',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Esignatures.getESignatures(this, this.parent_type_id, this.parent_id, this.status_id)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getESignatureStatus(this)
        LIST.getHRDocumentParentTypes(this)
      },
    },
    '$store.state.lists.hrLists.response': {
      immediate: true,
      handler() {
        this.onTypeChangedByWatch()
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.signatrueResponse.count = this.$store.state.query.page
    this.parent_type_id = this.$store.state.query.parent_type_id
    this.parent_id = this.$store.state.query.parent_id
    this.status_id = this.$store.state.query.status_id
    Esignatures.getESignatures(this, this.parent_type_id, this.parent_id, this.status_id)
    LIST.getESignatureStatus(this)
    LIST.getHRDocumentParentTypes(this)
    this.onTypeChangedByWatch()
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.receiver} ${obj.document_name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Esignatures.delete(this, obj.id)
        }
      })
    },
    onStatusChanged(esignature) {
      const updateStatus = this.signature_status.find(status => status.id === esignature.status_id)
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure want to change this Status to ${updateStatus.label}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Esignatures.toggleStatus(this, esignature.id, esignature.status_id)
        }
      })
    },
    confirmDownloadRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to download this ${obj.receiver} ${obj.document_name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, download it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Esignatures.downloadEsignDoc(this, obj.id)
        }
      })
    },
    confirmResendRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to resend this ${obj.receiver} ${obj.document_name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, resend it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Esignatures.getESignatureResendById(this, obj.id)
        }
      })
    },
    updateError(message) {
      this.$swal({
        icon: 'warning',
        title: 'E-Signature Not Updated!',
        text: message,
      })
    },
    updated(message) {
      Esignatures.getESignatures(this, this.parent_type_id, this.parent_id, this.status_id)
      this.$swal({
        icon: 'success',
        title: 'E-Signature Updated!',
        text: message.return_message,
      })
    },
    setPartners(parentTypes) {
      this.parentTypes = parentTypes
    },
    setEmployers(vendors) {
      this.parents = vendors
    },
    setEndClients(endClients) {
      this.parents = endClients
    },
    setSuppliers(vendors) {
      this.parents = vendors
    },
    setReferrers(referrers) {
      this.parents = referrers
    },
    setSubVendors(vendors) {
      this.parents = vendors
    },
    setVendors(vendors) {
      this.parents = vendors
    },
    setEmployees(employees) {
      this.parents = employees
    },
    setESignatureResentSuccess(data) {
      Esignatures.getESignatures(this, this.parent_type_id, this.parent_id, this.status_id)
      this.$swal({
        icon: 'success',
        title: 'E-Signature Resend Success!',
        text: data.return_message,
      })
    },
    setESignatureResentFailure(message) {
      this.$swal({
        icon: 'warning',
        title: 'E-Signature Resend Failure!',
        text: message,
      })
    },
    signatureDeleted(data) {
      Esignatures.getESignatures(this, this.parent_type_id, this.parent_id, this.status_id)
      this.$swal({
        icon: 'success',
        title: 'E-Signature Deleted!',
        text: data.return_message,
      })
    },
    signatureNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'E-Signature Not Deleted!',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setESignatures(signatrueResponse) {
      this.signatrueResponse = signatrueResponse
    },
    setSignatureStatus(sgnatureStatus) {
      this.signature_status = sgnatureStatus
    },
    setDocumentDownloaded(data) {
      window.open(data.pdf_path, '_blank')
    },
    setDocumentNotDownloaded(message) {
      this.$swal({
        icon: 'warning',
        title: 'Not Found!',
        text: message,
      })
    },
    onTypeChangedByWatch() {
      if (this.parent_type_id === null) {
        return
      }
      const employerType = this.parentTypes.find(type => type.parent_type_id === this.parent_type_id)
      switch (employerType.id) {
        case 'employer':
          LIST.getEmployersFullList(this)
          break
        case 'vendors':
          LIST.getVendorsFullList(this)
          break
        case 'suppliers':
          LIST.getSuppliersFullList(this)
          break
        case 'referrer':
          LIST.getReferrersFullList(this)
          break
        case 'subvendor':
          LIST.getSubVendorsFullList(this)
          break
        case 'employee':
          LIST.getEmployeeFullList(this)
          break
        case 'endclient':
          LIST.getEndClientsFullList(this)
          break
        default:
          break
      }
    },
    onTypeChanged() {
      this.parent_id = null
      this.$store.state.query.parent_id = null
      if (this.parent_type_id === null) {
        this.$store.state.query.parent_type_id = null
        this.parents = []
        this.currentPage = 1
        return
      }
      const employerType = this.parentTypes.find(type => type.parent_type_id === this.parent_type_id)
      switch (employerType.id) {
        case 'employer':
          LIST.getEmployersFullList(this)
          break
        case 'vendors':
          LIST.getVendorsFullList(this)
          break
        case 'suppliers':
          LIST.getSuppliersFullList(this)
          break
        case 'referrer':
          LIST.getReferrersFullList(this)
          break
        case 'subvendor':
          LIST.getSubVendorsFullList(this)
          break
        case 'employee':
          LIST.getEmployeeFullList(this)
          break
        case 'endclient':
          LIST.getEndClientsFullList(this)
          break
        default:
          break
      }
      this.$store.state.query.parent_type_id = this.parent_type_id
      this.currentPage = 1
    },
    statusChanged() {
      this.$store.state.query.status_id = this.status_id
      this.currentPage = 1
    },
    parentChanged() {
      this.$store.state.query.parent_id = this.parent_id
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
