<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Main Skill</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Main Skill</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Skill Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Skill"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addSkill.skill_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Secondary Skills</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="option in skills"
                :key="option.id"
                :value="option.id"
                cols="3"
                class="col-xxl-2 mb-2"
              >
                <b-form-checkbox
                  :checked="skillSelected(option.id)"
                  @input="checked(option.id)"
                >
                  {{ option.label }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Skill
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import MainSkill from '@/js/mainskill'

export default {
  name: 'MainSkillEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
  },
  directives: {
  },
  data() {
    return {
      required,
      addSkill: {
        skill_name: '',
        secondary_skill_ids: [],
      },
      skills: [],
    }
  },
  created() {
    MainSkill.getMainSkillById(this, this.$route.params.id)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          MainSkill.updateMainSkill(this, this.$route.params.id, this.addSkill)
        }
      })
    },
    checked(id) {
      if (this.addSkill.secondary_skill_ids.includes(id)) {
        this.addSkill.secondary_skill_ids.splice(this.addSkill.secondary_skill_ids.indexOf(id), 1)
      } else {
        this.addSkill.secondary_skill_ids.push(id)
      }
    },
    setMainSkill(mainSkill) {
      this.addSkill.skill_name = mainSkill.skill_name
      this.addSkill.secondary_skill_ids = mainSkill.secondary_skill_ids
      this.skills = mainSkill.skills
    },
    mainSkillUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    mainSkillNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    skillSelected(id) {
      return this.addSkill.secondary_skill_ids.includes(id)
    },
    back() {
      this.$router.push('/vendor-jobs-management/main-skills').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
</style>
