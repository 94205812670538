<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Advanceed Search
        </h5>
      </b-card-header>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-card-body>
            <b-form-group>
              <label>Role</label>
              <v-select
                v-model="request.role_id"
                :options="roles"
                class="w-100"
                :reduce="item => item.id"
                @input="roleSlected()"
              />
            </b-form-group>
            <b-form-group>
              <label>Visa</label>
              <v-select
                v-model="request.visa_type_id"
                :options="visaTypes"
                class="w-100"
                :reduce="item => item.id"
              />
            </b-form-group>
          </b-card-body>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-card-body class="invoice-padding form-item-section">
            <div
              ref="form"
              class="repeater-form"
            >
              <b-row
                ref="row"
                class="pb-2"
              >
                <b-col cols="12">
                  <div class="d-lg-flex">
                    <b-row class="flex-grow-1 px-1">
                      <b-col
                        cols="12"
                        lg="5"
                      >
                        Technology
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        Experience ( >= )
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        Rating ( >= )
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border rounded pb-2">
                    <b-row
                      v-for="(technology, index) in request.technologies"
                      :key="index"
                      :value="index"
                      class="flex-grow-1 pt-2 pl-2 pt-2"
                    >
                      <b-col
                        cols="12"
                        lg="5"
                      >
                        {{ technology.label }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        <money
                          id="experience"
                          v-model="technology.experience"
                          :min="0"
                          :step="0.5"
                          v-bind="money1"
                          class="form-control"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        <money
                          id="rating"
                          v-model="technology.rating"
                          :min="0"
                          :step="0.5"
                          v-bind="money1"
                          class="form-control"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="search"
            >
              Search
            </b-button>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <h4 class="card-title mb-0 pt-50">
              Candidates</h4>
          </b-col>
          <b-col
            cols="12"
            md="6 ml-auto"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-primary"
                class="ml-2 w-20"
                @click="back"
              >
                Back
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refAdvanceSearch"
        class="position-relative"
        :items="prospectsResponse.prospects"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No Data Available"
      >

        <template #cell(actions)="data">
          <b-link
            id="view"
            :to="{ name: 'human-resources-advanced-search-view', params: { id: data.item.id } }"
          >
            <b-badge
              pill
              variant="light-primary"
            ><feather-icon icon="EyeIcon" />
            </b-badge>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="prospectsResponse.count"
              :per-page="1"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BTable, BPagination, BFormGroup, BButton, BLink, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import LIST from '@/js/lists'
import Technology from '@/js/skillstechnologies'
import Prospects from '@/js/prospects'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AdvancedSearch',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormGroup,
    BButton,
    BLink,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      experience: '',
      rating: '',
      roles: [],
      money1: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 1,
        masked: false,
      },
      items: [],
      tableColumns: [
        {
          key: 'index', label: '#',
        },
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'email_addreess', label: 'Email',
        },
        {
          key: 'phone_number', label: 'Phone',
        },
        {
          key: 'actions', label: 'Actions',
        },
      ],
      request: {
        role_id: null,
        visa_type_id: null,
        technologies: [],
      },
      skills: [],
      prospectsResponse: {
        count: 1,
      },
      visaTypes: [],
      title: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Prospects.getProspectsAdvancedSearch(this, this.request)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getSkillsFullList(this)
        LIST.getVisaFullList(this)
      },
    },
  },
  created() {
    LIST.getSkillsFullList(this)
    LIST.getVisaFullList(this)
  },
  methods: {
    search() {
      Prospects.getProspectsAdvancedSearch(this, this.request)
    },
    roleSlected() {
      if (this.request.role_id !== null) {
        Technology.getTechnologiesBySkillId(this, this.request.role_id)
      } else {
        this.request.technologies = []
      }
    },
    setTechnologies(technologies) {
      this.request.technologies = technologies
      for (let i = 0; i < this.request.technologies.length; i += 1) {
        this.request.technologies[i].experience = 0.0
        this.request.technologies[i].rating = 0.0
      }
    },
    setRoles(roles) {
      this.roles = roles
    },
    setSkills(skills) {
      this.roles = skills
    },
    setProspects(prospectsResponse) {
      this.prospectsResponse = prospectsResponse
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    setStatus(status) {
      this.status = status
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.form-item-section {
    background-color: #fcfcfc;
}
</style>
