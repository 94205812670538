<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Consultants By Supplier</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="invoice-preview">
      <b-col
        cols="12"
        class="col-xxl-10 offset-xxl-1"
      >
        <b-card-body class="px-0">
          <b-card
            v-for="(parent, index) in data.parents"
            :key="index"
            class="border"
            no-body
          >
            <b-card-body class="p-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle="'collapse-1-inner' + index"
                size="sm"
                variant="flat-secondary"
                class="btn-collapse font-medium-2 d-block w-100 text-left"
                @click="selectedIndex = index"
              >
                {{ parent.name }}
              </b-button>
              <b-collapse
                v-if="selectedIndex == index"
                :id="'collapse-1-inner' + index"
                class="mt-2"
              >
                <b-card class="custom-body-padding mb-0">
                  <div class="table-responsive mb-0">
                    <table class="table b-table table-bordered custom-reports-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Consultant Name</th>
                          <th class="min-w-120">
                            Start Date</th>
                          <th class="min-w-120">
                            End Date</th>
                          <th>Status</th>
                          <th>Discount<br>VE/EM/SV/SU/RE</th>
                          <th>
                            <span>Pay Rate<br>VE/EM/SV/SU/RE</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(employee, index1) in parent.parent_employees"
                          :key="index1"
                        >
                          <th>{{ ++index1 }}</th>
                          <td>{{ employee.name }}</td>
                          <td>{{ employee.start_date }}</td>
                          <td>{{ employee.end_date }}</td>
                          <td>
                            <b-badge variant="light-primary">
                              {{ employee.status }}
                            </b-badge>
                          </td>
                          <td class="text-center">
                            {{ employee.vendor_discount | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.employer_discount | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.subvendor_discount | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.supplier_discount | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.referrer_discount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                          </td>
                          <td class="text-right">
                            {{ employee.vendor_pay_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.employer_pay_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.subvendor_pay_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.supplier_pay_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }} / {{ employee.referrer_pay_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                          </td>
                        </tr>
                        <tr v-if="parent.parent_employees.length == 0">
                          <td
                            colspan="7"
                            class="text-center"
                          >
                            No Employees Available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BCardBody, BCollapse, VBToggle, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Reports from '@/js/reports'

export default {
  name: 'ConsultantsBySupplier',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardBody,
    BCollapse,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      data: {},
      selectedIndex: '',
    }
  },
  mounted() {
  },
  created() {
    Reports.getConsultantsBySupplier(this)
  },
  destroyed() {
  },
  methods: {
    setResponse(data) {
      this.data = data
    },
    back() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
