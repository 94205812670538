var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Add Exception")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Parent Type")]),_c('validation-provider',{attrs:{"name":"Parent Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"parent_type","options":_vm.types,"reduce":function (item) { return item.parent_type_id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.onTypeChanged()}},model:{value:(_vm.addException.parent_type),callback:function ($$v) {_vm.$set(_vm.addException, "parent_type", $$v)},expression:"addException.parent_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Parent")]),_c('validation-provider',{attrs:{"name":"Parent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"parent_id","options":_vm.employees,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addException.parent_id),callback:function ($$v) {_vm.$set(_vm.addException, "parent_id", $$v)},expression:"addException.parent_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Parent Level")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"mt-50",attrs:{"id":"is_parent_level"},on:{"change":_vm.checkYes},model:{value:(_vm.addException.is_parent_level),callback:function ($$v) {_vm.$set(_vm.addException, "is_parent_level", $$v)},expression:"addException.is_parent_level"}},[_vm._v(" Yes ")]),_c('b-form-checkbox',{staticClass:"mt-50",attrs:{"id":"parent_level_no"},on:{"change":_vm.checkNo},model:{value:(_vm.addException.parent_level_no),callback:function ($$v) {_vm.$set(_vm.addException, "parent_level_no", $$v)},expression:"addException.parent_level_no"}},[_vm._v(" No ")])],1)])],1),(!_vm.addException.is_parent_level)?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"id":"month_year"}},[_c('label',[_vm._v("Select Month/Year")]),_c('validation-provider',{attrs:{"name":"Select Month/Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.monthConfig,"placeholder":"MM-YYYY"},model:{value:(_vm.month_year),callback:function ($$v) {_vm.month_year=$$v},expression:"month_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3379924698)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-checkbox',{attrs:{"id":"is_bypass_invoice"},model:{value:(_vm.addException.is_bypass_invoice),callback:function ($$v) {_vm.$set(_vm.addException, "is_bypass_invoice", $$v)},expression:"addException.is_bypass_invoice"}},[_vm._v(" Bypass Invoice ")])],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-checkbox',{attrs:{"id":"is_bypass_timesheet"},model:{value:(_vm.addException.is_bypass_timesheet),callback:function ($$v) {_vm.$set(_vm.addException, "is_bypass_timesheet", $$v)},expression:"addException.is_bypass_timesheet"}},[_vm._v(" Bypass Timesheet ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Exception Reason")]),_c('validation-provider',{attrs:{"name":"Exception Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"reason","rows":"2"},model:{value:(_vm.addException.reason),callback:function ($$v) {_vm.$set(_vm.addException, "reason", $$v)},expression:"addException.reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.addException.user_defined_fields.length !== 0),expression:"addException.user_defined_fields.length !== 0"}],staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("User Defined Fields")])]),_c('b-row',_vm._l((_vm.addException.user_defined_fields),function(field){return _c('b-col',{key:field.id,attrs:{"id":field.id,"cols":"12","md":"6","lg":"4"}},[(field.type.toLowerCase() === 'input')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'dropdown')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":field.options,"label":"option","reduce":function (item) { return item.option; },"clearable":!field.required,"placeholder":"Select"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'textarea')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Add Exception ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }