import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const supplier = `${HOST}supplier`

export default {
  getSuppliers(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(supplier, params).then(response => {
      context.setSuppliers(response.data)
    }, () => {
    })
  },
  addSupplier(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(supplier, body).then(response => {
      context.$vs.loading.close()
      context.supplierAdded(response.data)
      context.$store.state.lists.commonLists.lists.suppliers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.supplierNotAdded(error.response.data.return_message)
    })
  },
  updateSupplier(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${supplier}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.supplierUpdated(response.data)
      context.$store.state.lists.commonLists.lists.suppliers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.supplierNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${supplier}/${id}`).then(response => {
      context.$vs.loading.close()
      context.parentDeleted(response.data)
      context.$store.state.lists.commonLists.lists.suppliers = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.parentNotDeleted(error.response.data.return_message)
    })
  },
  getSupplierById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${supplier}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setSupplier(response.data)
    }, error => {
      context.$vs.loading.close()
      context.supplierNotFound(error.response.data.return_message)
    })
  },

}
