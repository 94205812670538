import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const candidate = `${HOST}job-cadidates`

export default {
  getCandidates(context1, jobId, page) {
    const context = context1
    const params = {
      params: {
        type_id: context.employer_type_id, user_id: context.employer_id, status_id: context.status_id, query: context.searchQuery, page: page - 1,
      },
    }
    axios.get(`${candidate}/${jobId}/candidates`, params).then(response => {
      context.setCandidates(response.data)
    }, () => {
    })
  },
  addCandidate(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(candidate, body).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },
  applyCandidateToJobs(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${candidate}/apply/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },
  applyProspectToJobs(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${candidate}/apply/prospect/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },
  updateCandidate(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${candidate}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.candidateUpdated(response.data)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.candidateNotUpdated(error.response.data.return_message)
    })
  },
  updateCandidateTask(context1, id, body, index) {
    const context = context1
    context.$vs.loading()
    axios.put(`${candidate}/canidate-task/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.candidateTaskUpdated(response.data, index)
    }, error => {
      context.$vs.loading.close()
      context.candidateTaskNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${candidate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.candidateDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotDeleted(error.response.data.return_message)
    })
  },
  getCandidateById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${candidate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setCandidate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotFound(error.response.data.return_message)
    })
  },
  scheduleInterview(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${candidate}/schedule-interview/${id}`, data).then(response => {
      context.$vs.loading.close()
      context.scheduleAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.scheduleNotAdded(error.response.data.return_message)
    })
  },
  endProcessByJobCandidateId(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.put(`${candidate}/end-process/${id}`, data).then(response => {
      context.$vs.loading.close()
      context.endProcessUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.endProcessNotUpdated(error.response.data.return_message)
    })
  },
  addExistingusers(context1, jobId, typeId, employerId, userIds, Experience, submittedDate) {
    const request = {
      submitteddate: submittedDate, parent_type_id: typeId, parent_id: employerId, user_ids: userIds, experience: Experience,
    }
    const context = context1
    context.$vs.loading()
    axios.post(`${candidate}/${jobId}`, request).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },

}
