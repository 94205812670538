<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Required Document</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Parent Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="requiredDocument.parent_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
            v-show="requiredDocument.workforce !== null"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="requiredDocument.workforce"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Required Document Details</h6>
        </div>

        <div class="repeat-border">
          <div
            class="d-flex"
          >
            <div class="left w-100">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <dl class="row">
                    <dt class="col-sm-12 dt-text">
                      Document Type
                    </dt>
                    <dd class="col-sm-12">
                      <span
                        class="form-control h-auto"
                        v-text="requiredDocument.document_type"
                      />
                    </dd>
                  </dl>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group>
                    <label>Required Document Names</label>
                    <span class="form-control height-auto min-h-38">
                      <b-badge
                        v-for="(required_document_name, fieldIndex) in requiredDocument.required_document_names"
                        :key="fieldIndex"
                        :value="fieldIndex"
                        variant="light-primary"
                        class="mr-50 my-25"
                      >
                        {{ required_document_name }}
                      </b-badge>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group>
                    <label>Optional Document Names</label>
                    <span class="form-control height-auto min-h-38">
                      <b-badge
                        v-for="(optional_document_name, fieldIndex) in requiredDocument.optional_document_names"
                        :key="fieldIndex"
                        :value="fieldIndex"
                        variant="light-primary"
                        class="mr-50 my-25"
                      >
                        {{ optional_document_name }}
                      </b-badge>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <dl class="row">
                    <dt class="col-sm-12 dt-text">
                      Optional Min No.of
                    </dt>
                    <dd class="col-sm-12">
                      <span
                        class="form-control h-auto"
                        v-text="requiredDocument.optional_document_count"
                      />
                    </dd>
                  </dl>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </app-timeline-item>

    </app-timeline>
    <hr>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BFormGroup,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ParentDocumentrequired from '@/js/parentDocumentrequired'

export default {
  name: 'DocumentRequiredView',
  components: {
    BCard,
    AppTimeline,
    BButton,
    BBadge,
    BFormGroup,
    AppTimelineItem,
    BRow,
    BCol,
    // currency,
  },
  data() {
    return {
      requiredDocument: { },
      documentNames: [],
    }
  },
  created() {
    ParentDocumentrequired.getDocumentRequiredById(this, this.$route.params.id)
  },
  methods: {
    setDocumentRequired(requiredDocument) {
      this.requiredDocument = requiredDocument
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
